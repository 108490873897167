/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  DateWidget,
  setValue,
  getValue,
  disable,
  hide,
  show,
  getData,
  setData,
  enable,
  goTo,
} from "../../shared/WindowImports";

import "./FV95Search.scss";

// START_USER_CODE-USER_IMPORTS
import * as XLSX from 'xlsx/xlsx.mjs';
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"
import jsPDF from 'jspdf';
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../../Settlements/Service/SettlementService"
import { useDropzone } from "react-dropzone";
import ModalPopUp from "react-bootstrap/Modal";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_FV95Search(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FV95Search",
    windowName: "FV95Search",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.FV95Search",
    horizontalForm: true,
    // START_USER_CODE-USER_FV95Search_PROPERTIES
    headerData: {
      scrName: "SC95/1007 Inquiry",
      scrCode: "PN1060A",
    },
    // END_USER_CODE-USER_FV95Search_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn2_PROPERTIES

      // END_USER_CODE-USER_btn2_PROPERTIES
    },
    btn3: {
      name: "btn3",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn3_PROPERTIES

      // END_USER_CODE-USER_btn3_PROPERTIES
    },
    btn4: {
      name: "btn4",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn4_PROPERTIES

      // END_USER_CODE-USER_btn4_PROPERTIES
    },
    btnAuthorizeApplicationEdit: {
      name: "btnAuthorizeApplicationEdit",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "Authorize Application Edit",
      CharWidth: "52",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAuthorizeApplicationEdit_PROPERTIES

      // END_USER_CODE-USER_btnAuthorizeApplicationEdit_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCreateManualSC95: {
      name: "btnCreateManualSC95",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "Create Manual SC95",
      CharWidth: "38",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateManualSC95_PROPERTIES

      // END_USER_CODE-USER_btnCreateManualSC95_PROPERTIES
    },
    btnDeleteSC95: {
      name: "btnDeleteSC95",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "Delete SC95",
      CharWidth: "25",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeleteSC95_PROPERTIES

      // END_USER_CODE-USER_btnDeleteSC95_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnKCMOdataTransmitted: {
      name: "btnKCMOdataTransmitted",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "KCMO data Transmitted",
      CharWidth: "44",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnKCMOdataTransmitted_PROPERTIES

      // END_USER_CODE-USER_btnKCMOdataTransmitted_PROPERTIES
    },
    btnOpenSC95: {
      name: "btnOpenSC95",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Open SC95",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOpenSC95_PROPERTIES

      // END_USER_CODE-USER_btnOpenSC95_PROPERTIES
    },
    btnOpen1007: {
      name: "btnOpen1007",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Open 1007",
      CharWidth: "33",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_ Open1007_PROPERTIES 

      // END_USER_CODE-USER_ Open1007_PROPERTIES 
    },
    btnPrintSearch: {
      name: "btnPrintSearch",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "Print Search",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintSearch_PROPERTIES

      // END_USER_CODE-USER_btnPrintSearch_PROPERTIES
    },
    btnPrintWorkSheet: {
      name: "btnPrintWorkSheet",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Print Worksheet",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintWorkSheet_PROPERTIES

      // END_USER_CODE-USER_btnPrintWorkSheet_PROPERTIES
    },
    btnReprint: {
      name: "btnReprint",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Reprint",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReprint_PROPERTIES

      // END_USER_CODE-USER_btnReprint_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnSignSettlements: {
      name: "btnSignSettlements",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Sign Settlements",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSignSettlements_PROPERTIES

      // END_USER_CODE-USER_btnSignSettlements_PROPERTIES
    },
    btnTransferSC95File: {
      name: "btnTransferSC95File",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "Transfer SC95 File",
      CharWidth: "38",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnTransferSC95File_PROPERTIES

      // END_USER_CODE-USER_btnTransferSC95File_PROPERTIES
    },
    btnUnSignSettlements: {
      name: "btnUnSignSettlements",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "UnSign Settlements",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUnSignSettlements_PROPERTIES

      // END_USER_CODE-USER_btnUnSignSettlements_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxNumber",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    txtVndor: {
      name: "txtVndor",
      type: "TextBoxWidget",
      parent: "grpbxLocation",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Enabled: false,
      // START_USER_CODE-USER_txtVndor_PROPERTIES

      // END_USER_CODE-USER_txtVndor_PROPERTIES
    },
    btnViewSettlements: {
      name: "btnViewSettlements",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "View Settlements",
      CharWidth: "33",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewSettlements_PROPERTIES

      // END_USER_CODE-USER_btnViewSettlements_PROPERTIES
    },
    btnViewTransferFailures: {
      name: "btnViewTransferFailures",
      type: "ButtonWidget",
      parent: "grpbxAcions",
      Label: "View Transfer Failures",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewTransferFailures_PROPERTIES

      // END_USER_CODE-USER_btnViewTransferFailures_PROPERTIES
    },
    chkboxAll1007s: {
      name: "chkboxAll1007s",
      type: "CheckBoxWidget",
      parent: "grpbxMiscOpt",
      Label: "All 1007s",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAll1007s_PROPERTIES

      // END_USER_CODE-USER_chkboxAll1007s_PROPERTIES
    },
    chkboxIncludeResidueLoads: {
      name: "chkboxIncludeResidueLoads",
      type: "CheckBoxWidget",
      parent: "grpbxMiscOpt",
      Label: "Include Residue Loads",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxIncludeResidueLoads_PROPERTIES

      // END_USER_CODE-USER_chkboxIncludeResidueLoads_PROPERTIES
    },
    chkboxPercentOnly: {
      name: "chkboxPercentOnly",
      type: "CheckBoxWidget",
      parent: "grpbxNumber",
      Label: "% Only",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxPercentOnly_PROPERTIES

      // END_USER_CODE-USER_chkboxPercentOnly_PROPERTIES
    },
    chkbx1007: {
      name: "chkbx1007",
      type: "CheckBoxWidget",
      parent: "gridFV95SearchData",
      colName: "chkbx1007Col",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxPercentOnly_PROPERTIES
      isMultiSelectAlternate: true
      // END_USER_CODE-USER_chkboxPercentOnly_PROPERTIES
    },
    chkbx1007Col: {
      name: "chkbx1007Col",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Height: "",
      Width: "",
      HasLabel: false
      // START_USER_CODE-USER_txtColSC95_PROPERTIES

      // END_USER_CODE-USER_txtColSC95_PROPERTIES
    },
    ddArea: {
      name: "ddArea",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Area:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddArea_PROPERTIES

      // END_USER_CODE-USER_ddArea_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Buying Point:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES
      optionMaxLength: 35,
      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddCollectionPoint: {
      name: "ddCollectionPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Coll Pt:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCollectionPoint_PROPERTIES

      // END_USER_CODE-USER_ddCollectionPoint_PROPERTIES
    },
    ddContractType: {
      name: "ddContractType",
      type: "DropDownFieldWidget",
      parent: "grpbxType",
      Label: "Contract Type:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddContractType_PROPERTIES

      // END_USER_CODE-USER_ddContractType_PROPERTIES
    },
    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxFarm",
      Label: "County:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddDisposition: {
      name: "ddDisposition",
      type: "DropDownFieldWidget",
      parent: "grpbxType",
      Label: "Disposition:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      optionMaxLength: 23,
      // START_USER_CODE-USER_ddDisposition_PROPERTIES

      // END_USER_CODE-USER_ddDisposition_PROPERTIES
    },
    ddFarm: {
      name: "ddFarm",
      type: "DropDownFieldWidget",
      parent: "grpbxFarm",
      Label: "Farm:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarm_PROPERTIES

      // END_USER_CODE-USER_ddFarm_PROPERTIES
    },
    ddHighMoist: {
      name: "ddHighMoist",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanut",
      Label: "High Moist:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddHighMoist_PROPERTIES

      // END_USER_CODE-USER_ddHighMoist_PROPERTIES
    },
    ddObligation: {
      name: "ddObligation",
      type: "DropDownFieldWidget",
      parent: "grpbxWhseBin",
      Label: "Obligation:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddObligation_PROPERTIES
      optionMaxLength: 23,
      // END_USER_CODE-USER_ddObligation_PROPERTIES
    },
    ddObligationPoint: {
      name: "ddObligationPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxLocation",
      Label: "Obligation Point:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddObligationPoint_PROPERTIES

      // END_USER_CODE-USER_ddObligationPoint_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanut",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddOrganic: {
      name: "ddOrganic",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanut",
      Label: "Organic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOrganic_PROPERTIES

      // END_USER_CODE-USER_ddOrganic_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanut",
      Label: "Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddPeanutVariety: {
      name: "ddPeanutVariety",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanut",
      Label: "Variety:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_ddPeanutVariety_PROPERTIES
    },
    ddReceipted: {
      name: "ddReceipted",
      type: "DropDownFieldWidget",
      parent: "grpbxType",
      Label: "Receipted:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceipted_PROPERTIES

      // END_USER_CODE-USER_ddReceipted_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanut",
      Label: "Seed:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSeg: {
      name: "ddSeg",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanut",
      Label: "Seg:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeg_PROPERTIES

      // END_USER_CODE-USER_ddSeg_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxFarm",
      Label: "State:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    ddStatus: {
      name: "ddStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxType",
      Label: "Status:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddStatus_PROPERTIES

      // END_USER_CODE-USER_ddStatus_PROPERTIES
    },
    ddUnload: {
      name: "ddUnload",
      type: "DropDownFieldWidget",
      parent: "grpbxWhseBin",
      Label: "Unload:",
      ColSpan: "5",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddUnload_PROPERTIES
      optionMaxLength: 23,
      // END_USER_CODE-USER_ddUnload_PROPERTIES
    },
    gridFV95SearchData: {
      name: "gridFV95SearchData",
      type: "GridWidget",
      parent: "grpbxSearchResults",
      gridCellsOrder:
        //chkbx1007,
        "chkbx1007,txtSC95C,txt1007C,txtStatusC,txtFarmC,txtOriginPoint,txtObligationPoint,txtPeanutType,txtVariety,txtGen,txtSegC,txtNetWeight,txtValue,txtContractC,txtReceipt,txtDACOC,txtInspectionDate",
      //"txtStatusHidden,txtBuyingPoint,txtReprint,txtOneDocBP",//hidden columns
      ColSpan: "9",
      Pagination: false,
      HasLabel: false,
      Cols: "5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridFV95SearchData_PROPERTIES
      clientPagination: true,
      DisplaySize: 10,
      expandedRows: [],
      pushExpandedRows: (isExpanded, row) => {
        if (isExpanded) {
          states.gridFV95SearchData.expandedRows.push(row)
        }
        else {
          states.gridFV95SearchData.expandedRows = states.gridFV95SearchData.expandedRows.filter(rows => rows.rowID$ !== row.rowID$)
        }
      },
      isEditable: true,
      isExpandable: true,
      defaultExpand: false,
      needGridChange: false,
      conditionalRowStyles: [{
        when: (row) => { return !(row.chkbx1007 !== undefined) },
        style: {
          input: {
            display: 'none'
          }
        }
      }],
      expandedCompTemplate: ({ data }) => (
        <div>
          {data.contData ? (
            <div className="py-3 expandable-area col-sm-12 rdt_TableRow">
              {data.contData.map((elem, i) =>
                <div className="py-3 expandable-area col-sm-12 rdt_TableRow selectable" key={"Row" + i}>
                  <div className={"row" + " rdt_ExpanderRow"} key={"ExpanderRow" + i} >
                    <div className="extraRowCell_1 ch-3" key="expandCell1">
                      {elem.vendors}
                    </div>
                    <div className="extraRowCell_2 ch-3" key="expandCell2">
                      {elem.share}
                    </div>
                    <div className="extraRowCell_3 ch-3" key="expandCell3">
                      {elem.weightShare}
                    </div>
                    <div className="extraRowCell_4 ch-3" key="expandCell4">
                      {elem.shareContNumber}
                    </div>
                    <div className="extraRowCell_5 ch-3" key="expandCell5">
                      {elem.shareWR}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
      // END_USER_CODE-USER_gridFV95SearchData_PROPERTIES
    },
    grpbxFarm: {
      name: "grpbxFarm",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFarm_PROPERTIES

      // END_USER_CODE-USER_grpbxFarm_PROPERTIES
    },
    grpbxLocation: {
      name: "grpbxLocation",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxLocation_PROPERTIES

      // END_USER_CODE-USER_grpbxLocation_PROPERTIES
    },
    grpbxMiscOpt: {
      name: "grpbxMiscOpt",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxMiscOpt_PROPERTIES

      // END_USER_CODE-USER_grpbxMiscOpt_PROPERTIES
    },
    grpbxNumber: {
      name: "grpbxNumber",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxNumber_PROPERTIES

      // END_USER_CODE-USER_grpbxNumber_PROPERTIES
    },
    grpbxLocFarm: {
      name: "grpbxLocFarm",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxLocFarm_PROPERTIES

      // END_USER_CODE-USER_grpbxLocFarm_PROPERTIES
    },
    grpbxTypeWhse: {
      name: "grpbxTypeWhse",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxTypeWhse_PROPERTIES

      // END_USER_CODE-USER_grpbxTypeWhse_PROPERTIES
    },
    grpbxPeanut: {
      name: "grpbxPeanut",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPeanut_PROPERTIES

      // END_USER_CODE-USER_grpbxPeanut_PROPERTIES
    },
    grpbxType: {
      name: "grpbxType",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxType_PROPERTIES

      // END_USER_CODE-USER_grpbxType_PROPERTIES
    },
    grpbxWhseBin: {
      name: "grpbxWhseBin",
      type: "GroupBoxWidget",
      parent: "grpbxFV95Search",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxWhseBin_PROPERTIES

      // END_USER_CODE-USER_grpbxWhseBin_PROPERTIES
    },
    labelSC95Insp1007Stlmnt: {
      name: "labelSC95Insp1007Stlmnt",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "SC95 Inspections / 1007 Settlements:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_labelSC95Insp1007Stlmnt_PROPERTIES

      // END_USER_CODE-USER_labelSC95Insp1007Stlmnt_PROPERTIES
    },
    lblContracted: {
      name: "lblContracted",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "Contracted:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContracted_PROPERTIES

      // END_USER_CODE-USER_lblContracted_PROPERTIES
    },
    lblContractedValue: {
      name: "lblContractedValue",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContractedValue_PROPERTIES

      // END_USER_CODE-USER_lblContractedValue_PROPERTIES
    },
    lblFarm: {
      name: "lblFarm",
      type: "LabelWidget",
      parent: "grpbxFarm",
      Label: "Farm:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFarm_PROPERTIES

      // END_USER_CODE-USER_lblFarm_PROPERTIES
    },
    lblLocation: {
      name: "lblLocation",
      type: "LabelWidget",
      parent: "grpbxLocation",
      Label: "Location:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLocation_PROPERTIES

      // END_USER_CODE-USER_lblLocation_PROPERTIES
    },
    lblNetWeight: {
      name: "lblNetWeight",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "Net Weight:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNetWeight_PROPERTIES

      // END_USER_CODE-USER_lblNetWeight_PROPERTIES
    },
    lblNetWeightValue: {
      name: "lblNetWeightValue",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNetWeightValue_PROPERTIES

      // END_USER_CODE-USER_lblNetWeightValue_PROPERTIES
    },
    lblNumber: {
      name: "lblNumber",
      type: "LabelWidget",
      parent: "grpbxNumber",
      Label: "Number:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblNumber_PROPERTIES

      // END_USER_CODE-USER_lblNumber_PROPERTIES
    },
    lblPeanut: {
      name: "lblPeanut",
      type: "LabelWidget",
      parent: "grpbxPeanut",
      Label: "Peanut:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPeanut_PROPERTIES

      // END_USER_CODE-USER_lblPeanut_PROPERTIES
    },
    lblTotals: {
      name: "lblTotals",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "Totals:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblTotals_PROPERTIES

      // END_USER_CODE-USER_lblTotals_PROPERTIES
    },
    lblType: {
      name: "lblType",
      type: "LabelWidget",
      parent: "grpbxType",
      Label: "Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblType_PROPERTIES

      // END_USER_CODE-USER_lblType_PROPERTIES
    },
    lblUncontracted: {
      name: "lblUncontracted",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "Uncontracted:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUncontracted_PROPERTIES

      // END_USER_CODE-USER_lblUncontracted_PROPERTIES
    },
    lblUncontractedValue: {
      name: "lblUncontractedValue",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUncontractedValue_PROPERTIES

      // END_USER_CODE-USER_lblUncontractedValue_PROPERTIES
    },
    lblValue: {
      name: "lblValue",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "Value:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValue_PROPERTIES

      // END_USER_CODE-USER_lblValue_PROPERTIES
    },
    lblValueValue: {
      name: "lblValueValue",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblValueValue_PROPERTIES

      // END_USER_CODE-USER_lblValueValue_PROPERTIES
    },
    lblWhseBin: {
      name: "lblWhseBin",
      type: "LabelWidget",
      parent: "grpbxWhseBin",
      Label: "Whse/Bin#:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWhseBin_PROPERTIES

      // END_USER_CODE-USER_lblWhseBin_PROPERTIES
    },
    radioAsShownExpColl: {
      name: "radioAsShownExpColl",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxAcions",
      Options: "As Shown:1,Expanded:2,Collapsed:3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioAsShownExpColl_PROPERTIES

      // END_USER_CODE-USER_radioAsShownExpColl_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxNumber",
      Label: "1007#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txt1007C: {
      name: "txt1007C",
      type: "TextBoxWidget",
      colName: "txtCol1007",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007C_PROPERTIES

      // END_USER_CODE-USER_txt1007C_PROPERTIES
    },
    txtBuyingPoint: {
      name: "txtBuyingPoint",
      type: "TextBoxWidget",
      colName: "txtColBuyingPoint",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtBuyingPoint_PROPERTIES
    },
    txtCol1007: {
      name: "txtCol1007",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "1007#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtCol1007_PROPERTIES

      // END_USER_CODE-USER_txtCol1007_PROPERTIES
    },
    txtColBuyingPoint: {
      name: "txtColBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Buying Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtColBuyingPoint_PROPERTIES
    },
    txtColContract: {
      name: "txtColContract",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Contract#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColContract_PROPERTIES

      // END_USER_CODE-USER_txtColContract_PROPERTIES
    },
    txtColDACO: {
      name: "txtColDACO",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "DACO#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColDACO_PROPERTIES

      // END_USER_CODE-USER_txtColDACO_PROPERTIES
    },
    txtColFarm: {
      name: "txtColFarm",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColFarm_PROPERTIES

      // END_USER_CODE-USER_txtColFarm_PROPERTIES
    },
    txtColGen: {
      name: "txtColGen",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColGen_PROPERTIES

      // END_USER_CODE-USER_txtColGen_PROPERTIES
    },
    txtColInspectionDate: {
      name: "txtColInspectionDate",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Inspection Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColInspectionDate_PROPERTIES

      // END_USER_CODE-USER_txtColInspectionDate_PROPERTIES
    },
    txtColNetWeight: {
      name: "txtColNetWeight",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Net Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtColNetWeight_PROPERTIES
    },
    txtColObligationPoint: {
      name: "txtColObligationPoint",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Obligation Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColObligationPoint_PROPERTIES

      // END_USER_CODE-USER_txtColObligationPoint_PROPERTIES
    },
    txtColOneDocBP: {
      name: "txtColOneDocBP",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "OneDocBP",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOneDocBP_PROPERTIES

      // END_USER_CODE-USER_txtColOneDocBP_PROPERTIES
    },
    txtColOriginPoint: {
      name: "txtColOriginPoint",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Origin Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColOriginPoint_PROPERTIES

      // END_USER_CODE-USER_txtColOriginPoint_PROPERTIES
    },
    txtColPeanutType: {
      name: "txtColPeanutType",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtColPeanutType_PROPERTIES
    },
    txtColReceipt: {
      name: "txtColReceipt",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Receipt#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColReceipt_PROPERTIES

      // END_USER_CODE-USER_txtColReceipt_PROPERTIES
    },
    txtColReprint: {
      name: "txtColReprint",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Reprint",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColReprint_PROPERTIES

      // END_USER_CODE-USER_txtColReprint_PROPERTIES
    },
    txtColSC95: {
      name: "txtColSC95",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "SC95#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSC95_PROPERTIES

      // END_USER_CODE-USER_txtColSC95_PROPERTIES
    },
    txtColSeg: {
      name: "txtColSeg",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColSeg_PROPERTIES

      // END_USER_CODE-USER_txtColSeg_PROPERTIES
    },
    txtColStatus: {
      name: "txtColStatus",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColStatus_PROPERTIES

      // END_USER_CODE-USER_txtColStatus_PROPERTIES
    },
    txtColStatusHidden: {
      name: "txtColStatusHidden",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColStatusHidden_PROPERTIES

      // END_USER_CODE-USER_txtColStatusHidden_PROPERTIES
    },
    txtColValue: {
      name: "txtColValue",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColValue_PROPERTIES

      // END_USER_CODE-USER_txtColValue_PROPERTIES
    },
    txtColVariety: {
      name: "txtColVariety",
      type: "GridColumnWidget",
      parent: "gridFV95SearchData",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_txtColVariety_PROPERTIES

      // END_USER_CODE-USER_txtColVariety_PROPERTIES
    },
    txtContract: {
      name: "txtContract",
      type: "TextBoxWidget",
      parent: "grpbxNumber",
      Label: "Contract#:",
      ColSpan: "2",
      RowSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContract_PROPERTIES

      // END_USER_CODE-USER_txtContract_PROPERTIES
    },
    txtContractC: {
      name: "txtContractC",
      type: "TextBoxWidget",
      colName: "txtColContract",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractC_PROPERTIES

      // END_USER_CODE-USER_txtContractC_PROPERTIES
    },
    txtDACO: {
      name: "txtDACO",
      type: "TextBoxWidget",
      parent: "grpbxNumber",
      Label: "DACO#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 11 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDACO_PROPERTIES

      // END_USER_CODE-USER_txtDACO_PROPERTIES
    },
    txtDACOC: {
      name: "txtDACOC",
      type: "TextBoxWidget",
      colName: "txtColDACO",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDACOC_PROPERTIES

      // END_USER_CODE-USER_txtDACOC_PROPERTIES
    },
    txtFarmC: {
      name: "txtFarmC",
      type: "TextBoxWidget",
      colName: "txtColFarm",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmC_PROPERTIES

      // END_USER_CODE-USER_txtFarmC_PROPERTIES
    },
    txtGen: {
      name: "txtGen",
      type: "TextBoxWidget",
      colName: "txtColGen",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGen_PROPERTIES

      // END_USER_CODE-USER_txtGen_PROPERTIES
    },
    txtGeneration: {
      name: "txtGeneration",
      type: "TextBoxWidget",
      parent: "grpbxPeanut",
      Label: "Generation:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtGeneration_PROPERTIES

      // END_USER_CODE-USER_txtGeneration_PROPERTIES
    },
    txtInspectionDate: {
      name: "txtInspectionDate",
      type: "TextBoxWidget",
      colName: "txtColInspectionDate",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspectionDate_PROPERTIES

      // END_USER_CODE-USER_txtInspectionDate_PROPERTIES
    },
    txtInspectionDateFrom: {
      name: "txtInspectionDateFrom",
      type: "DateWidget",
      parent: "grpbxMiscOpt",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAgreementDate_PROPERTIES
      // END_USER_CODE-USER_txtAgreementDate_PROPERTIES
    },
    txtInspectionDateTo: {
      name: "txtInspectionDateTo",
      type: "DateWidget",
      parent: "grpbxMiscOpt",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAgreementDate_PROPERTIES
      // END_USER_CODE-USER_txtAgreementDate_PROPERTIES
    },
    txtNetWeight: {
      name: "txtNetWeight",
      type: "TextBoxWidget",
      colName: "txtColNetWeight",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtNetWeight_PROPERTIES
    },
    txtObligationPoint: {
      name: "txtObligationPoint",
      type: "TextBoxWidget",
      colName: "txtColObligationPoint",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtObligationPoint_PROPERTIES

      // END_USER_CODE-USER_txtObligationPoint_PROPERTIES
    },
    txtOneDocBP: {
      name: "txtOneDocBP",
      type: "TextBoxWidget",
      colName: "txtColOneDocBP",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOneDocBP_PROPERTIES

      // END_USER_CODE-USER_txtOneDocBP_PROPERTIES
    },
    txtOriginPoint: {
      name: "txtOriginPoint",
      type: "TextBoxWidget",
      colName: "txtColOriginPoint",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOriginPoint_PROPERTIES

      // END_USER_CODE-USER_txtOriginPoint_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      colName: "txtColPeanutType",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtReceipt: {
      name: "txtReceipt",
      type: "TextBoxWidget",
      colName: "txtColReceipt",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReceipt_PROPERTIES

      // END_USER_CODE-USER_txtReceipt_PROPERTIES
    },
    txtReprint: {
      name: "txtReprint",
      type: "TextBoxWidget",
      colName: "txtColReprint",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtReprint_PROPERTIES

      // END_USER_CODE-USER_txtReprint_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxNumber",
      Label: "SC95#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    txtSC95C: {
      name: "txtSC95C",
      type: "TextBoxWidget",
      colName: "txtColSC95",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95C_PROPERTIES

      // END_USER_CODE-USER_txtSC95C_PROPERTIES
    },
    txtSegC: {
      name: "txtSegC",
      type: "TextBoxWidget",
      colName: "txtColSeg",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegC_PROPERTIES

      // END_USER_CODE-USER_txtSegC_PROPERTIES
    },
    txtSharePercent: {
      name: "txtSharePercent",
      type: "TextBoxWidget",
      parent: "grpbxNumber",
      Label: "Share %:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePercent_PROPERTIES

      // END_USER_CODE-USER_txtSharePercent_PROPERTIES
    },
    txtStatusC: {
      name: "txtStatusC",
      type: "TextBoxWidget",
      colName: "txtColStatus",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatusC_PROPERTIES

      // END_USER_CODE-USER_txtStatusC_PROPERTIES
    },
    txtStatusHidden: {
      name: "txtStatusHidden",
      type: "TextBoxWidget",
      colName: "txtColStatusHidden",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatusHidden_PROPERTIES

      // END_USER_CODE-USER_txtStatusHidden_PROPERTIES
    },
    txtValue: {
      name: "txtValue",
      type: "TextBoxWidget",
      colName: "txtColValue",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValue_PROPERTIES

      // END_USER_CODE-USER_txtValue_PROPERTIES
    },
    txtVariety: {
      name: "txtVariety",
      type: "TextBoxWidget",
      colName: "txtColVariety",
      parent: "gridFV95SearchData",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVariety_PROPERTIES

      // END_USER_CODE-USER_txtVariety_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxNumber",
      Label: "Vendor#:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtWR: {
      name: "txtWR",
      type: "TextBoxWidget",
      parent: "grpbxNumber",
      Label: "WR#:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWR_PROPERTIES

      // END_USER_CODE-USER_txtWR_PROPERTIES
    },
    grpbxFV95Search: {
      name: "grpbxFV95Search",
      type: "GroupBoxWidget",
      parent: "FV95Search",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxFV95Search_PROPERTIES

      // END_USER_CODE-USER_grpbxFV95Search_PROPERTIES
    },
    grpbxSearchResults: {
      name: "grpbxSearchResults",
      type: "GroupBoxWidget",
      parent: "FV95Search",
      Height: "",
      Width: "",
      ColsForTabLandscape: "9",
      HasLabel: false,
      Cols: "9",
      ColsForTabPotrait: "9",
      ColsForLargeDesktop: "9",
      // START_USER_CODE-USER_grpbxSearchResults_PROPERTIES

      // END_USER_CODE-USER_grpbxSearchResults_PROPERTIES
    },
    grpbxAcions: {
      name: "grpbxAcions",
      type: "GroupBoxWidget",
      parent: "FV95Search",
      Height: "",
      Width: "",
      ColsForTabLandscape: "10",
      HasLabel: false,
      Cols: "10",
      ColsForTabPotrait: "10",
      ColsForLargeDesktop: "10",
      // START_USER_CODE-USER_grpbxAcions_PROPERTIES

      // END_USER_CODE-USER_grpbxAcions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#FV95": {},
        "Settlements#ContApplEditAuthorize": {},
        "Settlements#ViewUploads": {},
        "ContractManagement#VendorLookup": {}
      },
      REVERSE: {
        "Settlements#FV95": {},
        "Settlements#ContApplEditAuthorize": {},
        "Settlements#ViewUploads": {},
        "ContractManagement#VendorLookup": {}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCreateManualSC95: {
    //   DEFAULT: ["Settlements#FV95#DEFAULT#true#Click"],
    // },
    btnKCMOdataTransmitted: {
      DEFAULT: ["Settlements#KCMOdataSearch#DEFAULT#true#Click"],
    },
    btnAuthorizeApplicationEdit: {
      DEFAULT: ["Settlements#ContApplEditAuthorize#DEFAULT#true#Click"],
    },
    // btnViewTransferFailures: {
    //   DEFAULT: ["Settlements#ViewUploads#DEFAULT#true#Click"],
    // },
    // btnViewSettlements: {
    //   DEFAULT: ["Settlements#ViewSettlement#DEFAULT#true#Click"],
    // },
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnClose: {
      DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    if (getData(thisObj, "ClosingofFV95") !== true) {
      setVendorNameBasedOnVendorNumber()
    }
    else {
      setData(thisObj, "ClosingofFV95", false)
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  useEffect(() => {
    if (getData(thisObj, 'selectedRowReload') == true) {
      Refresh();
      setData(thisObj, 'selectedRowReload', false);
    }
  }, [getData(thisObj, 'selectedRowReload')])

  const CompId = (sessionStorage.getItem('compId'));
  const CropYear = (JSON.parse(sessionStorage.getItem('year')));
  const UserId = (sessionStorage.getItem('userid'));

  const [SuccessfulLoad, setSuccessfulLoad] = useState();
  const [isShow, invokeModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Checked1007Found, setChecked1007Found] = useState(false);
  const [SC95TransferLoc, setSC95Loc] = useState("");

  function FormLoad() {
    setData(thisObj, 'selectedRowReload', false);
    checkUser()
    InitialLoad()
    bFillStateList()
    bFillTypeList()
    bFillBuyingPointList()
    bFillObligationPointList()
    bFillSegmentList()
    bFillOleicList()
    bFillOrganicList()
    bFillHighMoistList()
    bFillReceiptStatusList()
    bFillStatusList()
    bFillDispositionList()
    bFillContTypeList()
    bFillFarmList()
    bFillSeedList()
    setValue(thisObj, "txtVendor", "")
  }

  //---------functions
  const checkUser = () => {
    ContractManagementService.RetrieveUserRoles(CompId, UserId, CropYear).then(response => {
      let data = response
      if (data.length > 0) {
        setSuccessfulLoad(true)
      }
      else {
        setSuccessfulLoad(false)
      }
    })
  }

  const InitialLoad = () => {
    disable(thisObj, 'btnOpenSC95');
    disable(thisObj, 'btnOpen1007');
    disable(thisObj, 'btnOpen1007');
    disable(thisObj, 'btnViewSettlements');
    hide(thisObj, 'btnViewSettlements', false);
    hide(thisObj, 'txtVndor', false);
    hide(thisObj, 'txtSharePercent', false);
    hide(thisObj, 'chkboxPercentOnly', false);
    disable(thisObj, 'btnUnSignSettlements');
    disable(thisObj, 'btnSignSettlements');
    setValue(thisObj, 'radioAsShownExpColl', '1')

    let jsObligation = [];
    jsObligation.push({ key: '', description: '>>> All Obligation Points <<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddObligationPoint: {
          ...state["ddObligationPoint"],
          valueList: jsObligation
        }
      }
    })
    setValue(thisObj, 'ddObligationPoint', jsObligation[0].key);

    let jsObg = [];
    jsObg.push({ key: '', description: '>>> All Whse/Bins <<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddObligation: {
          ...state["ddObligation"],
          valueList: jsObg
        }
      }
    })
    setValue(thisObj, 'ddObligation', jsObg[0].key);

    let jsUnload = [];
    jsUnload.push({ key: '', description: '>>> All Whse/Bins <<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddUnload: {
          ...state["ddUnload"],
          valueList: jsUnload
        }
      }
    })
    setValue(thisObj, 'ddUnload', jsUnload[0].key);

    let jsVariety = [];
    jsVariety.push({ key: '', description: '>>> All Varieties <<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: jsVariety
        }
      }
    })
    setValue(thisObj, 'ddPeanutVariety', jsVariety[0].key);


    //button access
    ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '006', 'U').then(response => {
      if (response[0].permission == 'N') {
        hide(thisObj, 'btnDeleteSC95', false);
      }
      else {
        show(thisObj, 'btnDeleteSC95');
      }
    })

    if (CropYear >= 2009) {
      ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '009', 'U').then(response => {
        if (response[0].permission == 'N') {
          hide(thisObj, 'btnAuthorizeApplicationEdit', false);
        }
        else {
          show(thisObj, 'btnAuthorizeApplicationEdit');
        }
      })
    }
    else {
      hide(thisObj, 'btnAuthorizeApplicationEdit', false);
    }

    if (CropYear >= 2009) {
      ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '010', 'U').then(response => {
        if (response[0].permission == 'N') {
          hide(thisObj, 'btnCreateManualSC95', false);
        }
        else {
          show(thisObj, 'btnCreateManualSC95');
        }
      })
    }
    else {
      hide(thisObj, 'btnCreateManualSC95', false);
    }

    ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '011', 'I').then(response => {
      if (response[0].permission == 'N') {
        hide(thisObj, 'btnKCMOdataTransmitted', false);
      }
      else {
        show(thisObj, 'btnKCMOdataTransmitted');
      }
    })

  }

  const bFillStateList = () => {
    let jsCounties = [], jsState = [];
    jsCounties.push({ key: '', description: '>>> All Counties <<<' })
    jsState.push({ key: '', description: '>>> All States <<<' })
    ContractManagementService.RetrievePeanutStateControlDetails(null, null, false).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].stateAbbr, description: data[i].stateName }
        jsState.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: jsState,
          }
        }
      })
    })
    setValue(thisObj, 'ddState', jsState[0].key);
    thisObj.setState(current => {
      return {
        ...current,
        ddCounty: {
          ...state["ddCounty"],
          valueList: jsCounties,
        }
      }
    })
    setValue(thisObj, 'ddCounty', jsCounties[0].key);
  }

  const bFillTypeList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' })
    ContractManagementService.RetrievePeanutTypeControls().then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
    setValue(thisObj, 'ddPeanutType', js[0].key);
  }

  const bFillBuyingPointList = () => {
    let js = []; let initialValue;
    js.push({ key: '', description: '>>> All Buying Points <<<' });
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + ' - ' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      if (js.length == 2) {
        delete js[0];
        initialValue = js[1].key;
      }
      else {
        initialValue = js[0].key;
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', initialValue);
    })
  }

  const bFillSegmentList = () => {
    let js = []
    js.push({ key: '', description: '> All <' })
    js.push({ key: '1', description: 'Seg1' })
    js.push({ key: '2', description: 'Seg2' })
    js.push({ key: '3', description: 'Seg3' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeg: {
          ...state["ddSeg"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeg', js[0].key);
  }

  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: '> All <' })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleic: {
          ...state["ddOleic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOleic', js[0].key);
  }

  const bFillOrganicList = () => {
    let js = []
    js.push({ key: '', description: '> All <' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOrganic: {
          ...state["ddOrganic"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOrganic', js[0].key);
  }

  const bFillHighMoistList = () => {
    let js = []
    js.push({ key: '', description: '> All <' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddHighMoist: {
          ...state["ddHighMoist"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddHighMoist', js[0].key);
  }

  const bFillReceiptStatusList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Statuses <<<' })
    js.push({ key: 'Y', description: 'Yes' })
    js.push({ key: 'N', description: 'No' })
    thisObj.setState(current => {
      return {
        ...current,
        ddReceipted: {
          ...state["ddReceipted"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddReceipted', js[0].key);
  }

  const bFillStatusList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Statuses <<<' })
    js.push({ key: 'P', description: 'Pending' })
    js.push({ key: 'I', description: 'Inspected' })
    js.push({ key: 'U', description: 'Unsigned' })
    js.push({ key: 'G', description: 'Signed' })
    js.push({ key: 'S', description: 'Settled' })
    js.push({ key: 'J', description: 'Settled - C' })
    js.push({ key: 'K', description: 'Settled - S' })
    js.push({ key: 'L', description: 'Settled - SC' })
    js.push({ key: 'C', description: 'In Process - C' })
    js.push({ key: 'E', description: 'In Process - S' })
    js.push({ key: 'V', description: 'Void/No Sale' })
    js.push({ key: 'R', description: 'Superseded SC95/1007' })
    js.push({ key: 'X', description: 'Pending No Sale' })
    js.push({ key: 'Z', description: 'Residue Load' })

    thisObj.setState(current => {
      return {
        ...current,
        ddStatus: {
          ...state["ddStatus"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddStatus', js[0].key);
  }

  const bFillDispositionList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Dispositions <<<' })
    js.push({ key: 'B', description: 'Both' })
    js.push({ key: 'S', description: 'Storage' })
    js.push({ key: 'P', description: 'Purchase' })
    thisObj.setState(current => {
      return {
        ...current,
        ddDisposition: {
          ...state["ddDisposition"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddDisposition', js[0].key);
  }

  const bFillContTypeList = () => {
    let js = []
    js.push({ key: '', description: '>>> All Contracts <<<' })
    js.push({ key: '1', description: 'Contract Firm' })
    js.push({ key: '2', description: 'Contract Basis' })
    js.push({ key: '3', description: 'Option Basis' })
    js.push({ key: '4', description: 'UnContracted' })
    js.push({ key: '5', description: 'Flex Market Basis' })
    if (CropYear >= 2011) {
      js.push({ key: '6', description: 'Floor Basis' })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddContractType: {
          ...state["ddContractType"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddContractType', js[0].key);
  }

  const bFillFarmList = () => {
    let buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
    let stateAbbrevations = getValue(thisObj, 'ddState');
    let countyId = getValue(thisObj, 'ddCounty');
    let jsFarms = [];
    jsFarms.push({ key: '', description: '>>> All Farms <<<' })
    if ((buy_pt_id != "" && buy_pt_id != undefined) || (stateAbbrevations != "" && stateAbbrevations != undefined) || (countyId != "" && countyId != undefined)) {
      ContractManagementService.RetrieveFarmControlDetails('PN1060', null, buy_pt_id, stateAbbrevations, countyId, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: i + '$' + data[i].farm_id + '-' + data[i].farm_suffix, description: data[i].farm_id + ' - ' + data[i].farm_suffix + ' - ' + data[i].farm_name }
          jsFarms.push(obj);
        }
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddFarm: {
          ...state["ddFarm"],
          valueList: jsFarms,
        }
      }
    })
    setValue(thisObj, 'ddFarm', jsFarms[0].key);
  }

  const bFillSeedList = () => {
    let js = []
    js.push({ key: '', description: '>>> All <<<' })
    js.push({ key: 'Y', description: 'Seed' })
    js.push({ key: 'N', description: 'Non-Seed' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeed: {
          ...state["ddSeed"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeed', js[0].key);
  }

  const bFillCountyList = () => {
    let jsCounty = [], jsFarms = [];
    let stateValue = getValue(thisObj, "ddState");
    jsCounty.push({ key: '', description: '>>> All Counties <<<' })
    if (stateValue != "" && stateValue != undefined && stateValue != null) {
      ContractManagementService.RetrieveCountyControlDetails('NA', false, stateValue, null).then(response => {
        let data = response
        if (data !== null && data !== undefined && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            jsCounty.push({ key: data[i].countyId, description: data[i].countyName })
          }
        }
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddCounty: {
          ...state["ddCounty"],
          valueList: jsCounty,
        }
      }
    })
    setValue(thisObj, 'ddCounty', jsCounty[0].key);

    jsFarms.push({ key: '', description: '>>> All Farms <<<' })
    thisObj.setState(current => {
      return {
        ...current,
        ddFarm: {
          ...state["ddFarm"],
          valueList: jsFarms,
        }
      }
    })
    setValue(thisObj, 'ddFarm', jsFarms[0].key);
  }

  const bFillObligationPointList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Obligation Points <<<' })
    let buy_point_id = getValue(thisObj, 'ddBuyingPoint');
    if (buy_point_id == "" || buy_point_id == undefined) {
      buy_point_id = null;
    }
    SettlementService.RetrieveTransferBuyingPointDetails(buy_point_id, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].xfer_buy_pt_id.trim() + ' - ' + data[i].xfer_buy_pt_name.trim() }
        js.push(obj)
      }
    })

    thisObj.setState(current => {
      return {
        ...current,
        ddObligationPoint: {
          ...state["ddObligationPoint"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddObligationPoint', js[0].key);
    onddObligationPointChange();
  }

  const bFillOblWhseBinList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Whse/Bins <<<' })
    let buy_pt_id = getValue(thisObj, 'ddObligationPoint');
    if (buy_pt_id != null && buy_pt_id != undefined && buy_pt_id != "") {
      SettlementService.RetrieveWhouseBinInventoryDetails(buy_pt_id, null, null, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].whouseNum + '-' + data[i].binNum, description: data[i].whouseNum.trim() + ' - ' + data[i].binNum.trim() + ' - ' + data[i].whouseBinId.trim() }
          js.push(obj)
        }
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddObligation: {
          ...state["ddObligation"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddObligation', js[0].key);
  }

  const bFillUnloadWhseBinList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Whse/Bins <<<' })
    let buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
    if (buy_pt_id != null && buy_pt_id != "" && buy_pt_id != undefined) {
      SettlementService.RetrieveWhouseBinInventoryDetails(buy_pt_id, null, null, null, null).then(response => {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].whouseNum + '-' + data[i].binNum, description: data[i].whouseNum.trim() + ' - ' + data[i].binNum.trim() + ' - ' + data[i].whouseBinId.trim() }
          js.push(obj)
        }
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddUnload: {
          ...state["ddUnload"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddUnload', js[0].key);
  }

  const bFillVarietyList = () => {
    let js = []; let buy_pt_id;
    js.push({ key: '', description: '>>> All Varieties <<<' })
    let buying_pt = getValue(thisObj, 'ddBuyingPoint');
    let oblg_pt = getValue(thisObj, 'ddObligationPoint');

    if (buying_pt != null && buying_pt != "" && buying_pt != undefined) {
      buy_pt_id = buying_pt;
    }
    else if (oblg_pt != null && oblg_pt != "" && oblg_pt != undefined) {
      buy_pt_id = oblg_pt;
    }
    let peanut_type = getValue(thisObj, 'ddPeanutType');

    if (peanut_type != null && peanut_type != "" && peanut_type != undefined) {
      ContractManagementService.RetrievePeanutVarietyControls(buy_pt_id, null, null, peanut_type, null).then(response => {
        let data = response;
        let count = 0;
        data.forEach(element => {
          count++;
          let newVar = true;
          for (let i = 0; i < count - 1; i++) {
            if ((element.pnut_variety_id).toUpperCase() == (data[i].pnut_variety_id).toUpperCase()) {
              newVar = false;
              break;
            }
          }
          let symbol;
          if (newVar) {
            switch (element.symbol_ind.trim()) {
              case "TRADEMARK":
                symbol = " \u2122"
                break;
              case "REGISTERED TRADEMARK":
                symbol = " \xAE"
                break;
              case "COPYRIGHT":
                symbol = " \xA9"
                break;
              default:
                symbol = "";
            }
            let obj = { key: element.pnut_variety_id, description: element.pnut_variety_name + symbol }
            js.push(obj)
          }
        });
      })
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddPeanutVariety: {
          ...state["ddPeanutVariety"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddPeanutVariety', js[0].key);
  }

  const setVendorNameBasedOnVendorNumber = () => {
    let isVendorLookup = false;
    let vendor_lookup = getData(thisObj, 'vendorDetails');
    let vendorNumber, vendorName;
    if (vendor_lookup !== null) {
      vendorNumber = vendor_lookup.VendorNumber;
      vendorName = vendor_lookup.vendorName;
      setValue(thisObj, "txtVndor", vendorName);
      setValue(thisObj, "txtVendor", vendorNumber);
      isVendorLookup = true;
      setData(thisObj, 'vendorDetails', null);
      show(thisObj, 'txtSharePercent');
      show(thisObj, 'chkboxPercentOnly');
      show(thisObj, 'txtVndor');
      return;
    }

    let vendorValueFromTextBox = getValue(thisObj, 'txtVendor');
    if (vendorValueFromTextBox !== undefined && isVendorLookup == false) {
      vendorNumber = vendorValueFromTextBox;
    }

    if (vendorNumber != "" && vendorNumber != undefined && isVendorLookup == false) {
      ContractManagementService.RetrieveVendorByNumber(vendorNumber).then(response => {
        let data = response;
        if (response !== undefined && response.length > 0) {
          vendorName = data[0].name;
        }
        setValue(thisObj, "txtVndor", vendorName);
        setValue(thisObj, "txtVendor", vendorNumber);
      })
    }
  }

  function replicateString(char, num) {
    return char.repeat(num);
  }

  const Search = async () => {
    setLoading(true);
    defaultButtonView();
    let obj = [];
    obj.func_id = "PN1060";
    obj.func_sub_id = null;
    obj.area_id = null;
    obj.coll_pt_id = null;
    obj.split_vendor = getValue(thisObj, 'txtVendor');
    obj.split_share_pct = getValue(thisObj, 'txtSharePercent');
    let chkPercent = getValue(thisObj, 'chkboxPercentOnly');
    if (chkPercent == true) {
      obj.only_share_pct = "Y";
    } else {
      obj.only_share_pct = "N";
    }
    obj.buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
    obj.insp_num = getValue(thisObj, 'txtSC95');
    obj.settlement_num = getValue(thisObj, 'txt1007');
    obj.weight_cert_num = getValue(thisObj, 'txtDACO');
    obj.pnut_type_id = getValue(thisObj, 'ddPeanutType');
    obj.pnut_variety_id = getValue(thisObj, 'ddPeanutVariety');

    let farm = getValue(thisObj, 'ddFarm');
    if (farm.length > 0) {
      let arr = farm.split('$');
      let values = arr[1].split('-');
      obj.farm_id = values[0];
      obj.farm_suffix = values[1];
    }
    else {
      obj.farm_id = "";
      obj.farm_suffix = "";
    }

    obj.insp_status = getValue(thisObj, 'ddStatus');
    obj.seg_type = getValue(thisObj, 'ddSeg');
    obj.oleic_ind = getValue(thisObj, 'ddOleic');
    obj.state_abbr = getValue(thisObj, 'ddState');
    obj.county_id = getValue(thisObj, 'ddCounty');
    obj.purch_store_ind = getValue(thisObj, 'ddDisposition');
    obj.obl_buy_id = getValue(thisObj, 'ddObligationPoint');
    let startDate = getValue(thisObj, 'txtInspectionDateFrom');
    if (startDate != "" && startDate != undefined) {
      obj.insp_date_time_start = moment(startDate).format('MM/DD/YYYY');
    } else {
      obj.insp_date_time_start = "";
    }
    obj.contract_num = getValue(thisObj, 'txtContract');
    obj.seed_gen = getValue(thisObj, 'txtGeneration');
    let endDate = getValue(thisObj, 'txtInspectionDateTo');
    if (endDate != "" && endDate != undefined) {
      obj.insp_date_time_end = moment(endDate).format('MM/DD/YYYY');
    } else {
      obj.insp_date_time_end = "";
    }
    obj.cont_type = getValue(thisObj, 'ddContractType');
    obj.whse_rcpt_num = getValue(thisObj, 'txtWR');

    let unload = getValue(thisObj, 'ddUnload');
    if (unload.length > 0) {
      let values = unload.split('-');
      obj.unld_whse_id = values[0];
      obj.unld_bin_id = values[1];
    }
    else {
      obj.unld_whse_id = "";
      obj.unld_bin_id = "";
    }

    let obligation = getValue(thisObj, 'ddObligation');
    if (obligation.length > 0) {
      let values = obligation.split('-');
      obj.obl_whse_id = values[0];
      obj.obl_bin_id = values[1];
    }
    else {
      obj.obl_whse_id = "";
      obj.obl_bin_id = "";
    }

    obj.organic_ind = getValue(thisObj, 'ddOrganic');
    obj.high_moist = getValue(thisObj, 'ddHighMoist');
    obj.seed_ind = getValue(thisObj, 'ddSeed');
    obj.receiptedstatus = getValue(thisObj, 'ddReceipted');
    let chkResidue = getValue(thisObj, 'chkboxIncludeResidueLoads');
    if (chkResidue == true || getValue(thisObj, 'ddStatus') == "Z") {
      obj.residue_load = "Y";
    }
    else {
      obj.residue_load = "N";
    }

    let totalValue = 0, totalPounds = 0, totalPoundsCont = 0, totalPoundsUncont = 0;
    //binding
    let gridData = [];
    setValue(thisObj, 'gridFV95SearchData', gridData);
    setValue(thisObj, 'lblNetWeightValue', '');
    setValue(thisObj, 'lblContractedValue', '');
    setValue(thisObj, 'lblUncontractedValue', '');
    setValue(thisObj, 'lblValueValue', '');
    let data = await SettlementService.RetrieveInspectionHeaderDetails(obj)
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let obj = {};
        let arrContracts = [], arrVendors = [], arrVendorPCT = [], arrVendorLBS = [], arrWRs = [], contData = [];
        obj.txtSC95C = data[i].insp_num;
        //obj.txt1007C = data[i].settlement_num;
        if (data[i].settlement_num !== null && data[i].settlement_num !== undefined && data[i].settlement_num.trim() !== "") {
          obj.txt1007C = replicateString("0", (7 - (((data[i].settlement_num).toString()).length))) + data[i].settlement_num;
        } else {
          obj.txt1007C = data[i].settlement_num;
        }
        switch (data[i].insp_status.trim()) {
          case "P":
            obj.txtStatusC = "Pending";
            break;
          case "I":
            if (data[i].settlement_num != 0) {
              obj.txtStatusC = "In Process - C";
              obj.txtReprint = "N";
            }
            else {
              if (data[i].superseded_settle != 0 && data[i].settlement_num == 0) {
                obj.txtStatusC = "In Process - S";
                obj.txtReprint = "N";
              }
              else {
                obj.txtStatusC = "Inspected";
              }
            }
            break;
          case "S":
            if ((data[i].kc_trans_ind).toUpperCase().trim() != "Y") {
              obj.txtStatusC = "Unsigned";
              //checkbox
              obj.chkbx1007 = false;
            }
            else {
              if ((data[i].kc_trans_date).toUpperCase().trim() != "") {
                if (data[i].settle_correct_cnt > 0 && (data[i].superseded_settle).toUpperCase().trim() != "" && (data[i].superseded_settle).toUpperCase().trim() != "0") {
                  obj.txtStatusC = "Settled - SC" + data[i].settle_correct_cnt;
                  obj.txtReprint = "N";
                }
                else if (data[i].settle_correct_cnt > 0) {
                  obj.txtStatusC = "Settled - C" + data[i].settle_correct_cnt;
                  obj.txtReprint = "N";
                }
                else if ((data[i].superseded_settle).toUpperCase().trim() != "" && (data[i].superseded_settle).toUpperCase().trim() != "0") {
                  obj.txtStatusC = "Settled - S";
                }
                else {
                  obj.txtStatusC = "Settled";
                }
                //checkbox
                obj.chkbx1007 = false;
              }
              else {
                obj.txtStatusC = "Signed";
                //checkbox
                obj.chkbx1007 = false;
              }
            }
            break;
          case "V":
            let voidReasonCode = (data[i].void_reason_code).toUpperCase().trim();
            if (voidReasonCode == "F") {
              obj.txtStatusC = "Void";
            }
            else if (voidReasonCode == "V") {
              obj.txtStatusC = "Void";
              //checkbox
              obj.chkbx1007 = false;
            }
            else if (voidReasonCode == "R") {
              if (isNumeric(data[i].settlement_num)) {
                obj.txtStatusC = "Superseded 1007";
              }
              else {
                obj.txtStatusC = "Superseded SC95";
              }
            }
            else {
              obj.txtStatusC = "No Sale";
            }
            break;
          case "R":
            if (isNumeric(data[i].settlement_num)) {
              obj.txtStatusC = "Superseded 1007";
            }
            else {
              obj.txtStatusC = "Superseded SC95";
            }
            break;
          case "X":
            obj.txtStatusC = "Pending No Sale";
            break;
          case "Z":
            if ((data[i].residue_supersede).toUpperCase().trim() == "Y") {
              obj.txtStatusC = "Residue Load-S";
            }
            else {
              obj.txtStatusC = "Residue Load";
            }
            break;
        }
        if (data[i].farm_name == "" || data[i].farm_name == "-") {
          obj.txtFarmC = data[i].void_reason_desc;
        }
        else {
          obj.txtFarmC = data[i].farm_name;
        }
        obj.txtOriginPoint = data[i].buy_pt_id + " - " + data[i].unld_pt_name;
        obj.txtPeanutType = data[i].pnut_type_name;
        obj.txtVariety = data[i].pnut_variety_name;
        switch ((data[i].symbol_ind.trim()).toUpperCase()) {
          case "TRADEMARK":
            obj.txtVariety = data[i].pnut_variety_name + " \u2122";
            break;
          case "REGISTERED TRADEMARK":
            obj.txtVariety = data[i].pnut_variety_name + " \xAE";
            break;
          case "COPYRIGHT":
            obj.txtVariety = data[i].pnut_variety_name + " \xA9";
            break;
        }
        obj.txtGen = data[i].seed_gen;
        obj.txtSegC = data[i].seg_type;
        if (data[i].net_wt != "") {
          obj.txtNetWeight = FormatNumber(data[i].net_wt, 0);
        }
        if (data[i].basis_grade_amt != "") {
          obj.txtValue = "$" + FormatNumber(data[i].basis_grade_amt, 2);
        }
        obj.txtObligationPoint = data[i].obl_buy_id + " - " + data[i].obl_pt_name;
        obj.txtDACOC = data[i].daco_num;
        if (data[i].insp_date_time.trim() != "") {
          obj.txtInspectionDate = moment(data[i].insp_date_time.trim()).format('MM/DD/YYYY');
        }
        obj.txtStatusHidden = data[i].insp_status;
        obj.txtBuyingPoint = data[i].buy_pt_id;
        obj.txtOneDocBP = data[i].onedoc_ind;

        if (data[i].insp_status == "I" || data[i].insp_status == "S") {
          totalPounds += parseFloat(isNumeric(data[i].net_wt) ? data[i].net_wt : 0);
          totalValue += parseFloat(isNumeric(data[i].basis_grade_amt) ? data[i].basis_grade_amt : 0);
          if (data[i].insp_status == "I") {
            totalPoundsUncont += parseFloat(isNumeric(data[i].net_wt) ? data[i].net_wt : 0);
          }
        }

        let contracts = data[i].contract_info.replace(".", "");
        let vendors = data[i].vendor_info;
        let vendorPCT = data[i].vendor_pct;
        let vendorLBS = data[i].vendor_lbs;
        let WRs = data[i].wr_info.replace(".", "");

        if (contracts.indexOf('|') == -1) {
          obj.txtContractC = contracts.replace("None", "");
        } else {
          arrContracts = contracts.split('|');
          if (arrContracts.length > 0) {
            for (let j = 0; j < arrContracts.length; j++) {
              arrContracts[j] = arrContracts[j].replace("None", "");
              if (isNumeric(arrContracts[j]) == true) {
                obj.txtContractC = "Multi ...";
              }
            }
          }
        }

        if (WRs.indexOf('|') == -1) {
          obj.txtReceipt = FormatCheckNumber(WRs.substring(1));
        } else {
          arrWRs = WRs.split('|');
          if (arrWRs.length > 0) {
            for (let j = 0; j < arrWRs.length; j++) {
              arrWRs[j] = arrWRs[j].replace(".", "");
              obj.txtReceipt = FormatCheckNumber(arrWRs[j].substring(1));
              if (isNumeric(obj.txtReceipt) == true) {
                obj.txtReceipt = "Multi ...";
              }
            }
          }
        }

        if (vendors.indexOf('|') == -1) {
          arrVendors[0] = vendors;
        }
        else {
          arrVendors = vendors.split('|');
        }

        if (vendorPCT.indexOf('|') == -1) {
          arrVendorPCT[0] = vendorPCT;
        }
        else {
          arrVendorPCT = vendorPCT.split('|');
        }

        if (vendorLBS.indexOf('|') == -1) {
          arrVendorLBS[0] = vendorLBS;
        }
        else {
          arrVendorLBS = vendorLBS.split('|');
        }

        if (WRs.indexOf('|') == -1) {
          arrWRs[0] = WRs;
        }
        else {
          arrWRs = WRs.split('|');
        }

        let icalCount = 0;
        for (let k = 0; k < arrVendorLBS.length; k++) {
          let objCont = {};
          objCont.vendors = arrVendors[k];
          objCont.share = arrVendorPCT[k];
          objCont.weightShare = FormatNumber(arrVendorLBS[k], 0);
          if (arrContracts[k] != "" && arrContracts[k] != null && arrContracts[k] != undefined) {
            objCont.shareContNumber = arrContracts[k].replace("None", "");
          }
          if ((data[i].insp_status).toUpperCase() == "S" && icalCount == 0) {
            if (contracts.indexOf('|') != -1 && contracts.indexOf("None") != -1) {
              if (isNumeric(arrContracts[k]) == true) {
                totalPoundsCont += parseFloat(isNumeric(arrVendorLBS[k]) ? arrVendorLBS[k] : 0);
              }
              else {
                if (FormatCheckNumber(arrWRs[k].substring(1)) > 0 && arrWRs[k].substring(0, 1) != "N") {
                  totalPoundsCont += parseFloat(isNumeric(arrVendorLBS[k]) ? arrVendorLBS[k] : 0);
                }
                else {
                  totalPoundsUncont += parseFloat(isNumeric(arrVendorLBS[k]) ? arrVendorLBS[k] : 0);
                }
              }
            }
            else {
              if (isNumeric(arrContracts[k]) == true || isNumeric(contracts) == true) {
                totalPoundsCont += parseFloat(isNumeric(data[i].net_wt) ? data[i].net_wt : 0);
              }
              else {
                if (FormatCheckNumber(arrWRs[k].substring(1)) > 0 && arrWRs[k].substring(0, 1) != "N") {
                  totalPoundsCont += parseFloat(isNumeric(data[i].net_wt) ? data[i].net_wt : 0);
                }
                else {
                  totalPoundsUncont += parseFloat(isNumeric(data[i].net_wt) ? data[i].net_wt : 0);
                }
              }
              icalCount = 1;
            }
          }
          if (FormatCheckNumber(arrWRs[k].substring(1)) > 0) {
            objCont.shareWR = FormatCheckNumber(arrWRs[k].substring(1));
          }
          if (objCont.vendors != "" && objCont.vendors != undefined && objCont.vendors != null) {
            contData.push(objCont);
          }
        }
        if (contData.length > 0) {
          obj.contData = contData;
        }

        gridData.push(obj);
      }
      setValue(thisObj, 'lblNetWeightValue', FormatNumber(totalPounds, 0));
      setValue(thisObj, 'lblContractedValue', FormatNumber(totalPoundsCont, 0));
      setValue(thisObj, 'lblUncontractedValue', FormatNumber(totalPoundsUncont, 0));
      setValue(thisObj, 'lblValueValue', "$" + FormatNumber(totalValue));
      //setValue(thisObj, 'gridFV95SearchData', gridData);
      thisObj.setState((current) => {
        return {
          ...current,
          gridFV95SearchData: {
            ...current["gridFV95SearchData"],
            paginationResetDefaultPage:
              !current.gridFV95SearchData.paginationResetDefaultPage,
          },
        };
      });
    }
    else {
      showMessage(thisObj, "NO SC95/1007 RECORDS FOUND FOR SPECIFIED CRITERIA");
    }
    setLoading(false);
  }

  const Refresh = async () => {
    setLoading(true);
    let value = thisObj.state.gridFV95SearchData.selected[0];
    let sc95changeData = getData(thisObj, 'SC95changeData')
    if(sc95changeData !== null && sc95changeData !== undefined){
      if(sc95changeData.flag){
        value.txtSC95C = sc95changeData.SC95Number
        setData(thisObj, 'SC95changeData', {
          flag : false,
          SC95Number : ''
        })
      }
    }    
    let gridData = getValue(thisObj, 'gridFV95SearchData');
    if (value != undefined) {
      let data = await SettlementService.RetrieveInspectionHeaderList(value.txtSC95C, null, null, "N")
      gridData[value.rowID$].txtSC95C = value.txtSC95C
      switch (data[0].insp_status.trim()) {
        case "P":
          gridData[value.rowID$].txtStatusC = "Pending";
          break;
        case "I":
          if (data[0].settlement_num != 0) {
            gridData[value.rowID$].txtStatusC = "In Process - C";
            gridData[value.rowID$].txtReprint = "N";
          }
          else {
            if (data[0].superseded_settle != 0 && data[0].settlement_num == 0) {
              gridData[value.rowID$].txtStatusC = "In Process - S";
              gridData[value.rowID$].txtReprint = "N";
            }
            else {
              gridData[value.rowID$].txtStatusC = "Inspected";
              gridData[value.rowID$].txtCol1007 = "";
            }
          }
          break;
        case "S":
          if (data[0].kc_trans_ind.trim().toUpperCase() != "Y") {
            gridData[value.rowID$].txtStatusC = "Unsigned";
            gridData[value.rowID$].chkbx1007 = false;
          }
          else {
            if (data[0].kc_trans_date.trim().toUpperCase() != "") {
              if (data[0].settle_correct_cnt > 0 && data[0].superseded_settle.trim().toUpperCase() != "" && data[0].superseded_settle.trim().toUpperCase() != "0") {
                gridData[value.rowID$].txtStatusC = "Settled - SC" + data[0].settle_correct_cnt;
                gridData[value.rowID$].txtReprint = "N";
              }
              else if (data[0].settle_correct_cnt > 0) {
                gridData[value.rowID$].txtStatusC = "Settled - C" + data[0].settle_correct_cnt;
                gridData[value.rowID$].txtReprint = "N";
              }
              else if (data[0].superseded_settle.trim().toUpperCase() != "" && data[0].superseded_settle.trim().toUpperCase() != "0") {
                gridData[value.rowID$].txtStatusC = "Settled - S";
              }
              else {
                gridData[value.rowID$].txtStatusC = "Settled";
              }
              gridData[value.rowID$].chkbx1007 = false;
            }
            else {
              gridData[value.rowID$].txtStatusC = "Signed";
              gridData[value.rowID$].chkbx1007 = false;
            }
          }
          break;
        case "V":
          let voidReasonCode = data[0].void_reason_code.trim().toUpperCase();
          if (voidReasonCode == "F" || voidReasonCode == "V") {
            gridData[value.rowID$].txtStatusC = "Void";
          }
          else if (voidReasonCode == "R") {
            if (isNumeric(value.txt1007C)) {
              gridData[value.rowID$].txtStatusC = "Superseded 1007";
            }
            else {
              gridData[value.rowID$].txtStatusC = "Superseded SC95";
            }
          }
          else {
            gridData[value.rowID$].txtStatusC = "No Sale";
          }
          break;
        case "R":
          if (isNumeric(value.txt1007C)) {
            gridData[value.rowID$].txtStatusC = "Superseded 1007";
          }
          else {
            gridData[value.rowID$].txtStatusC = "Superseded SC95";
          }
          break;
      }
      if (data[0].farm_name == "" || data[0].farm_name == "-") {
        gridData[value.rowID$].txtFarmC = data[0].void_reason_desc;
      }
      else {
        gridData[value.rowID$].txtFarmC = data[0].farm_id + " - " + data[0].farm_suffix + " - " + data[0].farm_name;
      }
      if (data[0].obl_buy_id != "") {
        let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, null, data[0].obl_buy_id);
        if (response.length > 0) {
          gridData[value.rowID$].txtObligationPoint = response[0].buy_pt_id + " - " + response[0].buy_pt_name;
        }
      }
      if (data[0].net_wt.trim() != "") {
        gridData[value.rowID$].txtNetWeight = FormatNumber(data[0].net_wt, 0);
      }
      if (data[0].basis_grade_amt.trim() != "") {
        gridData[value.rowID$].txtValue = "$" + FormatNumber(data[0].basis_grade_amt, 2);
      }
      if (data[0].insp_date_time.trim() != "") {
        gridData[value.rowID$].txtInspectionDate = moment(data[0].insp_date_time.trim()).format('MM/DD/YYYY');
      }
      gridData[value.rowID$].txtStatusHidden = data[0].insp_status;
      gridData[value.rowID$].txtBuyingPoint = data[0].buy_pt_id;
      gridData[value.rowID$].txtOneDocBP = data[0].onedoc_ind;
    }
    buttonUpdates(gridData[value.rowID$])
    setLoading(false);
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function FormatNumber(value, fraction = 2) {
    let formattedNo;
    if (value != null && value != undefined) {
      if (value.toString().indexOf(",") == -1) {
        formattedNo = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction,
        }).format(value);
      } else {
        formattedNo = value.toString();
      }
    } else {
      formattedNo = "";
    }
    return formattedNo;
  }

  function FormatCheckNumber(number) {
    let returnVal = "";
    let index = number.trim().indexOf('S');
    if (index != -1) {
      let subNumber = number.trim().substring(index + 1);
      if (subNumber.length > 0) {
        returnVal = "S" + ("0".repeat(5 - subNumber.length)) + subNumber;
      }
    }
    else {
      if (number.length > 0) {
        returnVal = ("0".repeat(6 - number.length)) + number;
      }
    }
    return returnVal;
  }

  const bChecked1007Found = () => {
    setChecked1007Found(false);
    let gridData = getValue(thisObj, 'gridFV95SearchData');
    if (gridData.length > 0) {
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkbx1007 != undefined) {
          if (gridData[i].chkbx1007 == true) {
            setChecked1007Found(true);
            setData(thisObj,"Checked1007Found", "Y")
            return;
          }
        }
      }
    }
  }

  const PrintChecked1007s = (sCMD) => {
    let gridData = getValue(thisObj, 'gridFV95SearchData');
     //changes for PRB0052917
     if(sCMD=="Reprint" || sCMD=="Worksheet")
     {
       setData(thisObj,"FV95SearchReprint","Y")
     }
    if (gridData.length > 0) {
      let count = 0;
      let bPDF;
      let objData = [];
      for (let i = 0; i < gridData.length; i++) {
        let obj = {};
        let sPath = "", strDevPrint = "";
        if (gridData[i].chkbx1007 == true) {
          count++;
          obj.SC95No = gridData[i].txtSC95C;
          obj.s1007No = gridData[i].txt1007C;
          obj.sStatus = gridData[i].txtStatusC;
          obj.sBuyPt = gridData[i].txtBuyingPoint;
          let FV95SearchReprint=  getData(thisObj,"FV95SearchReprint");
          if (isNumeric(obj.s1007No)) {
            if (count == 1 && FV95SearchReprint !="Y") {

              const confirmBox = window.confirm(
                "Do you want to save as a PDF file instead of printing to paper?"
              )
              if (confirmBox == true) {
                bPDF = true;
              }
              else {
                bPDF = false;
              }
              obj.mbPrintFirst = true;
              obj.msPrintPDFPath = "";
            }
            else {
              obj.mbPrintFirst = false;
              obj.msPrintPDFPath = sPath;
            }
            obj.pmViewDoc = true;
            obj.mbPrintSelected = true;
            obj.mbPrintPDF = bPDF;
            obj.strDev1007PrintOpt = strDevPrint;

            if (sCMD == "Reprint") {
              if (obj.sStatus.trim().substring(0, 11) == "SETTLED - C" || obj.sStatus.trim().substring(0, 11) == "SETTLED - SC") {
                obj.method_name = "rptCorrectionDocument";
                obj.bDisplayForm = false;
                obj.bBypassCorrectionLogging = true;
              }
              else {
                if (obj.SC95No == obj.s1007No) {
                  obj.method_name = "rptInspectionCertificate_onedoc";
                  obj.type = "REPRINT_WITH_CHECKBOX"
                }
                else {
                  obj.method_name = "rptInspectionCertificate";
                  obj.type = "REPRINT_FROM_HEADER";
                }
              }
            }
            else {
              obj.method_name = "rptSettlementWorkSheet";
            }
            obj.WaterMark = obj.sStatus
            objData.push(obj);
          }
        }
      }
      UnCheck1007Found();
      setData(thisObj, 'ReportPreviewInspectionScreenData', objData);
      goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click");
    }
  }
//changes for PRB0052917
const UnCheck1007Found = () => {
  // setChecked1007Found(false);
   let gridData = getValue(thisObj, "gridFV95SearchData");
   if (gridData.length > 0) {
     for (let i = 0; i < gridData.length; i++) {
       if (gridData[i].chkbx1007 != undefined) {
         if (gridData[i].chkbx1007 == true) {
           //setChecked1007Found(true);
           gridData[i].chkbx1007 =false;
          // return;
         }
       }
     }
   }
 };
  //------events

  const defaultButtonView = () => {
    show(thisObj, 'btnSearch');
    enable(thisObj, 'btnSearch');
    show(thisObj, 'btnReprint');
    enable(thisObj, 'btnReprint');
    show(thisObj, 'btnPrintWorkSheet');
    enable(thisObj, 'btnPrintWorkSheet');
    show(thisObj, 'btnSignSettlements');
    disable(thisObj, 'btnSignSettlements');
    show(thisObj, 'btnUnSignSettlements');
    disable(thisObj, 'btnUnSignSettlements');
    show(thisObj, 'btnOpenSC95');
    disable(thisObj, 'btnOpenSC95');
    show(thisObj, 'btnOpen1007');
    disable(thisObj, 'btnOpen1007');
    hide(thisObj, 'btnViewSettlements', false);
    disable(thisObj, 'btnViewSettlements');
  }

  const onbtnOpen1007Click = () => {
    let value = thisObj.state.gridFV95SearchData.selected[0];
    if (value != undefined) {
      ContractManagementService.RetrieveAccessPermissionDetails('PN1050', null, 'U').then(response => {
        if (response[0].permission == 'N') {
          showMessage(thisObj, "Invalid Security Permissions!!!");
          return;
        }
        let data = {
          "FV95_NUM": value.txtSC95C,
          "FV1007_NUM": value.txt1007C,
          "BuyPtId": value.txtBuyingPoint
        }
        setData(thisObj, '1007SettlementScreenData', data);
        goTo(thisObj, "Settlements#1007Settlement#DEFAULT#true#Click");
      })
    }
  }
  thisObj.onbtnOpen1007Click = onbtnOpen1007Click;

  const onbtnOpenSC95Click = () => {
    setData(thisObj,'stripRequire','Y')
    let value = thisObj.state.gridFV95SearchData.selected[0];
    if (value != undefined) {
      if (value.txtStatusC.toUpperCase() == "RESIDUE LOAD" || value.txtStatusC.toUpperCase() == "RESIDUE LOAD-S") {
        let data = {
          "C_ORG_BUY_POINT": value.txtBuyingPoint,
          "C_FV95_NUM": value.txtSC95C,
          "type": "EDIT"
        }
        setData(thisObj, 'FV95ResidueScreenData', data);
        goTo(thisObj, "Settlements#FV95Residue#DEFAULT#true#Click");
      }
      else {
        let data = {
          "C_FV95_NUM": value.txtSC95C,
          "type": "EDIT"
        }
        setData(thisObj, 'FV95ScreenData', data);
        goTo(thisObj, "Settlements#FV95#DEFAULT#true#Click");
      }
    }
  }
  thisObj.onbtnOpenSC95Click = onbtnOpenSC95Click;

  const onbtnViewTransferFailuresClick = () => {
    let value = thisObj.state.gridFV95SearchData.selected[0];
    let insp_num;
    if (value != undefined) {
      insp_num = value.txtSC95C;
    }
    else {
      insp_num = "";
    }
    let obj = {
      "inspection_number": insp_num,
      "buying_point": getValue(thisObj, 'ddBuyingPoint')
    }
    setData(thisObj, 'ViewUploadsScreenData', obj);
    goTo(thisObj, "Settlements#ViewUploads#DEFAULT#true#Click");
  }
  thisObj.onbtnViewTransferFailuresClick = onbtnViewTransferFailuresClick;

  const onbtnSignSettlementsClick = async () => {
    setLoading(true);
    let gridData = getValue(thisObj, 'gridFV95SearchData');
    let notSigned = "";
    if (gridData.length > 0) {
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkbx1007 == true && gridData[i].txtStatusC.toUpperCase() == "UNSIGNED") {
          let data = await SettlementService.UpdateSettlementSignDetails(gridData[i].txtBuyingPoint, gridData[i].txtSC95C)
          if (data.status == 200) {
            gridData[i].chkbx1007 = false;
            gridData[i].txtStatusC = "Signed";
          }
          else {
            if (notSigned == "") {
              notSigned = gridData[i].txtSC95C;
            }
            else {
              notSigned += "," + gridData[i].txtSC95C;
            }
          }
        }
      }
      if (notSigned == "") {
        showMessage(thisObj, "All selected settlements have been successfully signed!!!", true)
        disable(thisObj, 'btnSignSettlements');
      }
      else {
        showMessage(thisObj, "Some of the selected settlements were not able to be signed!!! Please review the settlements that are still selected (with a checkmark).");
      }
      setLoading(false);
    }
  }
  thisObj.onbtnSignSettlementsClick = onbtnSignSettlementsClick;

  const onbtnUnSignSettlementsClick = async () => {
    setLoading(true);
    let gridData = getValue(thisObj, 'gridFV95SearchData');
    let notUnSigned = "";
    if (gridData.length > 0) {
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkbx1007 == true && gridData[i].txtStatusC.toUpperCase() == "SIGNED") {
          let data = await SettlementService.UpdateUnSignSettlement(gridData[i].txtBuyingPoint, gridData[i].txtSC95C)
          if (data.status == 200) {
            gridData[i].chkbx1007 = false;
            gridData[i].txtStatusC = "Unsigned";
          }
          else {
            if (notUnSigned == "") {
              notUnSigned = gridData[i].txtSC95C;
            }
            else {
              notUnSigned += "," + gridData[i].txtSC95C;
            }
          }
        }
      }
      if (notUnSigned == "") {
        showMessage(thisObj, "All selected settlements have been successfully unsigned!!!", true)
        disable(thisObj, 'btnUnSignSettlements');
      }
      else {
        showMessage(thisObj, "Some of the selected settlements were not able to be unsigned!!! Please review the settlements that are still selected (with a checkmark).");
      }
      setLoading(false);
    }
  }
  thisObj.onbtnUnSignSettlementsClick = onbtnUnSignSettlementsClick;

  const onchkbx1007Change = () => {
    let gridData = getValue(thisObj, 'gridFV95SearchData');
    if (gridData.length > 0) {
      show(thisObj, 'btnSignSettlements');
      disable(thisObj, 'btnSignSettlements');
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkbx1007 != undefined) {
          if (gridData[i].chkbx1007 == true && gridData[i].txtStatusC.toUpperCase() == "UNSIGNED") {
            show(thisObj, 'btnSignSettlements');
            enable(thisObj, 'btnSignSettlements');
            break;
          }
        }
      }
      show(thisObj, 'btnUnSignSettlements');
      disable(thisObj, 'btnUnSignSettlements');
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkbx1007 != undefined) {
          if (gridData[i].chkbx1007 == true && gridData[i].txtStatusC.toUpperCase() == "SIGNED") {
            show(thisObj, 'btnUnSignSettlements');
            enable(thisObj, 'btnUnSignSettlements');
            break;
          }
        }
      }
      //onchkbx1007Change();
    }
  }
  thisObj.onchkbx1007Change = onchkbx1007Change;

  const onchkboxAll1007sChange = () => {
    let checkboxValue = getValue(thisObj, 'chkboxAll1007s');
    let gridData = getValue(thisObj, 'gridFV95SearchData');
    if (gridData.length > 0) {
      for (let i = 0; i < gridData.length; i++) {
        if (checkboxValue == true) {
          if (gridData[i].chkbx1007 !== undefined) {
            gridData[i].chkbx1007 = true;
          }
        }
        else {
          if (gridData[i].chkbx1007 !== undefined) {
            gridData[i].chkbx1007 = false;
          }
        }
      }
      onchkbx1007Change();
    }
    else {
      setValue(thisObj, 'chkboxAll1007s', false);
    }
  }
  thisObj.onchkboxAll1007sChange = onchkboxAll1007sChange;

  const onbtnReprintClick = () => {
    bChecked1007Found();
    let ReChecked1007Found= getData(thisObj,"Checked1007Found")
    if (Checked1007Found == true || ReChecked1007Found=="Y") {
      setData(thisObj,"Checked1007Found","N")
      PrintChecked1007s("Reprint");
      return;
    }
    else {
      let value = thisObj.state.gridFV95SearchData.selected[0];
      if (value != undefined) {
        let obj = {};
        obj.pmViewDoc = true;
        if (isNumeric(value.txtSC95C)) {
          obj.method_name = "rptInspectionCertificate_onedoc";
          obj.type = "REPRINT_WITH_CHECKBOX"
        }
        else {
          obj.method_name = "rptInspectionCertificate";
          obj.type = "REPRINT_FROM_HEADER";
        }
        if (value.txtStatusC.toUpperCase() == "VOID" || value.txtStatusC.toUpperCase() == "NO SALE" || value.txtStatusC.toUpperCase() == "SUPERSEDED 1007") {
          obj.status = "VOID";
        }
        else if (value.txtStatusC.toUpperCase() == "RESIDUE LOAD" || value.txtStatusC.toUpperCase() == "RESIDUE LOAD-S") {
          obj.status = "RESIDUE";
        }
        else {
          obj.status = "";
        }
        obj.SC95No = value.txtSC95C;
        //obj.type = "REPRINT_FROM_HEADER";
        obj.s1007No = value.txt1007C;
        obj.buy_pt_id = getValue(thisObj, 'ddBuyingPoint');
        obj.WaterMark = value.txtStatusC.toUpperCase()
        setData(thisObj, 'ReportPreviewInspectionScreenData', obj);
        goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click");
      }
    }
  }
  thisObj.onbtnReprintClick = onbtnReprintClick;

  const onbtnPrintWorkSheetClick = () => {
    bChecked1007Found();
    let ReChecked1007Found= getData(thisObj,"Checked1007Found")
    if (Checked1007Found == true || ReChecked1007Found=="Y") {
      PrintChecked1007s("Worksheet");
      return;
    }
    else {
      let value = thisObj.state.gridFV95SearchData.selected[0];
      if (value != undefined) {
        let obj = {};
        obj.method_name = "rptSettlementWorkSheet";
        obj.SC95No = value.txtSC95C;
        setData(thisObj, 'ReportPreviewInspectionScreenData', obj);
        goTo(thisObj, "Settlements#ReportPreviewInspection#DEFAULT#true#Click");
      }
    }
  }
  thisObj.onbtnPrintWorkSheetClick = onbtnPrintWorkSheetClick;

  const onbtnCreateManualSC95Click = () => {
    setData(thisObj,'stripRequire','N')
    let residue = getValue(thisObj, 'chkboxIncludeResidueLoads');
    if (residue == true) {
      let data = {
        "type": "ADD"
      }
      setData(thisObj, 'FV95ResidueScreenData', data);
      goTo(thisObj, "Settlements#FV95Residue#DEFAULT#true#Click");
    }
    else {
      let data = {
        "type": "ADD"
      }
      setData(thisObj, 'FV95ScreenData', data);
      goTo(thisObj, "Settlements#FV95#DEFAULT#true#Click");
    }
  }
  thisObj.onbtnCreateManualSC95Click = onbtnCreateManualSC95Click;

  const onbtnViewSettlementsClick = () => {
    let value = thisObj.state.gridFV95SearchData.selected[0];
    if (value != undefined && value != "" && value != null) {
      let viewSettlementScreenData = {
        inspNum: value.txtSC95C,
        buyPtId: value.txtBuyingPoint
      }
      setData(thisObj, 'viewSettlementData', viewSettlementScreenData);
      goTo(thisObj, "Settlements#ViewSettlement#DEFAULT#true#Click");
    }
    else {
      showMessage(thisObj, "Select Inspection to View Settlement");
    }
  }
  thisObj.onbtnViewSettlementsClick = onbtnViewSettlementsClick;

  const onbtnPrintSearchClick = () => {
    var obj = new jsPDF('landscape', 'mm', 'a4');
    let datatable = getValue(thisObj, 'gridFV95SearchData');
    let secondaryRows = thisObj.state.gridFV95SearchData.expandedRows;
    if (datatable != undefined && datatable != null && datatable != "") {
      let type = thisObj.values.radioAsShownExpColl;
      let data = []
      let headerarray = []

      if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
        headerarray.push("SC95#")
      }
      if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
        headerarray.push("1007#")
      }
      if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
        headerarray.push("Status")
      }
      if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
        headerarray.push("Farm")
      }
      if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
        headerarray.push("Origin Point")
      }
      if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
        headerarray.push("Obligation Point")
      }
      if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
        headerarray.push("Peanut Type")
      }
      if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
        headerarray.push("Variety")
      }
      if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
        headerarray.push("Gen")
      }
      if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
        headerarray.push("Seg")
      }
      if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
        headerarray.push("Net Weight")
      }
      if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
        headerarray.push("Value")
      }
      if (thisObj.state.gridFV95SearchData.columns[13].Visible) {
        headerarray.push("Contract#")
      }
      if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
        headerarray.push("Receipt#")
      }
      if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
        headerarray.push("DACO#")
      }
      if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
        headerarray.push("Inspection Date")
      }

      for (let i = 0; i < datatable.length; i++) {
        let bodyarray = []
        let childArray = []

        if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
          bodyarray.push(datatable[i].txtSC95C)
        }
        if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
          bodyarray.push(datatable[i].txt1007C)
        }
        if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
          bodyarray.push(datatable[i].txtStatusC)
        }
        if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
          bodyarray.push(datatable[i].txtFarmC)
        }
        if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
          bodyarray.push(datatable[i].txtOriginPoint)
        }
        if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
          bodyarray.push(datatable[i].txtObligationPoint)
        }
        if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
          bodyarray.push(datatable[i].txtPeanutType)
        }
        if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
          bodyarray.push(datatable[i].txtVariety)
        }
        if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
          bodyarray.push(datatable[i].txtGen)
        }
        if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
          bodyarray.push(datatable[i].txtSegC)
        }
        if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
          bodyarray.push(datatable[i].txtNetWeight)
        }
        if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
          bodyarray.push(datatable[i].txtValue)
        }
        if (thisObj.state.gridFV95SearchData.columns[13].Visible) { 
          bodyarray.push(datatable[i].txtContractC)
        }
        if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
          bodyarray.push(datatable[i].txtReceipt)
        }
        if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
          bodyarray.push(datatable[i].txtDACOC)
        }
        if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
          bodyarray.push(datatable[i].txtInspectionDate)
        }

        
        // data.push(rowdata);

        if (type == 1) {
          if (secondaryRows.length > 0) {
            for (let k = 0; k < secondaryRows.length; k++) {
              if (secondaryRows[k].rowID$ == i) {
                if (secondaryRows[k].contData != undefined) {
                  for (let j = 0; j < secondaryRows[k].contData.length; j++) {
                    let extendedData = []
                    if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
                      extendedData.push(secondaryRows[k].contData[j].vendors)
                    }
                    if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
                      extendedData.push(secondaryRows[k].contData[j].share)
                    }
                    if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
                      extendedData.push(secondaryRows[k].contData[j].weightShare != 0 ? secondaryRows[k].contData[j].weightShare : "")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[13].Visible) {
                      extendedData.push(secondaryRows[k].contData[j].shareContNumber)
                    }
                    if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
                      extendedData.push(secondaryRows[k].contData[j].shareWR)
                    }
                    if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
                      extendedData.push("")
                    }
                    if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
                      extendedData.push("")
                    }
                    
                    childArray.push(extendedData);
                  }
                }
              }
            }
          }
        }

        if (type == 2) {
          if (datatable[i].contData != undefined) {
            for (let j = 0; j < datatable[i].contData.length; j++) {
              let extendedData = []
              if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
                extendedData.push(datatable[i].contData[j].vendors)
              }
              if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
                extendedData.push(datatable[i].contData[j].share)
              }
              if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
                extendedData.push(datatable[i].contData[j].weightShare != 0 ? datatable[i].contData[j].weightShare : "")
              }
              if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[13].Visible) {
                extendedData.push(datatable[i].contData[j].shareContNumber)
              }
              if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
                extendedData.push(datatable[i].contData[j].shareWR)
              }
              if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
                extendedData.push("")
              }
              if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
                extendedData.push("")
              }
              childArray.push(extendedData);
            }
          }
        }
        data.push(bodyarray)
        for (let x = 0; x < childArray.length; x++) {
          data.push(childArray[x])
        }
      }

      data.push("");
      let dataLength = data.length;
      for (let k = dataLength; k < dataLength + 4; k++) {
        let rowdata = [];
        if (k == dataLength) {
          if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
            rowdata.push('Totals:')
          }
          if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
            rowdata.push(getValue(thisObj, 'lblNetWeightValue'))
          }
          if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
            rowdata.push(getValue(thisObj, 'lblValueValue'))
          }
          if (thisObj.state.gridFV95SearchData.columns[13].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
            rowdata.push("")
          }
        }
        if (k == dataLength + 2) {
          if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
            rowdata.push('Contracted:')
          }
          if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
            rowdata.push(getValue(thisObj, 'lblContractedValue'))
          }
          if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[13].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
            rowdata.push("")
          }
        }
        if (k == dataLength + 3) {
          if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
            rowdata.push('Uncontracted:')
          }
          if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
            rowdata.push(getValue(thisObj, 'lblUncontractedValue'))
          }
          if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[13].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
            rowdata.push("")
          }
          if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
            rowdata.push("")
          }
        }
        data.push(rowdata);
      }

      obj.autoTable({
        startY: 10,
        startX: 10,
        headStyles: { fontSize: 6 },
        styles: { fontSize: 6, lineWidth: 0.2, color: 10 },
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerarray],
        body: data
      });
     
      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(7);
        obj.text(Number(sessionStorage.getItem('year')) + ' SC95 SEARCH GRID', 10, obj.internal.pageSize.height - 10);
      }
      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Failed to print , please provide proper name to file")
        }
      }
    }
  }
  thisObj.onbtnPrintSearchClick = onbtnPrintSearchClick;

  const onbtnExportToExcelClick = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let datatable = getValue(thisObj, 'gridFV95SearchData');
    let secondaryRows = thisObj.state.gridFV95SearchData.expandedRows;
    var fileName = "SC95 Search";
    let type = thisObj.values.radioAsShownExpColl;
    let excelData = [];
    if (datatable != undefined) {
      for (let i = 0; i < datatable.length; i++) {
        let rowdata = {};
        if (thisObj.state.gridFV95SearchData.columns[1].Visible) {
          rowdata["SC95#"] = datatable[i].txtSC95C;
        }
        if (thisObj.state.gridFV95SearchData.columns[2].Visible) {
          rowdata["1007#"] = datatable[i].txt1007C;
        }
        if (thisObj.state.gridFV95SearchData.columns[3].Visible) {
          rowdata["Status"] = datatable[i].txtStatusC;
        }
        if (thisObj.state.gridFV95SearchData.columns[4].Visible) {
          rowdata["Farm"] = datatable[i].txtFarmC;
        }
        if (thisObj.state.gridFV95SearchData.columns[5].Visible) {
          rowdata["Origin Point"] = datatable[i].txtOriginPoint;
        }
        if (thisObj.state.gridFV95SearchData.columns[6].Visible) {
          rowdata["Obligation Point"] = datatable[i].txtObligationPoint;
        }
        if (thisObj.state.gridFV95SearchData.columns[7].Visible) {
          rowdata["Peanut Type"] = datatable[i].txtPeanutType;
        }
        if (thisObj.state.gridFV95SearchData.columns[8].Visible) {
          rowdata["Variety"] = datatable[i].txtVariety;
        }
        if (thisObj.state.gridFV95SearchData.columns[9].Visible) {
          rowdata["Gen"] = datatable[i].txtGen;
        }
        if (thisObj.state.gridFV95SearchData.columns[10].Visible) {
          rowdata["Seg"] = ExcelNumericDataConvert(datatable[i].txtSegC);
        }
        if (thisObj.state.gridFV95SearchData.columns[11].Visible) {
          rowdata["Net Weight"] = ExcelNumericDataConvert(datatable[i].txtNetWeight,[',']);
        }
        if (thisObj.state.gridFV95SearchData.columns[12].Visible) {
          rowdata["Value"] = ExcelNumericDataConvert(datatable[i].txtValue,[',','$']);
        }
        if (thisObj.state.gridFV95SearchData.columns[13].Visible) {
          rowdata["Contract#"] = ExcelNumericDataConvert(datatable[i].txtContractC);
        }
        if (thisObj.state.gridFV95SearchData.columns[14].Visible) {
          rowdata["Receipt#"] = datatable[i].txtReceipt;
        }
        if (thisObj.state.gridFV95SearchData.columns[15].Visible) {
          rowdata["DACO#"] = datatable[i].txtDACOC;
        }
        if (thisObj.state.gridFV95SearchData.columns[16].Visible) {
          rowdata["Inspection Date"] = datatable[i].txtInspectionDate;
        }
        excelData.push(rowdata);

        if (type == 1) {
          if (secondaryRows.length > 0) {
            for (let k = 0; k < secondaryRows.length; k++) {
              if (secondaryRows[k].rowID$ == i) {
                if (datatable[i].contData != undefined) {
                  for (let j = 0; j < datatable[i].contData.length; j++) {
                    let extendedData = {};
                    extendedData["Farm"] = datatable[i].contData[j].vendors;
                    extendedData["Variety"] = datatable[i].contData[j].share;
                    extendedData["Net Weight"] = datatable[i].contData[j].weightShare != 0 ? ExcelNumericDataConvert(datatable[i].contData[j].weightShare, [',']) : "";
                    extendedData["Contract#"] = datatable[i].contData[j].shareContNumber;
                    extendedData["Receipt#"] = datatable[i].contData[j].shareWR;
                    excelData.push(extendedData);
                  }
                }
              }
            }
          }
        }

        if (type == 2) {
          if (datatable[i].contData != undefined) {
            for (let j = 0; j < datatable[i].contData.length; j++) {
              let extendedData = {};
              extendedData["Farm"] = datatable[i].contData[j].vendors;
              extendedData["Variety"] = datatable[i].contData[j].share;
              extendedData["Net Weight"] = datatable[i].contData[j].weightShare != 0 ? ExcelNumericDataConvert(datatable[i].contData[j].weightShare, [',']) : "";
              extendedData["Contract#"] = datatable[i].contData[j].shareContNumber;
              extendedData["Receipt#"] = datatable[i].contData[j].shareWR;
              excelData.push(extendedData);
            }
          }
        }
      }

      excelData.push("");
      let dataLength = excelData.length;
      for (let k = dataLength; k < dataLength + 4; k++) {
        let totals = {};
        if (k == dataLength) {
          totals["Variety"] = "Totals:";
          totals["Net Weight"] = ExcelNumericDataConvert(getValue(thisObj, 'lblNetWeightValue'), [',']);
          totals["Value"] = ExcelNumericDataConvert(getValue(thisObj, 'lblValueValue'), [',', '$']);
        }
        if (k == dataLength + 2) {
          totals["Variety"] = "Contracted:";
          totals["Net Weight"] = ExcelNumericDataConvert(getValue(thisObj, 'lblContractedValue'), [',']);
        }
        if (k == dataLength + 3) {
          totals["Variety"] = "Uncontracted:";
          totals["Net Weight"] = ExcelNumericDataConvert(getValue(thisObj, 'lblUncontractedValue'), [',']);
        }
        excelData.push(totals);
      }


      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;

  const onbtnTransferSC95FileClick = async () => {
    setSC95Loc("");
    let mLoc = await prompt("Enter a Location ID.");
    if (mLoc != "" && mLoc != undefined && mLoc != null) {
      let strLocation = await mLoc.toUpperCase();
      if (strLocation === "") {
        return;
      } else {

        let buyptdata = await ContractManagementService.RetrieveUserControlDetails(UserId, strLocation, null, null)
        let buyingPoint = buyptdata[0].buyPtId;
        let FTPEnableLog, FTPTimerInterval, FTPRetryLimit, FTP_Method, LocationName, UploadFormat, EFV95UploadSelect;
        let datawithBuyPt = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, null, buyingPoint);
        if (datawithBuyPt.length > 0) {
          buyingPoint = datawithBuyPt[0].buy_pt_id;
          FTPEnableLog = datawithBuyPt[0].ftp_enable_log.toUpperCase();
          FTPTimerInterval = datawithBuyPt[0].ftp_timer_interval;
          FTPRetryLimit = datawithBuyPt[0].ftp_retry_limit;
          FTP_Method = datawithBuyPt[0].ftp_method.toUpperCase();
        }
        let locationMatch = false;
        let data = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1060', null, null, null, null);
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].buy_pt_id == strLocation) {
              LocationName = data[i].buy_pt_name;
              UploadFormat = data[i].insp_upload_format_id.toUpperCase();
              EFV95UploadSelect = data[i].e_fv95_upload_select_ind;
              locationMatch = true;
              break
            }
          }
        }
        if (locationMatch != true) {
          showMessage(thisObj, strLocation + " is not a valid location.  Please enter another Location ID.");
          return;
        }
        if (UploadFormat.trim() != "FL" && UploadFormat.trim() != "SE" && UploadFormat.trim() != "SW" && UploadFormat.trim() != "ST") {
          showMessage(thisObj, "Inspection upload format was not found in Buying Point Control for Location ID: " + strLocation + ". Please select an upload format on the Buying Point Profile screen and then retry.");
          return;
        }
        setSC95Loc(strLocation);
        // invokeModal(true);
        handleShow();
      }
    }
  }
  thisObj.onbtnTransferSC95FileClick = onbtnTransferSC95FileClick;

  // Start method for getting popup and Drag-n Drop the files
  const { getRootProps } = useDropzone({
    accept: ".txt",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )

      if (acceptedFiles != undefined && acceptedFiles != null) {
        if (acceptedFiles[0].name.split('.')[1] != 'txt') {
          // invokeModal(false);
          showMessage(thisObj, "File is not in .txt format");
          return;
        }
        // invokeModal(false);
      }
      state.currentFile = acceptedFiles;
      FTPContract(acceptedFiles);
    }
  })
  // End method for getting popup and Drag-n Drop the files

  async function FTPContract(file) {
    var date = new Date();
    let environmentType;

    const numArr = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]
    var fileNameToProcess = "FV95" + CompId.slice(0, 2) + CropYear + SC95TransferLoc + date.getFullYear() + numArr[date.getMonth() + 1] + numArr[date.getDate()] + numArr[date.getHours()] +
      numArr[date.getMinutes()] + numArr[date.getSeconds()] + ".txt"

    if (process.env.REACT_APP_ENVIR == 'PROD') {
      environmentType = 'ppsprod';
    }
    else if (process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT') {
      environmentType = 'ppstest';
    }
    else {
      environmentType = 'ppstest/dev';
    }

    const formData = new FormData();
    formData.append('FormFile', file[0]);
    formData.append('filename', fileNameToProcess);
    formData.append('environment', environmentType);
    formData.append('filetype', 'txt');
    formData.append('folderType', 'FV95');

    let response = await ContractManagementService.UploadFtpFile(formData)
    if (response != undefined && response != null) {
      if (response.message == 'File transfer successfully') {
        showMessage(thisObj, "File " + file[0].name + " successfully transferred as " + fileNameToProcess, true);
      }
      else if (response.message != 'File transfer successfully') {
        showMessage(thisObj, "Transfer of file " + file[0].name + " failed");
      }
    }
    else if (response == null || response == undefined)  {
      setValue(thisObj, "txtarVerResult", "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
      showMessage(thisObj, "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
    }
  }

  const ongridFV95SearchDataDblclick = () => {
    let value = thisObj.state.gridFV95SearchData.selected[0].txtStatusHidden;
    if (value != "" && value != undefined && value != null) {
      switch (value.trim()) {
        case "P":
          onbtnOpenSC95Click();
          break;
        case "I":
          onbtnOpenSC95Click();
          break;
        case "X":
          onbtnOpenSC95Click();
          break;
        case "S":
          onbtnViewSettlementsClick();
          break;
        case "Z":
          onbtnOpenSC95Click();
          break;
      }
    }
  }
  thisObj.ongridFV95SearchDataDblclick = ongridFV95SearchDataDblclick;

  const ongridFV95SearchDataRowSelect = (rowSelected) => {
    let data = rowSelected.selectedRows[0];
    let rowChecked = false;
    let gridData = getValue(thisObj, 'gridFV95SearchData');
    if (gridData.length > 0) {
      switch ((data.txtStatusHidden).toUpperCase()) {
        case "P":
          show(thisObj, 'btnOpenSC95');
          enable(thisObj, 'btnOpenSC95');
          disable(thisObj, 'btnOpen1007');
          hide(thisObj, 'btnOpen1007', false);
          disable(thisObj, 'btnViewSettlements');
          hide(thisObj, 'btnViewSettlements', false);
          break
        case "I":
          show(thisObj, 'btnOpenSC95');
          enable(thisObj, 'btnOpenSC95');
          show(thisObj, 'btnOpen1007');
          document.getElementById("btnOpen1007").innerText = "Create 1007"
          enable(thisObj, 'btnOpen1007');
          disable(thisObj, 'btnViewSettlements');
          hide(thisObj, 'btnViewSettlements', false);
          if (data.txtStatusC.toUpperCase().indexOf("IN PROCESS - C") != -1 || data.txtStatusC.toUpperCase().indexOf("IN PROCESS - S") != -1) {
            disable(thisObj, 'btnOpen1007');
            hide(thisObj, 'btnOpen1007', false);
          }
          break
        case "S":
          disable(thisObj, 'btnOpenSC95');
          hide(thisObj, 'btnOpenSC95', false);
          disable(thisObj, 'btnOpen1007');
          hide(thisObj, 'btnOpen1007', false);
          enable(thisObj, 'btnViewSettlements');
          show(thisObj, 'btnViewSettlements');
          break
        case "V":
          disable(thisObj, 'btnOpenSC95');
          hide(thisObj, 'btnOpenSC95', false);
          disable(thisObj, 'btnOpen1007');
          hide(thisObj, 'btnOpen1007', false);
          disable(thisObj, 'btnViewSettlements');
          hide(thisObj, 'btnViewSettlements', false);
          break
        case "R":
          disable(thisObj, 'btnOpenSC95');
          hide(thisObj, 'btnOpenSC95', false);
          disable(thisObj, 'btnOpen1007');
          hide(thisObj, 'btnOpen1007', false);
          disable(thisObj, 'btnViewSettlements');
          hide(thisObj, 'btnViewSettlements', false);
          break
        case "X":
          show(thisObj, 'btnOpenSC95');
          enable(thisObj, 'btnOpenSC95');
          disable(thisObj, 'btnOpen1007');
          hide(thisObj, 'btnOpen1007', false);
          disable(thisObj, 'btnViewSettlements');
          hide(thisObj, 'btnViewSettlements', false);
          break
        case "Z":
          show(thisObj, 'btnOpenSC95');
          enable(thisObj, 'btnOpenSC95');
          disable(thisObj, 'btnOpen1007');
          hide(thisObj, 'btnOpen1007', false);
          disable(thisObj, 'btnViewSettlements');
          hide(thisObj, 'btnViewSettlements', false);
          break
      }
      if (isNumeric(data.txt1007C) == true && data.txtReprint != "N") {
        if (data.txtStatusHidden == "Z") {
          show(thisObj, 'btnReprint');
          enable(thisObj, 'btnReprint');
          disable(thisObj, 'btnPrintWorkSheet');
          hide(thisObj, 'btnPrintWorkSheet', false);
        }
        else {
          show(thisObj, 'btnReprint');
          enable(thisObj, 'btnReprint');
          show(thisObj, 'btnPrintWorkSheet');
          enable(thisObj, 'btnPrintWorkSheet');
        }
      }
      else {
        if ((data.txtOneDocBP).toUpperCase() == "N") {
          if (rowChecked == true) {
            show(thisObj, 'btnReprint');
            enable(thisObj, 'btnReprint');
            show(thisObj, 'btnPrintWorkSheet');
            enable(thisObj, 'btnPrintWorkSheet');
          }
          else {
            bChecked1007Found();
            if (Checked1007Found == true) {
              show(thisObj, 'btnReprint');
              enable(thisObj, 'btnReprint');
              show(thisObj, 'btnPrintWorkSheet');
              enable(thisObj, 'btnPrintWorkSheet');
            }
            else {
              disable(thisObj, 'btnReprint');
              hide(thisObj, 'btnReprint', false);
              disable(thisObj, 'btnPrintWorkSheet');
              hide(thisObj, 'btnPrintWorkSheet', false);
            }
          }
        }
        else {
          show(thisObj, 'btnReprint');
          enable(thisObj, 'btnReprint');
          show(thisObj, 'btnPrintWorkSheet');
          enable(thisObj, 'btnPrintWorkSheet');
        }
      }
    }
    else {
      disable(thisObj, 'btnOpenSC95');
      hide(thisObj, 'btnOpenSC95', false);
      disable(thisObj, 'btnOpen1007');
      hide(thisObj, 'btnOpen1007', false);
      disable(thisObj, 'btnViewSettlements');
      hide(thisObj, 'btnViewSettlements', false);
      bChecked1007Found();
      if (Checked1007Found == true) {
        show(thisObj, 'btnReprint');
        enable(thisObj, 'btnReprint');
        show(thisObj, 'btnPrintWorkSheet');
        enable(thisObj, 'btnPrintWorkSheet');
      }
      else {
        disable(thisObj, 'btnReprint');
        hide(thisObj, 'btnReprint', false);
        disable(thisObj, 'btnPrintWorkSheet');
        hide(thisObj, 'btnPrintWorkSheet', false);
      }
    }
  }
  thisObj.ongridFV95SearchDataRowSelect = ongridFV95SearchDataRowSelect;

  function buttonUpdates(rowSelected) {
    try {
      let data = rowSelected
      let rowChecked = false;
      let gridData = getValue(thisObj, 'gridFV95SearchData');
      if (gridData.length > 0) {
        switch ((data.txtStatusHidden).toUpperCase()) {
          case "P":
            show(thisObj, 'btnOpenSC95');
            enable(thisObj, 'btnOpenSC95');
            disable(thisObj, 'btnOpen1007');
            hide(thisObj, 'btnOpen1007', false);
            disable(thisObj, 'btnViewSettlements');
            hide(thisObj, 'btnViewSettlements', false);
            break
          case "I":
            show(thisObj, 'btnOpenSC95');
            enable(thisObj, 'btnOpenSC95');
            show(thisObj, 'btnOpen1007');
            document.getElementById("btnOpen1007").innerText = "Create 1007"
            enable(thisObj, 'btnOpen1007');
            disable(thisObj, 'btnViewSettlements');
            hide(thisObj, 'btnViewSettlements', false);
            if (data.txtStatusC.toUpperCase().indexOf("IN PROCESS - C") != -1 || data.txtStatusC.toUpperCase().indexOf("IN PROCESS - S") != -1) {
              disable(thisObj, 'btnOpen1007');
              hide(thisObj, 'btnOpen1007', false);
            }
            break
          case "S":
            disable(thisObj, 'btnOpenSC95');
            hide(thisObj, 'btnOpenSC95', false);
            disable(thisObj, 'btnOpen1007');
            hide(thisObj, 'btnOpen1007', false);
            enable(thisObj, 'btnViewSettlements');
            show(thisObj, 'btnViewSettlements');
            break
          case "V":
            disable(thisObj, 'btnOpenSC95');
            hide(thisObj, 'btnOpenSC95', false);
            disable(thisObj, 'btnOpen1007');
            hide(thisObj, 'btnOpen1007', false);
            disable(thisObj, 'btnViewSettlements');
            hide(thisObj, 'btnViewSettlements', false);
            break
          case "R":
            disable(thisObj, 'btnOpenSC95');
            hide(thisObj, 'btnOpenSC95', false);
            disable(thisObj, 'btnOpen1007');
            hide(thisObj, 'btnOpen1007', false);
            disable(thisObj, 'btnViewSettlements');
            hide(thisObj, 'btnViewSettlements', false);
            break
          case "X":
            show(thisObj, 'btnOpenSC95');
            enable(thisObj, 'btnOpenSC95');
            disable(thisObj, 'btnOpen1007');
            hide(thisObj, 'btnOpen1007', false);
            disable(thisObj, 'btnViewSettlements');
            hide(thisObj, 'btnViewSettlements', false);
            break
          case "Z":
            show(thisObj, 'btnOpenSC95');
            enable(thisObj, 'btnOpenSC95');
            disable(thisObj, 'btnOpen1007');
            hide(thisObj, 'btnOpen1007', false);
            disable(thisObj, 'btnViewSettlements');
            hide(thisObj, 'btnViewSettlements', false);
            break
        }
        if (isNumeric(data.txt1007C) == true && data.txtReprint != "N") {
          if (data.txtStatusHidden == "Z") {
            show(thisObj, 'btnReprint');
            enable(thisObj, 'btnReprint');
            disable(thisObj, 'btnPrintWorkSheet');
            hide(thisObj, 'btnPrintWorkSheet', false);
          }
          else {
            show(thisObj, 'btnReprint');
            enable(thisObj, 'btnReprint');
            show(thisObj, 'btnPrintWorkSheet');
            enable(thisObj, 'btnPrintWorkSheet');
          }
        }
        else {
          if ((data.txtOneDocBP).toUpperCase() == "N") {
            if (rowChecked == true) {
              show(thisObj, 'btnReprint');
              enable(thisObj, 'btnReprint');
              show(thisObj, 'btnPrintWorkSheet');
              enable(thisObj, 'btnPrintWorkSheet');
            }
            else {
              bChecked1007Found();
              if (Checked1007Found == true) {
                show(thisObj, 'btnReprint');
                enable(thisObj, 'btnReprint');
                show(thisObj, 'btnPrintWorkSheet');
                enable(thisObj, 'btnPrintWorkSheet');
              }
              else {
                disable(thisObj, 'btnReprint');
                hide(thisObj, 'btnReprint', false);
                disable(thisObj, 'btnPrintWorkSheet');
                hide(thisObj, 'btnPrintWorkSheet', false);
              }
            }
          }
          else {
            show(thisObj, 'btnReprint');
            enable(thisObj, 'btnReprint');
            show(thisObj, 'btnPrintWorkSheet');
            enable(thisObj, 'btnPrintWorkSheet');
          }
        }
      }
      else {
        disable(thisObj, 'btnOpenSC95');
        hide(thisObj, 'btnOpenSC95', false);
        disable(thisObj, 'btnOpen1007');
        hide(thisObj, 'btnOpen1007', false);
        disable(thisObj, 'btnViewSettlements');
        hide(thisObj, 'btnViewSettlements', false);
        bChecked1007Found();
        if (Checked1007Found == true) {
          show(thisObj, 'btnReprint');
          enable(thisObj, 'btnReprint');
          show(thisObj, 'btnPrintWorkSheet');
          enable(thisObj, 'btnPrintWorkSheet');
        }
        else {
          disable(thisObj, 'btnReprint');
          hide(thisObj, 'btnReprint', false);
          disable(thisObj, 'btnPrintWorkSheet');
          hide(thisObj, 'btnPrintWorkSheet', false);
        }
      }
    }
    catch (err) {
      showMessage(thisObj, err.message)
    }
  }

  const onbtnDeleteSC95Click = async () => {
    let value = thisObj.state.gridFV95SearchData.selected[0];
    let originalBuyPt, fv95Number;
    if (value != undefined) {
      let status = (value.txtStatusC).toUpperCase();
      if (status == "PENDING" || status == "VOID" || status == "NO SALE" || status == "PENDING NO SALE" || status == "SUPERSEDED SC95") {
        originalBuyPt = value.txtBuyingPoint.trim().substring(0, 3);
        fv95Number = value.txtSC95C.trim();
        const confirmBox = window.confirm(
          "Are you sure you want to Delete Inspection " + fv95Number + " from location " + originalBuyPt + "?"
        )
        if (confirmBox == true) {
          let data = await SettlementService.RemoveInspection(originalBuyPt, fv95Number);
          if (data.status == 200) {
            let strMessage = "Deleted inspection " + fv95Number + " for location " + originalBuyPt;
            let dataObj = {
              trans_num: 9,
              message: strMessage
            }
            let logData = await ContractManagementService.CreateInspectUploadLogDetails(originalBuyPt, dataObj);
            if (logData.status == 200) {
              showMessage(thisObj, "Inspection " + fv95Number + " from location " + originalBuyPt + " deleted.  Click Search to refresh the list of inspections.");
            }
          }
        }
        else {
          return
        }
      }
      else {
        showMessage(thisObj, "This inspection must be voided before it can be deleted.");
      }
    }
    else {
      showMessage(thisObj, "Select Inspection to Delete");
    }
  }
  thisObj.onbtnDeleteSC95Click = onbtnDeleteSC95Click;

  const handleKeyDownNumeric = (data) => {
    if (data.keyCode != 3 && data.keyCode != 22 && data.keyCode != 24) {
      if (data.keyCode != 8 && data.keyCode != 9 && (data.keyCode < 48 || data.keyCode > 57)) {
        data.preventDefault();
      }
    }
  }

  const handleKeyDownSC95Numeric = (data) => {
    let textlength = (data.currentTarget.value).length;
    if (textlength >= 1) {
      if (data.keyCode != 3 && data.keyCode != 22 && data.keyCode != 24) {
        if (data.keyCode != 8 && data.keyCode != 9 && (data.keyCode < 48 || data.keyCode > 57)) {
          data.preventDefault();
        }
      }
    }
  }

  const ontxtVendorChange = () => {
    let vendorNumber = txtVendor.value;
    if (vendorNumber.length == 6) {
      setVendorNameBasedOnVendorNumber()
      show(thisObj, 'txtSharePercent');
      show(thisObj, 'chkboxPercentOnly');
      show(thisObj, "txtVndor");
    }
    else {
      setValue(thisObj, "txtVndor", '');
      hide(thisObj, "txtVndor", false);
      hide(thisObj, 'txtSharePercent', false);
      hide(thisObj, 'chkboxPercentOnly', false);
    }
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;

  const onddBuyingPointChange = () => {
    bFillFarmList();
    bFillObligationPointList();
    bFillUnloadWhseBinList();
    bFillVarietyList();
  }
  thisObj.onddBuyingPointChange = onddBuyingPointChange;

  const onddObligationPointChange = () => {
    bFillOblWhseBinList();
    bFillVarietyList();
  }
  thisObj.onddObligationPointChange = onddObligationPointChange;

  const onddStateChange = () => {
    bFillCountyList();
  }
  thisObj.onddStateChange = onddStateChange;

  const onddCountyChange = () => {
    bFillFarmList();
  }
  thisObj.onddCountyChange = onddCountyChange;

  const onddPeanutTypeChange = () => {
    bFillVarietyList();
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddStatusChange = () => {
    let statusValue = getValue(thisObj, 'ddStatus');
    if (statusValue == "") {
      show(thisObj, 'chkboxIncludeResidueLoads');
      setValue(thisObj, 'chkboxIncludeResidueLoads', false);
    }
    else {
      hide(thisObj, 'chkboxIncludeResidueLoads', false);
      if (statusValue == "Z") {
        setValue(thisObj, 'chkboxIncludeResidueLoads', true);
      } else {
        setValue(thisObj, 'chkboxIncludeResidueLoads', false);
      }
    }
  }
  thisObj.onddStatusChange = onddStatusChange;

  const onbtnSearchClick = () => {
    let buy_pt = getValue(thisObj, 'ddBuyingPoint');
    let obl_buy_pt = getValue(thisObj, 'ddObligationPoint');
    let sc95 = getValue(thisObj, 'txtSC95');
    let txt1007 = getValue(thisObj, 'txt1007');
    let vendor = getValue(thisObj, 'txtVendor');
    let DACO = getValue(thisObj, 'txtDACO');
    let WR = getValue(thisObj, 'txtWR');
    let contract = getValue(thisObj, 'txtContract');
    if ((buy_pt == "" || buy_pt == undefined) && (obl_buy_pt == "" || obl_buy_pt == undefined) && (sc95 == "" || sc95 == undefined) && (txt1007 == "" || txt1007 == undefined) && (vendor == "" || vendor == undefined) && (DACO == "" || DACO == undefined) && (WR == "" || WR == undefined) && (contract == "" || contract == undefined)) {
      showMessage(thisObj, "SC95#, 1007#, Vendor# WR# or Contract# must be specified for searches across all buying points");
      defaultButtonView();
      let gridData = [];
      setValue(thisObj, 'gridFV95SearchData', gridData);
      setValue(thisObj, 'lblNetWeightValue', '');
      setValue(thisObj, 'lblContractedValue', '');
      setValue(thisObj, 'lblUncontractedValue', '');
      setValue(thisObj, 'lblValueValue', '');
      return;
    }
    Search();
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;
  const handleClose = () => invokeModal(false)
  const handleShow = () => invokeModal(true)
  // END_USER_CODE-USER_METHODS

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={() => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FV95Search*/}

              {/* END_USER_CODE-USER_BEFORE_FV95Search*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFV95Search*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFV95Search*/}

              <GroupBoxWidget conf={state.grpbxFV95Search} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_grpbxNumber*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxNumber*/}

                <GroupBoxWidget conf={state.grpbxNumber} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_lblNumber*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblNumber}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblNumber*/}

                  {/* END_USER_CODE-USER_AFTER_lblNumber*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSC95}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                  {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                  {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSharePercent*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSharePercent*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSharePercent}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSharePercent*/}

                  {/* END_USER_CODE-USER_AFTER_txtSharePercent*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxPercentOnly*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxPercentOnly*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxPercentOnly}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxPercentOnly*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxPercentOnly*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDACO*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDACO*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDACO}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDACO*/}

                  {/* END_USER_CODE-USER_AFTER_txtDACO*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWR*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWR*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWR}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWR*/}

                  {/* END_USER_CODE-USER_AFTER_txtWR*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContract*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContract*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContract}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContract*/}

                  {/* END_USER_CODE-USER_AFTER_txtContract*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxNumber*/}

                {/* END_USER_CODE-USER_AFTER_grpbxNumber*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLocation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLocation*/}
                <GroupBoxWidget conf={state.grpbxLocFarm} screenConf={state}>

                  <GroupBoxWidget conf={state.grpbxLocation} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblLocation*/}

                    {/* END_USER_CODE-USER_BEFORE_lblLocation*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblLocation}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblLocation*/}

                    {/* END_USER_CODE-USER_AFTER_lblLocation*/}
                    {/* START_USER_CODE-USER_BEFORE_ddArea*/}

                    {/* END_USER_CODE-USER_BEFORE_ddArea*/}

                    {/* <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddArea}
                    screenConf={state}
                  ></DropDownWidget> */}
                    {/* START_USER_CODE-USER_AFTER_ddArea*/}

                    {/* END_USER_CODE-USER_AFTER_ddArea*/}
                    {/* START_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                    {/* END_USER_CODE-USER_BEFORE_ddCollectionPoint*/}

                    {/* <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCollectionPoint}
                    screenConf={state}
                  ></DropDownWidget> */}
                    {/* START_USER_CODE-USER_AFTER_ddCollectionPoint*/}

                    {/* END_USER_CODE-USER_AFTER_ddCollectionPoint*/}
                    {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                    {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddBuyingPoint}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                    {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                    {/* START_USER_CODE-USER_BEFORE_ddObligationPoint*/}

                    {/* END_USER_CODE-USER_BEFORE_ddObligationPoint*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddObligationPoint}
                      screenConf={state}
                    ></DropDownWidget>
                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVndor}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_ddObligationPoint*/}

                    {/* END_USER_CODE-USER_AFTER_ddObligationPoint*/}
                  </GroupBoxWidget>
                  <GroupBoxWidget conf={state.grpbxFarm} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblFarm*/}

                    {/* END_USER_CODE-USER_BEFORE_lblFarm*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblFarm}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblFarm*/}

                    {/* END_USER_CODE-USER_AFTER_lblFarm*/}
                    {/* START_USER_CODE-USER_BEFORE_ddState*/}

                    {/* END_USER_CODE-USER_BEFORE_ddState*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddState}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddState*/}

                    {/* END_USER_CODE-USER_AFTER_ddState*/}
                    {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                    {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddCounty}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                    {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                    {/* START_USER_CODE-USER_BEFORE_ddFarm*/}

                    {/* END_USER_CODE-USER_BEFORE_ddFarm*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddFarm}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddFarm*/}

                    {/* END_USER_CODE-USER_AFTER_ddFarm*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxLocation*/}
                </GroupBoxWidget>

                {/* END_USER_CODE-USER_AFTER_grpbxLocation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxPeanut*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPeanut*/}

                <GroupBoxWidget conf={state.grpbxPeanut} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblPeanut*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPeanut*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPeanut}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPeanut*/}

                  {/* END_USER_CODE-USER_AFTER_lblPeanut*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutVariety*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutVariety}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutVariety*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutVariety*/}
                  {/* START_USER_CODE-USER_BEFORE_txtGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_txtGeneration*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtGeneration}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_txtGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeed}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeg*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeg}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeg*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOrganic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOrganic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOrganic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOrganic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOrganic*/}
                  {/* START_USER_CODE-USER_BEFORE_ddHighMoist*/}

                  {/* END_USER_CODE-USER_BEFORE_ddHighMoist*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddHighMoist}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddHighMoist*/}

                  {/* END_USER_CODE-USER_AFTER_ddHighMoist*/}
                </GroupBoxWidget>

                {/* START_USER_CODE-USER_AFTER_grpbxPeanut*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPeanut*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxType*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxType*/}
                <GroupBoxWidget conf={state.grpbxTypeWhse} screenConf={state}>

                  <GroupBoxWidget conf={state.grpbxType} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblType*/}

                    {/* END_USER_CODE-USER_BEFORE_lblType*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblType}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblType*/}

                    {/* END_USER_CODE-USER_AFTER_lblType*/}
                    {/* START_USER_CODE-USER_BEFORE_ddStatus*/}

                    {/* END_USER_CODE-USER_BEFORE_ddStatus*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddStatus}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddStatus*/}

                    {/* END_USER_CODE-USER_AFTER_ddStatus*/}
                    {/* START_USER_CODE-USER_BEFORE_ddDisposition*/}

                    {/* END_USER_CODE-USER_BEFORE_ddDisposition*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddDisposition}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddDisposition*/}

                    {/* END_USER_CODE-USER_AFTER_ddDisposition*/}
                    {/* START_USER_CODE-USER_BEFORE_ddContractType*/}

                    {/* END_USER_CODE-USER_BEFORE_ddContractType*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddContractType}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddContractType*/}

                    {/* END_USER_CODE-USER_AFTER_ddContractType*/}
                    {/* START_USER_CODE-USER_BEFORE_ddReceipted*/}

                    {/* END_USER_CODE-USER_BEFORE_ddReceipted*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddReceipted}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddReceipted*/}

                    {/* END_USER_CODE-USER_AFTER_ddReceipted*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxType*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxType*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxFarm*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxFarm*/}


                  {/* START_USER_CODE-USER_AFTER_grpbxFarm*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxFarm*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxWhseBin*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxWhseBin*/}

                  <GroupBoxWidget conf={state.grpbxWhseBin} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblWhseBin*/}

                    {/* END_USER_CODE-USER_BEFORE_lblWhseBin*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblWhseBin}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblWhseBin*/}

                    {/* END_USER_CODE-USER_AFTER_lblWhseBin*/}
                    {/* START_USER_CODE-USER_BEFORE_ddUnload*/}

                    {/* END_USER_CODE-USER_BEFORE_ddUnload*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddUnload}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddUnload*/}

                    {/* END_USER_CODE-USER_AFTER_ddUnload*/}
                    {/* START_USER_CODE-USER_BEFORE_ddObligation*/}

                    {/* END_USER_CODE-USER_BEFORE_ddObligation*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddObligation}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddObligation*/}

                    {/* END_USER_CODE-USER_AFTER_ddObligation*/}
                  </GroupBoxWidget>
                </GroupBoxWidget>

                {/* START_USER_CODE-USER_AFTER_grpbxWhseBin*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWhseBin*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxMiscOpt*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxMiscOpt*/}

                <GroupBoxWidget conf={state.grpbxMiscOpt} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_chkboxAll1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxAll1007s*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxAll1007s}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxAll1007s*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxAll1007s*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxIncludeResidueLoads*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxIncludeResidueLoads*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxIncludeResidueLoads}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxIncludeResidueLoads*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxIncludeResidueLoads*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInspectionDateFrom*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInspectionDateFrom*/}
                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInspectionDateFrom}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInspectionDateFrom*/}

                  {/* END_USER_CODE-USER_AFTER_txtInspectionDateFrom*/}
                  {/* START_USER_CODE-USER_BEFORE_txtInspectionDateTo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtInspectionDateTo*/}
                  <DateWidget
                    onChange={event =>
                      invokeEventHandler(event, handleChange, handleBlur, thisObj)
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtInspectionDateTo}
                    screenConf={state}
                  ></DateWidget>
                  {/* START_USER_CODE-USER_AFTER_txtInspectionDateTo*/}

                  {/* END_USER_CODE-USER_AFTER_txtInspectionDateTo*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxMiscOpt*/}

                {/* END_USER_CODE-USER_AFTER_grpbxMiscOpt*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFV95Search*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFV95Search*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxSearchResults*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSearchResults*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxSearchResults}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_labelSC95Insp1007Stlmnt*/}

                {/* END_USER_CODE-USER_BEFORE_labelSC95Insp1007Stlmnt*/}

                <LabelWidget
                  values={values}
                  conf={state.labelSC95Insp1007Stlmnt}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_labelSC95Insp1007Stlmnt*/}

                {/* END_USER_CODE-USER_AFTER_labelSC95Insp1007Stlmnt*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnReprint*/}

                {/* END_USER_CODE-USER_BEFORE_btnReprint*/}

                <ButtonWidget
                  conf={state.btnReprint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReprint*/}

                {/* END_USER_CODE-USER_AFTER_btnReprint*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintWorkSheet*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintWorkSheet*/}

                <ButtonWidget
                  conf={state.btnPrintWorkSheet}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintWorkSheet*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintWorkSheet*/}
                {/* START_USER_CODE-USER_BEFORE_btnUnSignSettlements*/}

                {/* END_USER_CODE-USER_BEFORE_btnUnSignSettlements*/}

                <ButtonWidget
                  conf={state.btnUnSignSettlements}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUnSignSettlements*/}

                {/* END_USER_CODE-USER_AFTER_btnUnSignSettlements*/}
                {/* START_USER_CODE-USER_BEFORE_btnSignSettlements*/}

                {/* END_USER_CODE-USER_BEFORE_btnSignSettlements*/}

                <ButtonWidget
                  conf={state.btnSignSettlements}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSignSettlements*/}

                {/* END_USER_CODE-USER_AFTER_btnSignSettlements*/}
                {/* START_USER_CODE-USER_BEFORE_btnOpenSC95*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpenSC95*/}

                <ButtonWidget
                  conf={state.btnOpenSC95}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOpenSC95*/}

                {/* END_USER_CODE-USER_AFTER_btnOpenSC95*/}

                {/* START_USER_CODE-USER_BEFORE_btnOpen1007*/}

                {/* END_USER_CODE-USER_BEFORE_btnOpen1007*/}

                <ButtonWidget
                  conf={state.btnOpen1007}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>

                {/* START_USER_CODE-USER_AFTER_btnOpen1007*/}

                {/* END_USER_CODE-USER_AFTER_btnOpen1007*/}

                {/* START_USER_CODE-USER_BEFORE_btnViewSettlements*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewSettlements*/}

                <ButtonWidget
                  conf={state.btnViewSettlements}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewSettlements*/}

                {/* END_USER_CODE-USER_AFTER_btnViewSettlements*/}
                {/* START_USER_CODE-USER_BEFORE_gridFV95SearchData*/}

                {/* END_USER_CODE-USER_BEFORE_gridFV95SearchData*/}

                <GridWidget
                  conf={state.gridFV95SearchData}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridFV95SearchData}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridFV95SearchData*/}

                {/* END_USER_CODE-USER_AFTER_gridFV95SearchData*/}
                {/* START_USER_CODE-USER_BEFORE_lblTotals*/}

                {/* END_USER_CODE-USER_BEFORE_lblTotals*/}

                <LabelWidget
                  values={values}
                  conf={state.lblTotals}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblTotals*/}

                {/* END_USER_CODE-USER_AFTER_lblTotals*/}
                {/* START_USER_CODE-USER_BEFORE_lblNetWeight*/}

                {/* END_USER_CODE-USER_BEFORE_lblNetWeight*/}

                <LabelWidget
                  values={values}
                  conf={state.lblNetWeight}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblNetWeight*/}

                {/* END_USER_CODE-USER_AFTER_lblNetWeight*/}
                {/* START_USER_CODE-USER_BEFORE_lblNetWeightValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblNetWeightValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblNetWeightValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblNetWeightValue*/}

                {/* END_USER_CODE-USER_AFTER_lblNetWeightValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblContracted*/}

                {/* END_USER_CODE-USER_BEFORE_lblContracted*/}

                <LabelWidget
                  values={values}
                  conf={state.lblContracted}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblContracted*/}

                {/* END_USER_CODE-USER_AFTER_lblContracted*/}
                {/* START_USER_CODE-USER_BEFORE_lblContractedValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblContractedValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblContractedValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblContractedValue*/}

                {/* END_USER_CODE-USER_AFTER_lblContractedValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblUncontracted*/}

                {/* END_USER_CODE-USER_BEFORE_lblUncontracted*/}

                <LabelWidget
                  values={values}
                  conf={state.lblUncontracted}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblUncontracted*/}

                {/* END_USER_CODE-USER_AFTER_lblUncontracted*/}
                {/* START_USER_CODE-USER_BEFORE_lblUncontractedValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblUncontractedValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblUncontractedValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblUncontractedValue*/}

                {/* END_USER_CODE-USER_AFTER_lblUncontractedValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblValue*/}

                {/* END_USER_CODE-USER_AFTER_lblValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblValueValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblValueValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblValueValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblValueValue*/}

                {/* END_USER_CODE-USER_AFTER_lblValueValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSearchResults*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSearchResults*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxAcions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxAcions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxAcions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnTransferSC95File*/}

                {/* END_USER_CODE-USER_BEFORE_btnTransferSC95File*/}

                <ButtonWidget
                  conf={state.btnTransferSC95File}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnTransferSC95File*/}

                {/* END_USER_CODE-USER_AFTER_btnTransferSC95File*/}
                {/* START_USER_CODE-USER_BEFORE_btnViewTransferFailures*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewTransferFailures*/}

                <ButtonWidget
                  conf={state.btnViewTransferFailures}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewTransferFailures*/}

                {/* END_USER_CODE-USER_AFTER_btnViewTransferFailures*/}
                {/* START_USER_CODE-USER_BEFORE_btnDeleteSC95*/}

                {/* END_USER_CODE-USER_BEFORE_btnDeleteSC95*/}

                <ButtonWidget
                  conf={state.btnDeleteSC95}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDeleteSC95*/}

                {/* END_USER_CODE-USER_AFTER_btnDeleteSC95*/}
                {/* START_USER_CODE-USER_BEFORE_btnAuthorizeApplicationEdit*/}

                {/* END_USER_CODE-USER_BEFORE_btnAuthorizeApplicationEdit*/}

                <ButtonWidget
                  conf={state.btnAuthorizeApplicationEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAuthorizeApplicationEdit*/}

                {/* END_USER_CODE-USER_AFTER_btnAuthorizeApplicationEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateManualSC95*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateManualSC95*/}

                <ButtonWidget
                  conf={state.btnCreateManualSC95}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateManualSC95*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateManualSC95*/}
                {/* START_USER_CODE-USER_BEFORE_btnKCMOdataTransmitted*/}

                {/* END_USER_CODE-USER_BEFORE_btnKCMOdataTransmitted*/}

                <ButtonWidget
                  conf={state.btnKCMOdataTransmitted}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnKCMOdataTransmitted*/}

                {/* END_USER_CODE-USER_AFTER_btnKCMOdataTransmitted*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintSearch*/}

                <ButtonWidget
                  conf={state.btnPrintSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintSearch*/}
                {/* START_USER_CODE-USER_BEFORE_radioAsShownExpColl*/}

                {/* END_USER_CODE-USER_BEFORE_radioAsShownExpColl*/}

                <RadioButtonGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.radioAsShownExpColl}
                  screenConf={state}
                ></RadioButtonGroupWidget>
                {/* START_USER_CODE-USER_AFTER_radioAsShownExpColl*/}

                {/* END_USER_CODE-USER_AFTER_radioAsShownExpColl*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btn2*/}

                {/* END_USER_CODE-USER_BEFORE_btn2*/}

                <ButtonWidget
                  conf={state.btn2}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn2*/}

                {/* END_USER_CODE-USER_AFTER_btn2*/}
                {/* START_USER_CODE-USER_BEFORE_btn3*/}

                {/* END_USER_CODE-USER_BEFORE_btn3*/}

                <ButtonWidget
                  conf={state.btn3}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn3*/}

                {/* END_USER_CODE-USER_AFTER_btn3*/}
                {/* START_USER_CODE-USER_BEFORE_btn4*/}

                {/* END_USER_CODE-USER_BEFORE_btn4*/}

                <ButtonWidget
                  conf={state.btn4}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn4*/}

                {/* END_USER_CODE-USER_AFTER_btn4*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxAcions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxAcions*/}

              <ModalPopUp id="blur"
                show={isShow}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                keyboard="false"
                style={{ width: "50%", height: "100%", left: "26%" }}
                autoFocus
                centered
              >
                <ModalPopUp.Header closeButton style={{backgroundColor:"#2c3146", borderBottom:"none", padding:"0"}}>
                </ModalPopUp.Header>
                <ModalPopUp.Body>
                  <div style={{ alignContent: "centered", textAlign: "centered", height: "100%" }}>

                    <div className="dropArea" {...getRootProps()} style={{ border: "1px solid #ccc", textAlign: "centered", height: "100%",backgroundColor:"white" }}>
                      <label htmlFor="dropFile" style={{ textAlign: "center", width: "100%", height: "100%", fontSize: "20px" }} className="btn">
                        <strong> Drag 'n' drop some file here, or click to select file. </strong>
                      </label>
                    </div>
                  </div>
                </ModalPopUp.Body>
              </ModalPopUp>



              {/* START_USER_CODE-USER_AFTER_FV95Search*/}

              {/* END_USER_CODE-USER_AFTER_FV95Search*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_FV95Search);
