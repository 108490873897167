/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  TextAreaWidget,
  GridWidget,
  setValue,
  getData,
  getValue,
  disable,
  enable,
  setData,
  goTo,
  CheckboxWidget,
  DateWidget
} from "../../shared/WindowImports";
import DataTable, { createTheme } from "react-data-table-component";

import "./ExceptPremRequestProfile.scss";
import { ContractManagementService } from "../Service/ContractManagementService";
import { CommonMessages } from "../../Common/Constants";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import CommonContext from "../../Store/CommonContext";
import { set } from "dot-object";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function ContractManagement_ExceptPremRequestProfile(props) {
  const commonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const useridFrom = sessionStorage.getItem('userid');

  // START_USER_CODE-USER_PROPERTIES
  const js = []
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ExceptPremRequestProfile",
    windowName: "ExceptPremRequestProfile",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ExceptPremRequestProfile",
    // START_USER_CODE-USER_ExceptPremRequestProfile_PROPERTIES
    horizontalForm: true,
    callapi: false,
    headerData: {
      scrName: "Exception Premium Request Profile",
      scrCode: "PN1190D"
    },
    // END_USER_CODE-USER_ExceptPremRequestProfile_PROPERTIES
    btnApprove: {
      name: "btnApprove",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Approve",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnApprove_PROPERTIES

      // END_USER_CODE-USER_btnApprove_PROPERTIES
    },
    chkboxAll1007s: {
      name: "chkboxAll1007s",
      type: "CheckBoxWidget",
      parent: "gridExcPremReqProf",
      Label: "All 1007s",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAll1007s_PROPERTIES

      // END_USER_CODE-USER_chkboxAll1007s_PROPERTIES
    },
    chkbxCol: {
      name: "chkbxCol",
      type: "CheckBoxWidget",
      colName: "colChckbx",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
    },
    colChckbx: {
      name: "colChckbx",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChckbx_PROPERTIES

      // END_USER_CODE-USER_colChckbx_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "grpbx1007s",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES

      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    col1007: {
      name: "col1007",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      Label: "1007 #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007_PROPERTIES

      // END_USER_CODE-USER_col1007_PROPERTIES
    },
    colAmnt: {
      name: "colAmnt",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmnt_PROPERTIES

      // END_USER_CODE-USER_colAmnt_PROPERTIES
    },
    colCntrct: {
      name: "colCntrct",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      Label: "Contract #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCntrct_PROPERTIES

      // END_USER_CODE-USER_colCntrct_PROPERTIES
    },
    colPremRate: {
      name: "colPremRate",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      Label: "Premium Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremRate_PROPERTIES

      // END_USER_CODE-USER_colPremRate_PROPERTIES
    },
    colRev: {
      name: "colRev",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      Label: "REV IND",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRev_PROPERTIES

      // END_USER_CODE-USER_colRev_PROPERTIES
    },
    colsc95: {
      name: "colsc95",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      Label: "SC95 #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colsc95_PROPERTIES

      // END_USER_CODE-USER_colsc95_PROPERTIES
    },
    colWr: {
      name: "colWr",
      type: "GridColumnWidget",
      parent: "gridExcPremReqProf",
      Label: "WR #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWr_PROPERTIES

      // END_USER_CODE-USER_colWr_PROPERTIES
    },
    txtcol1007: {
      name: "txtcol1007",
      type: "TextBoxWidget",
      colName: "col1007",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007_PROPERTIES

      // END_USER_CODE-USER_txtcol1007_PROPERTIES
    },
    txtcolAmnt: {
      name: "txtcolAmnt",
      type: "TextBoxWidget",
      colName: "colAmnt",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAmnt_PROPERTIES

      // END_USER_CODE-USER_txtcolAmnt_PROPERTIES
    },
    txtcolCntrct: {
      name: "txtcolCntrct",
      type: "TextBoxWidget",
      colName: "colCntrct",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCntrct_PROPERTIES

      // END_USER_CODE-USER_txtcolCntrct_PROPERTIES
    },
    txtcolPremRate: {
      name: "txtcolPremRate",
      type: "TextBoxWidget",
      colName: "colPremRate",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPremRate_PROPERTIES

      // END_USER_CODE-USER_txtcolPremRate_PROPERTIES
    },
    txtcolRev: {
      name: "txtcolRev",
      type: "TextBoxWidget",
      colName: "colRev",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRev_PROPERTIES

      // END_USER_CODE-USER_txtcolRev_PROPERTIES
    },
    txtcolsc95: {
      name: "txtcolsc95",
      type: "TextBoxWidget",
      colName: "colsc95",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolsc95_PROPERTIES

      // END_USER_CODE-USER_txtcolsc95_PROPERTIES
    },
    txtcolWr: {
      name: "txtcolWr",
      type: "TextBoxWidget",
      colName: "colWr",
      parent: "gridExcPremReqProf",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWr_PROPERTIES

      // END_USER_CODE-USER_txtcolWr_PROPERTIES
    },
    gridExcPremReqProf: {
      name: "gridExcPremReqProf",
      type: "GridWidget",
      parent: "frpbxUpdateExceptPrem",
      gridCellsOrder:
        "colIsSts,txtcol1007,txtcolsc95,txtcolWr,txtcolCntrct,txtcolPremRate,txtcolAmnt,txtcolRev",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isEditable: true,
      isMultiSelect: false,
      // START_USER_CODE-USER_gridExcPremReqSrch_PROPERTIES
      hideAllCheckBox: false,
      needGridChange: false
      // START_USER_CODE-USER_gridExcPremReqProf_PROPERTIES

      // END_USER_CODE-USER_gridExcPremReqProf_PROPERTIES
    },

    btnCreatePayable: {
      name: "btnCreatePayable",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create Payable",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreatePayable_PROPERTIES

      // END_USER_CODE-USER_btnCreatePayable_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnReject: {
      name: "btnReject",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reject",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReject_PROPERTIES

      // END_USER_CODE-USER_btnReject_PROPERTIES
    },
    btnReprint: {
      name: "btnReprint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reprint",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReprint_PROPERTIES

      // END_USER_CODE-USER_btnReprint_PROPERTIES
    },
    btnReverse: {
      name: "btnReverse",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Reverse",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReverse_PROPERTIES

      // END_USER_CODE-USER_btnReverse_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    btnUpdateExceptPrems: {
      name: "btnUpdateExceptPrems",
      type: "ButtonWidget",
      parent: "frpbxUpdateExceptPrem",
      Label: "Update Except Prems",
      CharWidth: "39",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdateExceptPrems_PROPERTIES

      // END_USER_CODE-USER_btnUpdateExceptPrems_PROPERTIES
    },
    ddSpoolLocation: {
      name: "ddSpoolLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxSpoolDetails",
      Label: "Spool Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      Enabled: false,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSpoolLocation_PROPERTIES

      // END_USER_CODE-USER_ddSpoolLocation_PROPERTIES
    },
    grpbxLabels: {
      name: "grpbxLabels",
      type: "GroupBoxWidget",
      parent: "grpbxReqDetails",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLabels_PROPERTIES

      // END_USER_CODE-USER_grpbxLabels_PROPERTIES
    },
    txtApprovedBy: {
      name: "txtApprovedBy",
      type: "TextBoxWidget",
      parent: "grpbxSpoolDetails",
      Label: "Approved By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtApprovedBy_PROPERTIES

      // END_USER_CODE-USER_txtApprovedBy_PROPERTIES
    },
    txtarExceptPrem: {
      name: "txtarExceptPrem",
      type: "TextAreaWidget",
      parent: "frpbxUpdateExceptPrem",
      ColSpan: "2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarExceptPrem_PROPERTIES

      // END_USER_CODE-USER_txtarExceptPrem_PROPERTIES
    },
    txtarExplanation: {
      name: "txtarExplanation",
      type: "TextAreaWidget",
      parent: "grpbxSpoolDetails",
      Label: "Explanation:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarExplanation_PROPERTIES

      // END_USER_CODE-USER_txtarExplanation_PROPERTIES
    },
    txtDateApproved: {
      name: "txtDateApproved",
      type: "DateTimeWidget",
      parent: "grpbxSpoolDetails",
      Label: "Date Approved:",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtDateReq_PROPERTIES
      // END_USER_CODE-USER_txtDateReq_PROPERTIES
    },

    txtDateRequested: {
      name: "txtDateRequested",
      type: "DateTimeWidget",
      parent: "grpbxSpoolDetails",
      Label: "Date Requested:",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtDateReq_PROPERTIES
      // END_USER_CODE-USER_txtDateReq_PROPERTIES
    },

    txtLocation: {
      name: "txtLocation",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Location: ",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES

      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    // txtLocationID: {
    //   name: "txtLocationID",
    //   type: "TextBoxWidget",
    //   parent: "grpbxLabels",
    //   HasLabel: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtLocationID_PROPERTIES

    //   // END_USER_CODE-USER_txtLocationID_PROPERTIES
    // },
    lblLocationID: {
      name: "lblLocationID",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "lblLocationId",
      ColSpan: "1",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGeneral_PROPERTIES

      // END_USER_CODE-USER_lblGeneral_PROPERTIES
    },
    lblVendorID: {
      name: "lblVendorID",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "lblVendorID",
      ColSpan: "1",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGeneral_PROPERTIES

      // END_USER_CODE-USER_lblGeneral_PROPERTIES
    },
    lblPremiumCodeID: {
      name: "lblPremiumCodeID",
      type: "LabelWidget",
      parent: "grpbxLabels",
      Label: "lblPremiumCodeID",
      ColSpan: "1",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGeneral_PROPERTIES

      // END_USER_CODE-USER_lblGeneral_PROPERTIES
    },
    txtPayRequest: {
      name: "txtPayRequest",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Pay Request #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayRequest_PROPERTIES

      // END_USER_CODE-USER_txtPayRequest_PROPERTIES
    },
    txtPremiumCode: {
      name: "txtPremiumCode",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Premium Code:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumCode_PROPERTIES

      // END_USER_CODE-USER_txtPremiumCode_PROPERTIES
    },
    txtPremiumCodeID: {
      name: "txtPremiumCodeID",
      type: "TextBoxWidget",
      parent: "grpbxLabels",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumCodeID_PROPERTIES

      // END_USER_CODE-USER_txtPremiumCodeID_PROPERTIES
    },
    txtRequestedBy: {
      name: "txtRequestedBy",
      type: "TextBoxWidget",
      parent: "grpbxSpoolDetails",
      Label: "Requested By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRequestedBy_PROPERTIES

      // END_USER_CODE-USER_txtRequestedBy_PROPERTIES
    },
    txtSpoolLocID: {
      name: "txtSpoolLocID",
      type: "TextBoxWidget",
      parent: "grpbxSpoolDetails",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpoolLocID_PROPERTIES

      // END_USER_CODE-USER_txtSpoolLocID_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Status:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtTotalAmount: {
      name: "txtTotalAmount",
      type: "TextBoxWidget",
      parent: "frpbxUpdateExceptPrem",
      Label: "Total Amount:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalAmount_PROPERTIES

      // END_USER_CODE-USER_txtTotalAmount_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxReqDetails",
      Label: "Vendor:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorID: {
      name: "txtVendorID",
      type: "TextBoxWidget",
      parent: "grpbxLabels",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorID_PROPERTIES

      // END_USER_CODE-USER_txtVendorID_PROPERTIES
    },
    grpbxReqDetails: {
      name: "grpbxReqDetails",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxReqDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxReqDetails_PROPERTIES
    },
    frpbxUpdateExceptPrem: {
      name: "frpbxUpdateExceptPrem",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_frpbxUpdateExceptPrem_PROPERTIES

      // END_USER_CODE-USER_frpbxUpdateExceptPrem_PROPERTIES
    },
    grpbxSpoolDetails: {
      name: "grpbxSpoolDetails",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSpoolDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxSpoolDetails_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ExceptPremRequestProfile",
      Height: "",
      Width: "",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#ExceptPremiumAdjustments": {},
        "ContractManagement#ReportPreviewExcPrem": {},
        "ContractManagement#ExceptPremRequestSearch": {},
      },
      REVERSE: {
        "ContractManagement#ExceptPremiumAdjustments": {},
        "ContractManagement#ReportPreviewExcPrem": {},
        "ContractManagement#ExceptPremRequestSearch": {},
      },
    },
  };
  let _buttonServices = { ExceptPremiumAdjustments: {}, ReportPreviewExcPrem: {}, ExceptPremRequestSearch: {} };
  let _buttonNavigation = {
    btnUpdateExceptPrems: {
      DEFAULT: [
        "ContractManagement#ExceptPremiumAdjustments#DEFAULT#true#Click",
      ],
    },
    btnApprove: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestSearch#DEFAULT#true#Click",
      ],
    },
    btnDelete: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestSearch#DEFAULT#true#Click",
      ],
    },
    btnReject: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestSearch#DEFAULT#true#Click",
      ],
    },
    btnUpdate: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestSearch#DEFAULT#true#Click",
      ],
    },
    btnReverse: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestSearch#DEFAULT#true#Click",
      ],
    },
    btnCreatePayable: {
      DEFAULT: [
        "ContractManagement#ExceptPremRequestSearch#DEFAULT#true#Click",
      ],
    },
    // btnReprint: {
    //   DEFAULT: [
    //     "ContractManagement#ReportPreviewExcPrem#DEFAULT#true#Click",
    //   ],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  // From load variable
  var mbAuthorizedToDelete = false;
  var mbAuthorizedToUpdate = false;
  var mbAuthorizedToApprove = false;
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setValue(thisObj, "gridExcPremReqProf", js);
    frmExceptPremRequestProfileData()
    // bFillSpoolLoc()
    FormLoad()
    // EnableDisableControl();
  }, [getData(thisObj, 'frmExceptPremRequestProfileData')]);
  useEffect(() => {
    let reload = getData(thisObj, 'ExceptPremRequestProfile_reload')
    if (reload == true) {
      frmExceptPremRequestProfileData()
    }
  }, [getData(thisObj, 'ExceptPremRequestProfile_reload')]);
  const FormLoad = async () => {
    EnableDisableControl();
    mbAuthorizedToDelete = false;
  }
  const EnableDisableControl = async () => {
    // ''''''''''''''''''''''''
    // 'INITIALIZE AS DISABLED.
    // ''''''''''''''''''''''''
    disable(thisObj, 'btnUpdate');
    disable(thisObj, 'btnUpdateExceptPrems');
    disable(thisObj, 'btnApprove');
    disable(thisObj, 'btnCreatePayable');
    disable(thisObj, 'btnReject');
    disable(thisObj, 'btnDelete');
    disable(thisObj, 'btnReverse');
    disable(thisObj, 'btnReprint');
    disable(thisObj, 'txtRequestedBy')
    disable(thisObj, 'txtDateRequested')
    disable(thisObj, 'txtApprovedBy')
    disable(thisObj, 'txtDateApproved')
    disable(thisObj, 'txtExplanation')
    // disable(thisObj, 'ddSpoolLocation')
    disable(thisObj, 'txtarExplanation')

    let lblLocationID = getValue(thisObj, "lblLocationID"), lblVendorID = getValue(thisObj, "lblVendorID"),
      lblPremiumCodeID = getValue(thisObj, "lblPremiumCodeID"), farm_id = null, farm_suffix = null, contract_num = 0
    if (state.callapi == true) {
      GetRetrieveExceptionPremiumAvailable1007Details(lblLocationID, lblVendorID, lblPremiumCodeID, farm_id, farm_suffix, contract_num, null, null, null, null, null, null, null)
    }
    // DISABLE BASED ON SECURITY.

  }
  const EnableBasedStatus = async (statusVal, buyptid) => {
    let buy_pt_id = buyptid;
    let status = statusVal

    let AuthorizedToDelete = await ContractManagementService.RetrieveAccessPermissionDetails('PN1190', null, 'D', buy_pt_id);
    if (AuthorizedToDelete.length > 0 && AuthorizedToDelete[0].permission == 'Y') {
      mbAuthorizedToDelete = true;

    }

    if (mbAuthorizedToDelete) {
      mbAuthorizedToUpdate = true;
    } else {
      let AuthorizedToUpdate = await ContractManagementService.RetrieveAccessPermissionDetails('PN1190', null, 'U', buy_pt_id);
      if (AuthorizedToUpdate.length > 0 && AuthorizedToUpdate[0].permission == 'Y') {
        mbAuthorizedToUpdate = true;
      }
    }

    let AuthorizedToApprove = await ContractManagementService.RetrieveAccessPermissionDetails('PN1190', '003', 'U', buy_pt_id);
    if (AuthorizedToApprove.length > 0 && AuthorizedToApprove[0].permission == 'Y') {

      mbAuthorizedToApprove = true;

    }
    bFillSpoolLoc(status, mbAuthorizedToDelete)
    let value = getValue(thisObj, "txtPayRequest")
    if (value !== '') {
      enable(thisObj, 'btnReprint');
    }
    if (status === "APPROVED") {

      enable(thisObj, 'btnReject');
    }
    if (status === "APPROVED" && mbAuthorizedToDelete) {

      enable(thisObj, 'btnCreatePayable');
      enable(thisObj, 'txtSpoolLocID')

    }
    if (status === "PENDING") {

      enable(thisObj, 'btnApprove');
      enable(thisObj, 'btnUpdate');
      enable(thisObj, 'btnReject');
      enable(thisObj, 'btnDelete');
      enable(thisObj, 'txtRequestedBy')
      enable(thisObj, 'txtDateRequested')
      enable(thisObj, 'txtApprovedBy')
      enable(thisObj, 'txtDateApproved')
      enable(thisObj, 'txtarExplanation')
      disable(thisObj, 'btnCreatePayable');
      disable(thisObj, 'btnReverse');
    }
    if (status === "PAID" && mbAuthorizedToDelete) {
      enable(thisObj, 'btnReverse')
    }
    if (status === "REJECTED") {
      enable(thisObj, 'btnUpdate')
      enable(thisObj, 'btnApprove');
      enable(thisObj, 'btnDelete');
      enable(thisObj, 'txtRequestedBy')
      enable(thisObj, 'txtDateRequested')
      enable(thisObj, 'txtApprovedBy')
      enable(thisObj, 'txtDateApproved')
      enable(thisObj, 'txtarExplanation')
    }
    if (!mbAuthorizedToUpdate) {
      disable(thisObj, 'btnUpdateExceptPrems');
      disable(thisObj, 'btnCreatePayable');
      disable(thisObj, 'btnReject');
      disable(thisObj, 'btnUpdate');
      disable(thisObj, 'btnReverse')
      disable(thisObj, 'txtRequestedBy')
      disable(thisObj, 'txtDateRequested')
      disable(thisObj, 'txtExplanation')
      disable(thisObj, 'txtSpoolLocID')

    }
    if (status !== "PENDING") {
      disable(thisObj, 'btnUpdateExceptPrems');

    }
    if (!mbAuthorizedToDelete) {
      disable(thisObj, 'btnDelete');
    }
    if (!mbAuthorizedToApprove) {
      disable(thisObj, 'btnApprove')
      disable(thisObj, 'txtApprovedBy')
      disable(thisObj, 'txtDateApproved')
    }
  }

  function bFillSpoolLoc(Status, mbAuthorizedToDelete) {
    let func_id = 'PN1190', func_sub_id = null, area_id = null, coll_pt_id = null
    let js = [];
    js.push({ key: '', description: '-' });
    ContractManagementService.RetrieveBuyingPointControlDetails(func_id, func_sub_id, area_id, coll_pt_id, null).then(response => {
      let data = response;
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id.trim(), description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          js.push(obj);

        }
        if (Status === "APPROVED" && mbAuthorizedToDelete) {
          thisObj.setState(current => {
            return {
              ...current,
              ddSpoolLocation: {
                ...state["ddSpoolLocation"],
                Enabled: true,
                valueList: js
              },
            }
          });
        }
        else {
          thisObj.setState(current => {
            return {
              ...current,
              ddSpoolLocation: {
                ...state["ddSpoolLocation"],
                valueList: js,
                Enabled: false,
              },
            }
          });
        }
      }
    });
  }

  const GetRetrieveUserControlDetails = (uid, buy_pt_id, approve_by, approve_date_time, request_explanation) => {
    ContractManagementService.RetrieveUserControlDetails(uid, buy_pt_id).then(response => {
      let data = response;
      if (approve_by == '') {
        setValue(thisObj, "txtApprovedBy", data[0].userName);
      }
      if (approve_date_time == '') {
        let setdatetime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
        var dateTime = moment(String(setdatetime)).format('MM/DD/YYYY h:mm A');
        setValue(thisObj, 'txtDateApproved', dateTime)
      }
      if (request_explanation !== '') {
        PerformRequestAction('APPROVE')
      }
    });
  }
  const PerformRequestAction = (action) => {
    setData(thisObj, "flagToggle", action);
    let setdatetime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
    let request_memo_num = getValue(thisObj, 'txtPayRequest')
    let request_by = getValue(thisObj, 'txtRequestedBy')
    let approve_by = getValue(thisObj, 'txtApprovedBy')
    let request_date12 = getValue(thisObj, 'txtDateRequested')
    let request_date_time = request_date12 !== '' ? new Date(request_date12).toISOString() : null
    let approve_date = getValue(thisObj, 'txtDateApproved')
    let approve_date_time = approve_date !== "" ? new Date(approve_date).toISOString() : null
    let request_explanation = getValue(thisObj, 'txtarExplanation')
    let spool_buy_pt_id = getValue(thisObj, 'ddSpoolLocation')
    
    let useridFromLS = sessionStorage.getItem('userid')

    let objSave = {
      "user_id": useridFromLS,
      "spool_buy_pt_id": spool_buy_pt_id,
      "request_by": request_by,
      "request_date_time": request_date_time,
      "approve_by": approve_by,
      "approve_date_time": approve_date_time,
      "request_explanation": request_explanation,
    }
    ContractManagementService.UpdateExceptionPremiumRequest(request_memo_num, action, objSave).then(response => {

    });
    document.getElementById("ContractManagement_ExceptPremRequestProfilePopUp").childNodes[0].click();
  };
  const GetRetrieveExceptionPremiumAvailable1007Details = (buy_pt_id, prem_vendor, prem_code, farm_id, farm_suffix, contract_num, insp_num, pnut_type_id, pnut_variety_id, seed_ind, seed_gen, seg_type, oleic_ind) => {
    ContractManagementService.RetrieveExceptionPremiumAvailable1007Details(buy_pt_id, prem_vendor, prem_code, farm_id, farm_suffix, contract_num, insp_num, pnut_type_id, pnut_variety_id, seed_ind, seed_gen, seg_type, oleic_ind).then(response => {
      let data = response;
      if (data !== undefined && data.length > 0) {
        let js = [];
        let obj;
        for (var i = 0; i < data.length; i++) {
          obj = { key: data[i].recordKey, description: data[i].pricePerTon + ' ' + data[i].oleicInd + ' ' + data[i].pricingTypeDesc };
          js.push(obj);
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddPrc: {
              ...state["ddPrc"],
              valueList: js
            }
          }
        });
        thisObj.setState(current => {
          return {
            ...current,
            ddPrice: {
              ...state["ddPrice"],
              valueList: js
            }
          }
        });
      }
    });
  };
  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'USD'
    }).format(value);

  const frmExceptPremRequestProfileData = async () => {
    let frmExceptPremRequestProfileData = getData(thisObj, 'frmExceptPremRequestProfileData');

    if (frmExceptPremRequestProfileData !== null) {
      let payrequest = frmExceptPremRequestProfileData.txtPayRequestC
      let VendorC = frmExceptPremRequestProfileData.txtVendorC

      ContractManagementService.RetrieveExceptionPremiumRequests(payrequest, null).then(response => {
        let data = response;
        if (data.length > 0) {
          let js = [];
          for (var i = 0; i < data.length; i++) {
            commonContext.setExceptPremCreate_MemoNum(data[i].request_memo_num)

            let Status = data[i].request_status
            if (Status !== '' && Status !== undefined) {
              EnableBasedStatus(Status, data[i].buy_pt_id)
            }
            var vendor_name = "", premium_code = "", localtion_name = ""
            vendor_name = data[i].vendor_name
            premium_code = data[i].prem_code + '-' + data[i].pd_desc
            localtion_name = data[i].buy_pt_id + '-' + data[i].buy_pt_name
            let requestdate = data[i].request_date_time
            let approvedate = data[i].approve_date_time
            let requestdatetime = requestdate !== '' ? moment(requestdate).format('MM/DD/YYYY h:mm A') : ''
            let approvedatetime = approvedate !== '' ? moment(approvedate).format('MM/DD/YYYY h:mm A') : ''
            setValue(thisObj, "lblLocationID", data[i].buy_pt_id);
            setValue(thisObj, "lblVendorID", data[i].prem_vendor);
            setValue(thisObj, "lblPremiumCodeID", data[i].prem_code);
            thisObj.setFieldValue("lblVendor", vendor_name);
            setValue(thisObj, "txtPayRequest", data[i].request_memo_num);
            setValue(thisObj, "txtStatus", data[i].request_status);
            thisObj.setFieldValue("txtVendor", VendorC);
            thisObj.setFieldValue("txtPremiumCode", premium_code);
            thisObj.setFieldValue("txtLocation", localtion_name);
            setValue(thisObj, "txtRequestedBy", data[i].request_by);
            setValue(thisObj, "txtDateRequested", requestdatetime);
            setValue(thisObj, "txtApprovedBy", data[i].approve_by);
            setValue(thisObj, "txtDateApproved", approvedatetime);
            setValue(thisObj, "txtarExplanation", data[i].request_explanation);
            setValue(thisObj, "txtSpoolLocID", data[i].spool_buy_pt_id);
            setValue(thisObj, "ddSpoolLocation", data[i].spool_buy_pt_id);
            if (Status === "PENDING") {
              setState({ ...state, gridExcPremReqProf: { ...state.gridExcPremReqProf, isMultiSelect: true, needGridChange: false } })
            }
            let js = [];
            let obj = {}
            let exceptpremrequestdetailsdata = data[i].exceptpremrequestdetails
            for (var i = 0; i < exceptpremrequestdetailsdata.length; i++) {
              obj.colSts = getValue(thisObj, "colSts")
              obj.transaction_num = exceptpremrequestdetailsdata[i].transaction_num;
              obj.txtcol1007 = exceptpremrequestdetailsdata[i].settle_num;
              obj.txtcolsc95 = exceptpremrequestdetailsdata[i].insp_num;
              obj.txtcolWr = exceptpremrequestdetailsdata[i].whse_rcpt_num;
              obj.txtcolCntrct = exceptpremrequestdetailsdata[i].contract_num;
              // obj.txtcolPremRate = exceptpremrequestdetailsdata[i].prem_rate;
              obj.txtcolAmnt = exceptpremrequestdetailsdata[i].prem_amount;
              obj.txtcolRev = exceptpremrequestdetailsdata[i].reversal_ind;
              const premratebase = exceptpremrequestdetailsdata[i].prem_rate_base
              obj.txtcolPremRate = premratebase === "G" ? exceptpremrequestdetailsdata[i].prem_rate + ' ' + "per Gross Ton" :
                premratebase === "N" ? exceptpremrequestdetailsdata[i].prem_rate + ' ' + "per Net Ton" :
                  premratebase === "K" ? exceptpremrequestdetailsdata[i].prem_rate + ' ' + "per Net less LSK Ton" :
                    premratebase === "B" ? exceptpremrequestdetailsdata[i].prem_rate + ' ' + "per Net Ton Basis Grade" :
                      premratebase === "L" ? exceptpremrequestdetailsdata[i].prem_rate + ' ' + "per Load" :
                        ""

              let lenInfo = exceptpremrequestdetailsdata.length;
              let valueAdded = 0
              for (let i = 0; i < lenInfo; i++) {
                valueAdded = valueAdded + parseFloat(exceptpremrequestdetailsdata[i].prem_amount)
              }
              let value = numberFormat(valueAdded)
              setValue(thisObj, 'txtTotalAmount', value)
              js.push(obj)
              obj = {}
            }

            setValue(thisObj, "gridExcPremReqProf", js)

          }

        }
      });
    }
  };
  const dateIsValid = (dateEntered) => {

    if (dateEntered !== "") {
      let dateFormat = 'MM/DD/YYYY h:mm A';
      let datavaild = moment(dateEntered, dateFormat, true).isValid();
      return datavaild

    }

  }


  const onbtnUpdateExceptPremsClick = () => {
    let rowselectedvalue_gridExcPremReqProf = getValue(thisObj, "gridExcPremReqProf")
    let js = [];
    let obj = {}
    let array = []
    array.push(rowselectedvalue_gridExcPremReqProf)
    obj.lblPayRequestNumber = getValue(thisObj, "txtPayRequest")
    obj.lblLocation = getValue(thisObj, "txtLocation")
    obj.lblVendor = getValue(thisObj, "txtVendor")
    obj.lblPremiumCode = getValue(thisObj, "txtPremiumCode")
    obj.lblStatus = getValue(thisObj, "txtStatus")
    obj.lblLocationID = getValue(thisObj, "lblLocationID")
    obj.lblVendorID = getValue(thisObj, "lblVendorID")
    obj.lblPremiumCodeID = getValue(thisObj, "lblPremiumCodeID")
    obj.UpdatedExcepPremList = array
    js.push(obj)
    obj = {}

    setTimeout(() => {
      let selElem = rowselectedvalue_gridExcPremReqProf.filter(elem => elem.isSelected === true);
      if (selElem.length > 0) {
        let js = [];
        let obj = {}
        let array = []
        array.push(selElem)
        obj.lblPayRequestNumber = getValue(thisObj, "txtPayRequest")
        obj.lblLocation = getValue(thisObj, "txtLocation")
        obj.lblVendor = getValue(thisObj, "txtVendor")
        obj.lblPremiumCode = getValue(thisObj, "txtPremiumCode")
        obj.lblStatus = getValue(thisObj, "txtStatus")
        obj.lblLocationID = getValue(thisObj, "lblLocationID")
        obj.lblVendorID = getValue(thisObj, "lblVendorID")
        obj.lblPremiumCodeID = getValue(thisObj, "lblPremiumCodeID")
        obj.UpdatedExcepPremList = array
        js.push(obj)
        obj = {}
        commonContext.setDataForExceptPremiumAdjustment(js)
        goTo(thisObj, "ContractManagement#ExceptPremiumAdjustments#DEFAULT#true#Click")
      } else {
        let js = [];
        commonContext.setDataForExceptPremiumAdjustment(js)
        showMessage(thisObj, 'Must select at least one Exception Premium in the result grid!!!', false);
      }
    })
    if (rowselectedvalue_gridExcPremReqProf == null) {
      let js = [];
      commonContext.setDataForExceptPremiumAdjustment(js)
      showMessage(thisObj, 'Must select at least one Exception Premium in the result grid!!!', false);
    } else if (rowselectedvalue_gridExcPremReqProf.length > 0) {
      obj.lblPayRequestNumber = getValue(thisObj, "txtPayRequest")
      obj.lblLocation = getValue(thisObj, "txtLocation")
      obj.lblVendor = getValue(thisObj, "txtVendor")
      obj.lblPremiumCode = getValue(thisObj, "txtPremiumCode")
      obj.lblStatus = getValue(thisObj, "txtStatus")
      obj.lblLocationID = getValue(thisObj, "lblLocationID")
      obj.lblVendorID = getValue(thisObj, "lblVendorID")
      obj.lblPremiumCodeID = getValue(thisObj, "lblPremiumCodeID")
      obj.UpdatedExcepPremList = array
      js.push(obj)
      obj = {}
      //setData(thisObj,'frmExceptPremiumAdjustmentsdata',js)
      commonContext.setDataForExceptPremiumAdjustment(js)
      // goTo(thisObj, "ContractManagement#ExceptPremiumAdjustments#DEFAULT#true#Click")
    }
  }
  thisObj.onbtnUpdateExceptPremsClick = onbtnUpdateExceptPremsClick;

  const onbtnUpdateClick = () => {
    try {

      let request_by = getValue(thisObj, 'txtRequestedBy')
      let request_date_time = getValue(thisObj, 'txtDateRequested')
      let approve_date_time = getValue(thisObj, 'txtDateApproved')
      let request_explanation = getValue(thisObj, 'txtarExplanation')
      if (request_by === '') {
        alert('Requested By is required!!!');
      }
      else if (dateIsValid(request_date_time) == false) {
        alert('Date Requested is an invalid date!!!');
      }
      else if (dateIsValid(approve_date_time) == false) {
        alert('Date Approved is an invalid date!!!');
      }
      else if (request_explanation === '') {
        alert('Some Explanation is required!!!');
      } else {
        PerformRequestAction("UPDATE")
      }

    }
    catch (err) {
    }
    return true
  };
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  const onbtnReprintClick = async () => {

    try {

      let req_Memo_Num = getValue(thisObj, 'txtPayRequest')
      if (req_Memo_Num == undefined || req_Memo_Num == null || req_Memo_Num == '') {
        showMessage(thisObj, "Pay Request # is required !");
      }
      else if (req_Memo_Num !== "" || req_Memo_Num !== undefined) {
        var data = await ContractManagementService.RetrieveExceptionPremiumRequests(req_Memo_Num, null);

        var js = [];
        for (var i = 0; i < data[0].exceptpremrequestdetails.length; i++) {
          var obj = {
            "txtCol1007": data[0].exceptpremrequestdetails[i].settle_num,
            "txtColFarm": data[0].exceptpremrequestdetails[i].farm_id,
            "txtColContrct": data[0].exceptpremrequestdetails[i].contract_num,
            "txtColInsp": data[0].exceptpremrequestdetails[i].insp_num,
            "txtColWr": data[0].exceptpremrequestdetails[i].whse_rcpt_num,
            "txtColGross": data[0].exceptpremrequestdetails[i].gross_wt,
            "txtColNetWt": data[0].exceptpremrequestdetails[i].net_wt,
            "txtColLskWt": data[0].exceptpremrequestdetails[i].lsk_wt,
            "txtColBasisGrd": data[0].exceptpremrequestdetails[i].basis_grade_amt,
            "txtColSeg": data[0].exceptpremrequestdetails[i].seg_type,
            "txtColPnutTyp": data[0].exceptpremrequestdetails[i].pnut_type_id,
            "txtColPnutVrty": data[0].exceptpremrequestdetails[i].pnut_variety_id,
            "txtColGen": data[0].exceptpremrequestdetails[i].seed_gen,
            "txtColSeedSpec": data[0].exceptpremrequestdetails[i].seed_meeting_spec_ind,
            "txtColOleic": data[0].exceptpremrequestdetails[i].oleic_ind,
            "txtColPremRate": data[0].exceptpremrequestdetails[i].prem_rate,
            "txtColPremAmnt": data[0].exceptpremrequestdetails[i].prem_amount
          };
          js.push(obj);
        }
        setData(thisObj, 'frmReportPreviewExceptPrem', js)
        commonContext.setExceptPremCreate_MemoNum(req_Memo_Num);
        goTo(thisObj, "ContractManagement#ReportPreviewExcPrem#DEFAULT#true#Click");
      }


    } catch (error) {

    }
  }
  thisObj.onbtnReprintClick = onbtnReprintClick;
  const onbtnApproveClick = () => {
    try {
      let request_by = getValue(thisObj, "txtRequestedBy");
      let approve_by = getValue(thisObj, "txtApprovedBy");
      let request_explanation = getValue(thisObj, "txtarExplanation");
      let buy_pt_id = getValue(thisObj, "lblLocationID");
      let request_date_time = getValue(thisObj, "txtDateRequested");
      let approve_date_time = getValue(thisObj, "txtDateApproved");
      let useridFromLS = sessionStorage.getItem("userid");

      if (request_by === "") {
        showMessage(thisObj, "Requested By is required!!!", false);
      } else if (request_date_time == "") {
        showMessage(thisObj, "Date Requested is an invalid date!!!", false);
      } else if (dateIsValid(request_date_time) === false) {
        showMessage(thisObj, "Date Requested is an invalid date!!!", false);
      } else if (dateIsValid(approve_date_time) === false) {
        showMessage(thisObj, "Date Approved is an invalid date!!!", false);
      } else if (request_explanation == "") {
        showMessage(thisObj, "Some Explanation is required!!!", false);
        GetRetrieveUserControlDetails(
          useridFromLS,
          buy_pt_id,
          approve_by,
          approve_date_time,
          request_explanation
        );
      }
      if (approve_by == "" || approve_date_time == "") {
        return GetRetrieveUserControlDetails(
          useridFromLS,
          buy_pt_id,
          approve_by,
          approve_date_time,
          request_explanation
        );
      }
      if (approve_date_time == "") {
        let setdatetime = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        ).toISOString();
        var dateTime = moment(String(setdatetime)).format("MM/DD/YYYY h:mm A");
        setValue(thisObj, "txtDateApproved", dateTime);
      }
      if (request_explanation !== "") {
        PerformRequestAction("APPROVE");
      }
    } catch (err) {
      showMessage(thisObj, "Error");
    }
  };

  thisObj.onbtnApproveClick = onbtnApproveClick;

  

  async function AssignValue(){
    let locationid=getValue(thisObj, 'lblLocationID');
    setValue(thisObj, "ddSpoolLocation",locationid);
  }

  const onbtnCreatePayableClick = async() => {
    try {
     let spool_buy_pt_id= getValue(thisObj, 'ddSpoolLocation');
        if (spool_buy_pt_id == null || spool_buy_pt_id == "" || spool_buy_pt_id == undefined)
        {
          await AssignValue()
        }
      PerformRequestAction("PAY")
    }
    catch (err) {
      showMessage(thisObj, err.message, false);
    }
  };
  thisObj.onbtnCreatePayableClick = onbtnCreatePayableClick;

  const onbtnRejectClick = () => {
    try {
      setValue(thisObj, "txtApprovedBy", '');
      setValue(thisObj, "txtDateApproved", '');
      setTimeout(() => {
        PerformRequestAction("REJECT")
      }, 1000)
    }
    catch (err) {
    }
  };
  thisObj.onbtnRejectClick = onbtnRejectClick;

  const onbtnDeleteClick = () => {
    try {
      PerformRequestAction("DELETE")
    }
    catch (err) {
    }
  };
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnReverseClick = () => {
    try {
      if (confirm("If any checks for this request are to be VOIDED... You MUST do that BEFORE doing this reverse!!!     Are you sure you want to reverse this exception premium request now?") == true) {
        PerformRequestAction("REVERSE")
      }
    }
    catch (err) {
    }
  };
  thisObj.onbtnReverseClick = onbtnReverseClick;
  // START_USER_CODE-USER_METHODS
  const onbtnExitClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      // Modal.close();
      // document.getElementsByClassName("close")[0].click();
      document.getElementById("ContractManagement_ExceptPremRequestProfilePopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;
  const onchkboxAll1007sChange = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      const chk = getValue(thisObj, "chkboxAll1007s");
      const target = document.getElementsByClassName("rdt_TableHeadRow")[0].firstChild.firstChild;
      if (chk) {
        target.click();
      }
      else {
        target.click();
      }

      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onchkboxAll1007sChange = onchkboxAll1007sChange;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({

        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ExceptPremRequestProfile*/}

              {/* END_USER_CODE-USER_BEFORE_ExceptPremRequestProfile*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxReqDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxReqDetails*/}

              <GroupBoxWidget conf={state.grpbxReqDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtPayRequest*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayRequest*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayRequest}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayRequest*/}

                {/* END_USER_CODE-USER_AFTER_txtPayRequest*/}
                {/* START_USER_CODE-USER_BEFORE_txtStatus*/}

                {/* END_USER_CODE-USER_BEFORE_txtStatus*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStatus}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStatus*/}

                {/* END_USER_CODE-USER_AFTER_txtStatus*/}
                {/* START_USER_CODE-USER_BEFORE_txtLocation*/}

                {/* END_USER_CODE-USER_BEFORE_txtLocation*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtLocation}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

                {/* START_USER_CODE-USER_AFTER_txtLocation*/}

                {/* END_USER_CODE-USER_AFTER_txtLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_txtPremiumCode*/}

                {/* END_USER_CODE-USER_BEFORE_txtPremiumCode*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPremiumCode}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPremiumCode*/}

                {/* END_USER_CODE-USER_AFTER_txtPremiumCode*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLabels*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLabels*/}

                <GroupBoxWidget conf={state.grpbxLabels} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtLocationID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLocationID*/}

                  {/* <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLocationID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget> */}
                  {/* <LabelWidget
                  values={values}
                  conf={state.lblLocationID}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblVendorID}
                  screenConf={state}
                ></LabelWidget>
                <LabelWidget
                  values={values}
                  conf={state.lblPremiumCodeID}
                  screenConf={state}
                ></LabelWidget> */}
                  {/* START_USER_CODE-USER_AFTER_txtLocationID*/}

                  {/* END_USER_CODE-USER_AFTER_txtLocationID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorID*/}

                  {/* <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget> */}
                  {/* START_USER_CODE-USER_AFTER_txtVendorID*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorID*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPremiumCodeID*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPremiumCodeID*/}

                  {/* <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPremiumCodeID}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget> */}
                  {/* START_USER_CODE-USER_AFTER_txtPremiumCodeID*/}

                  {/* END_USER_CODE-USER_AFTER_txtPremiumCodeID*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLabels*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLabels*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxReqDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxReqDetails*/}
              {/* START_USER_CODE-USER_BEFORE_frpbxUpdateExceptPrem*/}

              {/* END_USER_CODE-USER_BEFORE_frpbxUpdateExceptPrem*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.frpbxUpdateExceptPrem}
                screenConf={state}
              >
                {/* <CheckboxWidget
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  rows={values.gridExcPremReqSrch}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxAll1007s}
                  screenConf={state}
                ></CheckboxWidget> */}
                {/* START_USER_CODE-USER_BEFORE_txtarExceptPrem*/}

                {/* END_USER_CODE-USER_BEFORE_txtarExceptPrem*/}

                {/* <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarExceptPrem}
                  screenConf={state}
                ></TextAreaWidget> */}

                <GridWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  conf={state.gridExcPremReqProf}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={selectedRows => onGridRowChange(selectedRows)}
                  errors={errors}
                  touched={touched}
                  rows={values.gridExcPremReqProf}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  setFieldValue={setFieldValue}
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_txtarExceptPrem*/}

                {/* END_USER_CODE-USER_AFTER_txtarExceptPrem*/}
                {/* START_USER_CODE-USER_BEFORE_btnUpdateExceptPrems*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdateExceptPrems*/}

                <ButtonWidget
                  conf={state.btnUpdateExceptPrems}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdateExceptPrems*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdateExceptPrems*/}
                {/* START_USER_CODE-USER_BEFORE_txtTotalAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtTotalAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTotalAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTotalAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtTotalAmount*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_frpbxUpdateExceptPrem*/}

              {/* END_USER_CODE-USER_AFTER_frpbxUpdateExceptPrem*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxSpoolDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSpoolDetails*/}

              <GroupBoxWidget conf={state.grpbxSpoolDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddSpoolLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddSpoolLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSpoolLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSpoolLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddSpoolLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtRequestedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtRequestedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRequestedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRequestedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtRequestedBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtDateRequested*/}

                {/* END_USER_CODE-USER_BEFORE_txtDateRequested*/}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateRequested}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtDateRequested*/}

                {/* END_USER_CODE-USER_AFTER_txtDateRequested*/}
                {/* START_USER_CODE-USER_BEFORE_txtApprovedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtApprovedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtApprovedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtApprovedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtApprovedBy*/}
                {/* START_USER_CODE-USER_BEFORE_txtDateApproved*/}

                {/* END_USER_CODE-USER_BEFORE_txtDateApproved*/}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDateApproved}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtDateApproved*/}

                {/* END_USER_CODE-USER_AFTER_txtDateApproved*/}
                {/* START_USER_CODE-USER_BEFORE_txtarExplanation*/}

                {/* END_USER_CODE-USER_BEFORE_txtarExplanation*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarExplanation}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarExplanation*/}

                {/* END_USER_CODE-USER_AFTER_txtarExplanation*/}
                {/* START_USER_CODE-USER_BEFORE_txtSpoolLocID*/}

                {/* END_USER_CODE-USER_BEFORE_txtSpoolLocID*/}

                {/* <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSpoolLocID}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget> */}
                {/* START_USER_CODE-USER_AFTER_txtSpoolLocID*/}

                {/* END_USER_CODE-USER_AFTER_txtSpoolLocID*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSpoolDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSpoolDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnReprint*/}

                {/* END_USER_CODE-USER_BEFORE_btnReprint*/}

                <ButtonWidget
                  conf={state.btnReprint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReprint*/}

                {/* END_USER_CODE-USER_AFTER_btnReprint*/}
                {/* START_USER_CODE-USER_BEFORE_btnApprove*/}

                {/* END_USER_CODE-USER_BEFORE_btnApprove*/}

                <ButtonWidget
                  conf={state.btnApprove}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnApprove*/}

                {/* END_USER_CODE-USER_AFTER_btnApprove*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreatePayable*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreatePayable*/}

                <ButtonWidget
                  conf={state.btnCreatePayable}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreatePayable*/}

                {/* END_USER_CODE-USER_AFTER_btnCreatePayable*/}
                {/* START_USER_CODE-USER_BEFORE_btnReject*/}

                {/* END_USER_CODE-USER_BEFORE_btnReject*/}

                <ButtonWidget
                  conf={state.btnReject}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReject*/}

                {/* END_USER_CODE-USER_AFTER_btnReject*/}
                {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                <ButtonWidget
                  conf={state.btnDelete}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                {/* START_USER_CODE-USER_BEFORE_btnReverse*/}

                {/* END_USER_CODE-USER_BEFORE_btnReverse*/}

                <ButtonWidget
                  conf={state.btnReverse}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReverse*/}

                {/* END_USER_CODE-USER_AFTER_btnReverse*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ExceptPremRequestProfile*/}

              {/* END_USER_CODE-USER_AFTER_ExceptPremRequestProfile*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ExceptPremRequestProfile);