/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  CheckboxWidget,
  getData,
  setData,
  getValue,
  setValue,
  hideWidgets,
  show,
  hide,
  disable,
} from "../../shared/WindowImports";

import "./FreightRateCopy.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import Loading from "../../../Loader/Loading";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_FreightRateCopy(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let MODE = ""
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "FreightRateCopy",
    windowName: "FreightRateCopy",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.FreightRateCopy",
    // START_USER_CODE-USER_FreightRateCopy_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Copy Freight Rate",
      scrCode: "PN0250F",
    },
    // END_USER_CODE-USER_FreightRateCopy_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCopy: {
      name: "btnCopy",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Copy",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCopy_PROPERTIES

      // END_USER_CODE-USER_btnCopy_PROPERTIES
    },
    chkboxNoLoad: {
      name: "chkboxNoLoad",
      type: "CheckBoxWidget",
      parent: "grpbxFreightDetails",
      Label: "No Load",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxNoLoad_PROPERTIES

      // END_USER_CODE-USER_chkboxNoLoad_PROPERTIES
    },
    ddDestLocation1: {
      name: "ddDestLocation1",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation1_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation1_PROPERTIES
    },
    ddDestLocation2: {
      name: "ddDestLocation2",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation2_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation2_PROPERTIES
    },
    ddDestLocation3: {
      name: "ddDestLocation3",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation3_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation3_PROPERTIES
    },
    ddDestLocation4: {
      name: "ddDestLocation4",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation4_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation4_PROPERTIES
    },
    ddDestLocation5: {
      name: "ddDestLocation5",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation5_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation5_PROPERTIES
    },
    ddDestLocation6: {
      name: "ddDestLocation6",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation6_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation6_PROPERTIES
    },
    ddDestLocation7: {
      name: "ddDestLocation7",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation7_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation7_PROPERTIES
    },
    ddDestLocation8: {
      name: "ddDestLocation8",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation8_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation8_PROPERTIES
    },
    ddDestLocation9: {
      name: "ddDestLocation9",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation9_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation9_PROPERTIES
    },
    ddDestLocation10: {
      name: "ddDestLocation10",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation10_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation10_PROPERTIES
    },
    ddDestLocation11: {
      name: "ddDestLocation11",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation11_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation11_PROPERTIES
    },
    ddDestLocation12: {
      name: "ddDestLocation12",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation12_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation12_PROPERTIES
    },
    ddDestLocation13: {
      name: "ddDestLocation13",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation13_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation13_PROPERTIES
    },
    ddDestLocation14: {
      name: "ddDestLocation14",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation14_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation14_PROPERTIES
    },
    ddDestLocation15: {
      name: "ddDestLocation15",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation15_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation15_PROPERTIES
    },
    ddDestLocation16: {
      name: "ddDestLocation16",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation16_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation16_PROPERTIES
    },
    ddDestLocation17: {
      name: "ddDestLocation17",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation17_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation17_PROPERTIES
    },
    ddDestLocation18: {
      name: "ddDestLocation18",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation18_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation18_PROPERTIES
    },
    ddDestLocation19: {
      name: "ddDestLocation19",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation19_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation19_PROPERTIES
    },
    ddDestLocation20: {
      name: "ddDestLocation20",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation20_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation20_PROPERTIES
    },
    ddDestLocation21: {
      name: "ddDestLocation21",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation21_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation21_PROPERTIES
    },
    ddDestLocation22: {
      name: "ddDestLocation22",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation22_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation22_PROPERTIES
    },
    ddDestLocation23: {
      name: "ddDestLocation23",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation23_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation23_PROPERTIES
    },
    ddDestLocation24: {
      name: "ddDestLocation24",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation24_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation24_PROPERTIES
    },
    ddDestLocation25: {
      name: "ddDestLocation25",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation25_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation25_PROPERTIES
    },
    ddDestLocation26: {
      name: "ddDestLocation26",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation26_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation26_PROPERTIES
    },
    ddDestLocation27: {
      name: "ddDestLocation27",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation27_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation27_PROPERTIES
    },
    ddDestLocation28: {
      name: "ddDestLocation28",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation28_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation28_PROPERTIES
    },
    ddDestLocation29: {
      name: "ddDestLocation29",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation29_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation20_PROPERTIES
    },
    ddDestLocation30: {
      name: "ddDestLocation30",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation30_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation30_PROPERTIES
    },
    ddDestLocation31: {
      name: "ddDestLocation31",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation31_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation31_PROPERTIES
    },
    ddDestLocation32: {
      name: "ddDestLocation32",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation32_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation32_PROPERTIES
    },
    ddDestLocation33: {
      name: "ddDestLocation33",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation33_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation33_PROPERTIES
    },
    ddDestLocation34: {
      name: "ddDestLocation34",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation34_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation34_PROPERTIES
    },
    ddDestLocation35: {
      name: "ddDestLocation35",
      type: "DropDownFieldWidget",
      parent: "grpbxCopyTo",
      Label: "Dest Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddDestLocation35_PROPERTIES

      // END_USER_CODE-USER_ddDestLocation35_PROPERTIES
    },
    grpbxCopyTo: {
      name: "grpbxCopyTo",
      type: "GroupBoxWidget",
      parent: "grpbxFreightRateCopy",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "1",
      // START_USER_CODE-USER_grpbxCopyTo_PROPERTIES

      // END_USER_CODE-USER_grpbxCopyTo_PROPERTIES
    },
    grpbxFreightDetails: {
      name: "grpbxFreightDetails",
      type: "GroupBoxWidget",
      parent: "grpbxFreightRateCopy",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxFreightDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightDetails_PROPERTIES
    },
    lblCopyTo: {
      name: "lblCopyTo",
      type: "LabelWidget",
      parent: "grpbxFreightRateCopy",
      Label: "Copy To:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCopyTo_PROPERTIES

      // END_USER_CODE-USER_lblCopyTo_PROPERTIES
    },
    txtFromBuyPt: {
      name: "txtFromBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxFreightDetails",
      Label: "From Buying Pt:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFromBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtFromBuyPt_PROPERTIES
    },
    txtFromCarrier: {
      name: "txtFromCarrier",
      type: "TextBoxWidget",
      parent: "grpbxFreightDetails",
      Label: "From Carrier:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFromCarrier_PROPERTIES

      // END_USER_CODE-USER_txtFromCarrier_PROPERTIES
    },
    txtToCarrier: {
      name: "txtToCarrier",
      type: "TextBoxWidget",
      parent: "grpbxFreightDetails",
      Label: "To Carrier:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtToCarrier_PROPERTIES

      // END_USER_CODE-USER_txtToCarrier_PROPERTIES
    },
    grpbxFreightRateCopy: {
      name: "grpbxFreightRateCopy",
      type: "GroupBoxWidget",
      parent: "FreightRateCopy",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxFreightRateCopy_PROPERTIES

      // END_USER_CODE-USER_grpbxFreightRateCopy_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "FreightRateCopy",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    //initialdestlocationide()
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  //START_USER_CODE-USER_METHODS


  // FormLoad
  const FormLoad = async () => {
    try{
    await bFillLocationList()
    await initialdestlocationide()
    // integration for CopyLocation
    let frmFreightRateProfileRateLocation = await getData(thisObj, 'cmdCopyLocation');
    if (frmFreightRateProfileRateLocation != '' && frmFreightRateProfileRateLocation != undefined) {
      setData(thisObj, "MODE", frmFreightRateProfileRateLocation.CopyMode)
      if (frmFreightRateProfileRateLocation.Action == "locationCopy") {
        document.getElementById('btnCpyLoc').innerText = "Copy Location"
        show(thisObj, "txtFromBuyPt")
        hide(thisObj, "txtFromCarrier")
        hide(thisObj, "txtToCarrier")
        hide(thisObj, "chkboxNoLoad")
        setValue(thisObj, 'txtFromBuyPt', frmFreightRateProfileRateLocation.lblFrmBuyPt_Caption)
        disable(thisObj, 'txtFromBuyPt')
        //setLoading(false)
      }
    }
    // integration for CopyCarrier
    let frmFreightRateProfileCopyCarrier = await getData(thisObj, 'cmdCopyCarrier');
    if (frmFreightRateProfileCopyCarrier != '' && frmFreightRateProfileCopyCarrier != undefined) {
      setData(thisObj, "MODE", frmFreightRateProfileCopyCarrier.CopyMode)
      if (frmFreightRateProfileCopyCarrier.Action == "CarrierCopy") {
        document.getElementById('btnCpyCarr').innerText = "Copy Carrier"
        show(thisObj, "txtFromBuyPt")
        show(thisObj, "txtFromCarrier")
        show(thisObj, "txtToCarrier")
        show(thisObj, "chkboxNoLoad")
        setValue(thisObj, 'txtFromBuyPt', frmFreightRateProfileCopyCarrier.lblFrmBuyPt_Caption)
        setValue(thisObj, 'txtFromCarrier', frmFreightRateProfileCopyCarrier.lblCarrier)
        setValue(thisObj, 'txtToCarrier', frmFreightRateProfileCopyCarrier.txtCarrier_Abbrv)
        if (frmFreightRateProfileCopyCarrier.chkNoLoad_Value == undefined || frmFreightRateProfileCopyCarrier.chkNoLoad_Value == "") {
          setValue(thisObj, 'chkboxNoLoad', false)
        }
        else {
          setValue(thisObj, 'chkboxNoLoad', true)
        }
        disable(thisObj, 'txtFromBuyPt')
        disable(thisObj, 'txtFromCarrier')
      }
    }
    // integration for CopyRate
    let frmFreightRateProfileRateCopy = await getData(thisObj, 'cmdCopyRate');
    if (frmFreightRateProfileRateCopy != '' && frmFreightRateProfileRateCopy != undefined) {
      setData(thisObj, "MODE", frmFreightRateProfileRateCopy.CopyMode)
      if (frmFreightRateProfileRateCopy.Action == "RateCopy") {
        document.getElementById('btnCopyRate').innerText = "Copy Rate"
        show(thisObj, "txtFromBuyPt")
        show(thisObj, "txtFromCarrier")
        show(thisObj, "chkboxNoLoad")
        hide(thisObj, "txtToCarrier")
        setValue(thisObj, 'txtFromBuyPt', frmFreightRateProfileRateCopy.lblFrmBuyPt_Caption)
        setValue(thisObj, 'txtFromCarrier', frmFreightRateProfileRateCopy.lblCarrier)
        if (frmFreightRateProfileRateCopy.chkNoLoad_Value == undefined || frmFreightRateProfileRateCopy.chkNoLoad_Value == "") {
          setValue(thisObj, 'chkboxNoLoad', false)
        }
        else {
          setValue(thisObj, 'chkboxNoLoad', true)
        }
        hide(thisObj, "txtToCarrier", false)
        disable(thisObj, 'txtFromBuyPt')
        disable(thisObj, 'txtFromCarrier')
      }
    }
   }
   catch(err){
    showMessage(thisObj, err.message)
   }
   finally{
    setLoading(false)
   }
  }

  // Hiding the DestLocation
  const initialdestlocationide = async() => {
    //setLoading(true)
    let DestSplitsHideArray = []
    for (let i = 4; i <= 35; i++) {
      DestSplitsHideArray.push(`ddDestLocation${i}`)
    }
    hideWidgets(thisObj, DestSplitsHideArray)
    //setLoading(false)
  }
//Convert to PascalCase function
function ConvertToPascalCase(word){
  return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
}
  // Locations List
  const bFillLocationList = async (buy_point_id) => {
    let js = []
    let obj = { key: '', description: ">>> All Locations <<<" }
    js.push(obj)
    let response = await SettlementService.RetrieveTransferBuyingPointDetails(buy_point_id, null)
    let data = response
    for (var i = 0; i < data.length; i++) {
      let obj = {
        key: data[i].xfer_buy_pt_id, description: data[i].xfer_buy_pt_id.trim() + '-' +ConvertToPascalCase(data[i].xfer_buy_pt_name.trim())
      }
      js.push(obj)
    }
    for (let j = 0; j < 35; j++) {
      let setlocation = "ddDestLocation" + (j + 1)
      thisObj.state[setlocation].valueList = js;
      setValue(thisObj, setlocation, js.at(0).key)
    }

  }

  // to get the DestLocation
  const getnewlocation = async () => {
    let vndrRowNo = getData(thisObj, "vndrRowNo")
    let showset = false
    for (let j = 0; j < 35; j++) {
      let setlocation = "ddDestLocation" + (j + 1)
      let getlocation = getValue(thisObj, setlocation)
      if (getlocation !== '' && getlocation !== undefined && getlocation !== '') {
        showset = true
      }
    }
    if (vndrRowNo >= 3 && vndrRowNo <= 34 && showset == true) {
      let newGrpbx = "ddDestLocation" + (Number(vndrRowNo) + 1)
      show(thisObj, newGrpbx)
    }
  }

  // DestLocation event
  const ddLocationBlur = (event) => {
    let cntrlId = event.target.name
    let newCntrl = cntrlId.match(/(\d+)/)[0]
    setData(thisObj, "vndrRowNo", newCntrl)
    getnewlocation()

  }

  // Copy button
  const onbtnCopyClick = async () => {
    let mode = getData(thisObj, "MODE")
    let frmFreightRateProfile = "";
    let edate = null;
    if (mode == "3") {
      frmFreightRateProfile = getData(thisObj, "cmdCopyRate");
      edate = frmFreightRateProfile.eff_date
    }
    else if (mode == "2") {
      frmFreightRateProfile = getData(thisObj, "cmdCopyCarrier");
    }
    else if (mode == "1") {
      frmFreightRateProfile = getData(thisObj, "cmdCopyLocation");
    }
    let new_buy_pts = []
    let new_buy_pt = null
    let no_load_ind_new = [undefined, null].includes(getValue(thisObj, "chkboxNoLoad")) ? "N" : "Y"
    let no_load_ind_old = frmFreightRateProfile.chkNoLoad_Value ? "Y" : "N"

    for (let j = 0; j < 35; j++) {
      let setlocation = "ddDestLocation" + (j + 1)
      let getlocation = getValue(thisObj, setlocation)
      if (getlocation !== '' && getlocation !== undefined && getlocation !== '') {
        new_buy_pts.push(getlocation)
      }
    }
    if (mode == 3) {

      new_buy_pt = new_buy_pts.join('|')
      new_buy_pt = new_buy_pt + "|"

      let response = await SystemMaintenanceMasterManagementService.RetrieveCopyFrieghtRateDetails(frmFreightRateProfile.lblFrmBuyPt_Caption, edate, new_buy_pt, frmFreightRateProfile.lblCarrier, no_load_ind_old, no_load_ind_new, frmFreightRateProfile.lblFrtVendor, frmFreightRateProfile.cmbRateBasis, frmFreightRateProfile.txtDeadFreight, null)
      if (response.length > 0) {
        alert(response[0].exist_carrier)
      }
    }
    else if (mode == 1) {
      new_buy_pt = new_buy_pts.join('|') + "|"

      let response = await SystemMaintenanceMasterManagementService.RetrieveCopyFrieghtRateLocationDetails(frmFreightRateProfile.lblFrmBuyPt_Caption, new_buy_pt, null)
      if (response.length > 0) {
        alert(response[0].exist_carrier)
      }
    }
    else if (mode == 2) {
      let txtCarrier_Abbrv = getValue(thisObj, 'txtToCarrier') == undefined ? '' : getValue(thisObj, 'txtToCarrier').trim().toUpperCase()
      let lblToFrtVendor = ''
      if (txtCarrier_Abbrv.length > 0) {
        let response = await AccountPayment.RetrieveScacVendorLookupDetails(null, txtCarrier_Abbrv)
        if (response.length > 0) {
          lblToFrtVendor = response[0].number
        }
        else {
          alert("The carrier does not exist or does not have an active status!", "To Carrier Not Found");
          return false;
        }
      }
      new_buy_pt = new_buy_pts.join('|') + "|"

      let resp = await SystemMaintenanceMasterManagementService.RetrieveCopyFrieghtRateCarrierDetails(frmFreightRateProfile.lblFrmBuyPt_Caption, frmFreightRateProfile.lblCarrier, txtCarrier_Abbrv, lblToFrtVendor, no_load_ind_new, new_buy_pt, null)
      if (resp.length > 0) {
        alert(resp[0].exist_carrier)
      }
    }
    else {
      showMessage(thisObj, "Problem with copying freight rates.  No mode was selected", false);
    }

  }
  thisObj.onbtnCopyClick = onbtnCopyClick

  // close button
  const onbtnCloseClick = () => {
    try {
      setData(thisObj, "cmdCopyLocation", "")
      setData(thisObj, "cmdCopyCarrier", "")
      setData(thisObj, "cmdCopyRate", "")
      document.getElementById("SystemMaintenanceMasterManagement_FreightRateCopyPopUp").childNodes[0].click()

      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_FreightRateCopy*/}

              {/* END_USER_CODE-USER_BEFORE_FreightRateCopy*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxFreightRateCopy*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxFreightRateCopy*/}

              <GroupBoxWidget
                conf={state.grpbxFreightRateCopy}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxFreightDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFreightDetails*/}

                <GroupBoxWidget
                  conf={state.grpbxFreightDetails}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_txtFromBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFromBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFromBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFromBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtFromBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFromCarrier*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFromCarrier*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFromCarrier}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFromCarrier*/}

                  {/* END_USER_CODE-USER_AFTER_txtFromCarrier*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxNoLoad*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxNoLoad*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxNoLoad}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxNoLoad*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxNoLoad*/}
                  {/* START_USER_CODE-USER_BEFORE_txtToCarrier*/}

                  {/* END_USER_CODE-USER_BEFORE_txtToCarrier*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtToCarrier}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtToCarrier*/}

                  {/* END_USER_CODE-USER_AFTER_txtToCarrier*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFreightDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFreightDetails*/}
                {/* START_USER_CODE-USER_BEFORE_lblCopyTo*/}

                {/* END_USER_CODE-USER_BEFORE_lblCopyTo*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCopyTo}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCopyTo*/}

                {/* END_USER_CODE-USER_AFTER_lblCopyTo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCopyTo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCopyTo*/}

                <GroupBoxWidget conf={state.grpbxCopyTo} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddDestLocation1*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestLocation1*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation1}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestLocation1*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestLocation1*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDestLocation2*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestLocation2*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation2}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestLocation2*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestLocation2*/}
                  {/* START_USER_CODE-USER_BEFORE_ddDestLocation3*/}

                  {/* END_USER_CODE-USER_BEFORE_ddDestLocation3*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation3}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation4}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation5}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation6}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation7}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation8}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation9}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation10}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation11}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation12}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation13}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation14}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation15}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation16}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation17}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation18}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation19}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation20}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation21}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation22}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation23}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation24}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation25}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation26}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation27}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation28}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation29}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation30}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation31}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation32}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation33}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    //onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation34}
                    screenConf={state}
                  ></DropDownWidget>
                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event => ddLocationBlur(event)}
                    // onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddDestLocation35}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddDestLocation3*/}

                  {/* END_USER_CODE-USER_AFTER_ddDestLocation3*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCopyTo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCopyTo*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxFreightRateCopy*/}

              {/* END_USER_CODE-USER_AFTER_grpbxFreightRateCopy*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnCopy*/}

                {/* END_USER_CODE-USER_BEFORE_btnCopy*/}

                <ButtonWidget
                  conf={state.btnCopy}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCopy*/}

                {/* END_USER_CODE-USER_AFTER_btnCopy*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_FreightRateCopy*/}

              {/* END_USER_CODE-USER_AFTER_FreightRateCopy*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_FreightRateCopy);
