/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../Loader/Loading";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  getValue,
  setValue,
  enable,
  disable,
  goTo,
  getData,
  setData, 
  show,
  hide, 
  hideColumn,   
  getSelectedRowNumber,
   setGridValue, 
   setFieldValues
} from "../../shared/WindowImports";

import "./WarehouseReceipt.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { isDate } from "moment";
import * as XLSX from 'xlsx/xlsx.mjs';
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_WarehouseReceipt(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  let lbl1007GroupError = false
  let mboolElectronicWarehouseReceipt = false
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  const CompId = (sessionStorage.getItem('compId'));
  const CropYear = (JSON.parse(sessionStorage.getItem('year')));

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "WarehouseReceipt",
    windowName: "WarehouseReceipt",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.WarehouseReceipt",
    // START_USER_CODE-USER_WarehouseReceipt_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Warehouse Receipt",
      scrCode: "PN1090B",
    },
    // END_USER_CODE-USER_WarehouseReceipt_PROPERTIES
    
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCreateWR: {
      name: "btnCreateWR",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Create Warehouse Receipt",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCreateWR_PROPERTIES

      // END_USER_CODE-USER_btnCreateWR_PROPERTIES
    },
    btnMore: {
      name: "btnMore",
      type: "ButtonWidget",
      parent: "grpbxInventoriesAvailable",
      Label: "More",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnMore_PROPERTIES

      // END_USER_CODE-USER_btnMore_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxAvailable1007s",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxDetails1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    btnVendorMA: {
      name: "btnVendorMA",
      type: "ButtonWidget",
      parent: "grpbxMarketingAssociation",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendorMA_PROPERTIES

      // END_USER_CODE-USER_btnVendorMA_PROPERTIES
    },
    chkboxAll1007s: {
      name: "chkboxAll1007s",
      type: "CheckBoxWidget",
      parent: "grpbxAvailable1007s",
      Label: "All 1007s",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxAll1007s_PROPERTIES

      // END_USER_CODE-USER_chkboxAll1007s_PROPERTIES
    },
    chkboxDiferredRedemption: {
      name: "chkboxDiferredRedemption",
      type: "CheckBoxWidget",
      parent: "grpbxDetails3",
      Label: "Deferred Redemption",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDiferredRedemption_PROPERTIES

      // END_USER_CODE-USER_chkboxDiferredRedemption_PROPERTIES
    },
       col1007Num: {
      name: "col1007Num",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "1007#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007Num_PROPERTIES

      // END_USER_CODE-USER_col1007Num_PROPERTIES
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },
    col3: {
      name: "col3",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col3_PROPERTIES

      // END_USER_CODE-USER_col3_PROPERTIES
    },
    colAvailableForAssignment: {
      name: "colAvailableForAssignment",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Available For Assignment",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAvailableForAssignment_PROPERTIES

      // END_USER_CODE-USER_colAvailableForAssignment_PROPERTIES
    },
    colBinDescription: {
      name: "colBinDescription",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Bin Description",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBinDescription_PROPERTIES

      // END_USER_CODE-USER_colBinDescription_PROPERTIES
    },
    colCompanyOwned: {
      name: "colCompanyOwned",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Company Owned",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCompanyOwned_PROPERTIES

      // END_USER_CODE-USER_colCompanyOwned_PROPERTIES
    },
    colContract: {
      name: "colContract",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContract_PROPERTIES

      // END_USER_CODE-USER_colContract_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colFedLicense: {
      name: "colFedLicense",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Fed License",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFedLicense_PROPERTIES

      // END_USER_CODE-USER_colFedLicense_PROPERTIES
    },
    colGen: {
      name: "colGen",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Gen",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGen_PROPERTIES

      // END_USER_CODE-USER_colGen_PROPERTIES
    },
    colGovernmentCapacity: {
      name: "colGovernmentCapacity",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Government Capacity",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGovernmentCapacity_PROPERTIES

      // END_USER_CODE-USER_colGovernmentCapacity_PROPERTIES
    },
    colGroupNum: {
      name: "colGroupNum",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "GRP#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGroupNum_PROPERTIES

      // END_USER_CODE-USER_colGroupNum_PROPERTIES
    },
    colInspectionDate: {
      name: "colInspectionDate",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Inspection Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspectionDate_PROPERTIES

      // END_USER_CODE-USER_colInspectionDate_PROPERTIES
    },
    colInTransit: {
      name: "colInTransit",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "In Transit",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInTransit_PROPERTIES

      // END_USER_CODE-USER_colInTransit_PROPERTIES
    },
    colLocation: {
      name: "colLocation",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },
    colLSKWt: {
      name: "colLSKWt",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "LSK Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLSKWt_PROPERTIES

      // END_USER_CODE-USER_colLSKWt_PROPERTIES
    },
    colNetWt: {
      name: "colNetWt",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Net Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWt_PROPERTIES

      // END_USER_CODE-USER_colNetWt_PROPERTIES
    },
    colNetWtLessLSK: {
      name: "colNetWtLessLSK",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Net Weight Less LSK",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWtLessLSK_PROPERTIES

      // END_USER_CODE-USER_colNetWtLessLSK_PROPERTIES
    },
    colObligatedToWR: {
      name: "colObligatedToWR",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Obligated To WR",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colObligatedToWR_PROPERTIES

      // END_USER_CODE-USER_colObligatedToWR_PROPERTIES
    },
    colOblPt: {
      name: "colOblPt",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Obligation Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOblPt_PROPERTIES

      // END_USER_CODE-USER_colOblPt_PROPERTIES
    },
    colPhysicalInventory: {
      name: "colPhysicalInventory",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Physical Inventory",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPhysicalInventory_PROPERTIES

      // END_USER_CODE-USER_colPhysicalInventory_PROPERTIES
    },
    colSC95: {
      name: "colSC95",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "SC95#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSC95_PROPERTIES

      // END_USER_CODE-USER_colSC95_PROPERTIES
    },
    colTentativeOpenStorage: {
      name: "colTentativeOpenStorage",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Tentative Open Storage",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentativeOpenStorage_PROPERTIES

      // END_USER_CODE-USER_colTentativeOpenStorage_PROPERTIES
    },
    colValue: {
      name: "colValue",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      Label: "Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colValue_PROPERTIES

      // END_USER_CODE-USER_colValue_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    colWhouseBin: {
      name: "colWhouseBin",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      Label: "Whouse/Bin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWhouseBin_PROPERTIES

      // END_USER_CODE-USER_colWhouseBin_PROPERTIES
    },
    ddCounty: {
      name: "ddCounty",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "County:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCounty_PROPERTIES

      // END_USER_CODE-USER_ddCounty_PROPERTIES
    },
    ddFarmNum: {
      name: "ddFarmNum",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Farm #:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddFarmNum_PROPERTIES
      optionMaxLength: 35,
      // END_USER_CODE-USER_ddFarmNum_PROPERTIES
    },
    ddGeneration: {
      name: "ddGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Generation:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddGeneration_PROPERTIES
      optionMaxLength: 20
      // END_USER_CODE-USER_ddGeneration_PROPERTIES
    },
    ddHolderID: {
      name: "ddHolderID",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Holder ID:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddHolderID_PROPERTIES
      optionMaxLength: 35
      // END_USER_CODE-USER_ddHolderID_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "Location:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES
      optionMaxLength: 35
      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddOleic: {
      name: "ddOleic",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Oleic:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    ddPeanut: {
      name: "ddPeanut",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Peanut:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanut_PROPERTIES
      optionMaxLength: 20
      // END_USER_CODE-USER_ddPeanut_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Peanut Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES
      optionMaxLength: 15
      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxMarketingAssociation",
      Label: "Remit:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      Enabled: false,

      ofTypeDomain: ["d_String","d_Null"],
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES
      optionMaxLength: 30,
      SpecialFirstOption: ""
      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    ddSeed: {
      name: "ddSeed",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Seed:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeed_PROPERTIES

      // END_USER_CODE-USER_ddSeed_PROPERTIES
    },
    ddSegType: {
      name: "ddSegType",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails2",
      Label: "Seg Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSegType_PROPERTIES
      optionMaxLength: 15
      // END_USER_CODE-USER_ddSegType_PROPERTIES
    },
    ddState: {
      name: "ddState",
      type: "DropDownFieldWidget",
      parent: "grpbxDetails1",
      Label: "State:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddState_PROPERTIES

      // END_USER_CODE-USER_ddState_PROPERTIES
    },
    gridAvailable1007s: {
      name: "gridAvailable1007s",
      type: "GridWidget",
      parent: "grpbxAvailable1007s",
      gridCellsOrder:
      "btncol1,chkboxcol,txtcol1007Num,txtcolOblPt,txtcolFarm,txtcolContract,txtcolSC95,txtcolInspectionDate,txtcolNetWt,txtcolLSKWt,txtcolNetWtLessLSK,txtcolValue,txtcolGroupNum",
      Pagination: false,
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      clonedExtId: "24453",
      isCobolTable: true,
      isEditable: true,
      // START_USER_CODE-USER_gridAvailable1007s_PROPERTIES

      // END_USER_CODE-USER_gridAvailable1007s_PROPERTIES
    },
    chkboxcol: {
      name: "chkboxcol",
      type: "CheckBoxWidget",
      colName: "colCheckbox",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxcol_PROPERTIES
      
      // END_USER_CODE-USER_chkboxcol_PROPERTIES
    },
    colCheckbox: {
      name: "colCheckbox",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckbox_PROPERTIES

      // END_USER_CODE-USER_colCheckbox_PROPERTIES
    },
    chkboxcol1: {
      name: "chkboxcol1",
      type: "CheckBoxWidget",
      colName: "col1Checkbox",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxcol_PROPERTIES
      
      // END_USER_CODE-USER_chkboxcol_PROPERTIES
    },
    col1Checkbox: {
      name: "col1Checkbox",
      type: "GridColumnWidget",
      parent: "gridInventoriesAvailable",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheckbox_PROPERTIES

      // END_USER_CODE-USER_colCheckbox_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridAvailable1007s",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridAvailable1007s",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    gridInventoriesAvailable: {
      name: "gridInventoriesAvailable",
      type: "GridWidget",
      parent: "grpbxInventoriesAvailable",
      gridCellsOrder:
      "chkboxcol1,txtcolLocation,txtcolWhouseBin,txtcolBinDescription,txtcolVariety,txtcolGen,txtcolAvailableForAssignment,txtcolInTransit,txtcolCompanyOwned,txtcolTentativeOpenStorage,txtcolObligatedToWR,txtcolPhysicalInventory,txtcolGovernmentCapacity,txtcolFedLicense",
      Pagination: false,    
      HasLabel: false,
      Cols: "8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridInventoriesAvailable_PROPERTIES
      isCobolTable: true,
      isEditable: true,
      // END_USER_CODE-USER_gridInventoriesAvailable_PROPERTIES
    },
    grpbxAvailable1007s: {
      name: "grpbxAvailable1007s",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceipt",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxAvailable1007s_PROPERTIES

      // END_USER_CODE-USER_grpbxAvailable1007s_PROPERTIES
    },
    grpbxDetails1: {
      name: "grpbxDetails1",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceipt",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails1_PROPERTIES
    },
    grpbxDetails2: {
      name: "grpbxDetails2",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceipt",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails2_PROPERTIES
    },
    grpbxDetails3: {
      name: "grpbxDetails3",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceipt",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxDetails3_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails3_PROPERTIES
    },
    grpbxFactorsForAllPeanuts: {
      name: "grpbxFactorsForAllPeanuts",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceipt",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "11",
      ColsForTabLandscape: "11",
      HasLabel: false,
      Cols: "11",
      ColsForTabPotrait: "11",
      ColsForLargeDesktop: "11",
      // START_USER_CODE-USER_grpbxFactorsForAllPeanuts_PROPERTIES

      // END_USER_CODE-USER_grpbxFactorsForAllPeanuts_PROPERTIES
    },
    grpbxInventoriesAvailable: {
      name: "grpbxInventoriesAvailable",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceipt",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxInventoriesAvailable_PROPERTIES

      // END_USER_CODE-USER_grpbxInventoriesAvailable_PROPERTIES
    },
    grpbxMarketingAssociation: {
      name: "grpbxMarketingAssociation",
      type: "GroupBoxWidget",
      parent: "grpbxDetails3",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxMarketingAssociation_PROPERTIES

      // END_USER_CODE-USER_grpbxMarketingAssociation_PROPERTIES
    },
    grpbxStorageInformation: {
      name: "grpbxStorageInformation",
      type: "GroupBoxWidget",
      parent: "grpbxWarehouseReceipt",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxStorageInformation_PROPERTIES

      // END_USER_CODE-USER_grpbxStorageInformation_PROPERTIES
    },
    lblAvailable1007s: {
      name: "lblAvailable1007s",
      type: "LabelWidget",
      parent: "grpbxAvailable1007s",
      Label: "Available 1007's",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAvailable1007s_PROPERTIES

      // END_USER_CODE-USER_lblAvailable1007s_PROPERTIES
    },
    lblCropYear: {
      name: "lblCropYear",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Crop Year: ",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCropYear_PROPERTIES

      // END_USER_CODE-USER_lblCropYear_PROPERTIES
    },
    lblFactorsForAllPeanuts: {
      name: "lblFactorsForAllPeanuts",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Factors For All Peanuts",
      ColSpan: "11",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFactorsForAllPeanuts_PROPERTIES

      // END_USER_CODE-USER_lblFactorsForAllPeanuts_PROPERTIES
    },
    lblInventoriesAvailable: {
      name: "lblInventoriesAvailable",
      type: "LabelWidget",
      parent: "grpbxInventoriesAvailable",
      Label: "Inventories Available For Obligation Assignment",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblInventoriesAvailable_PROPERTIES

      // END_USER_CODE-USER_lblInventoriesAvailable_PROPERTIES
    },
    lblMarketAssociation: {
      name: "lblMarketAssociation",
      type: "LabelWidget",
      parent: "grpbxMarketingAssociation",
      Label: "Marketing Association",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMarketAssociation_PROPERTIES

      // END_USER_CODE-USER_lblMarketAssociation_PROPERTIES
    },
    lblStorageInformation: {
      name: "lblStorageInformation",
      type: "LabelWidget",
      parent: "grpbxStorageInformation",
      Label: "Storage Information",
      ColSpan: "6",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStorageInformation_PROPERTIES

      // END_USER_CODE-USER_lblStorageInformation_PROPERTIES
    },
    radioAppliedTo: {
      name: "radioAppliedTo",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxDetails2",
      Options:
        "Tentatively Applied to an Option Contract:1,Tentatively Applied to a Flex Market Contract :2,Tentatively Applied to a Farmer Stock Contract:3,Tentatively Applied to a Floor Contract:4,Not Applied to a Contract:5",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioAppliedTo_PROPERTIES

      // END_USER_CODE-USER_radioAppliedTo_PROPERTIES
    },
    radioCMADMA: {
      name: "radioCMADMA",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxMarketingAssociation",
      Options: "CMA:1,DMA:2,None:3",
      ColSpan: "3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioCMADMA_PROPERTIES

      // END_USER_CODE-USER_radioCMADMA_PROPERTIES
    },
    radioElectronicPaper: {
      name: "radioElectronicPaper",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxDetails2",
      Options: "Electronic:1,Paper:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioElectronicPaper_PROPERTIES

      // END_USER_CODE-USER_radioElectronicPaper_PROPERTIES
    },
    txt1007GrpNum: {
      name: "txt1007GrpNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "1007 Grp #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007GrpNum_PROPERTIES

      // END_USER_CODE-USER_txt1007GrpNum_PROPERTIES
    },
    txt1007GrpName: {
      name: "txt1007GrpName",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      ColSpan: "3",
      HasLabel:false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007GrpName_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txt1007GrpName_PROPERTIES
    },
       txtcol1007Num: {
      name: "txtcol1007Num",
      type: "TextBoxWidget",
      colName: "col1007Num",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007Num_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcol1007Num_PROPERTIES
    },
    txtcol2: {
      name: "txtcol2",
      type: "TextBoxWidget",
      colName: "col2",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol2_PROPERTIES

      // END_USER_CODE-USER_txtcol2_PROPERTIES
    },
    txtcol3: {
      name: "txtcol3",
      type: "TextBoxWidget",
      colName: "col3",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol3_PROPERTIES

      // END_USER_CODE-USER_txtcol3_PROPERTIES
    },
    txtcolAvailableForAssignment: {
      name: "txtcolAvailableForAssignment",
      type: "TextBoxWidget",
      colName: "colAvailableForAssignment",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAvailableForAssignment_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolAvailableForAssignment_PROPERTIES
    },
    txtcolBinDescription: {
      name: "txtcolBinDescription",
      type: "TextBoxWidget",
      colName: "colBinDescription",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBinDescription_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolBinDescription_PROPERTIES
    },
    txtcolCompanyOwned: {
      name: "txtcolCompanyOwned",
      type: "TextBoxWidget",
      colName: "colCompanyOwned",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCompanyOwned_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolCompanyOwned_PROPERTIES
    },
    txtcolContract: {
      name: "txtcolContract",
      type: "TextBoxWidget",
      colName: "colContract",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContract_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolContract_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolFedLicense: {
      name: "txtcolFedLicense",
      type: "TextBoxWidget",
      colName: "colFedLicense",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFedLicense_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolFedLicense_PROPERTIES
    },
    txtcolGen: {
      name: "txtcolGen",
      type: "TextBoxWidget",
      colName: "colGen",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGen_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolGen_PROPERTIES
    },
    txtcolGovernmentCapacity: {
      name: "txtcolGovernmentCapacity",
      type: "TextBoxWidget",
      colName: "colGovernmentCapacity",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGovernmentCapacity_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolGovernmentCapacity_PROPERTIES
    },
    txtcolGroupNum: {
      name: "txtcolGroupNum",
      type: "TextBoxWidget",
      colName: "colGroupNum",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGroupNum_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolGroupNum_PROPERTIES
    },
    txtcolInspectionDate: {
      name: "txtcolInspectionDate",
      type: "TextBoxWidget",
      colName: "colInspectionDate",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspectionDate_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolInspectionDate_PROPERTIES
    },
    txtcolInTransit: {
      name: "txtcolInTransit",
      type: "TextBoxWidget",
      colName: "colInTransit",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInTransit_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolInTransit_PROPERTIES
    },
    txtcolLocation: {
      name: "txtcolLocation",
      type: "TextBoxWidget",
      colName: "colLocation",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLocation_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolLocation_PROPERTIES
    },
    txtcolLSKWt: {
      name: "txtcolLSKWt",
      type: "TextBoxWidget",
      colName: "colLSKWt",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLSKWt_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolLSKWt_PROPERTIES
    },
    txtcolNetWt: {
      name: "txtcolNetWt",
      type: "TextBoxWidget",
      colName: "colNetWt",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWt_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolNetWt_PROPERTIES
    },
    txtcolNetWtLessLSK: {
      name: "txtcolNetWtLessLSK",
      type: "TextBoxWidget",
      colName: "colNetWtLessLSK",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWtLessLSK_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolNetWtLessLSK_PROPERTIES
    },
    txtcolObligatedToWR: {
      name: "txtcolObligatedToWR",
      type: "TextBoxWidget",
      colName: "colObligatedToWR",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolObligatedToWR_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolObligatedToWR_PROPERTIES
    },
    txtcolOblPt: {
      name: "txtcolOblPt",
      type: "TextBoxWidget",
      colName: "colOblPt",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOblPt_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolOblPt_PROPERTIES
    },
    txtcolPhysicalInventory: {
      name: "txtcolPhysicalInventory",
      type: "TextBoxWidget",
      colName: "colPhysicalInventory",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPhysicalInventory_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolPhysicalInventory_PROPERTIES
    },
    txtcolSC95: {
      name: "txtcolSC95",
      type: "TextBoxWidget",
      colName: "colSC95",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSC95_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolSC95_PROPERTIES
    },
    txtcolTentativeOpenStorage: {
      name: "txtcolTentativeOpenStorage",
      type: "TextBoxWidget",
      colName: "colTentativeOpenStorage",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTentativeOpenStorage_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolTentativeOpenStorage_PROPERTIES
    },
    txtcolValue: {
      name: "txtcolValue",
      type: "TextBoxWidget",
      colName: "colValue",
      parent: "gridAvailable1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolValue_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolValue_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },
    txtcolWhouseBin: {
      name: "txtcolWhouseBin",
      type: "TextBoxWidget",
      colName: "colWhouseBin",
      parent: "gridInventoriesAvailable",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWhouseBin_PROPERTIES
      Readonly: true,
      Enabled: false
      // END_USER_CODE-USER_txtcolWhouseBin_PROPERTIES
    },
    txtContractNum: {
      name: "txtContractNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Contract #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtContractNum_PROPERTIES

      // END_USER_CODE-USER_txtContractNum_PROPERTIES
    },
    txtControlNumber: {
      name: "txtControlNumber",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Control Number",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtControlNumber_PROPERTIES

      // END_USER_CODE-USER_txtControlNumber_PROPERTIES
    },
    txtFlavus: {
      name: "txtFlavus",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Flavus",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFlavus_PROPERTIES

      // END_USER_CODE-USER_txtFlavus_PROPERTIES
    },
    txtHillsPct: {
      name: "txtHillsPct",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Hulls%",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHillsPct_PROPERTIES

      // END_USER_CODE-USER_txtHillsPct_PROPERTIES
    },
    txtHoeStored: {
      name: "txtHoeStored",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "How Stored",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHoeStored_PROPERTIES

      // END_USER_CODE-USER_txtHoeStored_PROPERTIES
    },
    txtHullsBrightPct: {
      name: "txtHullsBrightPct",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Hulls Bright %",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsBrightPct_PROPERTIES

      // END_USER_CODE-USER_txtHullsBrightPct_PROPERTIES
    },
    txtIssueDate: {
      name: "txtIssueDate",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Issue Date",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtIssueDate_PROPERTIES

      // END_USER_CODE-USER_txtIssueDate_PROPERTIES
    },
    txtJumboPct: {
      name: "txtJumboPct",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Jumbo%",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumboPct_PROPERTIES

      // END_USER_CODE-USER_txtJumboPct_PROPERTIES
    },
    txtLSKLbs: {
      name: "txtLSKLbs",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "LSK Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKLbs_PROPERTIES

      // END_USER_CODE-USER_txtLSKLbs_PROPERTIES
    },
    txtLSKLbsShrunk: {
      name: "txtLSKLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtLSKLbsShrunk_PROPERTIES
    },
    txtLSKPct: {
      name: "txtLSKPct",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "LSK%",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKPct_PROPERTIES

      // END_USER_CODE-USER_txtLSKPct_PROPERTIES
    },
    txtNetLbs: {
      name: "txtNetLbs",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Net Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbs_PROPERTIES

      // END_USER_CODE-USER_txtNetLbs_PROPERTIES
    },
    txtNetLbsShrunk: {
      name: "txtNetLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtNetLbsShrunk_PROPERTIES
    },
    txtPctConcealedRMD: {
      name: "txtPctConcealedRMD",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%CnclRMD",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctConcealedRMD_PROPERTIES

      // END_USER_CODE-USER_txtPctConcealedRMD_PROPERTIES
    },
    txtPctCrackBroken: {
      name: "txtPctCrackBroken",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%CrackBroken",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctCrackBroken_PROPERTIES

      // END_USER_CODE-USER_txtPctCrackBroken_PROPERTIES
    },
    txtPctDiscolored: {
      name: "txtPctDiscolored",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Disclrd",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctDiscolored_PROPERTIES

      // END_USER_CODE-USER_txtPctDiscolored_PROPERTIES
    },
    txtPctELK: {
      name: "txtPctELK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%ELK",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctELK_PROPERTIES

      // END_USER_CODE-USER_txtPctELK_PROPERTIES
    },
    txtPctFancy: {
      name: "txtPctFancy",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Fancy",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctFancy_PROPERTIES

      // END_USER_CODE-USER_txtPctFancy_PROPERTIES
    },
    txtPctFM: {
      name: "txtPctFM",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%FM",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctFM_PROPERTIES

      // END_USER_CODE-USER_txtPctFM_PROPERTIES
    },
    txtPctFreezeDamage: {
      name: "txtPctFreezeDamage",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Freeze Damage",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctFreezeDamage_PROPERTIES

      // END_USER_CODE-USER_txtPctFreezeDamage_PROPERTIES
    },
    txtPctMoist: {
      name: "txtPctMoist",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "% Moist",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctMoist_PROPERTIES

      // END_USER_CODE-USER_txtPctMoist_PROPERTIES
    },
    txtPctOtherKernels: {
      name: "txtPctOtherKernels",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Other Kernels",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctOtherKernels_PROPERTIES

      // END_USER_CODE-USER_txtPctOtherKernels_PROPERTIES
    },
    txtPctSplitDamage: {
      name: "txtPctSplitDamage",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Split Dam",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctSplitDamage_PROPERTIES

      // END_USER_CODE-USER_txtPctSplitDamage_PROPERTIES
    },
    txtPctTotalDamage: {
      name: "txtPctTotalDamage",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Total Damage",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctTotalDamage_PROPERTIES

      // END_USER_CODE-USER_txtPctTotalDamage_PROPERTIES
    },
    txtPctTotalKernels: {
      name: "txtPctTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Total Kernels",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctTotalKernels_PROPERTIES

      // END_USER_CODE-USER_txtPctTotalKernels_PROPERTIES
    },
    txtPctTotalKrnlsHulls: {
      name: "txtPctTotalKrnlsHulls",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%Total Kernels & Hulls",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPctTotalKrnlsHulls_PROPERTIES

      // END_USER_CODE-USER_txtPctTotalKrnlsHulls_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Seg",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtSMKPct: {
      name: "txtSMKPct",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "SMK %",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMKPct_PROPERTIES

      // END_USER_CODE-USER_txtSMKPct_PROPERTIES
    },
    txtSSPct: {
      name: "txtSSPct",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "SS %",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSSPct_PROPERTIES

      // END_USER_CODE-USER_txtSSPct_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Status",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtStorageDate: {
      name: "txtStorageDate",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Storage Date",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStorageDate_PROPERTIES

      // END_USER_CODE-USER_txtStorageDate_PROPERTIES
    },
    txtTotalLbs: {
      name: "txtTotalLbs",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Total Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLbs_PROPERTIES

      // END_USER_CODE-USER_txtTotalLbs_PROPERTIES
    },
    txtTotalLbsShrunk: {
      name: "txtTotalLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtTotalLbsShrunk_PROPERTIES
    },
    txtTotalSMKPct: {
      name: "txtTotalSMKPct",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total SMK %",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMKPct_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMKPct_PROPERTIES
    },
    txtValShrinkPct: {
      name: "txtValShrinkPct",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Val Shrink %",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValShrinkPct_PROPERTIES

      // END_USER_CODE-USER_txtValShrinkPct_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails1",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    txtVendorNumMA: {
      name: "txtVendorNumMA",
      type: "TextBoxWidget",
      parent: "grpbxMarketingAssociation",
      Label: "Vendor #:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNumMA_PROPERTIES

      // END_USER_CODE-USER_txtVendorNumMA_PROPERTIES
    },
    txtVendorName: {
      name: "txtVendorName",
      type: "TextBoxWidget",
      parent: "grpbxMarketingAssociation",
      Label: "",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorName_PROPERTIES

      // END_USER_CODE-USER_txtVendorName_PROPERTIES
    },
    txtVICAMPPB: {
      name: "txtVICAMPPB",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "VICAM PPB",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVICAMPPB_PROPERTIES

      // END_USER_CODE-USER_txtVICAMPPB_PROPERTIES
    },
    txtWtsShrinkPct: {
      name: "txtWtsShrinkPct",
      type: "TextBoxWidget",
      parent: "grpbxStorageInformation",
      Label: "Wts Shrink Pct",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWtsShrinkPct_PROPERTIES

      // END_USER_CODE-USER_txtWtsShrinkPct_PROPERTIES
    },
    grpbxWarehouseReceipt: {
      name: "grpbxWarehouseReceipt",
      type: "GroupBoxWidget",
      parent: "WarehouseReceipt",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_grpbxWarehouseReceipt_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "WarehouseReceipt",
      ColSpan: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "WarehouseReceipts#ReportPreviewWR":{}

      },
      REVERSE: {
        "WarehouseReceipts#ReportPreviewWR":{}
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCreateWR: {
    //   DEFAULT: [
    //     "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click",
    //   ],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    
    setValue(thisObj, 'lblCropYear', cropYearFromLS())
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    disable(thisObj, 'btnSearch')
    disable(thisObj, 'txtWtsShrinkPct')
    disable(thisObj, 'btnCreateWR')
    setValue(thisObj, 'radioCMADMA', '3')
    setValue(thisObj, 'radioElectronicPaper', '1')
    disable(thisObj, 'txtControlNumber')
    document.getElementById('radioElectronicPaper1').disabled = true;
    setValue(thisObj, 'radioAppliedTo', '1')
    setValue(thisObj, 'txt1007GrpName', "*** Group Not Found! ***" )
    hide(thisObj, 'txt1007GrpName', false)
    setData(thisObj,'PrevddStateValue','')
    setData(thisObj, 'PrevddCountyValue', '')
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);


  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    let BtnName = getData(thisObj, 'BtnName')
    if (vendor_lookup !== null && vendor_lookup !== undefined && vendor_lookup !== '' && BtnName !== null && BtnName !== undefined && BtnName !== '') {
      if(BtnName == 'CmaDmaVendor'){
        thisObj.setFieldValue("txtVendorNumMA", vendor_lookup.VendorNumber);
        bFillCmaDmaRemitList(vendor_lookup.VendorNumber)
        thisObj.setFieldValue("txtVendorName", ConvertToPascalCase(vendor_lookup.vendorName))
      }
      if(BtnName == 'Vendor'){
        thisObj.setFieldValue("txtVendorNum", vendor_lookup.VendorNumber);
        bFillRemitToList(vendor_lookup.VendorNumber)
        thisObj.setFieldValue("txtVendor", ConvertToPascalCase(vendor_lookup.vendorName))
      }
      setData(thisObj, 'BtnName', '')
      setData(thisObj, 'vendorDetails', '')
    }
  },[getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    if (getData(thisObj, 'closingOfReportPreviewWR')) {
      handleCloseReportPreviewWR();
    }
  }, [getData(thisObj, 'closingOfReportPreviewWR')]);

  // START_USER_CODE-USER_METHODS

  const handleCloseReportPreviewWR = async () => {
    setData(thisObj, 'closingOfReportPreviewWR', null);
    setTimeout(async () => {
      const confirm1 = confirm('Do you want to enter another Warehouse Receipt?');
      if (!confirm1) {
        onbtnCancelClick();
      } else {
        document.getElementById('txt1007GrpNum').focus();
        document.getElementById('txt1007GrpNum').select();
        await onbtnSearchClick();
        // Commented above line because it's not returning Group not found error
        // if group does not has any data
      }
    }, 200);
  };

  const FormLoad = async () => {

    try {

      await bFillLocationList();

      disable(thisObj, 'btnMore')
      setData(thisObj, 'mboolElectronicWarehouseReceipt', true)
      setData(thisObj, 'mbResetForCMA', false)

      let isbFillStateList = await bFillStateList();
      if (isbFillStateList == false) {
        return;
      }

      await bFillCountyList();
      await bFillFarmlist();

      await bFillTypeList();
      await bFillSegmentList();
      await bGetControlNumber()
      await bFillPeanutVarietyList();

      await bFillGenerationList();
      disable(thisObj, 'ddGeneration')

      await bFillOleicList();
      await bFillSeed();
      await bFillHolderList();


      await GetAllowWeightShrinkChange()
      await ontxtVendorNumBlur()

      await EnableDisableCmaDma()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during FormLoad event"
        );
      }
    }
  }

  const onbtnVendorClick = () => {
    try {
      setData(thisObj, 'BtnName', 'Vendor')
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnVendorClick event"
        );
      }
    }
  }
  thisObj.onbtnVendorClick = onbtnVendorClick;

  const onbtnVendorMAClick = () => {
    try {
      setData(thisObj, 'BtnName', 'CmaDmaVendor')
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnVendorMAClick event"
        );
      }
    }
  }
  thisObj.onbtnVendorMAClick = onbtnVendorMAClick;

  const onbtnSearchClick = async () => {
    try {
      setLoading(true)
     
      let value3 = getValue(thisObj,'txt1007GrpNum')
      if(value3 == null || value3 == undefined || value3 == ""){
      if (document.getElementsByClassName("txt1007GrpName")[0].style.visibility == 'visible') {
        showMessage(thisObj, 'Cannot find 1007 group number.')
        setData(thisObj, 'isGroupDataForTopGrid', false);
        return;
      } else {
        const txt1007GrpNumValue = getValue(thisObj, 'txt1007GrpNum');
        if (Boolean(txt1007GrpNumValue)) {
          setData(thisObj, 'isGroupDataForTopGrid', true);
        } else {
          setData(thisObj, 'isGroupDataForTopGrid', false);
        }
      }
    }
    else{
      setData(thisObj, 'isGroupDataForTopGrid', true);
      let sVendorData = getData(thisObj,'sVendor')
      if (sVendorData == undefined || sVendorData == null || sVendorData == "") { 
        setData(thisObj, 'isGroupDataForTopGrid', false);
        alert('Cannot find 1007 group number.')
        return;
      }
    }

      let FarmNumber = getValue(thisObj, 'ddFarmNum')

      if (FarmNumber == null || FarmNumber == undefined || FarmNumber == '') {
        showMessage(thisObj, 'Farm# must be specified for searches')
        return;
      }

      let RadioAppliedToValue = getValue(thisObj, 'radioAppliedTo')
      let ContactNumber = getValue(thisObj, 'txtContractNum')

      if (RadioAppliedToValue == '3' && (ContactNumber == null || ContactNumber == undefined || ContactNumber == '')) {
        showMessage(thisObj, 'Must enter a contract number when searching farmer stock.')
        return;
      }

      setValue(thisObj, 'chkboxAll1007s', '')
      await DoSearch()
      enable(thisObj, 'btnMore')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnSearchClick event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const EnableDisableCmaDma = async () => {
    try {
      setLoading(true)

      let radioCMADMAValue = getValue(_kaledo.thisObj, 'radioCMADMA')
      if (radioCMADMAValue == '3') {
        disable(thisObj, "txtVendorNumMA")
        disable(thisObj, "btnVendorMA")
        disable(thisObj, "ddRemitTo")
      }
      else {
        enable(thisObj, "txtVendorNumMA")
        enable(thisObj, "btnVendorMA")
        enable(thisObj, 'ddRemitTo')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during EnableDisableCmaDma event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const onddSeedChange = () => {
    try {
      setLoading(true)
      setValue(thisObj, 'gridInventoriesAvailable', [])
      setValue(thisObj, 'gridAvailable1007s', [])

      let ddSeedValue = getValue(thisObj, 'ddSeed')

      if (ddSeedValue != undefined && ddSeedValue != null && ddSeedValue != '' && ddSeedValue == thisObj.state['ddSeed'].valueList[1].key) {
        enable(thisObj, 'ddGeneration')
      }
      else {
        setValue(thisObj, 'ddGeneration', '')
        disable(thisObj, 'ddGeneration')
      }

      if (ddSeedValue != getData(thisObj, 'PrevddSeedValue')) {
        setData(thisObj, 'mbFormDirty', true)
      }
      setData(thisObj, 'PrevddSeedValue', ddSeedValue)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddSeedChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddSeedChange = onddSeedChange;

  const onddSegTypeChange = () => {

    try {
      setLoading(true)
      setValue(thisObj, 'gridInventoriesAvailable', [])
      setValue(thisObj, 'gridAvailable1007s', [])

      let ddSegTypeValue = getValue(thisObj, 'ddSegType')
      if (ddSegTypeValue != getData(thisObj, 'PrevddSegTypeValue')) {
        setData(thisObj, 'mbFormDirty', true)
      }
      setData(thisObj, 'PrevddSegTypeValue', ddSegTypeValue)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddSegTypeChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddSegTypeChange = onddSegTypeChange;

  const onddFarmNumChange = () => {
    try {
      setLoading(true)
      let ddFarmNumValue = getValue(thisObj, 'ddFarmNum')
      if (ddFarmNumValue != getData(thisObj, 'PrevddFarmNumValue')) {
        setValue(thisObj, 'gridInventoriesAvailable', [])
        setValue(thisObj, 'gridAvailable1007s', [])
      }
      setData(thisObj, 'PrevddFarmNumValue', ddFarmNumValue)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddFarmNumChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddFarmNumChange = onddFarmNumChange;
 
  const onbtnCancelClick = () => {
    try {
      document.getElementById("WarehouseReceipts_WarehouseReceiptPopUp").childNodes[0].click();
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCancelClick event"
        );
      }
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onradioCMADMAChange = async (e, defaultCMADMAValue = null) => {
    try {
      setLoading(true)
      let radioAppliedToValue = getValue(_kaledo.thisObj, 'radioAppliedTo')
      let radioCMADMAValue = defaultCMADMAValue || getValue(_kaledo.thisObj, 'radioCMADMA')
      let mbResetForCMA = getData(thisObj, 'mbResetForCMA')

      setValue(thisObj, 'txtVendorNumMA', '')
      setValue(thisObj, 'txtVendorName', '')
      setValue(thisObj, 'ddRemitTo', '')
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: [],
          }
        }
      })


      if (radioCMADMAValue == '1') {
        if (radioAppliedToValue != '4') {
          setValue(thisObj, 'gridAvailable1007s', [])
        }
      }
      else if (radioCMADMAValue == '2') {
        if (mbResetForCMA == true) {
          setValue(thisObj, 'gridAvailable1007s', [])
          setData(thisObj, 'mbResetForCMA', false)
        }
      }
      else if (radioCMADMAValue == '3') {
        if (mbResetForCMA == true) {
          setValue(thisObj, 'gridAvailable1007s', [])
          setData(thisObj, 'mbResetForCMA', false)
        }
      }
      await SetDefaultHolder()
      await EnableDisableCmaDma()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onradioCMADMAChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }

  }
  thisObj.onradioCMADMAChange = onradioCMADMAChange;

  function ConvertToPascalCase(word){
    return word.replace(/(\w)(\w*)/g, function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  const onddGenerationChange = () => {
    try {
      setLoading(true)
      setValue(thisObj, 'gridInventoriesAvailable', [])
      setValue(thisObj, 'gridAvailable1007s', [])

      let ddGenerationValue = getValue(thisObj, 'ddGeneration')
      let ddSeedValue = getValue(thisObj, 'ddSeed')

      if (ddSeedValue != undefined && ddSeedValue != null && ddSeedValue != '' && ddSeedValue == thisObj.state['ddSeed'].valueList[1].key) {
        enable(thisObj, 'ddGeneration')
      }
      else {
        setValue(thisObj, 'ddGeneration', '')
        disable(thisObj, 'ddGeneration')
      }

      if (ddGenerationValue != getData(thisObj, 'PrevddGenerationValue')) {
        setData(thisObj, 'mbFormDirty', true)
      }
      setData(thisObj, 'PrevddGenerationValue', ddGenerationValue)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddGenerationChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddGenerationChange = onddGenerationChange;

  const onddLocationChange = async () => {
    try {
      setLoading(true)
      let ddLocationValue = getValue(thisObj, 'ddLocation')
      if (ddLocationValue != getData(thisObj, 'PrevddLocationValue')) {

        setValue(thisObj, 'gridInventoriesAvailable', [])
        setValue(thisObj, 'gridAvailable1007s', [])

        await bFillFarmlist(ddLocationValue)
        await bFillPeanutVarietyList()
        setData(thisObj, 'PrevddLocationValue', ddLocationValue)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddLocationChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddLocationChange = onddLocationChange;

  const onddOleicChange = () => {
    try {
      setLoading(true)
      setValue(thisObj, 'gridInventoriesAvailable', [])
      setValue(thisObj, 'gridAvailable1007s', [])

      let ddOleicValue = getValue(thisObj, 'ddOleic')

      if (ddOleicValue != undefined && ddOleicValue != null && ddOleicValue != '' && ddOleicValue == thisObj.state['ddOleic'].valueList[1].key) {
        enable(thisObj, 'ddGeneration')
      }
      else {
        setValue(thisObj, 'ddGeneration', '')
        disable(thisObj, 'ddGeneration')
      }

      if (ddOleicValue != getData(thisObj, 'PrevddOleicValue')) {
        setData(thisObj, 'mbFormDirty', true)
      }
      setData(thisObj, 'PrevddOleicValue', ddOleicValue)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddOleicChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddOleicChange = onddOleicChange;
 
  const DoSearch = async () => {
    try {
      setLoading(true)
      let LstrList = []
      let LstrApplied = ''
      let LstrContractType = ''
      let LstrCMA = ''
      let Farm_suffix = ''
      setData(thisObj, 'mbResetForCMA', false)

      let RadioCMADMAValue = getValue(thisObj, 'radioCMADMA')
      let RadioAppliedToValue = getValue(thisObj, 'radioAppliedTo')
      let CmaDmaVendorNumber = getValue(thisObj, 'txtVendorNumMA')
      let ddLocationValue = getValue(thisObj, 'ddLocation')
      let txtVendorNumValue = getValue(thisObj, 'txtVendorNum')
      let ddStateValue = getValue(thisObj, 'ddState')
      let ddCountyValue = getValue(thisObj, 'ddCounty')
      let ddFarmNumValue1 = getValue(thisObj, 'ddFarmNum')
      let ddFarmNumValue = ddFarmNumValue1.slice(0,7)
      let txtContractNumValue = getValue(thisObj, 'txtContractNum')
      let ddPeanutTypeValue = getValue(thisObj, 'ddPeanutType')
      let ddPeanutValue = getValue(thisObj, 'ddPeanut')
      let ddSegTypeValue = getValue(thisObj, 'ddSegType')
      let ddOleicValue = getValue(thisObj, 'ddOleic')
      let ddGenerationValue = getValue(thisObj, 'ddGeneration')
      let ddSeedValue = getValue(thisObj, 'ddSeed')
      let txt1007GrpNumValue = getValue(thisObj, 'txt1007GrpNum')

      let CountyList = getData(thisObj, 'ddCountyList')
      let CountyId = CountyList.find(elem => elem.key == ddCountyValue).CountyID

      if (RadioCMADMAValue == '1' && RadioAppliedToValue != 5) {
        if (CmaDmaVendorNumber == null || CmaDmaVendorNumber == undefined || CmaDmaVendorNumber == '') {
          showMessage(thisObj, 'Must enter CMA Vendor Number')
          return;
        }
      }

      LstrCMA = ""
      if (RadioAppliedToValue == '5') {
        LstrApplied = "N"
        LstrContractType = "S"
      }
      else {
        LstrApplied = "Y"
        if (RadioAppliedToValue == '1') {
          LstrContractType = "O"
        }
        else if (RadioAppliedToValue == '2') {
          LstrContractType = "M"
        }
        else if (RadioAppliedToValue == '3') {
          LstrContractType = "C"
        }
        else if (RadioAppliedToValue == '4') {
          LstrContractType = "L"
        }

        if (RadioCMADMAValue == '1') {
          LstrCMA = CmaDmaVendorNumber
        }
      }

      let FarmValueList = thisObj.state['ddFarmNum'].valueList;
      Farm_suffix = FarmValueList.find(elem => elem.key == ddFarmNumValue1).FarmSuffix;

      let Array = []
      let obj = {}

      await WarehouseReceiptService.RetrieveInspectHeaderAvailable1007Details(ddLocationValue, null, 'S', ddPeanutTypeValue, ddPeanutValue, ddFarmNumValue, Farm_suffix, null, ddSeedValue, ddGenerationValue, ddSegTypeValue, ddOleicValue, ddStateValue, CountyId, "S", null, null, null, txtVendorNumValue, txtContractNumValue, null, LstrContractType, txt1007GrpNumValue, null, null).then(response => {
        LstrList = response
      })
      if (LstrList != undefined && LstrList != null && LstrList.length > 0) {
        for (let i = 0; i < LstrList.length; i++) {

          if (LstrList[i].settlement_num.length < 7) {
            LstrList[i].settlement_num = '0'.repeat(7 - Number(LstrList[i].settlement_num.length)) + LstrList[i].settlement_num
          }
          obj.txtcol1007Num = LstrList[i].settlement_num
          obj.txtcolOblPt = LstrList[i].obl_pt_name
          obj.txtcolFarm = LstrList[i].farm_name
          obj.txtcolContract = LstrList[i].contract_num
          obj.txtcolSC95 = LstrList[i].insp_num
          obj.txtcolInspectionDate = moment(LstrList[i].insp_date_time).format('MM/DD/YYYY')
          obj.txtcolNetWt = LstrList[i].net_wt
          obj.txtcolLSKWt = LstrList[i].lsk_wt
          obj.txtcolNetWtLessLSK = Number(LstrList[i].net_wt) - Number(LstrList[i].lsk_wt)
          obj.txtcolValue = LstrList[i].basis_grade_amt
          obj.smkPct = LstrList[i].smk_pct
          obj.ssPct = LstrList[i].ss_pct
          obj.okPct = LstrList[i].ok_pct
          obj.damPct = LstrList[i].dam_pct
          obj.frezDamPct = LstrList[i].frez_dam_pct
          obj.cnclRmdPct = LstrList[i].cncl_rmd_pct
          obj.falvusDiscount = LstrList[i].flavus_discount
          obj.hullPct = LstrList[i].hull_pct
          obj.fmPct = LstrList[i].fm_pct
          obj.elkPct = LstrList[i].elk_pct
          obj.fanPct = LstrList[i].fan_pct
          obj.dcolShelPct = LstrList[i].dcol_shel_pct
          obj.crBrPct = LstrList[i].cr_br_pct
          obj.moistPct = LstrList[i].moist_pct
          obj.lskPct = LstrList[i].lsk_pct
          obj.hulBritPct = LstrList[i].hul_brit_pct
          obj.jumboPct = LstrList[i].jumbo_pct
          obj.damSplitPct = LstrList[i].dam_split_pct
          obj.vicamPpb = LstrList[i].vicam_ppb
          obj.tkcPct = LstrList[i].tkc_pct
          obj.grossWt = LstrList[i].gross_wt
          obj.oblBuyId = LstrList[i].obl_buy_id
          obj.oblWhseID = LstrList[i].obl_whse_id
          obj.oblBinID = LstrList[i].obl_bin_id
          obj.txtcolGroupNum = LstrList[i].settlement_grouping_num
          Array.push(obj)
          obj = {}
        }
      }
      setValue(thisObj, "gridAvailable1007s", Array);

      setValue(thisObj, 'txtIssueDate', moment(new Date()).format('MM/DD/YYYY'))
      enable(thisObj, 'txtControlNumber')

      if (ddPeanutTypeValue != undefined && ddPeanutTypeValue != null && ddPeanutTypeValue != '') {
        let ddPeanutTypeValueList = thisObj.state['ddPeanutType'].valueList
        let ddPeanutTypeDescription = ddPeanutTypeValueList.find(elem => elem.key == ddPeanutTypeValue).description
        setValue(thisObj, 'txtPeanutType', ddPeanutTypeDescription)
      }

      if (ddSegTypeValue != undefined && ddSegTypeValue != null && ddSegTypeValue != '') {
        let ddSegTypeValueList = thisObj.state['ddSegType'].valueList
        let ddSegTypeDescription = ddSegTypeValueList.find(elem => elem.key == ddSegTypeValue).description
        setValue(thisObj, 'txtSeg', ddSegTypeDescription)
      }
      setValue(thisObj, 'txtNetLbs', '0')
      setValue(thisObj, 'txtTotalLbs', '0')
      setValue(thisObj, 'txtTotalLbsShrunk', '0')
      setValue(thisObj, 'txtLSKLbs', '0')
      setValue(thisObj, 'txtNetLbsShrunk', '0')
      setValue(thisObj, 'txtLSKLbsShrunk', '0')
      setValue(thisObj, 'txtStorageDate', '')
      setValue(thisObj, 'txtPctFM', '0')
      setValue(thisObj, 'txtPctMoist', '0')
      setValue(thisObj, 'txtPctELK', '0')
      setValue(thisObj, 'txtPctFancy', '0')
      setValue(thisObj, 'txtPctCrackBroken', '0')
      setValue(thisObj, 'txtPctDiscolored', '0')
      setValue(thisObj, 'txtSMKPct', '0')
      setValue(thisObj, 'txtSSPct', '0')
      setValue(thisObj, 'txtTotalSMKPct', '0')
      setValue(thisObj, 'txtPctOtherKernels', '0')
      setValue(thisObj, 'txtPctTotalDamage', '0')
      setValue(thisObj, 'txtPctTotalKernels', '0')
      setValue(thisObj, 'txtHillsPct', '0')
      setValue(thisObj, 'txtPctTotalKrnlsHulls', '0')
      setValue(thisObj, 'txtFlavus', '')
      setValue(thisObj, 'txtPctFreezeDamage', '0')
      setValue(thisObj, 'txtPctConcealedRMD', '0')
      setValue(thisObj, 'txtLSKPct', '0')
      setValue(thisObj, 'txtHullsBrightPct', '0')
      setValue(thisObj, 'txtVICAMPPB', '0')
      setValue(thisObj, 'txtPctSplitDamage', '0')
      setValue(thisObj, 'txtJumboPct', '0')
      setValue(thisObj, 'txtHoeStored', 'Bulk')
      setValue(thisObj, 'txtStatus', 'Unshelled')


      setData(thisObj, 'lblNetTons', 0)
      setData(thisObj, 'lblGrossPounds', 0)
      setData(thisObj, 'lblTKC', 0)

      if ((getValue(thisObj, 'radioCMADMA') == '1') && (getValue(thisObj, 'radioAppliedTo') != '5')) {
        setData(thisObj, 'mbResetForCMA', true)
      }
      else {
        setData(thisObj, 'mbResetForCMA', false)
      }
      await ReadInventories_filtered()
      await ontxtNetLbsChange()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during DoSearch event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const ReadInventories_filtered = async () => {
    try {
      setLoading(true)
      let LstrList = []

      let buy_pt_id = getValue(thisObj, 'ddLocation')
      let pnut_type_id = getValue(thisObj, 'ddPeanutType')
      let pnut_variety_id = getValue(thisObj, 'ddPeanut')
      let seg_type = getValue(thisObj, 'ddSegType')
      let oleic_ind = getValue(thisObj, 'ddOleic')
      let seed_gen = getValue(thisObj, 'ddGeneration')
      let seed_rcpt = getValue(thisObj, 'ddSeed')

      let Array = []
      let obj = {}

      await WarehouseReceiptService.RetrieveObligationPointAvailableInventoryDetails('PN1090', buy_pt_id, null, pnut_type_id, pnut_variety_id, seed_rcpt, seed_gen, seg_type, oleic_ind).then(response => {
        LstrList = response
      })
      //   vsfResults2.CellChecked = flexUnchecked //##

      if (LstrList != undefined && LstrList.length > 0) {
        for (let i = 0; i < LstrList.length; i++) {
          obj.txtcolLocation = LstrList[i].buy_pt_id + '-' + LstrList[i].buy_pt_name
          obj.BuyPtId = LstrList[i].buy_pt_id
          obj.txtcolWhouseBin = LstrList[i].whouse_num + '-' + LstrList[i].bin_num
          obj.WhouseNum = LstrList[i].whouse_num
          obj.BinNum = LstrList[i].bin_num
          obj.txtcolBinDescription = LstrList[i].whouse_bin_id
          obj.PeanutVariety = LstrList[i].pnut_variety_name

          switch (LstrList[i].symbol_ind) {
            case 'TRADEMARK':
              obj.txtcolVariety = obj.PeanutVariety + `&reg;`
              break;
            case 'REGISTERED TRADEMARK':
              obj.txtcolVariety = obj.PeanutVariety + `&trade;`
              break;
            case 'COPYRIGHT':
              obj.txtcolVariety = obj.PeanutVariety + `&copy;`
              break;
            default:
              obj.txtcolVariety = obj.PeanutVariety
              break;
          }

          obj.txtcolGen = LstrList[i].seed_gen
          obj.txtcolAvailableForAssignment = Number(LstrList[i].available_for_assignment)
          obj.txtcolInTransit = Number(LstrList[i].intransit_tons)
          obj.txtcolCompanyOwned = Number(LstrList[i].comp_own_inv)
          obj.txtcolTentativeOpenStorage = Number(LstrList[i].tentative_open_storage)
          obj.txtcolObligatedToWR = Number(LstrList[i].whouse_rcpt_inv)
          obj.txtcolPhysicalInventory = Number(LstrList[i].physical_inv)
          obj.txtcolGovernmentCapacity = Number(LstrList[i].capacity_tons)
          obj.txtcolFedLicense = LstrList[i].fed_whouse_license

          Array.push(obj)
          obj = {}
        }
      }
      setValue(thisObj, "gridInventoriesAvailable", Array);
      hideColumn(thisObj, "gridInventoriesAvailable", 'txtcolFedLicense')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ReadInventories_filtered event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const ReadInventories = async () => {
    try {
      setLoading(true)
      let LstrList = []
      let Array = []
      let obj = {}

      let buy_pt_id = getValue(thisObj, 'ddLocation')
      LstrList = await WarehouseReceiptService.RetrieveObligationPointDetails('PN1090', buy_pt_id)
      if (LstrList != undefined && LstrList.length > 0) {
        for (let i = 0; i < LstrList.length; i++) {
          obj.txtcolLocation = LstrList[i].buy_pt_id + '-' + LstrList[i].buy_pt_name
          obj.BuyPtId = LstrList[i].buy_pt_id
          obj.txtcolWhouseBin = LstrList[i].whouse_num + '-' + LstrList[i].bin_num
          obj.WhouseNum = LstrList[i].whouse_num
          obj.BinNum = LstrList[i].bin_num
          obj.txtcolBinDescription = LstrList[i].whouse_bin_id
          obj.txtcolVariety = LstrList[i].pnut_variety_name

          switch (LstrList[i].symbol_ind) {
            case 'TRADEMARK':
              obj.txtcolVariety = obj.PeanutVariety + `&trade;`
              break;
            case 'REGISTERED TRADEMARK':
              obj.txtcolVariety = obj.PeanutVariety + `&reg;`
              break;
            case 'COPYRIGHT':
              obj.txtcolVariety = obj.PeanutVariety + `&copy;`
              break;
          }

          obj.txtcolGen = LstrList[i].seed_gen
          obj.txtcolAvailableForAssignment = Number(LstrList[i].available_for_assignment)
          obj.txtcolInTransit = Number(LstrList[i].intransit_tons)
          obj.txtcolCompanyOwned = Number(LstrList[i].comp_own_inv)
          obj.txtcolTentativeOpenStorage = Number(LstrList[i].tentative_open_storage)
          obj.txtcolObligatedToWR = Number(LstrList[i].whouse_rcpt_inv)
          obj.txtcolPhysicalInventory = Number(LstrList[i].physical_inv)
          obj.txtcolGovernmentCapacity = Number(LstrList[i].capacity_tons)
          obj.txtcolFedLicense = LstrList[i].fed_whouse_license

          Array.push(obj)
          obj = {}
        }
      }
      setValue(thisObj, "gridInventoriesAvailable", Array);
      hideColumn(thisObj, "gridInventoriesAvailable", 'txtcolFedLicense')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ReadInventories event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const onradioElectronicPaperChange = () => {
    try {
      setLoading(true)
      let RadioValue = getValue(thisObj, 'radioElectronicPaper')
      if (RadioValue == '1') {
        setData(thisObj, 'mboolElectronicWarehouseReceipt', true)
      }
      if (RadioValue == '2') {
        setData(thisObj, 'mboolElectronicWarehouseReceipt', false)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onradioElectronicPaperChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onradioElectronicPaperChange = onradioElectronicPaperChange;

  const ontxtWtsShrinkPctChange = () => {
    try {
      setLoading(true)
      const txtWtsShrinkPctValue = getValue(thisObj, 'txtWtsShrinkPct')
      const txtNetLbsValue = getValue(thisObj, 'txtNetLbs')
      const txtTotalLbsValue = getValue(thisObj, 'txtTotalLbs')
      const txtLSKLbsValue = getValue(thisObj, 'txtLSKLbs')
      const lblGrossPoundsValue = getData(thisObj, 'lblGrossPounds')

      let txtNetLbsShrunkValue = txtNetLbsValue;
      let txtTotalLbsShrunkValue = txtTotalLbsValue;
      let txtLSKLbsShrunkValue = txtLSKLbsValue;
      let txtGrossLbsShrunkValue = lblGrossPoundsValue;

      if (isNaN(txtWtsShrinkPctValue) == false) {
        if (isNaN(txtNetLbsValue) == false) {
          txtNetLbsShrunkValue = Math.round(Number(txtNetLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          txtNetLbsShrunkValue = ''
        }

        if (isNaN(lblGrossPoundsValue) == false) {
          txtGrossLbsShrunkValue = Math.round(Number(lblGrossPoundsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          txtGrossLbsShrunkValue = '0'
        }

        if (isNaN(txtTotalLbsValue) == false) {
          txtTotalLbsShrunkValue = Math.round(Number(txtTotalLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          txtTotalLbsShrunkValue = ''
        }

        if (isNaN(txtLSKLbsValue) == false) {
          txtLSKLbsShrunkValue = Math.round(Number(txtLSKLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          txtLSKLbsShrunkValue = ''
        }
      }
      else {
        txtNetLbsShrunkValue = Math.round(Number(txtNetLbsValue))
        txtGrossLbsShrunkValue = Math.round(Number(lblGrossPoundsValue))
        txtTotalLbsShrunkValue = Math.round(Number(txtTotalLbsValue))
        txtLSKLbsShrunkValue = Math.round(Number(txtLSKLbsValue))
      }

      setValue(thisObj, 'txtNetLbsShrunk', txtNetLbsShrunkValue);
      setValue(thisObj, 'txtTotalLbsShrunk', txtTotalLbsShrunkValue);
      setValue(thisObj, 'txtLSKLbsShrunk', txtLSKLbsShrunkValue);
        
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtWtsShrinkPctChange event"
        );
      }
    }
    finally {
        setLoading(false)
    }
  }
  thisObj.ontxtWtsShrinkPctChange = ontxtWtsShrinkPctChange;

  const ontxtWtsShrinkPctBlur = () => {
    try {
      setLoading(true)
      const txtWtsShrinkPctValue = getValue(thisObj, 'txtWtsShrinkPct')
      const txtNetLbsValue = getValue(thisObj, 'txtNetLbs')
      const txtTotalLbsValue = getValue(thisObj, 'txtTotalLbs')
      const txtLSKLbsValue = getValue(thisObj, 'txtLSKLbs')
      const lblGrossPoundsValue = getData(thisObj, 'lblGrossPounds')

      let txtNetLbsShrunkValue = txtNetLbsValue;
      let txtTotalLbsShrunkValue = txtTotalLbsValue;
      let txtLSKLbsShrunkValue = txtLSKLbsValue;
      let txtGrossLbsShrunkValue = lblGrossPoundsValue;

      if (isNaN(txtWtsShrinkPctValue) == false) {
        if (isNaN(txtNetLbsValue) == false) {
          txtNetLbsShrunkValue = Math.round(Number(txtNetLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          txtNetLbsShrunkValue = '0'
        }

        if (isNaN(lblGrossPoundsValue) == false) {
          txtGrossLbsShrunkValue = Math.round(Number(lblGrossPoundsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          txtGrossLbsShrunkValue = '0'
        }

        if (isNaN(txtTotalLbsValue) == false) {
          txtTotalLbsShrunkValue = Math.round(Number(txtTotalLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          let lblGrossPoundsShrunk = '0'
        }

        if (isNaN(txtLSKLbsValue) == false) {
          txtLSKLbsShrunkValue = Math.round(Number(txtLSKLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
        }
        else {
          txtLSKLbsShrunkValue = '0'
        }
      }
      else {
        txtNetLbsShrunkValue = Math.round(Number(txtNetLbsValue))
        txtGrossLbsShrunkValue = Math.round(Number(lblGrossPoundsValue))
        txtTotalLbsShrunkValue = Math.round(Number(txtTotalLbsValue))
        txtLSKLbsShrunkValue = Math.round(Number(txtLSKLbsValue))
      }

      setValue(thisObj, 'txtNetLbsShrunk', txtNetLbsShrunkValue?.toString());
      setValue(thisObj, 'txtTotalLbsShrunk', txtTotalLbsShrunkValue?.toString());
      setValue(thisObj, 'txtLSKLbsShrunk', txtLSKLbsShrunkValue?.toString());
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtWtsShrinkPctBlur event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtWtsShrinkPctBlur = ontxtWtsShrinkPctBlur;

  const FillDefaultShrink = async () => {
    try {
      setLoading(true)
      let lstrxml = []
      let n = 0
      let nOblRow = 0

      //  nOblRow = Selected row number

      let SelectedRow = []
      let GridData = getValue(thisObj, 'gridInventoriesAvailable')
      for(let i =0; i<GridData.length; i++){
        if(GridData[i].chkboxcol1 == true){
          SelectedRow = GridData[i]
        }
      }
      setValue(thisObj, 'txtWtsShrinkPct', '0.00')
      setValue(thisObj, 'txtValShrinkPct', '0.00')

      let ddPeanutValue = getValue(thisObj, 'ddPeanut')

      if (SelectedRow != undefined && SelectedRow != null && ddPeanutValue != undefined && ddPeanutValue != null && SelectedRow.txtcolFedLicense != undefined && SelectedRow.txtcolFedLicense != null) {
        let fed_whouse_license = SelectedRow.txtcolFedLicense
        let pnut_type_id = getValue(thisObj, 'ddPeanutType');
        lstrxml = await SystemMaintenanceMasterManagementService.RetrieveShrinkControlDetails(fed_whouse_license, pnut_type_id)

        if(lstrxml != undefined && lstrxml != null && lstrxml.length >0){
          let Proceed = true
        }
        else{
          return;
        }

        setValue(thisObj, 'txtValShrinkPct', lstrxml[0].value_shrink_pct)
        let radioAppliedToValue = getValue(thisObj, 'radioAppliedTo')

        if (radioAppliedToValue == '5') {
          if (isNaN(lstrxml[0].weight_shrink_pct) == false) {
            setValue(thisObj, 'txtWtsShrinkPct', lstrxml[0].weight_shrink_pct)
          }
          else {
            showMessage(thisObj, "Shrink Control data not found!")
            setValue(thisObj, 'txtWtsShrinkPct', '0.00')
            return;
          }
        }
      }

      const txtNetLbsValue = getValue(thisObj, 'txtNetLbs')
      const txtWtsShrinkPctValue = getValue(_kaledo.thisObj, 'txtWtsShrinkPct')
      const txtTotalLbsValue = getValue(thisObj, 'txtTotalLbs')
      const txtLSKLbsValue = getValue(thisObj, 'txtLSKLbs')
      let txtNetLbsShrunkValue = txtNetLbsValue;
      let txtTotalLbsShrunkValue = txtTotalLbsValue;
      let txtLSKLbsShrunkValue = txtLSKLbsValue;

      if (isNaN(txtNetLbsValue) == false) {
        let lblGrossPoundsShrunk = Math.round(Number(txtNetLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
      }
      else {
        let lblGrossPoundsShrunk = '0'
      }

      if (isNaN(txtTotalLbsValue) == false) {
        txtTotalLbsShrunkValue = Math.round(Number(txtTotalLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
      }
      else {
        let lblGrossPoundsShrunk = '0'
      }

      if (isNaN(txtLSKLbsValue) == false) {
        txtLSKLbsShrunkValue = Math.round(Number(txtLSKLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100))
      }
      else {
        txtLSKLbsShrunkValue = "0"
      }
      if (isNaN(txtTotalLbsShrunkValue) == false) {
        txtNetLbsShrunkValue = Math.round(Number(txtTotalLbsShrunkValue) - Number(txtLSKLbsShrunkValue))
        
      }
      setValue(thisObj, 'txtNetLbsShrunk', txtNetLbsShrunkValue?.toString());
      setValue(thisObj, 'txtTotalLbsShrunk', txtTotalLbsShrunkValue?.toString());
      setValue(thisObj, 'txtLSKLbsShrunk', txtLSKLbsShrunkValue?.toString());
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during FillDefaultShrink event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillTypeList = async () => {
    try {
      setLoading(true)
      let js = [];
      let data = []
      let obj = {}
      let isbFillTypeList = false
      setValue(thisObj, 'ddPeanutType', '')
      js.push({ key: '', description: '>>> All Types <<<' })
      await ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
        data = response
      })
      if (data != undefined && data != null && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          obj = {
            key: data[i].pnutTypeId,
            description: data[i].pnutTypeName,
          }
          js.push(obj)
          obj = {}
        }
        isbFillTypeList = true
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })

      setValue(thisObj, 'ddPeanutType', js[0].key);

      if (js.length > 1) {
        setValue(thisObj, 'ddPeanutType', js[1].key);
      }
      return isbFillTypeList;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillTypeList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillSeed = async () => {
    try {
      setLoading(true)
      setValue(thisObj, 'ddSeed', '')
      let isbFillSeed = false
      let js = [];
      js.push({ key: '', description: 'Both' })
      js.push({ key: 'Y', description: 'Seed' })
      js.push({ key: 'N', description: 'Non-Seed' })
      thisObj.setState(current => {
        return {
          ...current,
          ddSeed: {
            ...state["ddSeed"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSeed', js[0].key)
      isbFillSeed = true
      return isbFillSeed
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillSeed event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillHolderList = async () => {
    try {
      setLoading(true)
      let js = [];
      let data = []
      let obj = {}
      let isbFillHolderList = false
      js.push({ key: "", description: ">>> Select <<<" });
      setValue(thisObj, 'ddHolderID', '')
      await WarehouseReceiptService.RetrieveVendorHolderTransferDetails(CompId, CropYear).then((response) => {
        data = response;
      });
      if (data != undefined && data != null && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          obj = {
            key: data[i].holder_id,
            description: data[i].holder_name,
            HolderDefault: data[i].holder_default,
            HolderVendor: data[i].holder_vendor,
          };
          js.push(obj);
          obj = {}
        }
        await SetDefaultHolder()
        isbFillHolderList = true
      }
      thisObj.setState((current) => {
        return {
          ...current,
          ddHolderID: {
            ...state["ddHolderID"],
            valueList: js,
          },
        };
      });
      setValue(thisObj, "ddHolderID", js[0].key);

      if (js.length > 1) {
        setValue(thisObj, "ddHolderID", js[1].key);
      }
      return isbFillHolderList
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillHolderList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  };

  const SetDefaultHolder = async () => {
    try {
      setLoading(true)
      let LintCounter = 0
      let dfltFound = false

      let radioCMADMAValue = getValue(_kaledo.thisObj, 'radioCMADMA')
      let ddHolderIDValueList = thisObj.state['ddHolderID'].valueList
      let ddLocationValue = getValue(_kaledo.thisObj, 'ddLocation')

      if (radioCMADMAValue == '1') {
        for (let i = 0; i < ddHolderIDValueList.length; i++) {
          if (ddHolderIDValueList[i].HolderDefault == 'C') {
            if (ddLocationValue == ddHolderIDValueList[i].HolderVendor) {
              setValue(thisObj, 'ddHolderID', ddHolderIDValueList[i].key)
              dfltFound = true
              return;
            }
          }
        }

        if (dfltFound == false) {
          for (let j = 0; j < ddHolderIDValueList.length; j++) {
            if (ddHolderIDValueList[j].HolderDefault == 'Y') {
              setValue(thisObj, 'ddHolderID', ddHolderIDValueList[j].key)
              dfltFound = true
              return;
            }
          }
        }
      }

      if (radioCMADMAValue == '2') {
        for (let k = 0; k < ddHolderIDValueList.length; k++) {
          if (ddHolderIDValueList[k].HolderDefault == 'D') {
            setValue(thisObj, 'ddHolderID', ddHolderIDValueList[k].key)
            setValue(thisObj, 'txtVendorNumMA', ddHolderIDValueList[k].HolderVendor)
            await ontxtVendorNumMABlur()
            dfltFound = true
            return;
          }
        }
      }

      if (radioCMADMAValue != '1' && radioCMADMAValue != '2') {
        for (let l = 0; l < ddHolderIDValueList.length; l++) {
          if (ddHolderIDValueList[l].HolderDefault == 'Y') {
            setValue(thisObj, 'ddHolderID', ddHolderIDValueList[l].key)
            dfltFound = true
            return;
          }
        }
      }

      if (dfltFound == false) {
        setValue(thisObj, 'ddHolderID', '')
      }

      //     For LintCounter = 0 To cboCurrentHolder.ListCount - 1
      //         If lstCurrentHolder.List(LintCounter) = cboCurrentHolder.Tag Then
      //             cboCurrentHolder.ListIndex = LintCounter
      //         End If
      //     Next LintCounter

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during SetDefaultHolder event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillLocationList = async () => {
    try {
      setLoading(true)
      let js = []
      let data = []
      let obj = {}
      let isbFillLocationList = false

      setValue(thisObj, 'ddLocation', '')
      js.push({ key: '', description: '>>> All Buying Points <<<' });
      await ContractManagementService.RetrieveBuyingPointControlDetails('PN1090', '008', null, null, null).then(response => {
        data = response
      })
      if (data != undefined && data != null && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          obj = {
            key: data[i].buy_pt_id,
            description: data[i].buy_pt_id + ' - ' + ConvertToPascalCase(data[i].buy_pt_name),
            FedWhouseLicense: data[i].fed_whouse_license,
            AreaID: data[i].area_id,
            DefaultCmaVendorNum: data[i].default_cma_vendor_num,
          }
          js.push(obj)
          obj = {}
        }
        isbFillLocationList = true
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })

      if (js.length > 1) {
        setValue(thisObj, 'ddLocation', js[1].key)
      }
      return isbFillLocationList
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillLocationList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillSegmentList = async () => {
    try {
      setLoading(true)
      setValue(thisObj, 'ddSegType', '')
      let isbFillSegmentList = false
      let js = []
      js.push({ key: '', description: '>>> All Segments <<<' })
      js.push({ key: '1', description: 'Seg1' })
      js.push({ key: '2', description: 'Seg2' })
      js.push({ key: '3', description: 'Seg3' })

      thisObj.setState(current => {
        return {
          ...current,
          ddSegType: {
            ...state["ddSegType"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddSegType', js[0].key)
      if (js.length > 1) {
        setValue(thisObj, 'ddSegType', js[1].key)
      }
      isbFillSegmentList = true
      return isbFillSegmentList
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillSegmentList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillGenerationList = async () => {
    try {
      setLoading(true)
      setValue(thisObj, 'ddGeneration', '')
      let js = []
      js.push({ key: '', description: '>>> All <<<' })
      js.push({ key: 'F', description: 'Foundation' })
      js.push({ key: 'R', description: 'Registered' })
      js.push({ key: 'C', description: 'Certified' })
      thisObj.setState(current => {
        return {
          ...current,
          ddGeneration: {
            ...state["ddGeneration"],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddGeneration", js[0].key)
      return true
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillGenerationList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillOleicList = async () => {
    try {
      setLoading(true)
      let js = []
      setValue(thisObj, 'ddOleic', '')
      js.push({ key: '', description: '>>> All <<<' })
      js.push({ key: 'H', description: 'High' })
      js.push({ key: 'M', description: 'Mid' })
      js.push({ key: 'N', description: 'None' })
      thisObj.setState(current => {
        return {
          ...current,
          ddOleic: {
            ...state["ddOleic"],
            valueList: js
          }
        }
      })
      setValue(thisObj, "ddOleic", js[0].key)
      return true;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillOleicList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillStateList = async (buyingPointId = null, stateAbbrevations = null, filterStatus = false) => {
    try {
      setLoading(true)
      let js = [];
      let obj = {};
      let isbFillStateList = false
      obj = { key: "", description: ">>>Select<<<" };
      js.push(obj)
      let BuyPt = buyingPointId || getValue(_kaledo.thisObj, 'ddLocation')
      let LstrStateList = await ContractManagementService.RetrievePeanutStateControlDetails(BuyPt, stateAbbrevations, filterStatus)
      let data = LstrStateList;
      setValue(thisObj, 'ddState', '')
      setValue(thisObj, 'ddCounty', '')
      setValue(thisObj, 'ddFarmNum', '')

      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          obj = {
            key: data[i].stateAbbr,
            description: data[i].stateName,
            StateID: data[i].stateId
          }
          js.push(obj);
          obj = {};
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddState: {
            ...state["ddState"],
            valueList: js,
          }
        }
      })

      isbFillStateList = true
      if (js.length > 1 && !buyingPointId) {
        setValue(thisObj, 'ddState', js[1].key)
      }
      return isbFillStateList
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillStateList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  };

  const bFillFarmlist = async (
    defaultBuyPt = null,
    defaultStateAbbr = null,
    defaultCountyId = null
  ) => {
    try {
      setLoading(true)
       //clearing existing values
       thisObj.setState(current => {
        return {
          ...current,
          ddFarmNum: {
            ...state["ddFarmNum"],
            valueList: []
          }
        }
      })
      let js = [];
      let obj = {};
      let data = []
      let isbFillFarmList = false
      obj = ({ key: '', description: ">>> All Farms <<<" })
      js.push(obj);

      let buy_pt_id = defaultBuyPt || getValue(_kaledo.thisObj, 'ddLocation')
      let state_abbr = defaultStateAbbr || getValue(_kaledo.thisObj, 'ddState')
      let ddCountyValue = getValue(_kaledo.thisObj, 'ddCounty')
      let CountyList = getData(thisObj, 'ddCountyList')
      let county_id = defaultCountyId || CountyList.find(elem => elem.key == ddCountyValue)?.CountyID || ''

      setValue(thisObj, 'ddFarmNum', '')
      let response = await ContractManagementService.RetrieveFarmControlDetails('PN1090', '008', buy_pt_id, state_abbr, county_id, null, null)
      data = response

      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          obj = {
            key: data[i].farm_id +' - ' + data[i].farm_suffix,
            description: data[i].farm_id + " - " + data[i].farm_suffix + " - " + data[i].farm_name,
            FarmSuffix: data[i].farm_suffix,
          }
          js.push(obj)
          obj = {}
        }
        isbFillFarmList = true
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddFarmNum: {
            ...state["ddFarmNum"],
            valueList: js,
          }
        }
      })
      if (!defaultCountyId) {
        setValue(thisObj, 'ddFarmNum', js.at(0).key);
      }
      return isbFillFarmList
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillFarmlist event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  };

  const bFillCountyList = async (defaultStateValue = null) => {
    try {
      setLoading(true)
      let jsCounty = []
      let data = []
      let isbFillCountyList = false
      let stateValue = defaultStateValue || getValue(_kaledo.thisObj, "ddState");
      jsCounty.push({ key: '', description: '>>> All Counties <<<', CountyID: '' })
      await ContractManagementService.RetrieveCountyControlDetails(null, false, stateValue, null).then(response => {
        data = response
      })
      setValue(thisObj, 'ddCounty', '')
      setValue(thisObj, 'ddFarmNum', '')

      if (data !== null && data !== undefined && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          jsCounty.push({
            key: data[i].countyId + data[i].countyName.trim(),
            description: data[i].countyName,
            CountyID: data[i].countyId
          })
        }
        isbFillCountyList = true
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddCounty: {
            ...state["ddCounty"],
            valueList: jsCounty,
          }
        }
      })
      setData(thisObj, 'ddCountyList', jsCounty)
      if (!defaultStateValue) {
        setValue(thisObj, 'ddCounty', jsCounty[0].key);
        if (jsCounty.length > 1) {
          setValue(thisObj, 'ddCounty', jsCounty[1].key);
        }
      }
      return isbFillCountyList
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillCountyList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const bFillRemitToList = async (vnum) => {
    try {
      let isbFillRemitToList = false

      setValue(thisObj, 'txtVendor', '')

      let LstrList = []
      await ContractManagementService.RetrieveVendorByNumber(vnum).then(response => {
        LstrList = response
      })
      if (LstrList != undefined && LstrList.length > 0) {
        setValue(thisObj, 'txtVendor', ConvertToPascalCase(LstrList[0].name))
        isbFillRemitToList = true
        setData(thisObj, 'pre_vendornum', vnum)
      }

      return isbFillRemitToList;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillRemitToList event"
        );
      }
    }
  }

  const bFillCmaDmaRemitList = async (vnum) => {
    try {
      let isbFillCmaDmaRemitList = false
      let js = []
      let obj = {}
      setValue(thisObj, 'txtVendorName', '')

      let LstrList = []
      await ContractManagementService.RetrieveVendorByNumber(vnum).then(response => {
        LstrList = response
      })
      if (LstrList != undefined && LstrList.length > 0) {
        setValue(thisObj, 'txtVendorName', ConvertToPascalCase(LstrList[0].name))
      }

      await ContractManagementService.RetieveRemitDetails(vnum).then(response => {
        LstrList = response
      })

      // obj = {
      //   key: '',
      //   description: ''
      // }

      // js.push(obj)
      // obj = {}

      if (LstrList != undefined && LstrList.length > 0) {
        for (let i = 0; i < LstrList.length; i++) {

          let Value = LstrList[i].remittoid + '-' + LstrList[i].name + '-' + LstrList[i].city + ',' + LstrList[i].state

          Value = Value.replace(/(\w)(\w*)/g,
            function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });

          obj = {
            key: LstrList[i].remittoid,
            description: Value
          }
          js.push(obj)
          obj = {}
        }

        isbFillCmaDmaRemitList = true
        setData(thisObj, 'pre_CmaDmaVendornum', vnum)
        setValue(thisObj, 'ddRemitTo', js[1].key)
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: js,
          }
        }
      })

      return isbFillCmaDmaRemitList;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillCmaDmaRemitList event"
        );
      }
    }
  }

  const ontxtVendorNumMABlur = async () => {
    try {
      setLoading(true)
      let txtVendorNumMAValue = getValue(_kaledo.thisObj, 'txtVendorNumMA')
      await bFillCmaDmaRemitList(txtVendorNumMAValue)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtVendorNumMABlur event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtVendorNumMABlur = ontxtVendorNumMABlur;

  const bFillPeanutVarietyList = async () => {
    try {
      setLoading(true)
      let js = []
      let data = []
      let obj = { key: '', description: ">>> All Varieties <<<" }
      js.push(obj)
      let isbFillPeanutVarietyList = false

      let AreaID = getValue(_kaledo.thisObj, 'ddLocation')
      let pnut_type_id = getValue(_kaledo.thisObj, 'ddPeanutType')

      setValue(thisObj, 'ddPeanut', '')
      await ContractManagementService.RetrievePeanutVarietyControls(AreaID, null, null, pnut_type_id, null).then(response => {
        data = response;
      })
      if (data !== null && data !== undefined && data.length > 0) {
        let js1 = [];
        for (var i = 0; i < data.length; i++) {
          if (!(js1.includes(data[i].pnut_variety_id))) {
            obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name }
            if (data[i].symbol_ind == "TRADEMARK") {
              obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name + " \u2122" }
            }
            if (data[i].symbol_ind == "REGISTERED TRADEMARK") {
              obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name +  " \xAE" }
            }
            if (data[i].symbol_ind == "COPYRIGHT") {
              obj = { key: data[i].pnut_variety_id, description: data[i].pnut_variety_name +  " \xA9"}
            }
            js.push(obj);
            js1.push(data[i].pnut_variety_id)
            obj = {}
          }
        }
        isbFillPeanutVarietyList = true
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddPeanut: {
            ...state["ddPeanut"],
            valueList: js
          }
        }
      });
      setValue(thisObj, 'ddPeanut', js[0].key)
      return isbFillPeanutVarietyList;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bFillPeanutVarietyList event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const ontxtNetLbsChange = async () => {
    try {
      setLoading(true)
      let n = 0
      let nn = 0

      disable(thisObj, 'btnCreateWR')
      let txtNetLbsValue = getValue(_kaledo.thisObj, 'txtNetLbs')
      if (txtNetLbsValue == undefined || txtNetLbsValue == null || Number(txtNetLbsValue) == 0) {
        disable(thisObj, 'btnCreateWR')
      }
      else {
        let GridValue = getValue(_kaledo.thisObj, 'gridInventoriesAvailable')
        if (GridValue != undefined && GridValue.length > 0) {
          for (let i = 0; i < GridValue.length; i++) {
            if (GridValue[i].chkboxcol1 == true) {
              enable(thisObj, 'btnCreateWR')
            }
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtNetLbsChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtNetLbsChange = ontxtNetLbsChange;

  const ontxt1007GrpNumChange = async () => {
    try {
      hide(thisObj, 'txt1007GrpName', false)
      let value20 = getValue(thisObj,'txt1007GrpNum')
      let value21 = (value20===undefined?"":getValue(thisObj,'txt1007GrpNum'))
      if((value21.length) >5){
      setLoading(true)
      let sStateAbbr = ''
      let sCountyID = ''
      let sFarmId = ''
      let sBuyPtId = ''
      let sVendor = ''
      let sSegType = ''
      let sPnutTypeID = ''
      let sContractType = ''
      let sContractNum = ''
      let sCmaDmaInd = ''
      let radioCMADMAValue = '3'
      let sDeferInd = ''
      let sSeedGen = ''
      let sSeedInd = ''
      let sPnutVarID = ''
      let sOleicInd = ''
      let n1007Count = 0
      let LstrList = []
      let i = 0
      let lBuyPtIndex = 0
      let bNotFound = false

      let txt1007GrpNumValue = getValue(thisObj, 'txt1007GrpNum')

      if (txt1007GrpNumValue == undefined || txt1007GrpNumValue == null || txt1007GrpNumValue == '') {
        return;
      }

      setValue(thisObj, 'ddHolderID', '')

      LstrList = await WarehouseReceiptService.Retrieve1007GroupList(compIdFromLS(), cropYearFromLS(), null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, txt1007GrpNumValue, null, null, null)
      if (LstrList != undefined && LstrList != null && LstrList.length > 0) {
        n1007Count = n1007Count + 1

        if (n1007Count == '1') {
          sBuyPtId = LstrList[0].buy_pt_id
          sStateAbbr = LstrList[0].state_abbr
          sCountyID = LstrList[0].county_id
          sFarmId = LstrList[0].farm_id + " - " + LstrList[0].farm_suffix
          sVendor = LstrList[0].vendor_id
          setData(thisObj,'sVendor',sVendor)
          sSegType = LstrList[0].seg_type
          sPnutTypeID = LstrList[0].pnut_type_id
          sContractType = LstrList[0].contract_type
          sContractNum = LstrList[0].contract_num
          sCmaDmaInd = LstrList[0].settle_grp_cma_dma_ind
          sDeferInd = LstrList[0].settle_grp_deferred_ind
          sSeedGen = LstrList[0].seed_gen
          if (sSeedGen != '') {
            sSeedInd = "Y"
          }
          else {
            sSeedInd = "N"
          }
          sPnutVarID = LstrList[0].pnut_variety_id
          sOleicInd = LstrList[0].oleic_ind

          switch (sCmaDmaInd) {
            case "C":
              radioCMADMAValue = '1'
              break;
            case 'D':
              radioCMADMAValue = '2'
              break;
            default:
              radioCMADMAValue = '3'
              break;
          }

          setValue(thisObj, 'radioCMADMA', radioCMADMAValue);
          setValue(thisObj, 'ddHolderID', LstrList[0].settle_grp_holder_id)
        }
        else {
          if (sBuyPtId != LstrList[0].buy_pt_id) {
            sBuyPtId = ""
          }
          if (sStateAbbr != LstrList[0].state_abbr) {
            sStateAbbr = ""
          }
          if (sCountyID != LstrList[0].county_id) {
            sCountyID = ""
          }
          if (sFarmId != LstrList[0].farm_id + ' - ' + LstrList[0].farm_suffix) {
            sFarmId = ""
          }
          if (sVendor != LstrList[0].vendor_id) {
            sVendor = ""
          }
          if (sSegType != LstrList[0].seg_type) {
            sSegType = ""
          }
          if (sPnutTypeID != LstrList[0].pnut_type_id) {
            sPnutTypeID = ""
          }
          if (sContractType != LstrList[0].contract_type) {
            sContractType = ""
          }
          if (sContractNum != LstrList[0].contract_num) {
            sContractNum = ""
          }
          if (sCmaDmaInd != LstrList[0].settle_grp_cma_dma_ind) {
            sCmaDmaInd = ""
          }
          if (sDeferInd != LstrList[0].settle_grp_deferred_ind) {
            sDeferInd = ""
          }
          if (sSeedGen != LstrList[0].seed_gen) {
            sSeedGen = ""
            if (LstrList[0].seed_gen == '') {
              sSeedInd = ""
            }
            else {
              if (sSeedInd == "N") {
                sSeedInd = ""
              }
            }
          }
          if (sPnutVarID != LstrList[0].pnut_variety_id) {
            sPnutVarID = ""
          }
          if (sOleicInd != LstrList[0].oleic_ind) {
            sOleicInd = ""
          }
        }
      }
      else{
        setData(thisObj,'sVendor',null)
      }



      if (sVendor == undefined || sVendor == null || sVendor == "") {
        show(thisObj, 'txt1007GrpName')
        setLoading(false)
        return;
      }
      else {
        hide(thisObj, 'txt1007GrpName')
      }

      if (sBuyPtId != undefined && sBuyPtId != null && sBuyPtId != "") {
        bNotFound = true

        setValue(thisObj, 'ddLocation', sBuyPtId)

        if (getValue(thisObj, 'ddLocation') != undefined && getValue(thisObj, 'ddLocation') != null) {
          bNotFound = false
          setValue(thisObj, 'txtVendorNum', sVendor)
          await ontxtVendorNumBlur()
          const isStateListLoaded = await bFillStateList(sBuyPtId);
          const isCountyListLoaded = await bFillCountyList(sStateAbbr);
          const isFarmListLoaded = await bFillFarmlist(sBuyPtId, sStateAbbr, sCountyID);
          setValue(thisObj, 'ddState', sStateAbbr)
          let CountyList = getData(thisObj, 'ddCountyList')
          let CountyKey = CountyList.find(elem => elem.CountyID == sCountyID)?.key || ''
          setValue(thisObj, 'ddCounty', CountyKey)
          await bFillFarmlist()
          setValue(thisObj, 'ddFarmNum', sFarmId)
          setValue(thisObj, 'ddPeanutType', sPnutTypeID)
          setValue(thisObj, 'ddSegType', sSegType)
          setValue(thisObj, 'ddPeanut', sPnutVarID)
          setValue(thisObj, 'ddOleic', sOleicInd)
          setValue(thisObj, 'ddSeed', sSeedInd)

          if (sSeedInd == "Y") {
            setValue(thisObj, 'ddGeneration', sSeedGen)
            setData(thisObj, 'ddGeneration', sSeedGen)
            if(getData(thisObj,'ddGeneration') == ''){
              disable(thisObj,'ddGeneration')
            }
            else{
              enable(thisObj,'ddGeneration')
            }
          }

          switch (sContractType) {
            case "S":
              setValue(thisObj, 'radioAppliedTo', '5')
              await onradioAppliedToChange()
              setValue(thisObj, 'txtContractNum', '')
              break;
            case "O":
              setValue(thisObj, 'radioAppliedTo', '1')
              setValue(thisObj, 'txtContractNum', '')
              break;
            case "M":
              setValue(thisObj, 'radioAppliedTo', '2')
              setValue(thisObj, 'txtContractNum', '')
              break;
            case "C":
              setValue(thisObj, 'radioAppliedTo', '3')
              setValue(thisObj, 'txtContractNum', sContractNum)
              break;
            case "C":
              setValue(thisObj, 'radioAppliedTo', '4')
              setValue(thisObj, 'txtContractNum', '')
              break;
            default:
              setValue(thisObj, 'radioAppliedTo', '1')
              break;
          }
          await onradioCMADMAChange(null, radioCMADMAValue);
          setValue(thisObj, 'gridInventoriesAvailable', []) // EmptyGrids()
          setData(thisObj, 'PreviousRowID', null)
          setValue(thisObj, 'gridAvailable1007s', [])

          if (sDeferInd == 'Y') {
            setValue(thisObj, 'chkboxDiferredRedemption', '1')
          }
          else {
            setValue(thisObj, 'chkboxDiferredRedemption', '')
          }
        }
      }
    
      if (bNotFound) {
        showMessage(thisObj, "Cannot find 1007 group number.")
      }
      setLoading(false)
    }
  }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxt1007GrpNumBlur event"
        );
      }
    }
  }
  thisObj.ontxt1007GrpNumChange = ontxt1007GrpNumChange;

  const ontxtVendorNumBlur = async () => {
    try {
      setLoading(true)
      let txtVendorNumValue = getValue(_kaledo.thisObj, 'txtVendorNum')

      await bFillRemitToList(txtVendorNumValue)

      let txtVendorValue = getValue(_kaledo.thisObj, 'txtVendor')

      if (txtVendorNumValue == undefined || txtVendorNumValue == null || txtVendorNumValue == '' || txtVendorValue == undefined || txtVendorValue == null || txtVendorValue == '') {
        disable(thisObj, 'btnSearch')
      }
      else {
        enable(thisObj, 'btnSearch')
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtVendorNumBlur event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.ontxtVendorNumBlur = ontxtVendorNumBlur;

  const onddPeanutTypeChange = async () => {
    try {
      setLoading(true)
      let ddPeanutTypeValue = getValue(thisObj, 'ddPeanutType')
      if (ddPeanutTypeValue != getData(thisObj, 'PrevddPeanutTypeValue')) {
        await bFillPeanutVarietyList()
        setData(thisObj, 'mbFormDirty', true)
        setData(thisObj, 'PrevddPeanutTypeValue', ddPeanutTypeValue)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddPeanutTypeChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddPeanutTypeChange = onddPeanutTypeChange;

  const onddPeanutChange = () => {
    try {
      setLoading(true)
      let ddPeanutValue = getValue(thisObj, 'ddPeanut')

      setValue(thisObj, 'gridInventoriesAvailable', [])
      setValue(thisObj, 'gridAvailable1007s', [])

      if (ddPeanutValue != getData(thisObj, 'PrevddPeanutValue')) {
        setData(thisObj, 'mbFormDirty', true)
        setData(thisObj, 'PrevddPeanutValue', ddPeanutValue)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddPeanutChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddPeanutChange = onddPeanutChange;

  const onddStateChange = async () => {
    try {
      setLoading(true)
      let ddStateValue = getValue(thisObj, 'ddState')
      let prevStateValue = getData(thisObj, 'PrevddStateValue')
      if (ddStateValue != prevStateValue) {

        setValue(thisObj, 'gridInventoriesAvailable', [])
        setValue(thisObj, 'gridAvailable1007s', [])

        await bFillCountyList()
        await bFillFarmlist(null, ddStateValue);
        setData(thisObj, 'PrevddStateValue', ddStateValue)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddStateChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddStateChange = onddStateChange;

  const onddCountyChange = async () => {
    try {
      setLoading(true)
      let ddCountyValue = getValue(thisObj, 'ddCounty')
      let ddStateValue = getValue(thisObj, 'ddState')
      let PrevddCountyValue = getData(thisObj, 'PrevddCountyValue')
      let PrevddStateValue = getData(thisObj, 'PrevddStateValue')
      if ((ddCountyValue != PrevddCountyValue) || (ddStateValue != PrevddStateValue)) {

        setValue(thisObj, 'gridInventoriesAvailable', [])
        setValue(thisObj, 'gridAvailable1007s', [])

        await bFillFarmlist()
        setData(thisObj, 'PrevddCountyValue', ddCountyValue)
        setData(thisObj, 'PrevddStateValue', ddStateValue)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onddCountyChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onddCountyChange = onddCountyChange;

  const onbtnMoreClick = async () => {
    try {
      setLoading(true)
      await ReadInventories()
      disable(thisObj, 'btnMore')
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnMoreClick event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnMoreClick = onbtnMoreClick;

  const onchkboxcol1Change = async (event) => {
    try {
      setLoading(true)
      let gridData = getValue(thisObj, 'gridInventoriesAvailable')
      let newGridData = []
      let index=0;
      let nameEvent = event.target.name
      index = Number(nameEvent.split(".")[1])
      setData(thisObj, 'chkboxcol1Checked', false)
      for (let i = 0; i < gridData.length; i++) {
        if (gridData[i].chkboxcol1 == true && i!=index) {
            setData(thisObj, 'chkboxcol1Checked', true)
            setGridValue(thisObj, 'gridInventoriesAvailable', 'chkboxcol1', i, false)
            newGridData = getValue(thisObj, 'gridInventoriesAvailable')
            for (let j = 0; j < newGridData.length; j++) {
              if (gridData[j].chkboxcol1 == true) {
                setData(thisObj, 'PreviousRowID', j)
                await FillDefaultShrink()
                await ontxtNetLbsChange()
                return;
              }
            }
          }
        }
        if (getData(thisObj, 'chkboxcol1Checked') == false) {
          setData(thisObj, 'PreviousRowID', null)
        }
  
        await FillDefaultShrink()
        await ontxtNetLbsChange()
      }
  
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onchkboxcol1Change event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onchkboxcol1Change = onchkboxcol1Change;
  
  const onchkboxAll1007sChange = async () => {
    try {
      setLoading(true)
      let checkboxValue = getValue(thisObj, 'chkboxAll1007s');
      let gridData = getValue(thisObj, 'gridAvailable1007s');
      if (gridData.length > 0) {
        for (let i = 0; i < gridData.length; i++) {
          if (checkboxValue == true) {
            gridData[i].chkboxcol = true;
          }
          else {
            gridData[i].chkboxcol = false;
          }
        }
      }

      await onchkboxcolChange()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onchkboxAll1007sChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }

  }
  thisObj.onchkboxAll1007sChange = onchkboxAll1007sChange;

  const onchkboxcolChange = async (e) => {
    try {
      setLoading(true)
      let n = 0
      let LdblGROSSwt = 0
      let LdblNETwt = 0
      let LdblLSKwt = 0
      let LdblSMKwa = 0
      let LdblSSwa = 0
      let LdblTOTALSMKwa = 0
      let LdblOKwa = 0
      let LdblDAMwa = 0
      let LdblFREEZEwa = 0
      let LdblCNCLRMDwa = 0
      let LdblAFLAVUSwa = 0
      let LdblHULLSwa = 0
      let LdblFMwa = 0
      let LdblELKwa = 0
      let LdblFANwa = 0
      let LdblCRBRwa = 0
      let LdblDCOLSHELwa = 0
      let LdblMOISTwa = 0
      let LdblDATEwa = 0

      let LdblLSKwa = 0
      let LdblHULLBRIGHTwa = 0
      let LdblJUMBOwa = 0
      let LdblSPLITDAMwa = 0
      let LdblVICAMPPBwa = 0
      let LdblTKCwa = 0
      let LdblBasisGradeAmtwa = 0

      let GridData = getValue(thisObj, 'gridAvailable1007s')
      // let lblGrossPoundsValue = getData(thisObj, 'lblGrossPounds')
      let txtWtsShrinkPctValue = getValue(thisObj, 'txtWtsShrinkPct')

      if (GridData != undefined && GridData.length > 0) {
        const isGroupDataForTopGrid = getData(thisObj, 'isGroupDataForTopGrid');
        const isChecking = e?.target?.value === true;
        const checkedRows = GridData.filter(item => item.chkboxcol === true);
        if (isGroupDataForTopGrid && isChecking && checkedRows.length === 1) {
          GridData.forEach(item => item.chkboxcol = true);
        }
        for (let i = 0; i < GridData.length; i++) {
          if (GridData[i].chkboxcol == true) {
            setData(thisObj, 'setData', true)
            LdblGROSSwt = Number(LdblGROSSwt) + Number(GridData[i].grossWt)
            LdblNETwt = Number(LdblNETwt) + Number(GridData[i].txtcolNetWt)
            LdblLSKwt = Number(LdblLSKwt) + Number(GridData[i].txtcolLSKWt)
            LdblSMKwa = Number(LdblSMKwa) + (Number(GridData[i].smkPct) * Number(GridData[i].txtcolNetWt))
            LdblSSwa = Number(LdblSSwa) + (Number(GridData[i].ssPct) * Number(GridData[i].txtcolNetWt))
            LdblTKCwa = Number(LdblTKCwa) + (Number(GridData[i].tkcPct) * Number(GridData[i].txtcolNetWt))
            // LdblDATEwa = Number(LdblDATEwa) + Number(GridData[i].txtcolInspectionDate) * Number(GridData[i].txtcolNetWt) // check inspection date
            let date = GridData[i].txtcolInspectionDate
            const targetDate = new Date(date);
            const referenceDate = new Date("12/29/1899");
            const millisecondsDifference = targetDate.getTime() - referenceDate.getTime();
            const numericRepresentation = Math.floor(millisecondsDifference / (24 * 60 * 60 * 1000));
            LdblDATEwa = Number(LdblDATEwa) + Number(numericRepresentation) * Number(GridData[i].txtcolNetWt) 
            LdblOKwa = Number(LdblOKwa) + (Number(GridData[i].okPct) * Number(GridData[i].txtcolNetWt))
            LdblDAMwa = Number(LdblDAMwa) + (Number(GridData[i].damPct) * Number(GridData[i].txtcolNetWt))
            LdblFREEZEwa = Number(LdblFREEZEwa) + (Number(GridData[i].frezDamPct) * Number(GridData[i].txtcolNetWt))
            LdblCNCLRMDwa = Number(LdblCNCLRMDwa) + (Number(GridData[i].cnclRmdPct) * Number(GridData[i].txtcolNetWt))
            LdblAFLAVUSwa = Number(LdblAFLAVUSwa) + Number(GridData[i].falvusDiscount)
            LdblHULLSwa = Number(LdblHULLSwa) + (Number(GridData[i].hullPct) * Number(GridData[i].txtcolNetWt))
            LdblFMwa = Number(LdblFMwa) + (Number(GridData[i].fmPct) * Number(GridData[i].txtcolNetWt))
            LdblELKwa = Number(LdblELKwa) + (Number(GridData[i].elkPct) * Number(GridData[i].txtcolNetWt))
            LdblFANwa = Number(LdblFANwa) + (Number(GridData[i].fanPct) * Number(GridData[i].txtcolNetWt))
            LdblDCOLSHELwa = Number(LdblDCOLSHELwa) + (Number(GridData[i].dcolShelPct) * Number(GridData[i].txtcolNetWt))
            LdblCRBRwa = Number(LdblCRBRwa) + (Number(GridData[i].crBrPct) * Number(GridData[i].txtcolNetWt))
            LdblMOISTwa = Number(LdblMOISTwa) + (Number(GridData[i].moistPct) * Number(GridData[i].txtcolNetWt))
            LdblLSKwa = Number(LdblLSKwa) + (Number(GridData[i].lskPct) * Number(GridData[i].txtcolNetWt))
            LdblJUMBOwa = Number(LdblJUMBOwa) + (Number(GridData[i].hulBritPct) * Number(GridData[i].txtcolNetWt))
            LdblHULLBRIGHTwa = Number(LdblHULLBRIGHTwa) + (Number(GridData[i].jumboPct) * Number(GridData[i].txtcolNetWt))
            LdblSPLITDAMwa = Number(LdblSPLITDAMwa) + (Number(GridData[i].damSplitPct) * Number(GridData[i].txtcolNetWt))
            LdblVICAMPPBwa = Number(LdblVICAMPPBwa) + (Number(GridData[i].vicamPpb) * Number(GridData[i].txtcolNetWt))
            LdblTOTALSMKwa = Number(LdblTOTALSMKwa) + ((Number(GridData[i].smkPct) + Number(GridData[i].ssPct)) * Number(GridData[i].txtcolNetWt))
          }
        }
      }

      if (getData(thisObj, 'setData') == true) {
        setData(thisObj, 'setData', false)
        let serialNumber = Math.floor(LdblDATEwa / LdblNETwt);
        // const millisecondsPerDay = 24 * 60 * 60 * 1000;
        // const dateValue = new Date((serialNumber  + Date.UTC(1899, 11, 30) / millisecondsPerDay) * millisecondsPerDay);
        // const month = (dateValue.getMonth() + 1).toString().padStart(2, '0');
        // const day = dateValue.getDate().toString().padStart(2, '0');
        // const year = dateValue.getFullYear();
        // const formattedDate = `${month}/${day}/${year}`;
        const referenceDate = new Date('1899-12-31');
        const timeZoneOffsetMinutes = -6 * 60;
        const resultDate = new Date(referenceDate.getTime() + serialNumber * 24 * 60 * 60 * 1000 + timeZoneOffsetMinutes * 60 * 1000);
        const formattedDate = (resultDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
                      resultDate.getDate().toString().padStart(2, '0') + '/' +
                      resultDate.getFullYear();
        setValue(thisObj, 'txtStorageDate', formattedDate) 
        setData(thisObj, 'lblGrossPounds', LdblGROSSwt)
        setValue(thisObj, 'txtNetLbs', (LdblNETwt - LdblLSKwt))
        setData(thisObj, 'lblNetTons', (LdblNETwt / 2000))
        setValue(thisObj, 'txtTotalLbs', LdblNETwt)
        setValue(thisObj, 'txtLSKLbs', LdblLSKwt)

        let txtNetLbsValue = getValue(_kaledo.thisObj, 'txtNetLbs')
        let txtTotalLbsValue = getValue(_kaledo.thisObj, 'txtTotalLbs')
        let txtLSKLbsValue = getValue(_kaledo.thisObj, 'txtLSKLbs')
        let lblGrossPoundsValue = getData(thisObj, 'lblGrossPounds')

        if (isNaN(txtWtsShrinkPctValue) == false) {
          if (isNaN(lblGrossPoundsValue) == false) {
            setData(thisObj, 'lblGrossPoundsShrunk', Math.round((Number(lblGrossPoundsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100)).toFixed(2)))
          }
          else {
            setData(thisObj, 'lblGrossPoundsShrunk', '0')
          }
          if (isNaN(txtNetLbsValue) == false) {
            setValue(thisObj, 'txtNetLbsShrunk', Math.round((Number(txtNetLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100)).toFixed(2)))
          }
          else {
            setValue(thisObj, 'txtNetLbsShrunk', '0')
          }

          if (isNaN(txtTotalLbsValue) == false) {
            setValue(thisObj, 'txtTotalLbsShrunk', Math.round((Number(txtTotalLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100)).toFixed(2)))
          }
          else {
            setValue(thisObj, 'txtTotalLbsShrunk', '0')
          }

          if (isNaN(txtLSKLbsValue) == false) {
            setValue(thisObj, 'txtLSKLbsShrunk', Math.round((Number(txtLSKLbsValue) * ((100 - Number(txtWtsShrinkPctValue)) / 100)).toFixed(2)))
          }
          else {
            setValue(thisObj, 'txtLSKLbsShrunk', '0')
          }
        }
        else {
          setData(thisObj, 'lblGrossPoundsShrunk', Math.round(Number(lblGrossPoundsValue).toFixed(2)))
          setValue(thisObj, 'txtNetLbsShrunk', Math.round(Number(txtNetLbsValue).toFixed(2)))
          setValue(thisObj, 'txtTotalLbsShrunk', Math.round(Number(txtTotalLbsValue).toFixed(2)))
          setValue(thisObj, 'txtLSKLbsShrunk', Math.round(Number(txtLSKLbsValue).toFixed(2)))
        }

        setValue(thisObj, 'txtLSKPct', (LdblLSKwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtHullsBrightPct', (LdblHULLBRIGHTwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtVICAMPPB', (LdblVICAMPPBwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctSplitDamage', (LdblSPLITDAMwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtJumboPct', (LdblJUMBOwa / LdblNETwt).toFixed(2))
        setData(thisObj, 'lblTKC', (LdblTKCwa / LdblNETwt))
        setValue(thisObj, 'txtSMKPct', (LdblSMKwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtSSPct', (LdblSSwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctOtherKernels', (LdblOKwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctTotalDamage', (LdblDAMwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctFreezeDamage', (LdblFREEZEwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctConcealedRMD', (LdblCNCLRMDwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtHillsPct', (LdblHULLSwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctFM', (LdblFMwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctELK', (LdblELKwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctFancy', (LdblFANwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctCrackBroken', (LdblCRBRwa / LdblNETwt).toFixed(2))
        setValue(thisObj, 'txtPctDiscolored', (LdblDCOLSHELwa / LdblNETwt).toFixed(2))

        if (Number(LdblMOISTwa / LdblNETwt) > 10.49) {
          setValue(thisObj, 'txtPctMoist', '10.49')
        }
        else {
          setValue(thisObj, 'txtPctMoist', (LdblMOISTwa / LdblNETwt).toFixed(2))
        }

        setValue(thisObj, 'txtTotalSMKPct', (LdblTOTALSMKwa / LdblNETwt).toFixed(2))
        let txtTotalSMKPctValue = getValue(_kaledo.thisObj, 'txtTotalSMKPct')
        let txtPctOtherKernelsValue = getValue(_kaledo.thisObj, 'txtPctOtherKernels')
        let txtPctTotalDamageValue = getValue(_kaledo.thisObj, 'txtPctTotalDamage')
        setValue(thisObj, 'txtPctTotalKernels', (Number(txtTotalSMKPctValue) + Number(txtPctOtherKernelsValue) + Number(txtPctTotalDamageValue)).toFixed(2))
        
        setValue(thisObj, 'txtHoeStored', "Bulk")
        setValue(thisObj, 'txtStatus', "Unshelled")
        let txtPctTotalKernelsValue = getValue(_kaledo.thisObj, 'txtPctTotalKernels')
        let txtHillsPctValue = getValue(_kaledo.thisObj, 'txtHillsPct')
        setValue(thisObj, 'txtPctTotalKrnlsHulls', (Number(txtPctTotalKernelsValue) + Number(txtHillsPctValue)).toFixed(2))

        if (LdblAFLAVUSwa > 0) {
          setValue(thisObj, 'txtFlavus', 'Yes')
        }
        else{
          setValue(thisObj, 'txtFlavus', 'No')
        }
      }
      else {

        setValue(thisObj, 'txtSMKPct', '0')
        setValue(thisObj, 'txtNetLbs', '0')
        setValue(thisObj, 'txtLSKLbs', '0')
        setValue(thisObj, 'txtNetLbsShrunk', '0')
        setValue(thisObj, 'txtTotalLbsShrunk', '0')
        setValue(thisObj, 'txtTotalLbs', '0')
        setValue(thisObj, 'txtLSKLbsShrunk', '0')
        setValue(thisObj, 'txtStorageDate', '')
        setValue(thisObj, 'txtPctFM', '0')
        setValue(thisObj, 'txtPctMoist', '0')
        setValue(thisObj, 'txtPctELK', '0')
        setValue(thisObj, 'txtPctFancy', '0')
        setValue(thisObj, 'txtPctCrackBroken', '0')
        setValue(thisObj, 'txtPctDiscolored', '0')
        setValue(thisObj, 'txtSSPct', '0')
        setValue(thisObj, 'txtTotalSMKPct', '0')
        setValue(thisObj, 'txtPctOtherKernels', '0')
        setValue(thisObj, 'txtPctTotalDamage', '0')
        setValue(thisObj, 'txtPctTotalKernels', '0')
        setValue(thisObj, 'txtHillsPct', '0')
        setValue(thisObj, 'txtPctTotalKrnlsHulls', '0')
        setValue(thisObj, 'txtFlavus', "No")
        setValue(thisObj, 'txtPctFreezeDamage', '0')
        setValue(thisObj, 'txtPctConcealedRMD', '0')
        setValue(thisObj, 'txtLSKPct', '0')
        setValue(thisObj, 'txtHullsBrightPct', '0')
        setValue(thisObj, 'txtVICAMPPB', '0')
        setValue(thisObj, 'txtPctSplitDamage', '0')
        setValue(thisObj, 'txtJumboPct', '0')
        setValue(thisObj, 'txtHoeStored', "Bulk")
        setValue(thisObj, 'txtStatus', "Unshelled")
        setData(thisObj, 'lblTKC', '0')
        setData(thisObj, 'lblGrossPounds', '0')
        setData(thisObj, 'lblNetTons', '0')
        setData(thisObj, 'lblGrossPoundsShrunk', '0')
        setData(thisObj, 'lblBasisGradeAmt', '0')
      }

      await ontxtNetLbsChange()
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onchkboxcolChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onchkboxcolChange = onchkboxcolChange;

  const onradioAppliedToChange = async () => {
    try {
      setLoading(true)
      let radioAppliedToValue = getValue(_kaledo.thisObj, 'radioAppliedTo')
      let mbAllowChangeWeightShrink = getData(thisObj, 'mbAllowChangeWeightShrink')

      await FillDefaultShrink()

      setValue(thisObj, 'gridInventoriesAvailable', [])
      setValue(thisObj, 'gridAvailable1007s', [])

      if (radioAppliedToValue < '5') {
        disable(thisObj, 'txtWtsShrinkPct')
      }
      else {
        if (mbAllowChangeWeightShrink) {
          enable(thisObj, 'txtWtsShrinkPct')
        }
        else {
          disable(thisObj, 'txtWtsShrinkPct')
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onradioAppliedToChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onradioAppliedToChange = onradioAppliedToChange;

  const ontxtVendorNumChange = async () => {
    try {
      setValue(thisObj, 'gridInventoriesAvailable', []) // EmptyGrids()
      setValue(thisObj, 'gridAvailable1007s', [])
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during ontxtVendorNumChange event"
        );
      }
    }
  }
  thisObj.ontxtVendorNumChange = ontxtVendorNumChange;

  const bGetControlNumber = async () => {
    try {
      setLoading(true)
      let LstrList = []
      let msUBuyPtId = ''

      await ContractManagementService.RetrieveUserControlDetails(useridFromLS(), null, null, null).then(response => {
        LstrList = response
      })
      if (LstrList != undefined && LstrList != null && LstrList.length > 0) {
        msUBuyPtId = LstrList[0].buyPtId
        LstrList = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1090', null, null, null, msUBuyPtId)
        let CntlrNumber = '000000'
        if (LstrList != undefined && LstrList != null && LstrList.length > 0 && LstrList[0].next_whse_cntl != undefined && LstrList[0].next_whse_cntl.length < 6) {
          CntlrNumber = '0'.repeat(6 - LstrList[0].next_whse_cntl.length) + LstrList[0].next_whse_cntl
        }
        else if (LstrList != undefined && LstrList != null && LstrList.length > 0 && LstrList[0].next_whse_cntl != undefined && LstrList[0].next_whse_cntl.length == 6) {
          CntlrNumber = LstrList[0].next_whse_cntl
        }

        setValue(thisObj, 'txtControlNumber', CntlrNumber)
      }

      setData(thisObj, 'msUBuyPtId', msUBuyPtId)
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bGetControlNumber event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const GetAllowWeightShrinkChange = async () => {
    try {
      setLoading(true)
      let lstrxml = []

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '001', 'U', null)
      if (lstrxml[0].permission == 'N') {
        setData(thisObj, 'mbAllowChangeWeightShrink', false)
      }
      else {
        setData(thisObj, 'mbAllowChangeWeightShrink', true)
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during GetAllowWeightShrinkChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }

  const txtVendorNumMAChange = async () => {
    try {
      setLoading(true)
      let radioCMADMAValue = getValue(thisObj, 'radioCMADMA')
      let radioAppliedToValue = getValue(thisObj, 'radioAppliedTo')

      if (radioCMADMAValue == '1' && radioAppliedToValue != '5') {
        setValue(thisObj, 'gridAvailable1007s', [])
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtVendorNumMAChange event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.txtVendorNumMAChange = txtVendorNumMAChange;

  const onbtnCreateWRClick = async () => {
    try {
      setLoading(true)
      let s = ''
      let n = 0
      let nn = 0
      let WB = ''
      let lstrxml = []
      let LstrXML2 = []
      let LstrNBUY_PT_ID = ''
      let LstrFedWhseLic = ''
      let LdblValue = 0
      let sControlNumberPrompt = ''
      let nPrompt = 0
      let strReceiptNumber = ''
      let bTest = false
      let nOriginalControlNumber = 0
      let sHoldContract = ''

      if (document.getElementsByClassName("txt1007GrpName")[0].style.visibility == 'visible') {
        showMessage(thisObj, "Cannot find 1007 group number.")
        return;
      }

      if ((getValue(thisObj, 'txtVendorName') == undefined || getValue(thisObj, 'txtVendorName') == null || getValue(thisObj, 'txtVendorName') == '') && (getValue(thisObj, 'radioCMADMA') == '1' || getValue(thisObj, 'radioCMADMA') == '2')) {
        showMessage(thisObj, "Must enter a valid vendor")
        return;
      }

      sHoldContract = ""

      let radioAppliedToValue = getValue(thisObj, 'radioAppliedTo')
      let gridAvailable1007sValue = getValue(thisObj, 'gridAvailable1007s')
      if (radioAppliedToValue == '3') {
        for (let i = 0; i < gridAvailable1007sValue.length; i++) {
          if (gridAvailable1007sValue[i].chkboxcol == true) {
            if (sHoldContract == '') {
              sHoldContract = gridAvailable1007sValue[i].txtcolFarm
            }
            else {
              if (gridAvailable1007sValue[i].txtcolFarm != sHoldContract) {
                showMessage(thisObj, "Warning! Receipts issued from pounds applied to Farmer Stock Contracts ")
                return;
              }
            }
          }
        }
      }

      if (Number(getValue(thisObj, 'txtWtsShrinkPct')) > 10 || Number(getValue(thisObj, 'txtWtsShrinkPct')) < 0) {
        showMessage(thisObj, "Shrink percent must be between 0 and 10%")
        return;
      }

      nn = 0
      let gridInventoriesAvailableValue = getValue(thisObj, 'gridInventoriesAvailable')
      let txtNetLbsShrunkValue = getValue(thisObj, 'txtNetLbsShrunk')
      for (let i = 0; i < gridInventoriesAvailableValue.length; i++) {
        if (gridInventoriesAvailableValue[i].chkboxcol1 == true) {
          nn = i
          LstrNBUY_PT_ID = gridInventoriesAvailableValue[i].BuyPtId
          LstrFedWhseLic = gridInventoriesAvailableValue[i].txtcolFedLicense

          if ((Number(txtNetLbsShrunkValue) / 2000) > Number(gridInventoriesAvailableValue[i].txtcolAvailableForAssignment)) {
            const confirm1 = confirm("Warning! This receipt will cause this bin to be obligated for more than it's available pounds.")
            if (!confirm1) {
              return
            }
          }

          if (isNaN(Number(gridInventoriesAvailableValue[i].txtcolGovernmentCapacity)) == false) {
            if ((Number(txtNetLbsShrunkValue) / 2000) > Number(gridInventoriesAvailableValue[i].txtcolGovernmentCapacity)) {
              alert("Error! This receipt would cause this bin to be obligated for more than it's government capacity.")
              return
            }
          }
        }
      }

      if (LstrFedWhseLic == '') {
        showMessage(thisObj, "Selected Obligation Point is not set up to Create a Warehouse Reciept")
        return;
      }

      if (Number(getValue(thisObj, 'txtWtsShrinkPct')) <= 0 && getValue(thisObj, 'radioAppliedTo') == '5') {
        await GetAllowWeightShrinkChange()
        if (getData(thisObj, 'mbAllowChangeWeightShrink')) {
          let Confirm = confirm("Uncontracted receipt will have a zero shrink percentage. Is this correct?")
          if (Confirm) {
            showMessage(thisObj, "Verify Zero Shrink Percent")
          }
          else {
            return
          }
        }
        else {
          lstrxml = await SystemMaintenanceMasterManagementService.RetrieveShrinkControlDetails(LstrFedWhseLic, getValue(thisObj, 'ddPeanutType'))
          if (lstrxml == undefined || lstrxml == null || lstrxml.length <= 0) {
            return
          }

          if (isNaN(Number(lstrxml[0].weight_shrink_pct)) == false) {
            if (Number(lstrxml[0].weight_shrink_pct) > 0) {
              showMessage(thisObj, "Un-contracted receipt cannot have a zero shrink percentage.")
            }
          }
          else {
            showMessage(thisObj, "Shrink Control Percent not found.")
          }
        }
      }

      LdblValue = 0
      nn = 0

      WB = gridInventoriesAvailableValue[nn].txtcolWhouseBin

      let data = {}
      let dataObj = {}
      let LstObj = []

      for (let i = 0; i < gridAvailable1007sValue.length; i++) {
        if (gridAvailable1007sValue[i].chkboxcol == true) {
          LdblValue = LdblValue + Number(gridAvailable1007sValue[i].txtcolValue)

          nn = nn + 1
          dataObj.insP_NUM = gridAvailable1007sValue[i].txtcolSC95
          if (gridAvailable1007sValue[i].txtcolContract != undefined && gridAvailable1007sValue[i].txtcolContract.length > 0 && gridAvailable1007sValue[i].txtcolContract.length < 6) {
            gridAvailable1007sValue[i].txtcolContract = '0'.repeat(6 - gridAvailable1007sValue[i].txtcolContract.length) + gridAvailable1007sValue[i].txtcolContract
          }
          dataObj.contracT_NUM = gridAvailable1007sValue[i].txtcolContract
          dataObj.obL_BUY_ID = gridAvailable1007sValue[i].oblBuyId
          dataObj.obL_WHSE_ID = gridAvailable1007sValue[i].oblWhseID
          dataObj.obL_BIN_ID = gridAvailable1007sValue[i].oblBinID
          dataObj.neT_WT = gridAvailable1007sValue[i].txtcolNetWt

          LstObj.push(dataObj)
          dataObj = {}
        }
      }

      data.insplist = LstObj
      data.BUY_PT_ID = getValue(thisObj, 'ddLocation')
      data.NBUY_PT_ID = LstrNBUY_PT_ID
      data.FED_WHOUSE_LICENSE = LstrFedWhseLic
      data.INSP_NUM = ''
      data.UBUY_PT_ID = getData(thisObj, 'msUBuyPtId')

      if (getData(thisObj, 'mboolElectronicWarehouseReceipt')) {
        data.RCPT_CNTL_NUM = 0
      }
      else {
        data.RCPT_CNTL_NUM = getValue(thisObj, 'txtControlNumber')
      }

      data.RCPT_VENDOR = getValue(thisObj, 'txtVendorNum')
      data.BASIS_GRADE_AMT = LdblValue.toString()
      for (let i = 0; i < gridInventoriesAvailableValue.length; i++) {
        if (gridInventoriesAvailableValue[i].chkboxcol1 == true) {
          let nna = i
      data.WHOUSE_NUM = gridInventoriesAvailableValue[nna].WhouseNum
      data.BIN_NUM = gridInventoriesAvailableValue[nna].BinNum
        }
      }
      data.PNUT_TYPE_ID = getValue(thisObj, 'ddPeanutType')
      data.SEG_TYPE = getValue(thisObj, 'ddSegType').toString()

      let ddLocationValue = getValue(thisObj, 'ddLocation')
      let ddLocationValueList = thisObj.state['ddLocation'].valueList
      let AreaId = ddLocationValueList.find(elem => elem.key == ddLocationValue).AreaID

      let ddPeanutTypeValue = getValue(thisObj, 'ddPeanutType')
      let txtPctTotalDamageValue = getValue(thisObj, 'txtPctTotalDamage').toString()
      let txtSSPctValue = getValue(thisObj, 'txtSSPct').toString()
      let txtPctFMValue = getValue(thisObj, 'txtPctFM').toString()
      let txtPctOtherKernelsMValue = getValue(thisObj, 'txtPctOtherKernels').toString()
      let txtSMKPctValue = getValue(thisObj, 'txtSMKPct').toString()
      let txtPctELKValue = getValue(thisObj, 'txtPctELK').toString()
      let txtLSKLbsShrunkValue = getValue(thisObj, 'txtLSKLbsShrunk').toString()
      let ddSegTypeValue = getValue(thisObj, 'ddSegType')

      lstrxml = await WarehouseReceiptService.RetrieveCalculatedReceiptValue(AreaId, ddPeanutTypeValue, txtPctTotalDamageValue, txtSSPctValue, txtPctFMValue, txtPctOtherKernelsMValue, txtSMKPctValue, txtPctELKValue, Number(txtNetLbsShrunkValue), Number(txtLSKLbsShrunkValue), ddSegTypeValue)
      if(lstrxml != undefined && lstrxml != null && lstrxml.length > 0){
        let Aa = true
      }
      else{
        if(lstrxml.message != undefined){
          showMessage(thisObj, lstrxml.message)
        }
        return;
      }
      
      if (Number(lstrxml[0].rcpT_VALUE) <= 0) {
        showMessage(thisObj, "Value of receipt must be greater than zero. Receipt can not be issued!'")
        return;
      }

      data.RCPT_VALUE = parseFloat(lstrxml[0].rcpT_VALUE).toFixed(2)
      data.ISSUED_DATE = getValue(thisObj, 'txtIssueDate')
      data.STORE_DATE = getValue(thisObj, 'txtStorageDate')|| getData(thisObj,'txtStorageDate')
      // data.STORE_DATE = '01/01/2023' // Harcoded ##
      data.SHRINK_PCT = Number(getValue(thisObj, 'txtWtsShrinkPct'))
      data.SHRINK_GROSS_WT = getData(thisObj, 'lblGrossPoundsShrunk').toString() == 'NaN' ? 0 : Number(getData(thisObj, 'lblGrossPoundsShrunk'))
      data.SHRINK_NET_WT = Number(getValue(thisObj, 'txtTotalLbsShrunk'))
      data.SHRINK_LSK_WT = Number(getValue(thisObj, 'txtLSKLbsShrunk'))
      data.GROSS_WT = Number(getData(thisObj, 'lblGrossPounds'))
      data.NET_WT = Number(getValue(thisObj, 'txtTotalLbs'))
      data.LSK_WT = Number(getValue(thisObj, 'txtLSKLbs'))
      data.FLAVUS_IND = getValue(thisObj, 'txtFlavus').slice(0,1)

      if (Number(getValue(thisObj, 'txtVICAMPPB')) == 0) {
        data.WA_VICAM_IND = 'N'
      }
      else {
        data.WA_VICAM_IND = 'Y'
      }

      data.WA_VICAM_PPB = getValue(thisObj, 'txtVICAMPPB')
      data.WA_SMK_PCT = getValue(thisObj, 'txtSMKPct')
      data.WA_SS_PCT = getValue(thisObj, 'txtSSPct')
      data.WA_OK_PCT = getValue(thisObj, 'txtPctOtherKernels')
      data.WA_DAM_PCT = getValue(thisObj, 'txtPctTotalDamage')
      data.WA_TKC_PCT = getData(thisObj, 'lblTKC')
      data.WA_HULL_PCT = getValue(thisObj, 'txtHillsPct')
      data.WA_FREZ_DAM_PCT = getValue(thisObj, 'txtPctFreezeDamage')
      data.WA_PIT_DAM_PCT = getValue(thisObj, 'txtPctSplitDamage')
      data.WA_CNCL_RMD_PCT = getValue(thisObj, 'txtPctConcealedRMD')
      data.WA_CR_BR_PCT = getValue(thisObj, 'txtPctCrackBroken')
      data.WA_DCOL_SHEL_PCT = getValue(thisObj, 'txtPctDiscolored')
      data.WA_HUL_BRIT_PCT = getValue(thisObj, 'txtHullsBrightPct')
      data.WA_JUMBO_PCT = getValue(thisObj, 'txtJumboPct')
      data.WA_ELK_PCT = getValue(thisObj, 'txtPctELK')
      data.WA_FM_PCT = getValue(thisObj, 'txtPctFM')
      data.WA_LSK_PCT = getValue(thisObj, 'txtLSKPct')
      data.WA_MOIST_PCT = getValue(thisObj, 'txtPctMoist')
      data.WA_FAN_PCT = getValue(thisObj, 'txtPctFancy')
      data.ADD_USER = useridFromLS()
      data.UID = useridFromLS()
      data.ADD_DATE_TIME = new Date()

      data.PNUT_VARIETY_ID = getValue(thisObj, 'ddPeanut')
      data.OLEIC_IND = getValue(thisObj, 'ddOleic')
      data.SEED_GEN = getValue(thisObj, 'ddGeneration')
      data.SEED_RCPT = getValue(thisObj, 'ddSeed')
      data.insp_count = nn

      if (getData(thisObj, 'mboolElectronicWarehouseReceipt') == true) {
        data.receipt_type = 'E'
      }
      else {
        data.receipt_type = 'P'
      }

      data.current_holder = getValue(thisObj, 'ddHolderID')

      let radioCMADMAValue = getValue(thisObj, 'radioCMADMA')
      data.cma_dma_ind = ''
      data.cma_dma_vendor_num = ''
      data.cma_dma_vendor_remit = ''
      if (radioCMADMAValue != '3') {
        if (radioCMADMAValue == '1') {
          data.cma_dma_ind = 'C'
        }
        else if (radioCMADMAValue == '2') {
          data.cma_dma_ind = 'D'
        }
        data.cma_dma_vendor_num = getValue(thisObj, 'txtVendorNumMA')
        data.cma_dma_vendor_remit = getValue(thisObj, 'ddRemitTo')
      }

      if (getValue(thisObj, 'chkboxDiferredRedemption') == '1') {
        data.DEFERRED_REDEMPTION_IND = 'Y'
      }
      else {
        data.DEFERRED_REDEMPTION_IND = 'N'
      }

      await WarehouseReceiptService.CreateWareHouseReceipt(data).then(response => {
        if (response.status != 200) {
          showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persist.\n" + (response?.message || ''))
          return;
        }
        else{
          if (!isNaN(response?.result)) {
            strReceiptNumber = response.result
          }
    
          let js = [{
            buyingPt: LstrNBUY_PT_ID,
            wrRcptNum: strReceiptNumber,
            rcptValue : lstrxml[0].rcpT_VALUE,
            docName: 'rptWarehouseReceiptWorksheet'
          }]
    
          setData(thisObj, 'ReportPreviewWRData', js)
          goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
    
        }
      })

      //   While nPrompt = vbNo
      //       nPrompt = MsgBox("Do you want to enter another Warehouse Receipt?", vbYesNo, "Warehouse Receipt")
      //       If nPrompt = vbNo Then
      //           Unload frmWarehouseReceipt
      //           Exit Sub
      //       Else
      //           DoSearch
      //           chk1007.Value = vbUnchecked
      //           txt1007Group.SetFocus                           'PP 2010-069     10/06/2010
      //           txt1007Group.SelStart = 0                       'PP 2010-069     10/06/2010
      //           txt1007Group.SelLength = Len(txt1007Group.Text) 'PP 2010-069     10/06/2010
      //           Exit Sub
      //       End If
      //   Wend

    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtnCreateWRClick event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtnCreateWRClick = onbtnCreateWRClick;

  const onbtncol1Click = async (event) => {
    try {
      setLoading(true)
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let GridData = getValue(thisObj, 'gridAvailable1007s')
      if (GridData != undefined && GridData.length > 0) {
        let SelectedRow = GridData[rowID]
        let BuyPtID = getValue(thisObj, 'ddLocation')
        let StateID = getValue(thisObj, 'ddState')
        let countyID = getValue(thisObj, 'ddCounty')
        let viewSettlementScreenData = {
          inspNum: SelectedRow.txtcolSC95,
          buyPtId: BuyPtID,
          stateId: StateID,
          countyId: countyID,
          Value: SelectedRow
        }
        setData(thisObj, 'viewSettlementData', viewSettlementScreenData);
        goTo(thisObj, "Settlements#ViewSettlement#DEFAULT#true#Click");
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during onbtncol1Click event"
        );
      }
    }
    finally {
      setLoading(false)
    }
  }
  thisObj.onbtncol1Click = onbtncol1Click;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
            <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_WarehouseReceipt*/}

              {/* END_USER_CODE-USER_BEFORE_WarehouseReceipt*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxWarehouseReceipt*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxWarehouseReceipt*/}

              <GroupBoxWidget
                conf={state.grpbxWarehouseReceipt}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails1*/}

                <GroupBoxWidget conf={state.grpbxDetails1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txt1007GrpNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txt1007GrpNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007GrpNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                   <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txt1007GrpName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txt1007GrpNum*/}

                  {/* END_USER_CODE-USER_AFTER_txt1007GrpNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                  {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                  <ButtonWidget
                    conf={state.btnVendor}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                  {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddLocation}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                  {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_ddState*/}

                  {/* END_USER_CODE-USER_BEFORE_ddState*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddState}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddState*/}

                  {/* END_USER_CODE-USER_AFTER_ddState*/}
                  {/* START_USER_CODE-USER_BEFORE_ddCounty*/}

                  {/* END_USER_CODE-USER_BEFORE_ddCounty*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddCounty}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddCounty*/}

                  {/* END_USER_CODE-USER_AFTER_ddCounty*/}
                  {/* START_USER_CODE-USER_BEFORE_ddFarmNum*/}

                  {/* END_USER_CODE-USER_BEFORE_ddFarmNum*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddFarmNum}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddFarmNum*/}

                  {/* END_USER_CODE-USER_AFTER_ddFarmNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtContractNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtContractNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtContractNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtContractNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtContractNum*/}
                  {/* START_USER_CODE-USER_BEFORE_ddHolderID*/}

                  {/* END_USER_CODE-USER_BEFORE_ddHolderID*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddHolderID}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddHolderID*/}

                  {/* END_USER_CODE-USER_AFTER_ddHolderID*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails2*/}

                <GroupBoxWidget conf={state.grpbxDetails2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanutType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddPeanut*/}

                  {/* END_USER_CODE-USER_BEFORE_ddPeanut*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddPeanut}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddPeanut*/}

                  {/* END_USER_CODE-USER_AFTER_ddPeanut*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSeed*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSeed*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSeed}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSeed*/}

                  {/* END_USER_CODE-USER_AFTER_ddSeed*/}
                  {/* START_USER_CODE-USER_BEFORE_ddGeneration*/}

                  {/* END_USER_CODE-USER_BEFORE_ddGeneration*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddGeneration}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddGeneration*/}

                  {/* END_USER_CODE-USER_AFTER_ddGeneration*/}
                  {/* START_USER_CODE-USER_BEFORE_ddSegType*/}

                  {/* END_USER_CODE-USER_BEFORE_ddSegType*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddSegType}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddSegType*/}

                  {/* END_USER_CODE-USER_AFTER_ddSegType*/}
                  {/* START_USER_CODE-USER_BEFORE_ddOleic*/}

                  {/* END_USER_CODE-USER_BEFORE_ddOleic*/}

                  <DropDownWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.ddOleic}
                    screenConf={state}
                  ></DropDownWidget>
                  {/* START_USER_CODE-USER_AFTER_ddOleic*/}

                  {/* END_USER_CODE-USER_AFTER_ddOleic*/}
                  {/* START_USER_CODE-USER_BEFORE_radioElectronicPaper*/}

                  {/* END_USER_CODE-USER_BEFORE_radioElectronicPaper*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioElectronicPaper}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioElectronicPaper*/}

                  {/* END_USER_CODE-USER_AFTER_radioElectronicPaper*/}
                  {/* START_USER_CODE-USER_BEFORE_radioAppliedTo*/}

                  {/* END_USER_CODE-USER_BEFORE_radioAppliedTo*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioAppliedTo}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioAppliedTo*/}

                  {/* END_USER_CODE-USER_AFTER_radioAppliedTo*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails2*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails3*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails3*/}

                <GroupBoxWidget conf={state.grpbxDetails3} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxMarketingAssociation*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxMarketingAssociation*/}

                  <GroupBoxWidget
                    conf={state.grpbxMarketingAssociation}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblMarketAssociation*/}

                    {/* END_USER_CODE-USER_BEFORE_lblMarketAssociation*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblMarketAssociation}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblMarketAssociation*/}

                    {/* END_USER_CODE-USER_AFTER_lblMarketAssociation*/}
                    {/* START_USER_CODE-USER_BEFORE_radioCMADMA*/}

                    {/* END_USER_CODE-USER_BEFORE_radioCMADMA*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioCMADMA}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioCMADMA*/}

                    {/* END_USER_CODE-USER_AFTER_radioCMADMA*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendorNumMA*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendorNumMA*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendorNumMA}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendorNumMA*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendorNumMA*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendorMA*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendorMA*/}

                    <ButtonWidget
                      conf={state.btnVendorMA}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                     <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendorName}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendorMA*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendorMA*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemitTo}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxMarketingAssociation*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxMarketingAssociation*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxDiferredRedemption*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxDiferredRedemption*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxDiferredRedemption}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxDiferredRedemption*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxDiferredRedemption*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails3*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails3*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxAvailable1007s*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxAvailable1007s*/}

                <GroupBoxWidget
                  conf={state.grpbxAvailable1007s}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblAvailable1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_lblAvailable1007s*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblAvailable1007s}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblAvailable1007s*/}

                  {/* END_USER_CODE-USER_AFTER_lblAvailable1007s*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxAll1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxAll1007s*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxAll1007s}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxAll1007s*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxAll1007s*/}
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                  {/* START_USER_CODE-USER_BEFORE_gridAvailable1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_gridAvailable1007s*/}

                  <GridWidget
                    conf={state.gridAvailable1007s}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridAvailable1007s}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    setFieldValue={setFieldValue}
                    refObject = {thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridAvailable1007s*/}

                  {/* END_USER_CODE-USER_AFTER_gridAvailable1007s*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxAvailable1007s*/}

                {/* END_USER_CODE-USER_AFTER_grpbxAvailable1007s*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxInventoriesAvailable*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxInventoriesAvailable*/}

                <GroupBoxWidget
                  conf={state.grpbxInventoriesAvailable}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblInventoriesAvailable*/}

                  {/* END_USER_CODE-USER_BEFORE_lblInventoriesAvailable*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblInventoriesAvailable}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblInventoriesAvailable*/}

                  {/* END_USER_CODE-USER_AFTER_lblInventoriesAvailable*/}
                  {/* START_USER_CODE-USER_BEFORE_btnMore*/}

                  {/* END_USER_CODE-USER_BEFORE_btnMore*/}

                  <ButtonWidget
                    conf={state.btnMore}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnMore*/}

                  {/* END_USER_CODE-USER_AFTER_btnMore*/}
                  {/* START_USER_CODE-USER_BEFORE_gridInventoriesAvailable*/}

                  {/* END_USER_CODE-USER_BEFORE_gridInventoriesAvailable*/}

                  <GridWidget
                    conf={state.gridInventoriesAvailable}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridInventoriesAvailable}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    setFieldValue={setFieldValue}
                    refObject = {thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridInventoriesAvailable*/}

                  {/* END_USER_CODE-USER_AFTER_gridInventoriesAvailable*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxInventoriesAvailable*/}

                {/* END_USER_CODE-USER_AFTER_grpbxInventoriesAvailable*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxStorageInformation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxStorageInformation*/}

                <GroupBoxWidget
                  conf={state.grpbxStorageInformation}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblStorageInformation*/}

                  {/* END_USER_CODE-USER_BEFORE_lblStorageInformation*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblStorageInformation}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblStorageInformation*/}

                  {/* END_USER_CODE-USER_AFTER_lblStorageInformation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtIssueDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtIssueDate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtIssueDate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtIssueDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtIssueDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPeanutType}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                  {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSeg*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSeg*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSeg}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSeg*/}

                  {/* END_USER_CODE-USER_AFTER_txtSeg*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetLbs*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetLbs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalLbs*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLbs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSKLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKLbs*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKLbs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtValShrinkPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtValShrinkPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtValShrinkPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtValShrinkPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtValShrinkPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWtsShrinkPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWtsShrinkPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWtsShrinkPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWtsShrinkPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtWtsShrinkPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtNetLbsShrunk*/}

                  {/* END_USER_CODE-USER_BEFORE_txtNetLbsShrunk*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtNetLbsShrunk}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtNetLbsShrunk*/}

                  {/* END_USER_CODE-USER_AFTER_txtNetLbsShrunk*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalLbsShrunk*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalLbsShrunk*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalLbsShrunk}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalLbsShrunk*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalLbsShrunk*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSKLbsShrunk*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKLbsShrunk*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKLbsShrunk}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKLbsShrunk*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKLbsShrunk*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStorageDate*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStorageDate*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStorageDate}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStorageDate*/}

                  {/* END_USER_CODE-USER_AFTER_txtStorageDate*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHoeStored*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHoeStored*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHoeStored}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHoeStored*/}

                  {/* END_USER_CODE-USER_AFTER_txtHoeStored*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStatus*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStatus*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStatus}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStatus*/}

                  {/* END_USER_CODE-USER_AFTER_txtStatus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtControlNumber*/}

                  {/* END_USER_CODE-USER_BEFORE_txtControlNumber*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtControlNumber}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtControlNumber*/}

                  {/* END_USER_CODE-USER_AFTER_txtControlNumber*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxStorageInformation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxStorageInformation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFactorsForAllPeanuts*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFactorsForAllPeanuts*/}

                <GroupBoxWidget
                  conf={state.grpbxFactorsForAllPeanuts}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblFactorsForAllPeanuts*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFactorsForAllPeanuts*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFactorsForAllPeanuts}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFactorsForAllPeanuts*/}

                  {/* END_USER_CODE-USER_AFTER_lblFactorsForAllPeanuts*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMKPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMKPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMKPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMKPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMKPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSSPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSSPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSSPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSSPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtSSPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSMKPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSMKPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSMKPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSMKPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSMKPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctOtherKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctOtherKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctOtherKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctOtherKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctOtherKernels*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctTotalDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctTotalDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctTotalDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctTotalDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctTotalDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctTotalKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctTotalKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctTotalKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctTotalKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctTotalKernels*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSKPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSKPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSKPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSKPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSKPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHullsBrightPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullsBrightPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullsBrightPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullsBrightPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullsBrightPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctFM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctFM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctFM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctFM*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctFM*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctMoist*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctMoist*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctMoist}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctMoist*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctMoist*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctELK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctELK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctELK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctELK*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctELK*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctFancy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctFancy*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctFancy}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctFancy*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctFancy*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctCrackBroken*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctCrackBroken*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctCrackBroken}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctCrackBroken*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctCrackBroken*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctDiscolored*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctDiscolored*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctDiscolored}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctDiscolored*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctDiscolored*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHillsPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHillsPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHillsPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHillsPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtHillsPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctTotalKrnlsHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctTotalKrnlsHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctTotalKrnlsHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctTotalKrnlsHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctTotalKrnlsHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctConcealedRMD*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctConcealedRMD*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctConcealedRMD}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctConcealedRMD*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctConcealedRMD*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFlavus*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFlavus*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFlavus}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFlavus*/}

                  {/* END_USER_CODE-USER_AFTER_txtFlavus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctFreezeDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctFreezeDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctFreezeDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctFreezeDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctFreezeDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtJumboPct*/}

                  {/* END_USER_CODE-USER_BEFORE_txtJumboPct*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtJumboPct}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtJumboPct*/}

                  {/* END_USER_CODE-USER_AFTER_txtJumboPct*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPctSplitDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPctSplitDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPctSplitDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPctSplitDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtPctSplitDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVICAMPPB*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVICAMPPB*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVICAMPPB}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVICAMPPB*/}

                  {/* END_USER_CODE-USER_AFTER_txtVICAMPPB*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFactorsForAllPeanuts*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFactorsForAllPeanuts*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxWarehouseReceipt*/}

              {/* END_USER_CODE-USER_AFTER_grpbxWarehouseReceipt*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}

              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblCropYear*/}

                {/* END_USER_CODE-USER_BEFORE_lblCropYear*/}

                <LabelWidget
                  values={values}
                  conf={state.lblCropYear}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblCropYear*/}

                {/* END_USER_CODE-USER_AFTER_lblCropYear*/}
                {/* START_USER_CODE-USER_BEFORE_btnCreateWR*/}

                {/* END_USER_CODE-USER_BEFORE_btnCreateWR*/}

                <ButtonWidget
                  conf={state.btnCreateWR}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCreateWR*/}

                {/* END_USER_CODE-USER_AFTER_btnCreateWR*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_WarehouseReceipt*/}

              {/* END_USER_CODE-USER_AFTER_WarehouseReceipt*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_WarehouseReceipt);
