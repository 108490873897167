/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  CheckboxGroupWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  TextAreaWidget,
  ListboxWidget,
  setValue,
  getValue,
  enable,
  disable,
  goTo,
  getData,
  setData,
} from "../../shared/WindowImports";

import "./VendorMaintenance.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment";
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import { response } from "msw";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_VendorMaintenance(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);

  // START_USER_CODE-USER_PROPERTIES
  let mbSuccessfulLoad = true
  let bDirty = false
  let secLienHolder = ''
  let secVendor = ''
  let secScan = ''
  let secMinority = ''
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "VendorMaintenance",
    windowName: "VendorMaintenance",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.VendorMaintenance",
    // START_USER_CODE-USER_VendorMaintenance_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Vendor Maintenance",
      scrCode: "PN0350B",
    },
    // END_USER_CODE-USER_VendorMaintenance_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: ".",
      CharWidth: "6",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "grpbxLienHolders",
      Label: "Add",
      CharWidth: "10",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnDelete: {
      name: "btnDelete",
      type: "ButtonWidget",
      parent: "grpbxLienHolders",
      Label: "Delete",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDelete_PROPERTIES

      // END_USER_CODE-USER_btnDelete_PROPERTIES
    },
    btnEdit: {
      name: "btnEdit",
      type: "ButtonWidget",
      parent: "grpbxLienHolders",
      Label: "Edit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEdit_PROPERTIES

      // END_USER_CODE-USER_btnEdit_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    btnScan211Concordia: {
      name: "btnScan211Concordia",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Scan 211 Concordia",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnScan211Concordia_PROPERTIES

      // END_USER_CODE-USER_btnScan211Concordia_PROPERTIES
    },
    btnScan211GPC: {
      name: "btnScan211GPC",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Scan 211 GPC",
      CharWidth: "27",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnScan211GPC_PROPERTIES

      // END_USER_CODE-USER_btnScan211GPC_PROPERTIES
    },
    btnView211Concordia: {
      name: "btnView211Concordia",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "View 211 Concordia",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView211Concordia_PROPERTIES

      // END_USER_CODE-USER_btnView211Concordia_PROPERTIES
    },
    btnView211GPC: {
      name: "btnView211GPC",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "View 211 GPC",
      CharWidth: "27",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnView211GPC_PROPERTIES

      // END_USER_CODE-USER_btnView211GPC_PROPERTIES
    },
    chkboxMinority: {
      name: "chkboxMinority",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxMinority",
      Options:
        "African American:1,Indian (Sub-Continent):2,Hispanic American:3,Asian/Pacific Islander:4,Native American:5,Multi-Cultural:6",
      ColSpan: "1",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxMinority_PROPERTIES

      // END_USER_CODE-USER_chkboxMinority_PROPERTIES
    },
    chkboxVeteran: {
      name: "chkboxVeteran",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxVeteran",
      Options: "Vietnam Veteran:1,Service Disabled:2",
      ColSpan: "1",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxVeteran_PROPERTIES

      // END_USER_CODE-USER_chkboxVeteran_PROPERTIES
    },
    grpbxContactInformation: {
      name: "grpbxContactInformation",
      type: "GroupBoxWidget",
      parent: "grpbxVendorMaintenance",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxContactInformation_PROPERTIES

      // END_USER_CODE-USER_grpbxContactInformation_PROPERTIES
    },
    grpbxContractInformationFromAP: {
      name: "grpbxContractInformationFromAP",
      type: "GroupBoxWidget",
      parent: "grpbxVendorMaintenance",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxContractInformationFromAP_PROPERTIES

      // END_USER_CODE-USER_grpbxContractInformationFromAP_PROPERTIES
    },
    grpbxLienHolders: {
      name: "grpbxLienHolders",
      type: "GroupBoxWidget",
      parent: "grpbxVendorMaintenance",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxLienHolders_PROPERTIES

      // END_USER_CODE-USER_grpbxLienHolders_PROPERTIES
    },
    grpbxMinority: {
      name: "grpbxMinority",
      type: "GroupBoxWidget",
      parent: "grpbxVendorMaintenance",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxMinority_PROPERTIES

      // END_USER_CODE-USER_grpbxMinority_PROPERTIES
    },
    grpbxVeteran: {
      name: "grpbxVeteran",
      type: "GroupBoxWidget",
      parent: "grpbxVendorMaintenance",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxVeteran_PROPERTIES

      // END_USER_CODE-USER_grpbxVeteran_PROPERTIES
    },
    grpbxWomen: {
      name: "grpbxWomen",
      type: "GroupBoxWidget",
      parent: "grpbxVendorMaintenance",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxWomen_PROPERTIES

      // END_USER_CODE-USER_grpbxWomen_PROPERTIES
    },
    lblContactInfo: {
      name: "lblContactInfo",
      type: "LabelWidget",
      parent: "grpbxContactInformation",
      Label: "Contact Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContactInfo_PROPERTIES

      // END_USER_CODE-USER_lblContactInfo_PROPERTIES
    },
    lblContactInformationFromAP: {
      name: "lblContactInformationFromAP",
      type: "LabelWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Contact Information from AP",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContactInformationFromAP_PROPERTIES

      // END_USER_CODE-USER_lblContactInformationFromAP_PROPERTIES
    },
    lblLienHolders: {
      name: "lblLienHolders",
      type: "LabelWidget",
      parent: "grpbxLienHolders",
      Label: "Lien Holders",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLienHolders_PROPERTIES

      // END_USER_CODE-USER_lblLienHolders_PROPERTIES
    },
    lblMinority: {
      name: "lblMinority",
      type: "LabelWidget",
      parent: "grpbxMinority",
      Label: "Minority",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMinority_PROPERTIES

      // END_USER_CODE-USER_lblMinority_PROPERTIES
    },
    lblMustBeAUSCitizenM: {
      name: "lblMustBeAUSCitizenM",
      type: "LabelWidget",
      parent: "grpbxMinority",
      Label: "**Must be a US Citizen",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMustBeAUSCitizenM_PROPERTIES

      // END_USER_CODE-USER_lblMustBeAUSCitizenM_PROPERTIES
    },
    lblMustBeAUSCitizenV: {
      name: "lblMustBeAUSCitizenV",
      type: "LabelWidget",
      parent: "grpbxVeteran",
      Label: "**Must be a US Citizen",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMustBeAUSCitizenV_PROPERTIES

      // END_USER_CODE-USER_lblMustBeAUSCitizenV_PROPERTIES
    },
    lblMustBeAUSCitizenW: {
      name: "lblMustBeAUSCitizenW",
      type: "LabelWidget",
      parent: "grpbxWomen",
      Label: "**Must be a US Citizen",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblMustBeAUSCitizenW_PROPERTIES

      // END_USER_CODE-USER_lblMustBeAUSCitizenW_PROPERTIES
    },
    lblOMCMinority: {
      name: "lblOMCMinority",
      type: "LabelWidget",
      parent: "grpbxMinority",
      Label: "Owned, Managed and Controlled (51% or more)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOMCMinority_PROPERTIES

      // END_USER_CODE-USER_lblOMCMinority_PROPERTIES
    },
    lblOMCVetaran: {
      name: "lblOMCVetaran",
      type: "LabelWidget",
      parent: "grpbxVeteran",
      Label: "Owned, Managed and Controlled (51% or more)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOMCVetaran_PROPERTIES

      // END_USER_CODE-USER_lblOMCVetaran_PROPERTIES
    },
    lblOMCWomen: {
      name: "lblOMCWomen",
      type: "LabelWidget",
      parent: "grpbxWomen",
      Label: "Owned, Managed and Controlled (51% or more)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblOMCWomen_PROPERTIES

      // END_USER_CODE-USER_lblOMCWomen_PROPERTIES
    },
    lblVeteran: {
      name: "lblVeteran",
      type: "LabelWidget",
      parent: "grpbxVeteran",
      Label: "Veteran (Defined as past or present member of the US Military)",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVeteran_PROPERTIES

      // END_USER_CODE-USER_lblVeteran_PROPERTIES
    },
    lblWomen: {
      name: "lblWomen",
      type: "LabelWidget",
      parent: "grpbxWomen",
      Label: "Women",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWomen_PROPERTIES

      // END_USER_CODE-USER_lblWomen_PROPERTIES
    },
    radioYesNoNotDisclosedM: {
      name: "radioYesNoNotDisclosedM",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxMinority",
      Options: "Yes:1,No:2,Not Disclosed:3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioYesNoNotDisclosedM_PROPERTIES

      // END_USER_CODE-USER_radioYesNoNotDisclosedM_PROPERTIES
    },
    radioYesNoNotDisclosedV: {
      name: "radioYesNoNotDisclosedV",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxVeteran",
      Options: "Yes:1,No:2,Not Disclosed:3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioYesNoNotDisclosedV_PROPERTIES

      // END_USER_CODE-USER_radioYesNoNotDisclosedV_PROPERTIES
    },
    radioYesNoNotDisclosedW: {
      name: "radioYesNoNotDisclosedW",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxWomen",
      Options: "Yes:1,No:2,Not Disclosed:3",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioYesNoNotDisclosedW_PROPERTIES

      // END_USER_CODE-USER_radioYesNoNotDisclosedW_PROPERTIES
    },
    textboxwidget21: {
      name: "textboxwidget21",
      type: "TextBoxWidget",
      parent: "grpbxContactInformation",
      Label: "Cellular #:",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget21_PROPERTIES

      // END_USER_CODE-USER_textboxwidget21_PROPERTIES
    },
    txtAddedBy: {
      name: "txtAddedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAddedBy_PROPERTIES

      // END_USER_CODE-USER_txtAddedBy_PROPERTIES
    },
    lstLienHolder: {
      name: "lstLienHolder",
      type: "ListBoxFieldWidget",
      parent: "grpbxLienHolders",
      Label: "Variety:",
      ColSpan: "3",
      DataProviderForListBox: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLienHolder_PROPERTIES

      // END_USER_CODE-USER_lstLienHolder_PROPERTIES
    },
    txtarRemarks: {
      name: "txtarRemarks",
      type: "TextAreaWidget",
      parent: "grpbxContactInformation",
      Label: "Remarks:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarRemarks_PROPERTIES

      // END_USER_CODE-USER_txtarRemarks_PROPERTIES
    },
    txtChangedBy: {
      name: "txtChangedBy",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtChangedBy_PROPERTIES

      // END_USER_CODE-USER_txtChangedBy_PROPERTIES
    },
    txtCityMA: {
      name: "txtCityMA",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "City:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCityMA_PROPERTIES

      // END_USER_CODE-USER_txtCityMA_PROPERTIES
    },
    txtCityPA: {
      name: "txtCityPA",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "City:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCityPA_PROPERTIES

      // END_USER_CODE-USER_txtCityPA_PROPERTIES
    },
    txtCustomer: {
      name: "txtCustomer",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Customer #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCustomer_PROPERTIES

      // END_USER_CODE-USER_txtCustomer_PROPERTIES
    },
    txtEmail1: {
      name: "txtEmail1",
      type: "TextBoxWidget",
      parent: "grpbxContactInformation",
      Label: "Email #1:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail1_PROPERTIES

      // END_USER_CODE-USER_txtEmail1_PROPERTIES
    },
    txtEmail2: {
      name: "txtEmail2",
      type: "TextBoxWidget",
      parent: "grpbxContactInformation",
      Label: "Email #2:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail2_PROPERTIES

      // END_USER_CODE-USER_txtEmail2_PROPERTIES
    },
    txtEmail3: {
      name: "txtEmail3",
      type: "TextBoxWidget",
      parent: "grpbxContactInformation",
      Label: "Email #3:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEmail3_PROPERTIES

      // END_USER_CODE-USER_txtEmail3_PROPERTIES
    },
    txtFax: {
      name: "txtFax",
      type: "TextBoxWidget",
      parent: "grpbxContactInformation",
      Label: "Fax #:",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFax_PROPERTIES

      // END_USER_CODE-USER_txtFax_PROPERTIES
    },
    txtLienHolder: {
      name: "txtLienHolder",
      type: "TextBoxWidget",
      parent: "grpbxLienHolders",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLienHolder_PROPERTIES

      // END_USER_CODE-USER_txtLienHolder_PROPERTIES
    },
    txtMailingAddress: {
      name: "txtMailingAddress",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Mailing Address:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMailingAddress_PROPERTIES

      // END_USER_CODE-USER_txtMailingAddress_PROPERTIES
    },
    txtPhoneCI: {
      name: "txtPhoneCI",
      type: "TextBoxWidget",
      parent: "grpbxContactInformation",
      Label: "Phone #:",
      LengthRange: { MinLength: 0, MaxLength: 15 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhoneCI_PROPERTIES

      // END_USER_CODE-USER_txtPhoneCI_PROPERTIES
    },
    txtPhone: {
      name: "txtPhone",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Phone #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhone_PROPERTIES

      // END_USER_CODE-USER_txtPhone_PROPERTIES
    },
    txtPhysicalAddress: {
      name: "txtPhysicalAddress",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Physical Address:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPhysicalAddress_PROPERTIES

      // END_USER_CODE-USER_txtPhysicalAddress_PROPERTIES
    },
    txtStateMA: {
      name: "txtStateMA",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "State:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateMA_PROPERTIES

      // END_USER_CODE-USER_txtStateMA_PROPERTIES
    },
    txtStatePA: {
      name: "txtStatePA",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "State:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatePA_PROPERTIES

      // END_USER_CODE-USER_txtStatePA_PROPERTIES
    },
    txtVendorName: {
      name: "txtVendorName",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Vendor Name:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorName_PROPERTIES

      // END_USER_CODE-USER_txtVendorName_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Vendor #:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtZipMA: {
      name: "txtZipMA",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Zip:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZipMA_PROPERTIES

      // END_USER_CODE-USER_txtZipMA_PROPERTIES
    },
    txtZipPA: {
      name: "txtZipPA",
      type: "TextBoxWidget",
      parent: "grpbxContractInformationFromAP",
      Label: "Zip:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtZipPA_PROPERTIES

      // END_USER_CODE-USER_txtZipPA_PROPERTIES
    },
    grpbxVendorMaintenance: {
      name: "grpbxVendorMaintenance",
      type: "GroupBoxWidget",
      parent: "VendorMaintenance",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxVendorMaintenance_PROPERTIES

      // END_USER_CODE-USER_grpbxVendorMaintenance_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "VendorMaintenance",
      Height: "",
      Width: "",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "SystemMaintenanceApplicationSupport#Scan211Document":{},
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceApplicationSupport#LienHolderProfile":{}
       },
      REVERSE:{
        "SystemMaintenanceApplicationSupport#Scan211Document":{},
        "ContractManagement#MainMenu":{},
        "SystemMaintenanceApplicationSupport#LienHolderProfile":{}
      }
    }
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnExit: {
    //   DEFAULT:["ContractManagement#MainMenu#DEFAULT#false#Click"],
    // },
    // btnScan211GPC:{
    //   DEFAULT:["SystemMaintenanceApplicationSupport#Scan211Document#DEFAULT#true#Click"],
    // },
    // btnScan211Concordia:{
    //   DEFAULT:["SystemMaintenanceApplicationSupport#Scan211Document#DEFAULT#true#Click"],
    // },
    // btnAdd:{
    //   DEFAULT:["SystemMaintenanceApplicationSupport#LienHolderProfile#DEFAULT#true#Click"],
    // },
    // btnEdit:{
    //   DEFAULT:["SystemMaintenanceApplicationSupport#LienHolderProfile#DEFAULT#true#Click"],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);

    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    let ParentData = getData(thisObj, "frmVendorMaintenance")
    setData(thisObj, 'ParentData', ParentData)
    SetSecurity()
    
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    if(getData(thisObj, 'txtarLienHolder') == true){
      SetLeinholder()
      setData(thisObj, 'txtarLienHolder', false)
    }
  }, [getData(thisObj, 'txtarLienHolder')]);

  useEffect(()=>{
    return () =>{
      setData(thisObj, 'closingVendorMaintainance', true)
    }
  })

  // START_USER_CODE-USER_METHODS

  const Form_Load = async () => {
    try {
      setLoading(true)
      // lstLienholderId.Clear // visible false
      let ParentData = getData(thisObj, 'ParentData')
      setValue(thisObj, 'lstLienHolder', '')
      setValue(thisObj, 'txtAddedBy', '')
      setValue(thisObj, 'txtChangedBy', '')
      if (ParentData.optMinotityOwned != 'Yes') {
        disable(thisObj, 'chkboxMinority')
      }
      if (ParentData.optVeteranOwned != 'Yes') {
        disable(thisObj, 'chkboxVeteran')
      }
      await LoadVendor(ParentData.vendorNumber)
      mbSuccessfulLoad = true
      
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for Form_Load event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }

  const validateTextBox = (data) => {
    try {
      var res = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i].charCodeAt(0) != 38 || data[i].charCodeAt(0) != 39) {
          res += data[i]
        }
      }
      return res;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for validateTextBox event"
        );
      }
    }
  }

  const ontextboxwidget21Change = () => {
    try {
      let value = getValue(thisObj, 'textboxwidget21')
      setValue(thisObj, "textboxwidget21", validateTextBox(value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontextboxwidget21Change event"
        );
      }
    }
  }
  thisObj.ontextboxwidget21Change = ontextboxwidget21Change;

  const ontextboxwidget21Blur = () => {
    try {
      bDirty = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontextboxwidget21Blur event"
        );
      }
    }
  }
  thisObj.ontextboxwidget21Blur = ontextboxwidget21Blur;
  
  const ontxtEmail1Blur = () => {
    try {
      bDirty = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtEmail1Blur event"
        );
      }
    }
  }
  thisObj.ontxtEmail1Blur = ontxtEmail1Blur;

  const ontxtEmail1Change = () => {
    try {
      let value = getValue(thisObj, 'txtEmail1')
      setValue(thisObj, "txtEmail1", validateTextBox(value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtEmail1Change event"
        );
      }
    }
  }
  thisObj.ontxtEmail1Change = ontxtEmail1Change;

  const ontxtEmail2Blur = () => {
    try {
      bDirty = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtEmail2Blur event"
        );
      }
    }
  }
  thisObj.ontxtEmail2Blur = ontxtEmail2Blur;

  const ontxtEmail2Change = () => {
    try {
      let value = getValue(thisObj, 'txtEmail2')
      setValue(thisObj, "txtEmail2", validateTextBox(value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtEmail2Change event"
        );
      }
    }
  }
  thisObj.ontxtEmail2Change = ontxtEmail2Change;

  const ontxtEmail3Blur = () => {
    try {
      bDirty = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtEmail3Blur event"
        );
      }
    }
  }
  thisObj.ontxtEmail3Blur = ontxtEmail3Blur;

  const ontxtEmail3Change = () => {
    try {
      let value = getValue(thisObj, 'txtEmail3')
      setValue(thisObj, "txtEmail3", validateTextBox(value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtEmail3Change event"
        );
      }
    }
  }
  thisObj.ontxtEmail3Change = ontxtEmail3Change;

  const ontxtFaxBlur = () => {
    try {
      bDirty = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtFaxBlur event"
        );
      }
    }
  }
  thisObj.ontxtFaxBlur = ontxtFaxBlur;

  const ontxtFaxChange = () => {
    try {
      let value = getValue(thisObj, 'txtFax')
      setValue(thisObj, "txtFax", validateTextBox(value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtFaxChange event"
        );
      }
    }
  }
  thisObj.ontxtFaxChange = ontxtFaxChange;

  const ontxtPhoneCIBlur = () => {
    try {
      bDirty = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtPhoneCIBlur event"
        );
      }
    }
  }
  thisObj.ontxtPhoneCIBlur = ontxtPhoneCIBlur;

  const ontxtPhoneCIChange = () => {
    try {
      let value = getValue(thisObj, 'txtPhoneCI')
      setValue(thisObj, "txtPhoneCI", validateTextBox(value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtPhoneCIChange event"
        );
      }
    }
  }
  thisObj.ontxtPhoneCIChange = ontxtPhoneCIChange;

  const ontxtarRemarksBlur = () => {
    try {
      bDirty = true
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtarRemarksBlur event"
        );
      }
    }
  }
  thisObj.ontxtarRemarksBlur = ontxtarRemarksBlur;

  const ontxtarRemarksChange = () => {
    try {
      let value = getValue(thisObj, 'txtarRemarks')
      setValue(thisObj, "txtarRemarks", validateTextBox(value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for ontxtarRemarksChange event"
        );
      }
    }
  }
  thisObj.ontxtarRemarksChange = ontxtarRemarksChange;

  const onradioYesNoNotDisclosedMChange = () => {
    try {

      let chkBoxValue = getValue(thisObj, 'radioYesNoNotDisclosedM')

      if (chkBoxValue == '1') {
        enable(thisObj, 'chkboxMinority')
      }
      else {
        disable(thisObj, 'chkboxMinority')
        setValue(thisObj, 'chkboxMinority', '')
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onradioYesNoNotDisclosedMChange event"
        );
      }
    }
  }
  thisObj.onradioYesNoNotDisclosedMChange = onradioYesNoNotDisclosedMChange;

  const onradioYesNoNotDisclosedVChange = () => {
    try {

      let chkBoxValue = getValue(thisObj, 'radioYesNoNotDisclosedV')

      if (chkBoxValue == '1') {
        enable(thisObj, 'chkboxVeteran')
      }
      else {
        disable(thisObj, 'chkboxVeteran')
        setValue(thisObj, 'chkboxVeteran', '')
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onradioYesNoNotDisclosedVChange event"
        );
      }
    }
  }
  thisObj.onradioYesNoNotDisclosedVChange = onradioYesNoNotDisclosedVChange;

  const LoadVendor = async (sVendor) => {
    try {
      let LstrCMD = []
      let lstrxml = []
      let strStatus = ''

      if (sVendor == undefined || sVendor == null || sVendor == '') {
        alert('Vendor number is required.')
      }

      await ContractManagementService.RetrieveVendorEmailDetails(null, null, sVendor, null, null, null).then(response => {
        lstrxml = response
      })
      if (lstrxml != undefined && lstrxml.length > 0) {
        setValue(thisObj, 'txtVendor', lstrxml[0].vendorNumber)
        setValue(thisObj, 'txtPhysicalAddress', lstrxml[0].vendorPhysAddr1)
        setValue(thisObj, 'txtCityPA', lstrxml[0].vendorPhysCity)
        setValue(thisObj, 'txtStatePA', lstrxml[0].vendorPhysState)
        setValue(thisObj, 'txtZipPA', lstrxml[0].vendorPhysZip)
        setValue(thisObj, 'txtPhone', lstrxml[0].vendorphoneap)
        setValue(thisObj, 'txtVendorName', lstrxml[0].vendorName)
        setValue(thisObj, 'txtMailingAddress', lstrxml[0].vendorAddr1)
        setValue(thisObj, 'txtCityMA', lstrxml[0].vendorCity)
        setValue(thisObj, 'txtStateMA', lstrxml[0].vendorState)
        setValue(thisObj, 'txtZipMA', lstrxml[0].vendorZip)
        setValue(thisObj, 'txtCustomer', lstrxml[0].vendorCustno)
        setValue(thisObj, 'txtPhoneCI', lstrxml[0].vendorPhone)
        setValue(thisObj, 'textboxwidget21', lstrxml[0].vendorCellNumber)
        setValue(thisObj, 'txtFax', lstrxml[0].vendorFax)
        setValue(thisObj, 'txtEmail1', lstrxml[0].vendorEmail1)
        setValue(thisObj, 'txtEmail2', lstrxml[0].vendorEmail2)
        setValue(thisObj, 'txtEmail3', lstrxml[0].vendorEmail3)
        setValue(thisObj, 'txtarRemarks', lstrxml[0].vendorRemarks)
        let AddedByValue = lstrxml[0].addUser + ' ' + moment(lstrxml[0].addDateTime).format('MM/DD/YYYY')
        setValue(thisObj, 'txtAddedBy', AddedByValue)
        let ChangeByValue = lstrxml[0].chgUser + ' ' + moment(lstrxml[0].chgDateTime).format('MM/DD/YYYY')
        setValue(thisObj, 'txtChangedBy', ChangeByValue)

        setValue(thisObj, 'radioYesNoNotDisclosedW', '3')
        if (lstrxml[0].womenOwned == 'N') {
          setValue(thisObj, 'radioYesNoNotDisclosedW', '2')
        }
        if (lstrxml[0].womenOwned == 'Y') {
          setValue(thisObj, 'radioYesNoNotDisclosedW', '1')
        }

        setValue(thisObj, 'radioYesNoNotDisclosedM', '3')
        if (lstrxml[0].minorityOwned == 'N') {
          setValue(thisObj, 'radioYesNoNotDisclosedM', '2')
        }
        if (lstrxml[0].minorityOwned == 'Y') {
          setValue(thisObj, 'radioYesNoNotDisclosedM', '1')
          let Minoritylst = lstrxml[0].minority

          let Minorityset = []
          let Minoritychklst = []
          let val = ''
          for (let i = 0; i < Minoritylst.length; i++) {
            val = Minoritylst[i].disclosureStatus
            Minorityset.push(val)
            val = ''
          }
          if (Minorityset.includes('AFRICAN AMERICAN')) {
            Minoritychklst.push('1')
          }
          if (Minorityset.includes('INDIAN (SUB-CONTINENT)')) {
            Minoritychklst.push('2')
          }
          if (Minorityset.includes('HISPANIC AMERICAN')) {
            Minoritychklst.push('3')
          }
          if (Minorityset.includes('ASIAN/PACIFIC ISLANDER')) {
            Minoritychklst.push('4')
          }
          if (Minorityset.includes('NATIVE AMERICAN')) {
            Minoritychklst.push('5')
          }
          if (Minorityset.includes('MULTI-CULTURAL')) {
            Minoritychklst.push('6')
          }
          setValue(thisObj, 'chkboxMinority', Minoritychklst)
        }

        setValue(thisObj, 'radioYesNoNotDisclosedV', '3')
        if (lstrxml[0].veteranOwned == 'N') {
          setValue(thisObj, 'radioYesNoNotDisclosedV', '2')
        }
        if (lstrxml[0].veteranOwned == 'Y') {
          setValue(thisObj, 'radioYesNoNotDisclosedV', '1')
          let veteranlst = lstrxml[0].veteran
          let veteranset = []
          let veteranChklst = []
          let valuee = ''
          for (let i = 0; i < veteranlst.length; i++) {
            valuee = veteranlst[i].disclosureStatus
            veteranset.push(valuee)
            valuee = ''
          }
          if (veteranset.includes('VIETNAM VETERAN')) {
            veteranChklst.push('1')
          }
          if (veteranset.includes('SERVICE DISABLED')) {
            veteranChklst.push('2')
          }

          setValue(thisObj, 'chkboxVeteran', veteranChklst)
        }
      }

      await SetLeinholder()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for LoadVendor event"
        );
      }
    }
  }

  const SetLeinholder = async () => {
    try {
      let ParentData = getData(thisObj, 'ParentData')
      let txtVendorValue = getValue(thisObj, 'txtVendor') != undefined ? getValue(thisObj, 'txtVendor') : ParentData.vendorNumber
      let secLienHolder = getData(thisObj, 'sSetSecurity').secLienHolder
      let lstrxml = []
      let js = []
      let obj = {}

      if(txtVendorValue != undefined && txtVendorValue != null && txtVendorValue != '' && secLienHolder != '' && secLienHolder != undefined && secLienHolder != null){
        setValue(thisObj, 'lstLienHolder', [])
        await AccountPayment.RetrieveLienHolderControlDetails(compIdFromLS(), cropYearFromLS(), txtVendorValue, null).then(response => {
          lstrxml = response
        })
        if (lstrxml != undefined && lstrxml.length > 0) {
          for (let i = 0; i < lstrxml.length; i++) {
            obj = {
              key: i,
              description: lstrxml[i].lienholder_name,
              add_date_time: lstrxml[i].add_date_time,
              add_user: lstrxml[i].add_user,
              chg_date_time: lstrxml[i].chg_date_time,
              chg_user: lstrxml[i].chg_user,
              comp_id: lstrxml[i].comp_id,
              crop_year: lstrxml[i].crop_year,
              lienholder_address: lstrxml[i].lienholder_address,
              lienholder_city: lstrxml[i].lienholder_city,
              lienholder_line: lstrxml[i].lienholder_line,
              lienholder_name: lstrxml[i].lienholder_name,
              lienholder_state: lstrxml[i].lienholder_state,
              lienholder_zip: lstrxml[i].lienholder_zip,
              nature_of_lien: lstrxml[i].nature_of_lien,
              vend_remit: lstrxml[i].vend_remit,
              vendor: lstrxml[i].vendor,
            }
            js.push(obj)
            obj = {}
          }
          
          if (js.length > 0) {
            setValue(thisObj, "lstLienHolder", [js[0].key])
          }
        }
        setData(thisObj, 'LeinholderLst', js)
        thisObj.setState(current => {
          return {
            ...current,
            lstLienHolder: {
              ...state["lstLienHolder"],
              valueList: js
            }
          }
        });
      }
      await SetupControls('Lien_holder')
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for SetLeinholder event"
        );
      }
    }
  }

  const Scan211 = async (sDocType) => {
    try {
      let Data = getData(thisObj, 'ParentData')
      let sStatus = ''
      let sAction = ''
      let lstrxml = []
      let vendor_num = getValue(thisObj, 'txtVendor')

      vendor_num = Data.vendorNumber
      let doc_type = sDocType

      await SystemMaintenanceApplicationSupportService.Retrieve211DocumentScanDetails(vendor_num, doc_type).then(response => {
        lstrxml = response
      })
      if (lstrxml != undefined) {
        sStatus = lstrxml.length == 0 || lstrxml[0].statusInd == undefined || lstrxml[0].statusInd == null ? '' : lstrxml[0].statusInd
      }
      else {
        alert("Problem retrieving contract status.")
        return;
      }

      sStatus = sStatus == undefined || sStatus == null ? '' : sStatus.toLocaleUpperCase()
      if (sStatus == 'P' || sStatus == 'R') {
        alert("Form 211 has not been processed.  Try again in about 5 minutes?")
        return;
      }

      let data = {}
      data.vendor = vendor_num
      if (sDocType == 'GPC') {
        data.DocType = 'GPC'
      }
      else {
        data.DocType = 'CON'
      }
      if (sStatus == 'C') {
        let msg = confirm("There is an existing document on file.  Do you want to rescan and replace it?")
        if (msg == true) {
          data.actionType = 'R'
        }
        else {
          return;
        }
      }
      else {
        data.actionType = 'P'
      }
      setData(thisObj, 'ScanDocumentData', data)
      goTo(thisObj, "SystemMaintenanceApplicationSupport#Scan211Document#DEFAULT#true#Click")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for Scan211 event"
        );
      }
    }
  }

  const onbtnScan211GPCClick = async () => {
    try {
      setLoading(true)
      await Scan211('GPC')
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnScan211GPCClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnScan211GPCClick = onbtnScan211GPCClick;

  const onbtnScan211ConcordiaClick = async () => {
    try {
      setLoading(true)
      await Scan211('CON')
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnScan211ConcordiaClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnScan211ConcordiaClick = onbtnScan211ConcordiaClick;

  const onbtnAddClick = async () => {
    try {
      setLoading(true)
      let Obj = {}
      Obj.cmdOk_Caption = 'ADD'
      Obj.txtLine_enabled = true
      Obj.VendorNumber = getValue(thisObj, 'txtVendor')
      Obj.VendorName = getValue(thisObj, 'txtVendorName')

      setData(thisObj, 'LienHolderProfileData', Obj)
      goTo(thisObj, "SystemMaintenanceApplicationSupport#LienHolderProfile#DEFAULT#true#Click")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnAddClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnAddClick = onbtnAddClick;

  const onbtnEditClick = async () => {
    try {
      setLoading(true)
      let LeinholderLst = getData(thisObj, 'LeinholderLst')
      let value = getValue(thisObj, 'lstLienHolder')
      let SelectedLeinholderLst = LeinholderLst.find(elem => elem.key == value)
  
      let LstrList = []
      let frmLienHolderProfile = {}
      let VendorName = getValue(thisObj, 'txtVendorName')
      let vendor = getValue(thisObj, 'txtVendor')
      let lienholderLine = SelectedLeinholderLst.lienholder_line

      await AccountPayment.RetrieveLienHolderControlDetails(compIdFromLS(), cropYearFromLS(), vendor, lienholderLine).then(response => {
        LstrList = response
      })
      if (LstrList != undefined && LstrList.length > 0) {
        frmLienHolderProfile.cmdOk_Caption = "UPDATE"
        frmLienHolderProfile.txtLine_enabled = false
        frmLienHolderProfile.lblVendor = vendor
        frmLienHolderProfile.lblVendorName = VendorName
        frmLienHolderProfile.cboRemitTo = SelectedLeinholderLst.vend_remit
        frmLienHolderProfile.txtLine = lienholderLine
        frmLienHolderProfile.txtName = LstrList[0].lienholder_name
        frmLienHolderProfile.txtAddress = LstrList[0].lienholder_address
        frmLienHolderProfile.txtCity = LstrList[0].lienholder_city
        frmLienHolderProfile.cboState = LstrList[0].lienholder_state
        frmLienHolderProfile.txtZip = LstrList[0].lienholder_zip
        frmLienHolderProfile.txtNature = LstrList[0].nature_of_lien
        frmLienHolderProfile.lblAddedBy = LstrList[0].add_user
        frmLienHolderProfile.lblAddedByDate = LstrList[0].add_date_time
        frmLienHolderProfile.lblChangedBy = LstrList[0].chg_user
        frmLienHolderProfile.lblChangedByDate = LstrList[0].chg_date_time
        //           If secLienHolder = "I" Or secLienHolder = "" Then
        //               frmLienHolderProfile.cmdOk.Visible = False
        //               frmLienHolderProfile.c1(0).enabled = False
        //           End If

        setData(thisObj, 'LienHolderProfileData', frmLienHolderProfile)
        goTo(thisObj, "SystemMaintenanceApplicationSupport#LienHolderProfile#DEFAULT#true#Click")

      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnEditClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnEditClick = onbtnEditClick;

  const onbtnDeleteClick = async () => {
    try {
      setLoading(true)
      let LeinholderLst = getData(thisObj, 'LeinholderLst')
      let value = getValue(thisObj, 'lstLienHolder')
      let SelectedLeinholderLst = LeinholderLst.find(elem => elem.key == value)
      let lstrxml = []

      let n = confirm("Are you sure you wish to delete this lienholder  " + SelectedLeinholderLst.description)
      if (n == true) {
        let vendor = SelectedLeinholderLst.vendor
        let lienholder_line = SelectedLeinholderLst.lienholder_line
        await SystemMaintenanceApplicationSupportService.RemoveLienHolderControl(vendor, lienholder_line, null).then(response => {
          lstrxml = response
        })
        if (lstrxml.status == 200) {
          await SetLeinholder()
        }
        else {
          alert('An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.')
          return;
        }
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnDeleteClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnDeleteClick = onbtnDeleteClick;

  const onbtnSaveClick = async () => {
    try {
      setLoading(true)
      let strCMD = {}
      let strXML = []
      let ObjMinorityOwned = []
      let ObjVeteranOwned = []

      let txtVendorValue = getValue(thisObj, 'txtVendor')
      let txtPhoneCIValue = getValue(thisObj, 'txtPhoneCI')
      let textboxwidget21Value = getValue(thisObj, 'textboxwidget21')
      let txtFaxValue = getValue(thisObj, 'txtFax')
      let txtEmail1Value = getValue(thisObj, 'txtEmail1')
      let txtEmail2Value = getValue(thisObj, 'txtEmail2')
      let txtEmail3Value = getValue(thisObj, 'txtEmail3')
      let txtarRemarksValue = getValue(thisObj, 'txtarRemarks')
      let WomenOwnedValue = getValue(thisObj, 'radioYesNoNotDisclosedW')
      let MinorityOwnedValue = getValue(thisObj, 'radioYesNoNotDisclosedM')
      let VeteranOwnedValue = getValue(thisObj, 'radioYesNoNotDisclosedV')
      let txtAddedByValue = getValue(thisObj, 'txtAddedBy')
      let txtChangedByValue = getValue(thisObj, 'txtChangedBy')

      if (txtVendorValue == undefined && txtVendorValue == null && txtVendorValue == '' &&
        txtPhoneCIValue == undefined && txtPhoneCIValue == null && txtPhoneCIValue == '' &&
        textboxwidget21Value == undefined && textboxwidget21Value == null && textboxwidget21Value == '' &&
        txtFaxValue == undefined && txtFaxValue == null && txtFaxValue == '' &&
        txtEmail1Value == undefined && txtEmail1Value == null && txtEmail1Value == '' &&
        txtEmail2Value == undefined && txtEmail2Value == null && txtEmail2Value == '' &&
        txtEmail3Value == undefined && txtEmail3Value == null && txtEmail3Value == '' &&
        txtarRemarksValue == undefined && txtarRemarksValue == null && txtarRemarksValue == '' &&
        WomenOwnedValue == undefined && WomenOwnedValue == null && WomenOwnedValue == '' &&
        MinorityOwnedValue == undefined && MinorityOwnedValue == null && MinorityOwnedValue == '' &&
        VeteranOwnedValue == undefined && VeteranOwnedValue == null && VeteranOwnedValue == ''
      ) {
        return;
      }

      strCMD.VENDOR = txtVendorValue
      strCMD.PHONE = txtPhoneCIValue
      strCMD.CELL = textboxwidget21Value
      strCMD.FAX = txtFaxValue
      strCMD.EMAIL1 = txtEmail1Value
      strCMD.EMAIL2 = txtEmail2Value
      strCMD.EMAIL3 = txtEmail3Value
      strCMD.REMARKS = txtarRemarksValue
      if (WomenOwnedValue == '2') { strCMD.WOMEN_OWNED = 'N' }
      else if (WomenOwnedValue == '1') { strCMD.WOMEN_OWNED = 'Y' }
      else { strCMD.WOMEN_OWNED = '' }

      strCMD.minority_status = ''
      if (MinorityOwnedValue == '2') { strCMD.MINORITY_OWNED = 'N' }
      else if (MinorityOwnedValue == '1') {
        strCMD.MINORITY_OWNED = 'Y'
        let MinorityLst = getValue(thisObj, 'chkboxMinority')
        if (MinorityLst.includes('1')) { ObjMinorityOwned.push('AFRICAN AMERICAN') }
        if (MinorityLst.includes('2')) { ObjMinorityOwned.push('INDIAN (SUB-CONTINENT)') }
        if (MinorityLst.includes('3')) { ObjMinorityOwned.push('HISPANIC AMERICAN') }
        if (MinorityLst.includes('4')) { ObjMinorityOwned.push('ASIAN/PACIFIC ISLANDER') }
        if (MinorityLst.includes('5')) { ObjMinorityOwned.push('NATIVE AMERICAN') }
        if (MinorityLst.includes('6')) { ObjMinorityOwned.push('MULTI-CULTURAL') }
        strCMD.minority_status = ObjMinorityOwned.toString()
      }
      else { strCMD.MINORITY_OWNED = '' }

      strCMD.veteran_status = ''
      if (VeteranOwnedValue == '2') { strCMD.VETERAN_OWNED = "N" }
      else if (VeteranOwnedValue == '1') {
        strCMD.VETERAN_OWNED = "Y"
        if (getValue(thisObj, 'chkboxVeteran').includes('1')) { ObjVeteranOwned.push('VIETNAM VETERAN') }
        if (getValue(thisObj, 'chkboxVeteran').includes('2')) { ObjVeteranOwned.push('SERVICE DISABLED') }
        strCMD.veteran_status = ObjVeteranOwned.toString()
      }
      else { strCMD.VETERAN_OWNED = "" }

      await ContractManagementService.UpdateVendorEmails(txtVendorValue, strCMD).then(response => {
        strXML = response
      })
      if (strXML.status != 200) {
        showMessage(thisObj, "Vendor Maintenance Update Failed.")
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnSaveClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnSaveClick = onbtnSaveClick;


  const onbtnExitClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_VendorMaintenancePopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnExitClick event"
        );
      }
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick;

  const SetupControls = async (group) => {
    try {
      let lstrxml = []
      let LstLeinHolderValue = getData(thisObj, 'LeinholderLst')
      secVendor = getData(thisObj, 'sSetSecurity').secVendor
      secLienHolder = getData(thisObj, 'sSetSecurity').secLienHolder
      secScan = getData(thisObj, 'sSetSecurity').secScan
      secMinority = getData(thisObj, 'sSetSecurity').secMinority

      // Need PN0350(I) to see vendor info
      // Need PN0350(U) to edit vendor info
      if (group == 'ALL' || group == 'Contact_info') {
        if (secVendor == 'U') {
          enable(thisObj, 'btnSave')
          enable(thisObj, 'txtPhoneCI')
          enable(thisObj, 'textboxwidget21')
          enable(thisObj, 'txtFax')
          enable(thisObj, 'txtarRemarks')
          enable(thisObj, 'txtEmail1')
          enable(thisObj, 'txtEmail2')
          enable(thisObj, 'txtEmail3')
           
        }
        else {
          disable(thisObj, 'btnSave')
          disable(thisObj, 'txtPhoneCI')
          disable(thisObj, 'textboxwidget21')
          disable(thisObj, 'txtFax')
          disable(thisObj, 'txtarRemarks')
          disable(thisObj, 'txtEmail1')
          disable(thisObj, 'txtEmail2')
          disable(thisObj, 'txtEmail3')
        }
      }

      // need PN0350.001(I) to view Lien Holders frame
      if (group == 'ALL' || group == 'Lien_holder') {
        if (secLienHolder == "D") {
          document.getElementsByClassName("grpbxLienHolders")[0].style.visibility = 'visible';
          enable(thisObj, 'btnAdd')

          if (LstLeinHolderValue != undefined && LstLeinHolderValue.length > 0) {
            enable(thisObj, 'btnEdit')
            enable(thisObj, 'btnDelete')
          }
          else {
            disable(thisObj, 'btnEdit')
            disable(thisObj, 'btnDelete')
          }
        }
        else if (secLienHolder == 'U') {
          document.getElementsByClassName("grpbxLienHolders")[0].style.visibility = 'visible';
          enable(thisObj, 'btnAdd')
          if (LstLeinHolderValue != undefined && LstLeinHolderValue.length > 0) {
            enable(thisObj, 'btnEdit')
            disable(thisObj, 'btnDelete')
          }
          else {
            disable(thisObj, 'btnEdit')
            disable(thisObj, 'btnDelete')
          }
        }
        else if (secLienHolder == "I") {
          document.getElementsByClassName("grpbxLienHolders")[0].style.visibility = 'visible';
          disable(thisObj, 'btnAdd')
          if (LstLeinHolderValue != undefined && LstLeinHolderValue.length > 0) {
            enable(thisObj, 'btnEdit')
            disable(thisObj, 'btnDelete')
          }
          else {
            disable(thisObj, 'btnEdit')
            disable(thisObj, 'btnDelete')
          }
        }
        else {
          document.getElementsByClassName("grpbxLienHolders")[0].style.visibility = 'hidden';
          disable(thisObj, 'btnAdd')
          disable(thisObj, 'btnEdit')
          disable(thisObj, 'btnDelete')
        }
      }

      if (group == 'ALL' || group == 'Scan_211') {
        // 'need PN0350.002(I) to view 211s
        // 'need PN0350.002(U) to scan 211s
        if (secScan == 'U') {
          enable(thisObj, 'btnScan211GPC')
          enable(thisObj, 'btnView211GPC')
          enable(thisObj, 'btnScan211Concordia')
          enable(thisObj, 'btnView211Concordia')
        }
        else if (secScan == 'I') {
          disable(thisObj, 'btnScan211GPC')
          enable(thisObj, 'btnView211GPC')
          disable(thisObj, 'btnScan211Concordia')
          enable(thisObj, 'btnView211Concordia')
        }
        else {
          disable(thisObj, 'btnScan211GPC')
          disable(thisObj, 'btnView211GPC')
          disable(thisObj, 'btnScan211Concordia')
          disable(thisObj, 'btnView211Concordia')
        }
      }

      // 'need PN0350.003(I) to view Voluntary Vendor Minority Disclosure Info
      // 'need PN0350.003(U) to update Voluntary Vendor Minority Disclosure Info

      if (group == 'ALL' || group == 'Minority') {
        if (secMinority == 'U') {
          document.getElementsByClassName("grpbxWomen")[0].style.visibility = 'visible';
          enable(thisObj, 'radioYesNoNotDisclosedW')
          document.getElementsByClassName("grpbxMinority")[0].style.visibility = 'visible';
          enable(thisObj, 'radioYesNoNotDisclosedM')
          enable(thisObj, 'chkboxMinority')
          document.getElementsByClassName("grpbxVeteran")[0].style.visibility = 'visible';
          enable(thisObj, 'radioYesNoNotDisclosedV')
          enable(thisObj, 'chkboxVeteran')
        }
        else if (secMinority == 'I') {
          document.getElementsByClassName("grpbxWomen")[0].style.visibility = 'visible';
          disable(thisObj, 'radioYesNoNotDisclosedW')
          document.getElementsByClassName("grpbxMinority")[0].style.visibility = 'visible';
          disable(thisObj, 'radioYesNoNotDisclosedM')
          disable(thisObj, 'chkboxMinority')
          document.getElementsByClassName("grpbxVeteran")[0].style.visibility = 'visible';
          disable(thisObj, 'radioYesNoNotDisclosedV')
          disable(thisObj, 'chkboxVeteran')
        }
        else {
          document.getElementsByClassName("grpbxWomen")[0].style.visibility = 'hidden';
          disable(thisObj, 'radioYesNoNotDisclosedW')
          document.getElementsByClassName("grpbxMinority")[0].style.visibility = 'hidden';
          disable(thisObj, 'radioYesNoNotDisclosedM')
          disable(thisObj, 'chkboxMinority')
          document.getElementsByClassName("grpbxVeteran")[0].style.visibility = 'hidden';
          disable(thisObj, 'radioYesNoNotDisclosedV')
          disable(thisObj, 'chkboxVeteran')
        }
      }

      return true;
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for SetupControls event"
        );
      }
    }
  }

  const SetSecurity = async () => {
    try {
      let lstrxml = []
      let SetSecurityObj = {}

      await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', null, 'U', null).then(response => {
        lstrxml = response;
      })
      if (lstrxml != undefined) {
        if (lstrxml[0].permission == 'Y') {
          SetSecurityObj.secVendor = "U"
        }
        else {
          SetSecurityObj.secVendor = "I"
        }
      }

      await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', '001', 'D', null).then(response => {
        lstrxml = response;
      })
      if (lstrxml != undefined) {
        if (lstrxml[0].permission == 'Y') {
          SetSecurityObj.secLienHolder = "D"
        }
        else {
          await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', '001', 'U', null).then(response => {
            lstrxml = response;
          })
          if (lstrxml != undefined) {
            if (lstrxml[0].permission == 'Y') {
              SetSecurityObj.secLienHolder = "U"
            }
            else {
              await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', '001', 'I', null).then(response => {
                lstrxml = response;
              })
              if (lstrxml != undefined) {
                if (lstrxml[0].permission == 'Y') {
                  SetSecurityObj.secLienHolder = "I"
                }
                else {
                  SetSecurityObj.secLienHolder = ""
                }
              }
            }
          }
        }
      }

      await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', '002', 'U', null).then(response => {
        lstrxml = response;
      })
      if (lstrxml != undefined && lstrxml[0].permission == 'Y') {
        SetSecurityObj.secScan = "U"
      }
      else {
        await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', '002', 'I', null).then(response => {
          lstrxml = response;
        })
        if (lstrxml != undefined && lstrxml[0].permission == 'Y') {
          SetSecurityObj.secScan = "I"
        }
        else {
          SetSecurityObj.secScan = ""
        }
      }

      await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', '003', 'U', null).then(response => {
        lstrxml = response;
      })
      if (lstrxml != undefined && lstrxml[0].permission == 'Y') {
        SetSecurityObj.secMinority = "U"
      }
      else {
        await ContractManagementService.RetrieveAccessPermissionDetails('PN0350', '003', 'I', null).then(response => {
          lstrxml = response;
        })
        if (lstrxml != undefined && lstrxml[0].permission == 'Y') {
          SetSecurityObj.secMinority = "I"
        }
        else {
          SetSecurityObj.secMinority = ""
        }
      }

      setData(thisObj, 'sSetSecurity', SetSecurityObj)
      await SetupControls('ALL')
      await Form_Load()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for SetSecurity event"
        );
      }
    }
  }

  const onbtnView211GPCClick = async () => {
    try {
      setLoading(true)
      await Display211('GPC')
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnView211GPCClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnView211GPCClick = onbtnView211GPCClick;

  const onbtnView211ConcordiaClick = async () => {
    try {
      setLoading(true)
      await Display211('CON')
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for onbtnView211ConcordiaClick event"
        );
      }
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnView211ConcordiaClick = onbtnView211ConcordiaClick;

  const Display211 = async (sInDocType) => {
    try {
      let r = 0
      let LstrList = []
      let lstrxml = []
      let sHeader = ''
      let sDisplayedName = ''
      let sSharedFileName = ''
      let sSource = ''
      let sTarget = ''
      let sStatus = ''
      //     Dim fileSys As New FileSystemObject
      let vendor_num = getValue(thisObj, 'txtVendor')
      let doc_type = sInDocType
      await SystemMaintenanceApplicationSupportService.Retrieve211DocumentScanDetails(vendor_num, doc_type).then(response => {
        lstrxml = response
      })
      if (lstrxml != undefined) {
        sStatus = lstrxml.length == 0 || lstrxml[0].statusInd == undefined || lstrxml[0].statusInd == null ? '' : lstrxml[0].statusInd
      }
      else {
        alert("Problem retrieving form status.")
      }

      if (sStatus == 'I') {
        alert("Invalid file was uploaded.  Please rescan this form?")
        return
      }
      else if (sStatus == '' || sStatus == null || sStatus == undefined) {
        alert("File does not exist.")
        return
      }
      else if (sStatus != 'C') {
        alert("Form has not been processed.  Try again in about 5 minutes?")
        return
      }

      sSharedFileName = lstrxml[0].uploadFileName
      sDisplayedName = lstrxml[0].ftpFileName

      let redirecturl = null
      let createurl = null
      let result = []
      await ContractManagementService.RetrievePeanutStaticValues().then(response => {
        result = response
      })
      if (result !== undefined && result.length !== 0) {
        if (process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT')
          redirecturl = result[0].pps_defaults.pps_url.test
        else
          redirecturl = result[0].pps_defaults.pps_url.prod
      }
      else {
        return false;
      }

      if (sSharedFileName !== null && sSharedFileName !== undefined) {
        createurl = redirecturl + '/pps/form211/' + sSharedFileName
        window.open(createurl)
      } else {
        showMessage(thisObj, "Cannot find an uploaded file.")
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for Display211 event"
        );
      }
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            <Loading loading={loading}></Loading>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_VendorMaintenance*/}

              {/* END_USER_CODE-USER_BEFORE_VendorMaintenance*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxVendorMaintenance*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxVendorMaintenance*/}

              <GroupBoxWidget
                conf={state.grpbxVendorMaintenance}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxContractInformationFromAP*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContractInformationFromAP*/}

                <GroupBoxWidget
                  conf={state.grpbxContractInformationFromAP}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblContactInformationFromAP*/}

                  {/* END_USER_CODE-USER_BEFORE_lblContactInformationFromAP*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblContactInformationFromAP}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblContactInformationFromAP*/}

                  {/* END_USER_CODE-USER_AFTER_lblContactInformationFromAP*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVendorName*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVendorName*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVendorName}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVendorName*/}

                  {/* END_USER_CODE-USER_AFTER_txtVendorName*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhysicalAddress*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhysicalAddress*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhysicalAddress}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhysicalAddress*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhysicalAddress*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMailingAddress*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMailingAddress*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMailingAddress}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMailingAddress*/}

                  {/* END_USER_CODE-USER_AFTER_txtMailingAddress*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCityPA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCityPA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCityPA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCityPA*/}

                  {/* END_USER_CODE-USER_AFTER_txtCityPA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCityMA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCityMA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCityMA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCityMA*/}

                  {/* END_USER_CODE-USER_AFTER_txtCityMA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStatePA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStatePA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStatePA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStatePA*/}

                  {/* END_USER_CODE-USER_AFTER_txtStatePA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtStateMA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStateMA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStateMA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStateMA*/}

                  {/* END_USER_CODE-USER_AFTER_txtStateMA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtZipPA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtZipPA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtZipPA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtZipPA*/}

                  {/* END_USER_CODE-USER_AFTER_txtZipPA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtZipMA*/}

                  {/* END_USER_CODE-USER_BEFORE_txtZipMA*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtZipMA}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtZipMA*/}

                  {/* END_USER_CODE-USER_AFTER_txtZipMA*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhone*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhone*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhone}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhone*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhone*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCustomer*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCustomer*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCustomer}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCustomer*/}

                  {/* END_USER_CODE-USER_AFTER_txtCustomer*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContractInformationFromAP*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContractInformationFromAP*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxContactInformation*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContactInformation*/}

                <GroupBoxWidget
                  conf={state.grpbxContactInformation}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblContactInfo*/}

                  {/* END_USER_CODE-USER_BEFORE_lblContactInfo*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblContactInfo}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblContactInfo*/}

                  {/* END_USER_CODE-USER_AFTER_lblContactInfo*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPhoneCI*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPhoneCI*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPhoneCI}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPhoneCI*/}

                  {/* END_USER_CODE-USER_AFTER_txtPhoneCI*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEmail1*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEmail1*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEmail1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEmail1*/}

                  {/* END_USER_CODE-USER_AFTER_txtEmail1*/}
                  {/* START_USER_CODE-USER_BEFORE_textboxwidget21*/}

                  {/* END_USER_CODE-USER_BEFORE_textboxwidget21*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.textboxwidget21}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_textboxwidget21*/}

                  {/* END_USER_CODE-USER_AFTER_textboxwidget21*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEmail2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEmail2*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEmail2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEmail2*/}

                  {/* END_USER_CODE-USER_AFTER_txtEmail2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFax*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFax*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFax}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFax*/}

                  {/* END_USER_CODE-USER_AFTER_txtFax*/}
                  {/* START_USER_CODE-USER_BEFORE_txtEmail3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtEmail3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtEmail3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtEmail3*/}

                  {/* END_USER_CODE-USER_AFTER_txtEmail3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtarRemarks*/}

                  {/* END_USER_CODE-USER_BEFORE_txtarRemarks*/}

                  <TextAreaWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtarRemarks}
                    screenConf={state}
                  ></TextAreaWidget>
                  {/* START_USER_CODE-USER_AFTER_txtarRemarks*/}

                  {/* END_USER_CODE-USER_AFTER_txtarRemarks*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContactInformation*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContactInformation*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxLienHolders*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxLienHolders*/}

                <GroupBoxWidget
                  conf={state.grpbxLienHolders}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblLienHolders*/}

                  {/* END_USER_CODE-USER_BEFORE_lblLienHolders*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblLienHolders}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblLienHolders*/}

                  {/* END_USER_CODE-USER_AFTER_lblLienHolders*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLienHolder*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLienHolder*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLienHolder}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLienHolder*/}

                  {/* END_USER_CODE-USER_AFTER_txtLienHolder*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                  <ButtonWidget
                    conf={state.btnAdd}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                  {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                  {/* START_USER_CODE-USER_BEFORE_btnEdit*/}

                  {/* END_USER_CODE-USER_BEFORE_btnEdit*/}

                  <ButtonWidget
                    conf={state.btnEdit}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnEdit*/}

                  {/* END_USER_CODE-USER_AFTER_btnEdit*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDelete*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDelete*/}

                  <ButtonWidget
                    conf={state.btnDelete}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDelete*/}

                  {/* END_USER_CODE-USER_AFTER_btnDelete*/}
                  {/* START_USER_CODE-USER_BEFORE_txtarLienHolder*/}

                  {/* END_USER_CODE-USER_BEFORE_txtarLienHolder*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstLienHolder}
                  screenConf={state}
                ></ListboxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtarLienHolder*/}

                  {/* END_USER_CODE-USER_AFTER_txtarLienHolder*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxLienHolders*/}

                {/* END_USER_CODE-USER_AFTER_grpbxLienHolders*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxWomen*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxWomen*/}

                <GroupBoxWidget conf={state.grpbxWomen} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lblWomen*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWomen*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWomen}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWomen*/}

                  {/* END_USER_CODE-USER_AFTER_lblWomen*/}
                  {/* START_USER_CODE-USER_BEFORE_lblOMCWomen*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOMCWomen*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblOMCWomen}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblOMCWomen*/}

                  {/* END_USER_CODE-USER_AFTER_lblOMCWomen*/}
                  {/* START_USER_CODE-USER_BEFORE_radioYesNoNotDisclosedW*/}

                  {/* END_USER_CODE-USER_BEFORE_radioYesNoNotDisclosedW*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioYesNoNotDisclosedW}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioYesNoNotDisclosedW*/}

                  {/* END_USER_CODE-USER_AFTER_radioYesNoNotDisclosedW*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMustBeAUSCitizenW*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMustBeAUSCitizenW*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMustBeAUSCitizenW}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMustBeAUSCitizenW*/}

                  {/* END_USER_CODE-USER_AFTER_lblMustBeAUSCitizenW*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxWomen*/}

                {/* END_USER_CODE-USER_AFTER_grpbxWomen*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxMinority*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxMinority*/}

                <GroupBoxWidget
                  conf={state.grpbxMinority}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblMinority*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMinority*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMinority}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMinority*/}

                  {/* END_USER_CODE-USER_AFTER_lblMinority*/}
                  {/* START_USER_CODE-USER_BEFORE_lblOMCMinority*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOMCMinority*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblOMCMinority}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblOMCMinority*/}

                  {/* END_USER_CODE-USER_AFTER_lblOMCMinority*/}
                  {/* START_USER_CODE-USER_BEFORE_radioYesNoNotDisclosedM*/}

                  {/* END_USER_CODE-USER_BEFORE_radioYesNoNotDisclosedM*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioYesNoNotDisclosedM}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioYesNoNotDisclosedM*/}

                  {/* END_USER_CODE-USER_AFTER_radioYesNoNotDisclosedM*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxMinority*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxMinority*/}

                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxMinority}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxMinority*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxMinority*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMustBeAUSCitizenM*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMustBeAUSCitizenM*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMustBeAUSCitizenM}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMustBeAUSCitizenM*/}

                  {/* END_USER_CODE-USER_AFTER_lblMustBeAUSCitizenM*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxMinority*/}

                {/* END_USER_CODE-USER_AFTER_grpbxMinority*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxVeteran*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxVeteran*/}

                <GroupBoxWidget
                  conf={state.grpbxVeteran}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblVeteran*/}

                  {/* END_USER_CODE-USER_BEFORE_lblVeteran*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblVeteran}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblVeteran*/}

                  {/* END_USER_CODE-USER_AFTER_lblVeteran*/}
                  {/* START_USER_CODE-USER_BEFORE_lblOMCVetaran*/}

                  {/* END_USER_CODE-USER_BEFORE_lblOMCVetaran*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblOMCVetaran}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblOMCVetaran*/}

                  {/* END_USER_CODE-USER_AFTER_lblOMCVetaran*/}
                  {/* START_USER_CODE-USER_BEFORE_radioYesNoNotDisclosedV*/}

                  {/* END_USER_CODE-USER_BEFORE_radioYesNoNotDisclosedV*/}

                  <RadioButtonGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.radioYesNoNotDisclosedV}
                    screenConf={state}
                  ></RadioButtonGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_radioYesNoNotDisclosedV*/}

                  {/* END_USER_CODE-USER_AFTER_radioYesNoNotDisclosedV*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxVeteran*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxVeteran*/}

                  <CheckboxGroupWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxVeteran}
                    screenConf={state}
                  ></CheckboxGroupWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxVeteran*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxVeteran*/}
                  {/* START_USER_CODE-USER_BEFORE_lblMustBeAUSCitizenV*/}

                  {/* END_USER_CODE-USER_BEFORE_lblMustBeAUSCitizenV*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblMustBeAUSCitizenV}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblMustBeAUSCitizenV*/}

                  {/* END_USER_CODE-USER_AFTER_lblMustBeAUSCitizenV*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxVeteran*/}

                {/* END_USER_CODE-USER_AFTER_grpbxVeteran*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxVendorMaintenance*/}

              {/* END_USER_CODE-USER_AFTER_grpbxVendorMaintenance*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtAddedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAddedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_btnScan211GPC*/}

                {/* END_USER_CODE-USER_BEFORE_btnScan211GPC*/}

                <ButtonWidget
                  conf={state.btnScan211GPC}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnScan211GPC*/}

                {/* END_USER_CODE-USER_AFTER_btnScan211GPC*/}
                {/* START_USER_CODE-USER_BEFORE_btnView211GPC*/}

                {/* END_USER_CODE-USER_BEFORE_btnView211GPC*/}

                <ButtonWidget
                  conf={state.btnView211GPC}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnView211GPC*/}

                {/* END_USER_CODE-USER_AFTER_btnView211GPC*/}
                {/* START_USER_CODE-USER_BEFORE_btnScan211Concordia*/}

                {/* END_USER_CODE-USER_BEFORE_btnScan211Concordia*/}

                <ButtonWidget
                  conf={state.btnScan211Concordia}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnScan211Concordia*/}

                {/* END_USER_CODE-USER_AFTER_btnScan211Concordia*/}
                {/* START_USER_CODE-USER_BEFORE_btnView211Concordia*/}

                {/* END_USER_CODE-USER_BEFORE_btnView211Concordia*/}

                <ButtonWidget
                  conf={state.btnView211Concordia}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnView211Concordia*/}

                {/* END_USER_CODE-USER_AFTER_btnView211Concordia*/}
                {/* START_USER_CODE-USER_BEFORE_btn1*/}

                {/* END_USER_CODE-USER_BEFORE_btn1*/}

                <ButtonWidget
                  conf={state.btn1}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn1*/}

                {/* END_USER_CODE-USER_AFTER_btn1*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
                {/* START_USER_CODE-USER_BEFORE_txtChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_txtChangedBy*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtChangedBy}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_txtChangedBy*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_VendorMaintenance*/}

              {/* END_USER_CODE-USER_AFTER_VendorMaintenance*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_VendorMaintenance
);
