/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  ListboxWidget,
  CheckboxWidget,
  disable,
  enable,
  setValue,
  hide,
  show,
  getValue,
  hideColumn,
  showColumn,
  setData,
  getData,
  goTo,
  setGridValue,
  getGridValue,
  enableWidgets,
  isEnabled,
} from "../../shared/WindowImports";

import "./ContractLimitCollPt.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import Loading from "../../../Loader/Loading"
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_ContractLimitCollPt(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractLimitCollPt",
    windowName: "ContractLimitCollPt",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.ContractLimitCollPt",
    // START_USER_CODE-USER_ContractLimitCollPt_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Contract Price Limit Coll Pt Details",
      scrCode: "PN0260C",
    },
    // END_USER_CODE-USER_ContractLimitCollPt_PROPERTIES
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnExport: {
      name: "btnExport",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExport_PROPERTIES

      // END_USER_CODE-USER_btnExport_PROPERTIES
    },
    btnPrintGrid: {
      name: "btnPrintGrid",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Grid",
      CharWidth: "23",
      // START_USER_CODE-USER_btnPrintGrid_PROPERTIES

      // END_USER_CODE-USER_btnPrintGrid_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    colAllocTons: {
      name: "colAllocTons",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Allocated Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAllocTons_PROPERTIES

      // END_USER_CODE-USER_colAllocTons_PROPERTIES
    },
    colhideShow: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "HideShow",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colColor: {
      name: "Color",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Color",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colContrctDryLand: {
      name: "colContrctDryLand",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Contracted Dry Land Acres",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrctDryLand_PROPERTIES

      // END_USER_CODE-USER_colContrctDryLand_PROPERTIES
    },
    colContrctedTons: {
      name: "colContrctedTons",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Contracted Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrctedTons_PROPERTIES

      // END_USER_CODE-USER_colContrctedTons_PROPERTIES
    },
    colContrctIrrAcre: {
      name: "colContrctIrrAcre",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Contracted Irrigated Acres",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrctIrrAcre_PROPERTIES

      // END_USER_CODE-USER_colContrctIrrAcre_PROPERTIES
    },
    colDesc: {
      name: "colDesc",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Desc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDesc_PROPERTIES

      // END_USER_CODE-USER_colDesc_PROPERTIES
    },
    colLoanRepay: {
      name: "colLoanRepay",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Loan Repay Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanRepay_PROPERTIES

      // END_USER_CODE-USER_colLoanRepay_PROPERTIES
    },
    colMarketGain: {
      name: "colMarketGain",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Market Gain Share %",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMarketGain_PROPERTIES

      // END_USER_CODE-USER_colMarketGain_PROPERTIES
    },
    colMarketWeeksPrior: {
      name: "colMarketWeeksPrior",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Market Weeks Prior to Maturity",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMarketWeeksPrior_PROPERTIES

      // END_USER_CODE-USER_colMarketWeeksPrior_PROPERTIES
    },
    colMrketCeil: {
      name: "colMrketCeil",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Market Ceiling",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMrketCeil_PROPERTIES

      // END_USER_CODE-USER_colMrketCeil_PROPERTIES
    },
    colMrktFloor: {
      name: "colMrktFloor",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Market Floor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMrktFloor_PROPERTIES

      // END_USER_CODE-USER_colMrktFloor_PROPERTIES
    },
    colMultiPricings: {
      name: "colMultiPricings",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Multiple Pricings",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMultiPricings_PROPERTIES

      // END_USER_CODE-USER_colMultiPricings_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colOpenTons: {
      name: "colOpenTons",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Open Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenTons_PROPERTIES

      // END_USER_CODE-USER_colOpenTons_PROPERTIES
    },
    colOptPriceCap: {
      name: "colOptPriceCap",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Option Price Cap",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOptPriceCap_PROPERTIES

      // END_USER_CODE-USER_colOptPriceCap_PROPERTIES
    },
    colPayRebate: {
      name: "colPayRebate",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Pay Rebate At",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayRebate_PROPERTIES

      // END_USER_CODE-USER_colPayRebate_PROPERTIES
    },
    colPnutTyp: {
      name: "colPnutTyp",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutTyp_PROPERTIES

      // END_USER_CODE-USER_colPnutTyp_PROPERTIES
    },
    colPriceMtchEnd: {
      name: "colPriceMtchEnd",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Price Match End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceMtchEnd_PROPERTIES

      // END_USER_CODE-USER_colPriceMtchEnd_PROPERTIES
    },
    colPriceTon: {
      name: "colPriceTon",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Price/Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceTon_PROPERTIES

      // END_USER_CODE-USER_colPriceTon_PROPERTIES
    },
    colPricingTyp: {
      name: "colPricingTyp",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Pricing Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricingTyp_PROPERTIES

      // END_USER_CODE-USER_colPricingTyp_PROPERTIES
    },
    colPt: {
      name: "colPt",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "CollPt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPt_PROPERTIES

      // END_USER_CODE-USER_colPt_PROPERTIES
    },
    colArea: {
      name: "colArea",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Area",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colArea_PROPERTIES

      // END_USER_CODE-USER_colArea_PROPERTIES
    },

    colRebateRate: {
      name: "colRebateRate",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Rebate Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRebateRate_PROPERTIES

      // END_USER_CODE-USER_colRebateRate_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    grpbxMarket: {
      name: "grpbxMarket",
      type: "GroupBoxWidget",
      parent: "grpbxCntrctLimit",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxMarket_PROPERTIES

      // END_USER_CODE-USER_grpbxMarket_PROPERTIES
    },
    lstboxLockIn: {
      name: "lstboxLockIn",
      type: "ListBoxFieldWidget",
      parent: "grpbxCntrctLimit",
      RowSpanForTabPotrait: "4",
      RowSpanForLargeDesktop: "4",
      Label: "Lock in:",
      RowSpanForTabLandscape: "4",
      DataProviderForListBox: "inline",
      RowSpan: "4",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstboxLockIn_PROPERTIES

      // END_USER_CODE-USER_lstboxLockIn_PROPERTIES
    },
    txtArea: {
      name: "txtArea",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Area:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtArea_PROPERTIES

      // END_USER_CODE-USER_txtArea_PROPERTIES
    },
    txtAvailTons: {
      name: "txtAvailTons",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Available Tons:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAvailTons_PROPERTIES

      // END_USER_CODE-USER_txtAvailTons_PROPERTIES
    },
    txtcolAllocTons: {
      name: "txtcolAllocTons",
      type: "TextBoxWidget",
      colName: "colAllocTons",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAllocTons_PROPERTIES

      // END_USER_CODE-USER_txtcolAllocTons_PROPERTIES
    },
    txtcolhideShow: {
      name: "txtcolhideShow",
      type: "TextBoxWidget",
      colName: "colhideShow",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolhideShow_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolhideShow_PROPERTIES
    },

    txtcolColor: {
      name: "txtcolColor",
      type: "TextBoxWidget",
      colName: "colColor",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolhideShow_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolhideShow_PROPERTIES
    },

    colHideRecKey: {
      name: "colHideRecKey",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Hide_REC_KEY",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHideRecKey_PROPERTIES

      // END_USER_CODE-USER_colHideRecKey_PROPERTIES
    },
    colHideAlloc: {
      name: "colHideAlloc",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Hide_Allocated",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHideAlloc_PROPERTIES

      // END_USER_CODE-USER_colHideAlloc_PROPERTIES
    },
    colHideAssign: {
      name: "colHideAssign",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Hide_Assigned",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHideAssign_PROPERTIES

      // END_USER_CODE-USER_colHideAssign_PROPERTIES
    },

    txtcolHideRecKey: {
      name: "txtcolHideRecKey",
      type: "TextBoxWidget",
      colName: "colHideRecKey",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHideRecKey_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolHideRecKey_PROPERTIES
    },
    txtcolHideAlloc: {
      name: "txtcolHideAlloc",
      type: "TextBoxWidget",
      colName: "colHideAlloc",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHideAlloc_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolHideAlloc_PROPERTIES
    },
    txtcolHideAssign: {
      name: "txtcolHideAssign",
      type: "TextBoxWidget",
      colName: "colHideAssign",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHideAssign_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolHideAssign_PROPERTIES
    },

    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridContrctLimit",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_btncol1_PROPERTIES
    },

    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },

    txtcolContrctDryLand: {
      name: "txtcolContrctDryLand",
      type: "TextBoxWidget",
      colName: "colContrctDryLand",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContrctDryLand_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolContrctDryLand_PROPERTIES
    },
    txtcolContrctedTons: {
      name: "txtcolContrctedTons",
      type: "TextBoxWidget",
      colName: "colContrctedTons",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContrctedTons_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolContrctedTons_PROPERTIES
    },
    txtcolContrctIrrAcre: {
      name: "txtcolContrctIrrAcre",
      type: "TextBoxWidget",
      colName: "colContrctIrrAcre",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContrctIrrAcre_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolContrctIrrAcre_PROPERTIES
    },
    txtcolDesc: {
      name: "txtcolDesc",
      type: "TextBoxWidget",
      colName: "colDesc",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDesc_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolDesc_PROPERTIES
    },
    txtcolLoanRepay: {
      name: "txtcolLoanRepay",
      type: "TextBoxWidget",
      colName: "colLoanRepay",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLoanRepay_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolLoanRepay_PROPERTIES
    },
    txtcolMarketGain: {
      name: "txtcolMarketGain",
      type: "TextBoxWidget",
      colName: "colMarketGain",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMarketGain_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolMarketGain_PROPERTIES
    },
    txtcolMarketWeeksPrioruic25: {
      name: "txtcolMarketWeeksPrioruic25",
      type: "TextBoxWidget",
      colName: "colMarketWeeksPrior",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMarketWeeksPrioruic25_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolMarketWeeksPrioruic25_PROPERTIES
    },
    txtcolMrketCeil: {
      name: "txtcolMrketCeil",
      type: "TextBoxWidget",
      colName: "colMrketCeil",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMrketCeil_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolMrketCeil_PROPERTIES
    },
    txtcolMrktFloor: {
      name: "txtcolMrktFloor",
      type: "TextBoxWidget",
      colName: "colMrktFloor",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMrktFloor_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolMrktFloor_PROPERTIES
    },
    txtcolMultiPricings: {
      name: "txtcolMultiPricings",
      type: "TextBoxWidget",
      colName: "colMultiPricings",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolMultiPricings_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolMultiPricings_PROPERTIES
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOleic_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolOleic_PROPERTIES
    },
    txtcolOpenTons: {
      name: "txtcolOpenTons",
      type: "TextBoxWidget",
      colName: "colOpenTons",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenTons_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolOpenTons_PROPERTIES
    },
    txtcolOptPriceCap: {
      name: "txtcolOptPriceCap",
      type: "TextBoxWidget",
      colName: "colOptPriceCap",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOptPriceCap_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolOptPriceCap_PROPERTIES
    },
    txtcolPayRebate: {
      name: "txtcolPayRebate",
      type: "TextBoxWidget",
      colName: "colPayRebate",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPayRebate_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolPayRebate_PROPERTIES
    },
    txtcolPnutTyp: {
      name: "txtcolPnutTyp",
      type: "TextBoxWidget",
      colName: "colPnutTyp",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPnutTyp_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolPnutTyp_PROPERTIES
    },
    txtcolPriceMtchEnd: {
      name: "txtcolPriceMtchEnd",
      type: "TextBoxWidget",
      colName: "colPriceMtchEnd",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceMtchEnd_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolPriceMtchEnd_PROPERTIES
    },
    txtcolPriceTon: {
      name: "txtcolPriceTon",
      type: "TextBoxWidget",
      colName: "colPriceTon",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceTon_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolPriceTon_PROPERTIES
    },
    txtcolPricingTyp: {
      name: "txtcolPricingTyp",
      type: "TextBoxWidget",
      colName: "colPricingTyp",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPricingTyp_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolPricingTyp_PROPERTIES
    },
    txtcolPt: {
      name: "txtcolPt",
      type: "TextBoxWidget",
      colName: "colPt",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPt_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolPt_PROPERTIES
    },
    txtcolArea: {
      name: "txtcolArea",
      type: "TextBoxWidget",
      colName: "colArea",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolArea_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolArea_PROPERTIES
    },

    txtcolRebateRate: {
      name: "txtcolRebateRate",
      type: "TextBoxWidget",
      colName: "colRebateRate",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolRebateRate_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolRebateRate_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },

    txtcolLockInDate1: {
      name: "txtcolLockInDate1",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate2: {
      name: "txtcolLockInDate2",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate3: {
      name: "txtcolLockInDate3",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate4: {
      name: "txtcolLockInDate4",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate5: {
      name: "txtcolLockInDate5",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate6: {
      name: "txtcolLockInDate6",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },

    txtcolLockInDate7: {
      name: "txtcolLockInDate7",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate8: {
      name: "txtcolLockInDate8",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate9: {
      name: "txtcolLockInDate9",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate10: {
      name: "txtcolLockInDate10",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },

    colLockInDate1: {
      name: "colLockInDate1",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },

    colLockInDate2: {
      name: "colLockInDate2",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate3: {
      name: "colLockInDate3",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate4: {
      name: "colLockInDate4",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate5: {
      name: "colLockInDate5",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate6: {
      name: "colLockInDate6",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date6",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate7: {
      name: "colLockInDate7",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date7",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate8: {
      name: "colLockInDate8",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate9: {
      name: "colLockInDate9",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date9",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate10: {
      name: "colLockInDate10",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      Label: "Lock In Date10",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },

    txtDesc: {
      name: "txtDesc",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Desc:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDesc_PROPERTIES

      // END_USER_CODE-USER_txtDesc_PROPERTIES
    },
    txtLoanRepay: {
      name: "txtLoanRepay",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Loan Repay Method:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepay_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepay_PROPERTIES
    },
    txtMrktCeiling: {
      name: "txtMrktCeiling",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market Ceiling:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktCeiling_PROPERTIES

      // END_USER_CODE-USER_txtMrktCeiling_PROPERTIES
    },
    txtMrktFloor: {
      name: "txtMrktFloor",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market Floor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktFloor_PROPERTIES

      // END_USER_CODE-USER_txtMrktFloor_PROPERTIES
    },
    txtMrktGain: {
      name: "txtMrktGain",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market Gain Share %:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktGain_PROPERTIES

      // END_USER_CODE-USER_txtMrktGain_PROPERTIES
    },
    txtMrktWeeksPriorMatrty: {
      name: "txtMrktWeeksPriorMatrty",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market weeks prior to Maturity:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktWeeksPriorMatrty_PROPERTIES

      // END_USER_CODE-USER_txtMrktWeeksPriorMatrty_PROPERTIES
    },
    txtMultiPricings: {
      name: "txtMultiPricings",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Multiple Pricings:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMultiPricings_PROPERTIES

      // END_USER_CODE-USER_txtMultiPricings_PROPERTIES
    },
    txtOleic: {
      name: "txtOleic",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Oleic:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleic_PROPERTIES

      // END_USER_CODE-USER_txtOleic_PROPERTIES
    },
    txtOptPrcCap: {
      name: "txtOptPrcCap",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Option Price Cap:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptPrcCap_PROPERTIES

      // END_USER_CODE-USER_txtOptPrcCap_PROPERTIES
    },
    txtPayRebt: {
      name: "txtPayRebt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Pay Rebate at:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayRebt_PROPERTIES

      // END_USER_CODE-USER_txtPayRebt_PROPERTIES
    },
    txtPnutTyp: {
      name: "txtPnutTyp",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Peanut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutTyp_PROPERTIES

      // END_USER_CODE-USER_txtPnutTyp_PROPERTIES
    },
    txtPrcngTyp: {
      name: "txtPrcngTyp",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Pricing Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrcngTyp_PROPERTIES

      // END_USER_CODE-USER_txtPrcngTyp_PROPERTIES
    },
    txtPriceMtchEnd: {
      name: "txtPriceMtchEnd",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Price Match End Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPriceMtchEnd_PROPERTIES

      // END_USER_CODE-USER_txtPriceMtchEnd_PROPERTIES
    },
    txtPriceTon: {
      name: "txtPriceTon",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Price/Ton:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPriceTon_PROPERTIES

      // END_USER_CODE-USER_txtPriceTon_PROPERTIES
    },
    txtRebtRate: {
      name: "txtRebtRate",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Rebate Rate:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRebtRate_PROPERTIES

      // END_USER_CODE-USER_txtRebtRate_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Seg:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtVrty: {
      name: "txtVrty",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Variety:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVrty_PROPERTIES

      // END_USER_CODE-USER_txtVrty_PROPERTIES
    },
    grpbxCntrctLimit: {
      name: "grpbxCntrctLimit",
      type: "GroupBoxWidget",
      parent: "ContractLimitCollPt",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCntrctLimit_PROPERTIES

      // END_USER_CODE-USER_grpbxCntrctLimit_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridContrctLimit",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridContrctLimit",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES
      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    gridContrctLimit: {
      name: "gridContrctLimit",
      type: "GridWidget",
      parent: "ContractLimitCollPt",
      gridCellsOrder:
        "colIsSts,txtcolArea,txtcolPt,btncol1,txtcolhideShow,txtcolAllocTons,txtcolColor,txtcolContrctedTons,txtcolOpenTons,txtcolContrctIrrAcre,txtcolContrctDryLand,txtcolPricingTyp,txtcolDesc,txtcolPnutTyp,txtcolVariety,txtcolSeg,txtcolOleic,txtcolPriceTon,txtcolMrktFloor,txtcolMrketCeil,txtcolRebateRate,txtcolMarketGain,txtcolLoanRepay,txtcolMultiPricings,txtcolMarketWeeksPrioruic25,txtcolOptPriceCap,txtcolPayRebate,txtcolPriceMtchEnd,txtcolHideRecKey,txtcolHideAlloc,txtcolHideAssign,txtcolLockInDate1,txtcolLockInDate2,txtcolLockInDate3,txtcolLockInDate4,txtcolLockInDate5,txtcolLockInDate6,txtcolLockInDate7,txtcolLockInDate8,txtcolLockInDate9,txtcolLockInDate10",
      Pagination: false,
      HasLabel: false,
      toggleRender: true,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridContrctLimit_PROPERTIES
      isEditable: true,
      isCobolTable: true,

      conditionalRowStyles: [
        {
          when: (row) => { return row.txtcolhideShow == "N" },
          style: {
            button: {
              display: 'none'
            }
          }
        }
      ],
      // END_USER_CODE-USER_gridContrctLimit_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ContractLimitCollPt",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    disable(thisObj, 'btnSave')
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(()=>{
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolhideShow')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolPricingTyp')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolDesc')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolColor')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolHideRecKey')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolHideAlloc')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolHideAssign')
    
  },[thisObj.state.gridContrctLimit.toggleRender])

  useEffect(() => {
    return () => {
      crossButton()
      setData(thisObj, "closingOfContractLimitCollPt", true)
      goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitSearch#DEFAULT#false#Click");
      // setData(thisObj, "closingOfContractLimitCollPt", true)
    }
  },[])

  let crossButton=async()=>{
      let mbError=getData(thisObj,'MbError')
      let mbDirty=getData(thisObj,'MbDirty')
      if (mbDirty == true || mbError == true) {
        let response = confirm('There are changes on the grid that have not yet been saved! Do you want save these changes?')
        if (response == true) {
          if (mbError == true) {
            showMessage(thisObj, 'Please correct data errors before Continuing!')
            return;
          }
          else {
            if (await bSaveForm() == false) return;
          }
        }
      }
  }

  const [isEditable, setIsEditable] = useState(false);
  const [lblPriceType, setLblPriceType] = useState('');
  const [loading, setLoading] = useState(false);
  const [lblOrganic, setLblOrganic] = useState('')
  const [mbFormDirty, setMbFormDirty] = useState();
  const [mbFormError, setMbFormError] = useState();
  const [gridEditable, setGridEditable] = useState(false)
  // START_USER_CODE-USER_METHODS
  let FormLoad = async () => {
    setLoading(true)

    hideColumn(thisObj, 'gridContrctLimit', 'txtcolHideRecKey')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolHideAlloc')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolHideAssign')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolhideShow')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolColor')

    let permission = await bfillAccess('PN0260', '002', 'U', null)
    if (permission == 'Y') {
      enable(thisObj, 'btnSave')
      setGridEditable(true);
      setIsEditable(true)
    }

    let data = getData(thisObj, 'ContractLimitSearchData')
    let lstrRecordKey = data.recordKey
    setData(thisObj, 'lstrRecordKey', lstrRecordKey)
    await disableTextboxes();
    await bfillForm(lstrRecordKey);
    setLoading(false)
  }

  let disableTextboxes = async () => {
    disable(thisObj, 'lstboxLockIn')
    disable(thisObj, 'txtArea')
    disable(thisObj, 'txtAvailTons')
    disable(thisObj, 'txtDesc')
    disable(thisObj, 'txtLoanRepay')
    disable(thisObj, 'txtMrktCeiling')
    disable(thisObj, 'txtMrktFloor')
    disable(thisObj, 'txtMrktGain')
    disable(thisObj, 'txtMrktWeeksPriorMatrty')
    disable(thisObj, 'txtMultiPricings')
    disable(thisObj, 'txtOleic')
    disable(thisObj, 'txtOptPrcCap')
    disable(thisObj, 'txtPayRebt')
    disable(thisObj, 'txtPnutTyp')
    disable(thisObj, 'txtPrcngTyp')
    disable(thisObj, 'txtPriceMtchEnd')
    disable(thisObj, 'txtPriceTon')
    disable(thisObj, 'txtRebtRate')
    disable(thisObj, 'txtSeg')
    disable(thisObj, 'txtVrty')
  }

  let bfillAccess = async (funcId, funcSubId, access, buyPtId) => {
    let permission = await ContractManagementService.RetrieveAccessPermissionDetails(funcId, funcSubId, access, buyPtId)
    return permission[0].permission
  }

  // gridEditable=false;
  let bfillForm = async (lstrRecordKey) => {
    try {
      let gridObj = {};
      let dataObj = {};
      let collPtRecKey = [];

      let dblAvalableTons = 0;
      let TransformedRowsArray = [];
      dataObj.record_key = lstrRecordKey
      let response = await SystemMaintenanceMasterManagementService.RetrieveContractPriceLimitDetails(dataObj, 'EXPAND')

      let data = response
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let controlLevel = data[i].control_level;
          if (controlLevel == 'AR') {
            setLblPriceType(data[i].pricing_type)
            switch (data[i].pricing_type) {
              case 'C-BASIS':
                setValue(thisObj, 'txtPrcngTyp', 'Basis Contract')
                break;
              case 'C-FIRM':
                setValue(thisObj, 'txtPrcngTyp', 'Firm Contract')
                break;
              case 'OPTION':
                setValue(thisObj, 'txtPrcngTyp', 'Option Pricings')
                break;
              case 'FLEX MKT':
                setValue(thisObj, 'txtPrcngTyp', 'Flex Market Pricings')
                break;
              case 'SEED':
                setValue(thisObj, 'txtPrcngTyp', 'Seed Pricings')
                break;
              case 'FLOOR':
                setValue(thisObj, 'txtPrcngTyp', 'Floor Pricings')
                break;
            }

            setValue(thisObj, 'txtDesc', data[i].pricing_type_desc)
            setValue(thisObj, 'txtArea', data[i].area_id)
            setValue(thisObj, 'txtPnutTyp', data[i].pnut_type_id)
            setLblOrganic(data[i].organic_ind)
            if (data[i].organic_ind == 'Y') {
              setValue(thisObj, 'txtPnutTyp', data[i].pnut_type_id + 'Organic')
            }
            setValue(thisObj, 'txtVrty', data[i].pnut_variety_id)

            switch (data[i].symbol_ind) {
              case "TRADEMARK":
                setValue(thisObj, 'txtVrty', data[i].pnut_variety_id + ' ᵀᴹ')
                break;
              case "REGISTERED TRADEMARK":
                setValue(thisObj, 'txtVrty', data[i].pnut_variety_id + ' ®')
                break;
              case "COPYRIGHT":
                setValue(thisObj, 'txtVrty', data[i].pnut_variety_id + ' ©')
                break;
            }
            setValue(thisObj, 'txtSeg', data[i].seg_type)
            setValue(thisObj, 'txtOleic', data[i].oleic_ind)
            setValue(thisObj, 'txtPriceTon', Number(data[i].price_per_ton).toFixed(2))

            //Hide and show of some textbox
            if (data[i].market_floor != '') {
              show(thisObj, 'txtMrktFloor')
              setValue(thisObj, 'txtMrktFloor', Number(data[i].market_floor).toFixed(5));
            }
            else {
              hide(thisObj, 'txtMrktFloor',false)
              setValue(thisObj, 'txtMrktFloor', '');
            }

            if (data[i].market_ceiling != '') {
              show(thisObj, 'txtMrktCeiling')
              setValue(thisObj, 'txtMrktCeiling', Number(data[i].market_ceiling).toFixed(5));
            }
            else {
              hide(thisObj, 'txtMrktCeiling',false)
              setValue(thisObj, 'txtMrktCeiling', '');
            }

            if (data[i].rebate_rate != '') {
              show(thisObj, 'txtRebtRate')
              setValue(thisObj, 'txtRebtRate', Number(data[i].rebate_rate).toFixed(2));
            }
            else {
              hide(thisObj, 'txtRebtRate',false)
              setValue(thisObj, 'txtRebtRate', '');
            }

            if (data[i].mkt_gain_share != '') {
              show(thisObj, 'txtMrktGain')
              setValue(thisObj, 'txtMrktGain', Number(data[i].mkt_gain_share).toFixed(2));
            }
            else {
              hide(thisObj, 'txtMrktGain',false)
              setValue(thisObj, 'txtMrktGain', '');
            }

            if (data[i].mkt_gain_share != '') {
              show(thisObj, 'txtMrktGain')
              setValue(thisObj, 'txtMrktGain', Number(data[i].mkt_gain_share).toFixed(2));
            }
            else {
              hide(thisObj, 'txtMrktGain',false)
              setValue(thisObj, 'txtMrktGain', '');
            }

            if (data[i].loan_repay_ind != '') {
              show(thisObj, 'txtLoanRepay')
              if (data[i].loan_repay_ind == 'G') {
                setValue(thisObj, 'txtLoanRepay', 'Golden')
              }
              else if (data[i].loan_repay_ind == 'U') {
                setValue(thisObj, 'txtLoanRepay', 'USDA')
              }
              else {
                setValue(thisObj, 'txtLoanRepay', '')
              }
            }
            else {
              hide(thisObj, 'txtLoanRepay',false)
              setValue(thisObj, 'txtLoanRepay', '')
            }

            if (data[i].multi_pricing_ind != '') {
              show(thisObj, 'txtMultiPricings')
              if (data[i].multi_pricing_ind == 'Y') {
                setValue(thisObj, 'txtMultiPricings', 'Yes')
              }
              else if (data[i].multi_pricing_ind == 'N') {
                setValue(thisObj, 'txtMultiPricings', 'No')
              }
              else setValue(thisObj, 'txtMultiPricings', '')
            }
            else {
              hide(thisObj, 'txtMultiPricings',false)
              setValue(thisObj, 'txtMultiPricings', '')
            }

            if (data[i].mkt_wk_prior_mat != '') {
              show(thisObj, 'txtMrktWeeksPriorMatrty')
              setValue(thisObj, 'txtMrktWeeksPriorMatrty', data[i].mkt_wk_prior_mat)
            }
            else {
              hide(thisObj, 'txtMrktWeeksPriorMatrty',false)
              setValue(thisObj, 'txtMrktWeeksPriorMatrty', '')
            }

            if (data[i].max_option_price != '') {
              show(thisObj, 'txtOptPrcCap')
              setValue(thisObj, 'txtOptPrcCap', data[i].max_option_price)
            }
            else {
              hide(thisObj, 'txtOptPrcCap',false)
              setValue(thisObj, 'txtOptPrcCap', '')
            }

            if (data[i].pay_rebate_at != '') {
              show(thisObj, 'txtPayRebt')
              setValue(thisObj, 'txtPayRebt', data[i].pay_rebate_at)
            }
            else {
              hide(thisObj, 'txtPayRebt',false)
              setValue(thisObj, 'txtPayRebt', '')
            }

            if (data[i].price_match_end_date != '') {
              show(thisObj, 'txtPriceMtchEnd')
              setValue(thisObj, 'txtPriceMtchEnd', data[i].price_match_end_date)
            }
            else {
              hide(thisObj, 'txtPriceMtchEnd',false)
              setValue(thisObj, 'txtPriceMtchEnd', '')
            }

            let strLockInData = data[i].lock_in_data
            let js = [];
            if (strLockInData.length > 0) {
              let obj = {};
              show(thisObj, 'lstboxLockIn')

              for (let i = 0; i < strLockInData.length; i++) {
                obj = {
                  key: strLockInData[i].lock_in_key,
                  description: strLockInData[i].lock_in_pct + '% by ' + strLockInData[i].lock_in_date.slice(0, -9)
                }
                js.push(obj)
                obj = {};
              }

              thisObj.setState(current => {
                return {
                  ...current,
                  lstboxLockIn: {
                    ...state["lstboxLockIn"],
                    valueList: js,
                  }
                }
              })
              setData(thisObj, 'listboxData', js)
            }
            else {
              hide(thisObj, 'lstboxLockIn',false)
              strLockInData = [];
            }
            gridObj.txtcolArea = data[i].area_id.slice(0, 2);
            gridObj.txtcolPt = "";
            gridObj.txtcolHideRecKey = data[i].record_key
            gridObj.txtcolHideAlloc = data[i].tons_allocated
            gridObj.txtcolAllocTons = data[i].tons_allocated == "" ? '0.0000' : data[i].tons_allocated
            gridObj.txtcolContrctedTons = data[i].tons_contracted == "" ? '0.0000' : data[i].tons_contracted
            gridObj.txtcolOpenTons = data[i].tons_allocated - data[i].tons_contracted
            gridObj.txtcolContrctIrrAcre = data[i].irrigated_acre
            gridObj.txtcolContrctDryLand = data[i].dryland_acre
            gridObj.txtcolHideAssign = data[i].tons_assigned
            dblAvalableTons = Number(dblAvalableTons) + Number(data[i].tons_allocated)

            // gridObj.
            //txtcolArea,txtcolPt,txtcolBuyPt,txtcolAllocTons,txtcolContrctedTons,txtcolOpenTons,
            // txtcolContrctIrrAcre,txtcolContrctDryLand,txtcolPricingTyp,txtcolDesc,txtcolPnutTyp,txtcolVariety,
            // txtcolSeg,txtcolOleic,txtcolPriceTon,txtcolMrktFloor,txtcolMrketCeil,txtcolRebateRate,
            // txtcolMarketGain,txtcolLoanRepay,txtcolMultiPricings,txtcolMarketWeeksPrioruic25,txtcolOptPriceCap,
            // txtcolPayRebate,txtcolPriceMtchEnd

          }
          if (controlLevel == 'CP') {
            let objreckey = {};
            objreckey = {
              key: data[i].coll_pt_id.slice(0, 3),
              value: data[i].record_key
            }
            collPtRecKey.push(objreckey);

            gridObj.txtcolArea = "";
            gridObj.txtcolPt = data[i].coll_pt_id.slice(0, 3)
            gridObj.txtcolHideRecKey = data[i].record_key
            gridObj.txtcolHideAlloc = data[i].tons_allocated

            let permission = await bfillAccess('PN0260', '002', 'I', data[i].coll_pt_id.slice(0, 3))
            if (permission == 'Y') {
              if (data[i].tons_allocated != 0) {
                gridObj.txtcolhideShow = 'Y'
              }
              else {
                gridObj.txtcolhideShow = 'N'
              }

            }

            gridObj.txtcolAllocTons = data[i].tons_allocated == "" ? '0.0000' : data[i].tons_allocated
            gridObj.txtcolContrctedTons = data[i].tons_contracted == "" ? '0.0000' : data[i].tons_contracted
            gridObj.txtcolOpenTons = data[i].tons_allocated - data[i].tons_contracted
            gridObj.txtcolContrctIrrAcre = data[i].irrigated_acre
            gridObj.txtcolContrctDryLand = data[i].dryland_acre
            gridObj.txtcolHideAssign = data[i].tons_assigned
            dblAvalableTons = Number(dblAvalableTons) - Number(data[i].tons_allocated)
          }

          if (i != 0) {
            gridObj.txtcolPricingTyp = txtPrcngTyp.value
            gridObj.txtcolDesc = txtDesc.value
            gridObj.txtcolPnutTyp = txtPnutTyp.value.slice(0,2)
          gridObj.txtcolVariety = txtVrty.value=='>>> Any Variety <<<'?txtVrty.value:txtVrty.value.slice(0,2);
            gridObj.txtcolSeg = txtSeg.value
            gridObj.txtcolOleic = txtOleic.value
            gridObj.txtcolPriceTon = txtPriceTon.value
            gridObj.txtcolMrktFloor = txtMrktFloor.value
            gridObj.txtcolMrketCeil = txtMrktCeiling.value
            gridObj.txtcolRebateRate = txtRebtRate.value
            gridObj.txtcolMarketGain = txtMrktGain.value
            gridObj.txtcolLoanRepay = txtLoanRepay.value
            gridObj.txtcolMultiPricings = txtMultiPricings.value
            gridObj.txtcolMarketWeeksPrioruic25 = txtMrktWeeksPriorMatrty.value
            gridObj.txtcolOptPriceCap = txtOptPrcCap.value
            gridObj.txtcolPayRebate = txtPayRebt.value
            gridObj.txtcolPriceMtchEnd = txtPriceMtchEnd.value

            let lockinData = getData(thisObj, 'listboxData')
            if (lockinData != null && lockinData.length > 0) {
              for (let j = 0; j < lockinData.length; j++) {
                if (j == 0) {
                  gridObj.txtcolLockInDate1 = lockinData[j].description
                }
                if (j == 1) {
                  gridObj.txtcolLockInDate2 = lockinData[j].description
                }
                if (j == 2) {
                  gridObj.txtcolLockInDate3 = lockinData[j].description
                }
                if (j == 3) {
                  gridObj.txtcolLockInDate4 = lockinData[j].description
                }
                if (j == 4) {
                  gridObj.txtcolLockInDate5 = lockinData[j].description
                }
                if (j == 5) {
                  gridObj.txtcolLockInDate6 = lockinData[j].description
                }
                if (j == 6) {
                  gridObj.txtcolLockInDate7 = lockinData[j].description
                }
                if (j == 7) {
                  gridObj.txtcolLockInDate8 = lockinData[j].description
                }
                if (j == 8) {
                  gridObj.txtcolLockInDate9 = lockinData[j].description
                }
                if (j == 9) {
                  gridObj.txtcolLockInDate10 = lockinData[j].description
                }
              }
            }
          }
          TransformedRowsArray.push(gridObj);
          gridObj = {};
        }
        setValue(thisObj, 'gridContrctLimit', TransformedRowsArray)
        setData(thisObj, 'gridData', TransformedRowsArray)
        setValue(thisObj, 'txtAvailTons', dblAvalableTons.toString())
        setData(thisObj,'collPtRecKey', collPtRecKey);
      }
      PopulateGrid(false)
      if (isEnabled(thisObj,'btnSave') == true) {
        vsfResults_AfterEdit(true);
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during bfillForm Method"
        );
      }
      return false;
    }
  }

  let vsfResults_AfterEdit = async (editable) => {
    try {
      let dblAvalableTons = 0;
      let gridData = getData(thisObj, 'gridData')

      setMbFormDirty(false)
      setData(thisObj,'MbDirty',false)
      setMbFormError(false)
      setData(thisObj,'MbError',false)
      let formError = false;
      if (editable == false) return;

      dblAvalableTons = Number(getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolAllocTons', 0)).toFixed(4);

      for (let i = 1; i < gridData.length; i++) {

        let buyPtId = getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolPt', i)
        let C_ALLOCATED = Number(getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolAllocTons', i));
        let C_CONTRACTED = Number(getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolContrctedTons', i)).toFixed(4);
        let C_HID_ALLOCATED = Number(getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolHideAlloc', i)).toFixed(4);
        setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolOpenTons', i, (C_ALLOCATED - C_CONTRACTED).toFixed(4))
        dblAvalableTons = Number(dblAvalableTons - C_ALLOCATED).toFixed(4)

        let response = await bfillAccess('PN0260', '002', 'U', buyPtId)
        if (response == 'Y') {
          setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'AQUA')
        }
        let columnColor = getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i)
        if ((columnColor == 'AQUA') && C_ALLOCATED != C_HID_ALLOCATED) {
          setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'YELLOW')
          setMbFormDirty(true);
          setData(thisObj,'MbDirty',true)
        }
      }

      dblAvalableTons = Number(dblAvalableTons).toFixed(4);
      setValue(thisObj, 'txtAvailTons', dblAvalableTons)

      for (let i = 1; i < gridData.length; i++) {
        let columnColor = getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i)
        let C_ALLOCATED = Number(getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolAllocTons', i)).toFixed(4);
        let C_CONTRACTED = Number(getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolContrctedTons', i)).toFixed(4);
        let C_HID_ASSIGNED = Number(getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolHideAssign', i)).toFixed(4);

        if (columnColor == "AQUA" || columnColor == 'YELLOW') {
          if (isNaN(C_ALLOCATED) == true) {
            showMessage(thisObj, 'Contract Assignment must be numeric!')
            setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'RED')
            setMbFormError(true);
            setData(thisObj,'MbError',true)
            formError = true
            return;
          }

          if (Number(C_ALLOCATED) < 0) {
            showMessage(thisObj, 'Contract Assignment cannot be negitive!')
            setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'RED')
            setMbFormError(true);
            setData(thisObj,'MbError',true)
            formError = true;
            return;

          }

          if (Number(C_ALLOCATED) >= 100000000) {
            showMessage(thisObj, 'Contract Assignment too large for database field. Contact support to increase the field size in the database!')
            setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'RED')
            setMbFormError(true);
            setData(thisObj,'MbError',true)
            formError = true;
            return;

          }

          if (Number(C_ALLOCATED) < Number(C_CONTRACTED)) {
            showMessage(thisObj, 'Contract Assignment cannot be less than total tons contracted!')
            setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'RED')
            setMbFormError(true);
            setData(thisObj,'MbError',true)
            formError = true;
            return;
          }

          if (Number(C_ALLOCATED) < Number(C_HID_ASSIGNED)) {
            showMessage(thisObj, 'Contract Assignment cannot be less than total assigned to the Buying Points!')
            setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'RED')
            setMbFormError(true);
            setData(thisObj,'MbError',true)
            formError = true;
            return;
          }

          if (Number(dblAvalableTons) < 0) {
            if (formError == false) {
              showMessage(thisObj, 'Total Contract Assignment cannot exceed the avalable limit from the Area!')
              setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolColor', i, 'RED')
              setMbFormError(true);
              setData(thisObj,'MbError',true)
              formError = true;
              return;
            }
          }
        }
      } //End of for loop

    } // End of TRY block
    catch (err) {
      if (err instanceof EvalError) {
      } else {
        showMessage(
          thisObj,
          "Error occurred during in After_edit function Method"
        );
      }
      return false;
    }
  }

  //Save button click event
  let onbtnSaveClick = async () => {
    setLoading(true)
    if (mbFormError) {
      showMessage(thisObj, "Please correct data errors before Continuing!")
      setLoading(false);
      return;
    }
    if (await bSaveForm()) {
      let lstrRecordKey = getData(thisObj, 'lstrRecordKey')
      await bfillForm(lstrRecordKey)
    }
    setLoading(false)
    return;
  }
  thisObj.onbtnSaveClick = onbtnSaveClick

  //Export to excel button
  let onbtnExportClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridContrctLimit")

      let gridLength = excelData.length;
      let fileName = "Contract Price limit coll Pt Details"
      let excelArray = [];
      // "colIsSts,txtcolArea,txtcolPt,btncol1,txtcolhideShow,txtcolAllocTons ,txtcolContrctedTons 6,
      // txtcolOpenTons,txtcolContrctIrrAcre,txtcolContrctDryLand,txtcolPricingTyp,txtcolDesc,txtcolPnutTyp,
      // txtcolVariety,txtcolSeg,txtcolOleic,txtcolPriceTon,txtcolMrktFloor,txtcolMrketCeil,txtcolRebateRate,
      // txtcolMarketGain,txtcolLoanRepay,txtcolMultiPricings,txtcolMarketWeeksPrioruic25,txtcolOptPriceCap,
      // txtcolPayRebate,txtcolPriceMtchEnd,txtcolHideRecKey,txtcolHideAlloc,txtcolHideAssign",
      for (let i = 0; i < gridLength; i++) {
        let obj = {};
        if (thisObj.state.gridContrctLimit.columns[1].Visible) {
          obj['Area'] = excelData[i].txtcolArea
        }

        if (thisObj.state.gridContrctLimit.columns[2].Visible) {
          obj['CollPt'] = excelData[i].txtcolPt
        }

        if (thisObj.state.gridContrctLimit.columns[5].Visible) {
          obj['Allocated Tons'] = excelData[i].txtcolAllocTons
        }
        if (thisObj.state.gridContrctLimit.columns[7].Visible) {
          obj['Contracted Tons'] = excelData[i].txtcolContrctedTons
        }
        if (thisObj.state.gridContrctLimit.columns[8].Visible) {
          obj['Open Tons'] = excelData[i].txtcolOpenTons
        }
        if (thisObj.state.gridContrctLimit.columns[9].Visible) {
          obj['Contracted Irrigated Acres'] = excelData[i].txtcolContrctIrrAcre
        }
        if (thisObj.state.gridContrctLimit.columns[10].Visible) {
          obj['Contracted Dry Land Acers'] = excelData[i].txtcolContrctDryLand
        }
        if (thisObj.state.gridContrctLimit.columns[11].Visible) {
          obj['Pricing Type'] = excelData[i].txtcolPricingTyp
        }
        if (thisObj.state.gridContrctLimit.columns[12].Visible) {
          obj['Desc'] = excelData[i].txtcolDesc
        }
        if (thisObj.state.gridContrctLimit.columns[13].Visible) {
          obj['Peanut Type'] = excelData[i].txtcolPnutTyp
        }
        if (thisObj.state.gridContrctLimit.columns[14].Visible) {
          obj['Variety'] = excelData[i].txtcolVariety
        }
        if (thisObj.state.gridContrctLimit.columns[15].Visible) {
          obj['Seg'] = excelData[i].txtcolSeg
        }
        if (thisObj.state.gridContrctLimit.columns[16].Visible) {
          obj['Oleic'] = excelData[i].txtcolOleic
        }
        if (thisObj.state.gridContrctLimit.columns[17].Visible) {
          obj['Price/Ton'] = excelData[i].txtcolPriceTon
        }
        if (thisObj.state.gridContrctLimit.columns[18].Visible) {
          obj['Market Floor'] = excelData[i].txtcolMrktFloor
        }
        if (thisObj.state.gridContrctLimit.columns[19].Visible) {
          obj['Market Ceiling'] = excelData[i].txtcolMrketCeil
        }
        if (thisObj.state.gridContrctLimit.columns[20].Visible) {
          obj['Rebate Rate'] = excelData[i].txtcolRebateRate
        }
        if (thisObj.state.gridContrctLimit.columns[21].Visible) {
          obj['Market Share Gain%'] = excelData[i].txtcolMarketGain
        }
        if (thisObj.state.gridContrctLimit.columns[22].Visible) {
          obj['Loan Repay Method'] = excelData[i].txtcolLoanRepay
        }
        if (thisObj.state.gridContrctLimit.columns[23].Visible) {
          obj['Multiple Pricings'] = excelData[i].txtcolMultiPricings
        }
        if (thisObj.state.gridContrctLimit.columns[24].Visible) {
          obj['Market Weeks Prior to Maturity'] = excelData[i].txtcolMarketWeeksPrior
        }
        if (thisObj.state.gridContrctLimit.columns[25].Visible) {
          obj['Option Price Cap'] = excelData[i].txtcolOptPriceCap
        }
        if (thisObj.state.gridContrctLimit.columns[27].Visible) {
          obj['Pay Rebate At'] = excelData[i].txtcolPayRebate
        }
        if (thisObj.state.gridContrctLimit.columns[27].Visible) {
          obj['Price Match End Date'] = excelData[i].txtcolPriceMtchEnd
        }
        if (thisObj.state.gridContrctLimit.columns[31].Visible) {
          obj['Lock In Date1'] = excelData[i].txtcolLockInDate1
        }
        if (thisObj.state.gridContrctLimit.columns[32].Visible) {
          obj['Lock In Date2'] = excelData[i].txtcolLockInDate2
        }
        if (thisObj.state.gridContrctLimit.columns[33].Visible) {
          obj['Lock In Date3'] = excelData[i].txtcolLockInDate3
        }
        if (thisObj.state.gridContrctLimit.columns[34].Visible) {
          obj['Lock In Date4'] = excelData[i].txtcolLockInDate4
        }
        if (thisObj.state.gridContrctLimit.columns[35].Visible) {
          obj['Lock In Date5'] = excelData[i].txtcolLockInDate5
        }
        if (thisObj.state.gridContrctLimit.columns[36].Visible) {
          obj['Lock In Date6'] = excelData[i].txtcolLockInDate6
        }
        if (thisObj.state.gridContrctLimit.columns[37].Visible) {
          obj['Lock In Date7'] = excelData[i].txtcolLockInDate7
        }
        if (thisObj.state.gridContrctLimit.columns[38].Visible) {
          obj['Lock In Date8'] = excelData[i].txtcolLockInDate8
        }
        if (thisObj.state.gridContrctLimit.columns[39].Visible) {
          obj['Lock In Date9'] = excelData[i].txtcolLockInDate9
        }
        if (thisObj.state.gridContrctLimit.columns[40].Visible) {
          obj['Lock In Date10'] = excelData[i].txtcolLockInDate10
        }
        excelArray.push(obj);
      }
      const ws = XLSX.utils.json_to_sheet(excelArray)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on excel to export button")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportClick = onbtnExportClick

  //Print Button Click function
  let onbtnPrintGridClick = () => {
    try {
      var Griddata = getValue(thisObj, "gridContrctLimit")
      var res = []
      var headerArray = []
      if (thisObj.state.gridContrctLimit.columns[1].Visible) {
        headerArray.push('Area')
      }

      if (thisObj.state.gridContrctLimit.columns[2].Visible) {
        headerArray.push('CollPt')
      }

      if (thisObj.state.gridContrctLimit.columns[3].Visible) {
        headerArray.push("")
      }

      if (thisObj.state.gridContrctLimit.columns[5].Visible) {
        headerArray.push('Allocated Tons')
      }
      if (thisObj.state.gridContrctLimit.columns[7].Visible) {
        headerArray.push('Contracted Tons')
      }
      if (thisObj.state.gridContrctLimit.columns[8].Visible) {
        headerArray.push('Open Tons')
      }
      if (thisObj.state.gridContrctLimit.columns[9].Visible) {
        headerArray.push('Contracted Irrigated Acres')
      }
      if (thisObj.state.gridContrctLimit.columns[10].Visible) {
        headerArray.push('Contracted Dry Land Acers')
      }
      if (thisObj.state.gridContrctLimit.columns[11].Visible) {
        headerArray.push('Pricing Type')
      }
      if (thisObj.state.gridContrctLimit.columns[12].Visible) {
        headerArray.push('Desc')
      }
      if (thisObj.state.gridContrctLimit.columns[13].Visible) {
        headerArray.push('Peanut Type')
      }
      if (thisObj.state.gridContrctLimit.columns[14].Visible) {
        headerArray.push('Variety')
      }
      if (thisObj.state.gridContrctLimit.columns[15].Visible) {
        headerArray.push('Seg')
      }
      if (thisObj.state.gridContrctLimit.columns[16].Visible) {
        headerArray.push('Oleic')
      }
      if (thisObj.state.gridContrctLimit.columns[17].Visible) {
        headerArray.push('Price/Ton')
      }
      if (thisObj.state.gridContrctLimit.columns[18].Visible) {
        headerArray.push('Market Floor')
      }
      if (thisObj.state.gridContrctLimit.columns[19].Visible) {
        headerArray.push('Market Ceiling')
      }
      if (thisObj.state.gridContrctLimit.columns[20].Visible) {
        headerArray.push('Rebate Rate')
      }
      if (thisObj.state.gridContrctLimit.columns[21].Visible) {
        headerArray.push('Market Share Gain%')
      }
      if (thisObj.state.gridContrctLimit.columns[22].Visible) {
        headerArray.push('Loan Repay Method')
      }
      if (thisObj.state.gridContrctLimit.columns[23].Visible) {
        headerArray.push('Multiple Pricings')
      }
      if (thisObj.state.gridContrctLimit.columns[24].Visible) {
        headerArray.push('Market Weeks Prior to Maturity')
      }
      if (thisObj.state.gridContrctLimit.columns[25].Visible) {
        headerArray.push('Option Price Cap')
      }
      if (thisObj.state.gridContrctLimit.columns[26].Visible) {
        headerArray.push('Pay Rebate At')
      }
      if (thisObj.state.gridContrctLimit.columns[27].Visible) {
        headerArray.push('Price Match End Date')
      }
      if (thisObj.state.gridContrctLimit.columns[31].Visible) {
        headerArray.push('Lock In Date1') 
      }
      if (thisObj.state.gridContrctLimit.columns[32].Visible) {
        headerArray.push('Lock In Date2')
      }
      if (thisObj.state.gridContrctLimit.columns[33].Visible) {
        headerArray.push('Lock In Date3')
      }
      if (thisObj.state.gridContrctLimit.columns[34].Visible) {
        headerArray.push('Lock In Date4')
      }
      if (thisObj.state.gridContrctLimit.columns[35].Visible) {
        headerArray.push('Lock In Date5')
      }
      if (thisObj.state.gridContrctLimit.columns[36].Visible) {
        headerArray.push('Lock In Date6')
      }
      if (thisObj.state.gridContrctLimit.columns[37].Visible) {
        headerArray.push('Lock In Date7')
      }
      if (thisObj.state.gridContrctLimit.columns[38].Visible) {
        headerArray.push('Lock In Date8')
      }
      if (thisObj.state.gridContrctLimit.columns[39].Visible) {
        headerArray.push('Lock In Date9')
      }
      if (thisObj.state.gridContrctLimit.columns[40].Visible) {
        headerArray.push('Lock In Date10')
      }

      for (let i = 0; i < Griddata.length; i++) {
        let finalPrintArray = [];
        if (thisObj.state.gridContrctLimit.columns[1].Visible) {
          finalPrintArray.push(Griddata[i].txtcolArea)
        }

        if (thisObj.state.gridContrctLimit.columns[2].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPt)
        }

        if (thisObj.state.gridContrctLimit.columns[3].Visible) {
          finalPrintArray.push(Griddata[i].btncol1)
        }

        if (thisObj.state.gridContrctLimit.columns[5].Visible) {
          finalPrintArray.push(Griddata[i].txtcolAllocTons)
        }
        if (thisObj.state.gridContrctLimit.columns[7].Visible) {
          finalPrintArray.push(Griddata[i].txtcolContrctedTons)
        }
        if (thisObj.state.gridContrctLimit.columns[8].Visible) {
          finalPrintArray.push(Griddata[i].txtcolOpenTons)
        }
        if (thisObj.state.gridContrctLimit.columns[9].Visible) {
          finalPrintArray.push(Griddata[i].txtcolContrctIrrAcre)
        }
        if (thisObj.state.gridContrctLimit.columns[10].Visible) {
          finalPrintArray.push(Griddata[i].txtcolContrctDryLand)
        }
        if (thisObj.state.gridContrctLimit.columns[11].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPricingTyp)
        }
        if (thisObj.state.gridContrctLimit.columns[12].Visible) {
          finalPrintArray.push(Griddata[i].txtcolDesc)
        }
        if (thisObj.state.gridContrctLimit.columns[13].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPnutTyp)
        }
        if (thisObj.state.gridContrctLimit.columns[14].Visible) {
          finalPrintArray.push(Griddata[i].txtcolVariety)
        }
        if (thisObj.state.gridContrctLimit.columns[15].Visible) {
          finalPrintArray.push(Griddata[i].txtcolSeg)
        }
        if (thisObj.state.gridContrctLimit.columns[16].Visible) {
          finalPrintArray.push(Griddata[i].txtcolOleic)
        }
        if (thisObj.state.gridContrctLimit.columns[17].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPriceTon)
        }
        if (thisObj.state.gridContrctLimit.columns[18].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMrktFloor)
        }
        if (thisObj.state.gridContrctLimit.columns[19].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMrketCeil)
        }
        if (thisObj.state.gridContrctLimit.columns[20].Visible) {
          finalPrintArray.push(Griddata[i].txtcolRebateRate)
        }
        if (thisObj.state.gridContrctLimit.columns[21].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMarketGain)
        }
        if (thisObj.state.gridContrctLimit.columns[22].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLoanRepay)
        }
        if (thisObj.state.gridContrctLimit.columns[23].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMultiPricings)
        }
        if (thisObj.state.gridContrctLimit.columns[24].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMarketWeeksPrior)
        }
        if (thisObj.state.gridContrctLimit.columns[25].Visible) {
          finalPrintArray.push(Griddata[i].txtcolOptPriceCap)
        }
        if (thisObj.state.gridContrctLimit.columns[26].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPayRebate)
        }
        if (thisObj.state.gridContrctLimit.columns[27].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPriceMtchEnd)
        }
        if (thisObj.state.gridContrctLimit.columns[31].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate1) 
        }
        if (thisObj.state.gridContrctLimit.columns[32].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate2)
        }
        if (thisObj.state.gridContrctLimit.columns[33].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate3)
        }
        if (thisObj.state.gridContrctLimit.columns[34].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate4)
        }
        if (thisObj.state.gridContrctLimit.columns[35].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate5)
        }
        if (thisObj.state.gridContrctLimit.columns[36].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate6)
        }
        if (thisObj.state.gridContrctLimit.columns[37].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate7)
        }
        if (thisObj.state.gridContrctLimit.columns[38].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate8)
        }
        if (thisObj.state.gridContrctLimit.columns[39].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate9)
        }
        if (thisObj.state.gridContrctLimit.columns[40].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate10)
        }
  
        res.push(finalPrintArray)
      }

      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });

      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
        obj.text("Golden Peanut Company, LLC - "+cropYear + ' - Contract Price Control', 2, obj.internal.pageSize.height - 2);
      }

      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:FarmInquiry.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:FarmInquiry.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true
  }
  thisObj.onbtnPrintGridClick = onbtnPrintGridClick

  //Close button Click function
  let onbtnExitClick = async () => {
    try {
      if (mbFormDirty == true || mbFormError == true) {
        let response = confirm('There are changes on the grid that have not yet been saved! Do you want save these changes?')
        if (response == true) {
          if (mbFormError == true) {
            showMessage(thisObj, 'Please correct data errors before Continuing!')
            return;
          }
          else {

            if (await bSaveForm() == false) return;
          }
        }
      }
      setData(thisObj, "closingOfContractLimitCollPt", true)
      goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitSearch#DEFAULT#false#Click");
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in Exit button"
        );
      }
      return false;
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick

  //Grid Button Click function
  let onbtncol1Click = async (event) => {
    let rowSelected = event.target.parentElement.parentElement.parentElement
    let rowID = rowSelected.id.substring(4, 5);
    let valueGrid = getValue(thisObj, "gridContrctLimit")[rowID]
    let mbError=getData(thisObj,'MbError')
    let mbDirty=getData(thisObj,'MbDirty')
    if (mbError || mbDirty) {
      let response = confirm("There are changes on the grid that have not yet been saved! Do you want save these changes?")
      if (response == true) {
        if (mbFormError == true) {
          showMessage(thisObj, "Please correct data errors before Continuing!")
          return;
        }
        else {
          if (await bSaveForm() == false) return;
        }
      }
    }

    

    let frmContractLimitBuyPt = {};
    //frmContractLimitBuyPt.recordKey = valueGrid.txtcolHideRecKey;
    let RecKeydata = getData(thisObj,'collPtRecKey');
    for(let i = 0; i < RecKeydata.length; i++){
      if(RecKeydata[i].key == valueGrid.txtcolPt){
        frmContractLimitBuyPt.recordKey = RecKeydata[i].value;
      }
    }
    setData(thisObj, 'ContractLimitSearchDataForBuyingPoint', frmContractLimitBuyPt)
    goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitBuyPt#DEFAULT#true#Click"); //Need navigation from VEDA
  }
  thisObj.onbtncol1Click = onbtncol1Click

  let ontxtcolAllocTonsBlur = async (event) => {
    setLoading(true)
    let nameEvent = event.target.name
    let arr = Number(nameEvent.split(".")[1])
    let newValue = getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolAllocTons', arr)
    newValue = Number(newValue).toFixed(4)
    setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolAllocTons', arr, newValue)

    _kaledo.thisObj.setState({
      ...thisObj.state,
      gridContrctLimit: {
        ...state.gridContrctLimit,
        toggleRender: !state.gridContrctLimit.toggleRender
      }
    })
    PopulateGrid(false)
    await vsfResults_AfterEdit();
    setLoading(false)
  }
  thisObj.ontxtcolAllocTonsBlur = ontxtcolAllocTonsBlur

  let bSaveForm = async () => {
    try {
      let bSaveForm = false
      let TransformedRowsArray = getData(thisObj, 'gridData')
      let ArrayLength = TransformedRowsArray.length
      let lstrRecordKey = getData(thisObj, 'lstrRecordKey')

      for (let i = 1; i < ArrayLength; i++) {
        let C_ALLOCATED = getGridValue(thisObj, 'gridContrctLimit', 'txtcolAllocTons', i)
        let C_HID_ALLOCATED = getGridValue(thisObj, 'gridContrctLimit', 'txtcolHideAlloc', i)
        let C_HID_REC_KEY = getGridValue(thisObj, 'gridContrctLimit', 'txtcolHideRecKey', i)
        let coll_pt_id = getGridValue(thisObj, 'gridContrctLimit', 'txtcolPt', i)
        if (Number(C_ALLOCATED) != Number(C_HID_ALLOCATED)) {
          let modify_tag;
          if (C_HID_REC_KEY == '') {
            modify_tag = 'ADD'
          }
          else {
            modify_tag = 'UPDATE'
          }
          let record_key = C_HID_REC_KEY;
          let parent_key = lstrRecordKey
          let area_id = getValue(thisObj, 'txtArea').slice(0, 2)
          let coll_pt = coll_pt_id
          let pricing_type = getValue(thisObj, 'txtPrcngTyp')

          switch (pricing_type) {
            case 'Basis Contract':
              pricing_type='C-BASIS'
              break;
            case 'Firm Contract':
              pricing_type= 'C-FIRM'
              break;
            case 'Option Pricings':
              pricing_type= 'OPTION'
              break;
            case 'Flex Market Pricings':
              pricing_type= 'FLEX MKT'
              break;
            case 'Seed Pricings':
              pricing_type= 'SEED'
              break;
            case 'Floor Pricings':
              pricing_type= 'FLOOR'
              break;
          }

          let seg_type = getValue(thisObj, 'txtSeg')
          let organic_ind = lblOrganic
          let pnut_type_id = getValue(thisObj, 'txtPnutTyp').slice(0, 2)
          let pnut_variety_id = "";
          if (getValue(thisObj, 'txtVrty') != ">>> Any Variety <<<") {
            pnut_variety_id = getValue(thisObj, 'txtVrty').slice(0, 2)
          }
          let oleic_ind = "";
          if (getValue(thisObj, 'txtOleic') != '>>Any<<') {
            oleic_ind = getValue(thisObj, 'txtOleic')
          }
          let price_per_ton = getValue(thisObj, 'txtPriceTon')
          let market_floor = getValue(thisObj, 'txtMrktFloor')
          let market_ceiling = getValue(thisObj, 'txtMrktCeiling')
          let rebate_rate = getValue(thisObj, 'txtRebtRate')
          let mkt_gain_share = getValue(thisObj, 'txtMrktGain')
          let pricing_type_desc = getValue(thisObj, 'txtDesc')
          let loan_repay_ind = getValue(thisObj, 'txtLoanRepay')
          let mkt_wk_prior_mat = getValue(thisObj, 'txtMrktWeeksPriorMatrty')
          let multi_pricing_ind = getValue(thisObj, 'txtMultiPricings')
          let max_option_price = getValue(thisObj, 'txtOptPrcCap')
          let pay_rebate_at = getValue(thisObj, 'txtPayRebt')
          let price_match_end_date = getValue(thisObj, 'txtPriceMtchEnd')
          let tons_allocated = C_ALLOCATED;
          let dataObj = {
            "parent_key": Number(parent_key),
            "control_level": 'CP',
            "area_id": area_id,
            'coll_pt_id': coll_pt,
            "buy_pt_id": "",
            "vendor_num": "",
            "contract_num": 0,
            "pricing_type": pricing_type,
            "seg_type": seg_type,
            "organic_ind": organic_ind,
            "pnut_type_id": pnut_type_id,
            "pnut_variety_id": pnut_variety_id,
            "oleic_ind": oleic_ind,
            "price_per_ton": (price_per_ton),
            "market_floor": (market_floor),
            "market_ceiling": (market_ceiling),
            "rebate_rate": (rebate_rate),
            "mkt_gain_share": (mkt_gain_share),
            "pricing_type_desc": pricing_type_desc,
            "loan_repay_ind": loan_repay_ind,
            "multi_pricing_ind": multi_pricing_ind,
            "mkt_wk_prior_mat": (mkt_wk_prior_mat),
            "pay_rebate_at": (pay_rebate_at),
            "tons_allocated": (tons_allocated),
            "price_match_end_date": price_match_end_date,
            "max_option_price": max_option_price,
            "user_id": useridFromLS(),
            "lock_in_cnt": 0,
            "lock_in_data": [
              {
                "lock_in_key": 0,
                "lock_in_date": "",
                "lock_in_pct": 0
              }
            ]
          }

          let response = await SystemMaintenanceMasterManagementService.UpdateContractPriceLimit(modify_tag, record_key, dataObj)
          if (response != undefined && response != '' && response != null) {
            if (response.result != '') {
              setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolHideRecKey', i, response.result)
              let allocated_ColumnValue = getGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolHideAlloc', i)
              setGridValue(_kaledo.thisObj, 'gridContrctLimit', 'txtcolHideAlloc', i, allocated_ColumnValue)
            }
            else {
              setMbFormError(true);
              setData(thisObj,'MbError',true)
            }
          }
          else {
            setMbFormError(true)
            setData(thisObj,'MbError',true)
          }
        }
      }
      if (mbFormError) {
        showMessage(thisObj, "Errors occurred during update!")
        bSaveForm = false
      }
      else {
        showMessage(thisObj, "Contract Assignment Limits have been updated.",true)
        bSaveForm = true
      }
      return bSaveForm;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in Save Function"
        );
      }
      return false;
    }
  }


  let PopulateGrid = (hideShow) => {

    hideColumn(thisObj, 'gridContrctLimit', 'txtcolPricingTyp')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolDesc')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolPnutTyp')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolVariety')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolSeg')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolOleic')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolPriceTon')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolMrktFloor')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolMrketCeil')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolRebateRate')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolMarketGain')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolLoanRepay')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolMultiPricings')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolMarketWeeksPrioruic25')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolOptPriceCap')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolPayRebate')
    hideColumn(thisObj, 'gridContrctLimit', 'txtcolPriceMtchEnd')

    for (let i = 1; i <= 10; i++) {
      hideColumn(thisObj, 'gridContrctLimit', `txtcolLockInDate${i}`)
    }

    if (hideShow) {
      let PriceType = getValue(thisObj, 'txtcolPricingTyp')
      if (PriceType != "") {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolPricingTyp')
      }
      let description = getValue(thisObj, 'txtcolDesc')
      if (description != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolDesc')
      }
      let PeanutType = getValue(thisObj, 'txtcolPnutTyp')
      if (PeanutType != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolPnutTyp')
      }

      let Variety = getValue(thisObj, 'txtcolVariety')
      if (Variety != '>>> Any Variety <<<') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolVariety')
      }

      let seg = getValue(thisObj, 'txtcolSeg')
      if (seg != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolSeg')
      }

      let Oleic = getValue(thisObj, 'txtcolOleic')
      if (Oleic != '>>Any<<') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolOleic')
      }

      let PriceTon = getValue(thisObj, 'txtcolPriceTon')
      if (PriceTon != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolPriceTon')
      }

      let txtMrkt = getValue(thisObj, 'txtcolMrktFloor')
      if (txtMrkt != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolMrktFloor')
      }

      let MrktCeilling = getValue(thisObj, 'txtcolMrketCeil')
      if (MrktCeilling != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolMrketCeil')
      }

      let RebtRate = getValue(thisObj, 'txtcolRebateRate')
      if (RebtRate != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolRebateRate')
      }

      let MrktGain = getValue(thisObj, 'txtcolMarketGain')
      if (MrktGain != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolMarketGain')
      }

      let LoanRepan = getValue(thisObj, 'txtcolLoanRepay')
      if (LoanRepan != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolLoanRepay')
      }

      let MultiPricing = getValue(thisObj, 'txtcolMultiPricings')
      if (MultiPricing != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolMultiPricings')
      }

      let MrktWeeks = getValue(thisObj, 'txtcolMarketWeeksPrioruic25')
      if (MrktWeeks != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolMarketWeeksPrioruic25')
      }

      let OptPrc = getValue(thisObj, 'txtcolOptPriceCap')
      if (OptPrc != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolOptPriceCap')
      }

      let PayRebt = getValue(thisObj, 'txtcolPayRebate')
      if (PayRebt != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolPayRebate')
      }

      let PriceMtch = getValue(thisObj, 'txtcolPriceMtchEnd')
      if (PriceMtch != '') {
        showColumn(thisObj, 'gridContrctLimit', 'txtcolPriceMtchEnd')
      }
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractLimitCollPt*/}

              {/* END_USER_CODE-USER_BEFORE_ContractLimitCollPt*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCntrctLimit*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCntrctLimit*/}

              <GroupBoxWidget conf={state.grpbxCntrctLimit} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtPrcngTyp*/}

                {/* END_USER_CODE-USER_BEFORE_txtPrcngTyp*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPrcngTyp}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPrcngTyp*/}

                {/* END_USER_CODE-USER_AFTER_txtPrcngTyp*/}
                {/* START_USER_CODE-USER_BEFORE_txtDesc*/}

                {/* END_USER_CODE-USER_BEFORE_txtDesc*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDesc}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDesc*/}

                {/* END_USER_CODE-USER_AFTER_txtDesc*/}
                {/* START_USER_CODE-USER_BEFORE_txtArea*/}

                {/* END_USER_CODE-USER_BEFORE_txtArea*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtArea}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtArea*/}

                {/* END_USER_CODE-USER_AFTER_txtArea*/}
                {/* START_USER_CODE-USER_BEFORE_txtPnutTyp*/}

                {/* END_USER_CODE-USER_BEFORE_txtPnutTyp*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPnutTyp}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPnutTyp*/}

                {/* END_USER_CODE-USER_AFTER_txtPnutTyp*/}
                {/* START_USER_CODE-USER_BEFORE_txtVrty*/}

                {/* END_USER_CODE-USER_BEFORE_txtVrty*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVrty}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVrty*/}

                {/* END_USER_CODE-USER_AFTER_txtVrty*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeg*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeg*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeg*/}

                {/* END_USER_CODE-USER_AFTER_txtSeg*/}
                {/* START_USER_CODE-USER_BEFORE_txtOleic*/}

                {/* END_USER_CODE-USER_BEFORE_txtOleic*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOleic}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOleic*/}

                {/* END_USER_CODE-USER_AFTER_txtOleic*/}
                {/* START_USER_CODE-USER_BEFORE_txtPriceTon*/}

                {/* END_USER_CODE-USER_BEFORE_txtPriceTon*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPriceTon}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPriceTon*/}

                {/* END_USER_CODE-USER_AFTER_txtPriceTon*/}
                {/* START_USER_CODE-USER_BEFORE_txtPayRebt*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayRebt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayRebt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayRebt*/}

                {/* END_USER_CODE-USER_AFTER_txtPayRebt*/}
                {/* START_USER_CODE-USER_BEFORE_txtOptPrcCap*/}

                {/* END_USER_CODE-USER_BEFORE_txtOptPrcCap*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOptPrcCap}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOptPrcCap*/}

                {/* END_USER_CODE-USER_AFTER_txtOptPrcCap*/}
                {/* START_USER_CODE-USER_BEFORE_txtPriceMtchEnd*/}

                {/* END_USER_CODE-USER_BEFORE_txtPriceMtchEnd*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPriceMtchEnd}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPriceMtchEnd*/}

                {/* END_USER_CODE-USER_AFTER_txtPriceMtchEnd*/}
                {/* START_USER_CODE-USER_BEFORE_txtRebtRate*/}

                {/* END_USER_CODE-USER_BEFORE_txtRebtRate*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRebtRate}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRebtRate*/}

                {/* END_USER_CODE-USER_AFTER_txtRebtRate*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxMarket*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxMarket*/}

                <GroupBoxWidget conf={state.grpbxMarket} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtMrktFloor*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktFloor*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktFloor}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktFloor*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktFloor*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMrktCeiling*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktCeiling*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktCeiling}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktCeiling*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktCeiling*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMrktGain*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktGain*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktGain}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktGain*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktGain*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMultiPricings*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMultiPricings*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMultiPricings}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMultiPricings*/}

                  {/* END_USER_CODE-USER_AFTER_txtMultiPricings*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanRepay*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanRepay*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanRepay}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanRepay*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanRepay*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMrktWeeksPriorMatrty*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktWeeksPriorMatrty*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktWeeksPriorMatrty}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktWeeksPriorMatrty*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktWeeksPriorMatrty*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxMarket*/}

                {/* END_USER_CODE-USER_AFTER_grpbxMarket*/}
                {/* START_USER_CODE-USER_BEFORE_lstboxLockIn*/}

                {/* END_USER_CODE-USER_BEFORE_lstboxLockIn*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstboxLockIn}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstboxLockIn*/}

                {/* END_USER_CODE-USER_AFTER_lstboxLockIn*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCntrctLimit*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCntrctLimit*/}
              {/* START_USER_CODE-USER_BEFORE_gridContrctLimit*/}

              {/* END_USER_CODE-USER_BEFORE_gridContrctLimit*/}

              <GridWidget
                conf={state.gridContrctLimit}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridContrctLimit}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                refObject={thisObj}
                key={state.gridContrctLimit.toggleRender}
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridContrctLimit*/}

              {/* END_USER_CODE-USER_AFTER_gridContrctLimit*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAvailTons*/}

                {/* END_USER_CODE-USER_BEFORE_txtAvailTons*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAvailTons}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAvailTons*/}

                {/* END_USER_CODE-USER_AFTER_txtAvailTons*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintGrid*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintGrid*/}

                <ButtonWidget
                  conf={state.btnPrintGrid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintGrid*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintGrid*/}
                {/* START_USER_CODE-USER_BEFORE_btnExport*/}

                {/* END_USER_CODE-USER_BEFORE_btnExport*/}

                <ButtonWidget
                  conf={state.btnExport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExport*/}

                {/* END_USER_CODE-USER_AFTER_btnExport*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ContractLimitCollPt*/}

              {/* END_USER_CODE-USER_AFTER_ContractLimitCollPt*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_ContractLimitCollPt
);