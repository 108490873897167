/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import jsPDF from 'jspdf';
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  ListboxWidget,
  CheckboxWidget,
  disable,
  enable,
  setValue,
  hide,
  show,
  getValue,
  hideColumn,
  showColumn,
  setData,
  getData,
  goTo,
  setGridValue,
  getGridValue,
  isEnabled,
  disableGridColumn
} from "../../shared/WindowImports";

import "./ContractLimitBuyPt.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import CommonContext from "../../Store/CommonContext";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_ContractLimitBuyPt(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const commonContext = useContext(CommonContext);
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractLimitBuyPt",
    windowName: "ContractLimitBuyPt",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.ContractLimitBuyPt",
    // START_USER_CODE-USER_ContractLimitBuyPt_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Contract Price Limit Buy Pt Details",
      scrCode: "PN0260D",
    },
    // END_USER_CODE-USER_ContractLimitBuyPt_PROPERTIES
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Exit",
      CharWidth: "11",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    btnExport: {
      name: "btnExport",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Export to Excel",
      CharWidth: "32",
      // START_USER_CODE-USER_btnExport_PROPERTIES

      // END_USER_CODE-USER_btnExport_PROPERTIES
    },
    btnPrintGrid: {
      name: "btnPrintGrid",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Grid",
      CharWidth: "23",
      // START_USER_CODE-USER_btnPrintGrid_PROPERTIES

      // END_USER_CODE-USER_btnPrintGrid_PROPERTIES
    },
    btnSave: {
      name: "btnSave",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save",
      CharWidth: "11",
      // START_USER_CODE-USER_btnSave_PROPERTIES

      // END_USER_CODE-USER_btnSave_PROPERTIES
    },
    colAllocTons: {
      name: "colAllocTons",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Allocated Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAllocTons_PROPERTIES

      // END_USER_CODE-USER_colAllocTons_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "BuyPt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colContrctDryLand: {
      name: "colContrctDryLand",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Contracted Dry Land Acres",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrctDryLand_PROPERTIES

      // END_USER_CODE-USER_colContrctDryLand_PROPERTIES
    },
    colContrctedTons: {
      name: "colContrctedTons",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Contracted Tond",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrctedTons_PROPERTIES

      // END_USER_CODE-USER_colContrctedTons_PROPERTIES
    },
    colContrctIrrAcre: {
      name: "colContrctIrrAcre",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Contracted Irrigated Acres",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContrctIrrAcre_PROPERTIES

      // END_USER_CODE-USER_colContrctIrrAcre_PROPERTIES
    },
    colDesc: {
      name: "colDesc",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Desc",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDesc_PROPERTIES

      // END_USER_CODE-USER_colDesc_PROPERTIES
    },
    colLoanRepay: {
      name: "colLoanRepay",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Loan Repay Method",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLoanRepay_PROPERTIES

      // END_USER_CODE-USER_colLoanRepay_PROPERTIES
    },
    colMarketGain: {
      name: "colMarketGain",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Market Gain Share %",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMarketGain_PROPERTIES

      // END_USER_CODE-USER_colMarketGain_PROPERTIES
    },
    colMarketWeeksPrior: {
      name: "colMarketWeeksPrior",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Market Weeks Prior to Maturity",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMarketWeeksPrior_PROPERTIES

      // END_USER_CODE-USER_colMarketWeeksPrior_PROPERTIES
    },
    colMrketCeil: {
      name: "colMrketCeil",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Market Ceiling",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMrketCeil_PROPERTIES

      // END_USER_CODE-USER_colMrketCeil_PROPERTIES
    },
    colMrktFloor: {
      name: "colMrktFloor",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Market Floor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMrktFloor_PROPERTIES

      // END_USER_CODE-USER_colMrktFloor_PROPERTIES
    },
    colMultiPricings: {
      name: "colMultiPricings",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Multiple Pricings",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMultiPricings_PROPERTIES

      // END_USER_CODE-USER_colMultiPricings_PROPERTIES
    },
    colOleic: {
      name: "colOleic",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Oleic",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOleic_PROPERTIES

      // END_USER_CODE-USER_colOleic_PROPERTIES
    },
    colOpenTons: {
      name: "colOpenTons",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Open Tons",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenTons_PROPERTIES

      // END_USER_CODE-USER_colOpenTons_PROPERTIES
    },
    colOptPriceCap: {
      name: "colOptPriceCap",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Option Price Cap",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOptPriceCap_PROPERTIES

      // END_USER_CODE-USER_colOptPriceCap_PROPERTIES
    },
    colPayRebate: {
      name: "colPayRebate",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Pay Rebate At",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPayRebate_PROPERTIES

      // END_USER_CODE-USER_colPayRebate_PROPERTIES
    },
    colPnutTyp: {
      name: "colPnutTyp",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Peanut Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPnutTyp_PROPERTIES

      // END_USER_CODE-USER_colPnutTyp_PROPERTIES
    },
    colPriceMtchEnd: {
      name: "colPriceMtchEnd",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Price Match End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceMtchEnd_PROPERTIES

      // END_USER_CODE-USER_colPriceMtchEnd_PROPERTIES
    },
    colPriceTon: {
      name: "colPriceTon",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Price/Ton",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceTon_PROPERTIES

      // END_USER_CODE-USER_colPriceTon_PROPERTIES
    },
    colPricingTyp: {
      name: "colPricingTyp",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Pricing Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPricingTyp_PROPERTIES

      // END_USER_CODE-USER_colPricingTyp_PROPERTIES
    },
    colPt: {
      name: "colPt",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "CollPt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPt_PROPERTIES

      // END_USER_CODE-USER_colPt_PROPERTIES
    },
    colRebateRate: {
      name: "colRebateRate",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Rebate Rate",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRebateRate_PROPERTIES

      // END_USER_CODE-USER_colRebateRate_PROPERTIES
    },
    colSeg: {
      name: "colSeg",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Seg",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeg_PROPERTIES

      // END_USER_CODE-USER_colSeg_PROPERTIES
    },
    colVariety: {
      name: "colVariety",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVariety_PROPERTIES

      // END_USER_CODE-USER_colVariety_PROPERTIES
    },
    colHideRecKey: {
      name: "colHideRecKey",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Hide_REC_KEY",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHideRecKey_PROPERTIES

      // END_USER_CODE-USER_colHideRecKey_PROPERTIES
    },
    colHideAlloc: {
      name: "colHideAlloc",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Hide_Allocated",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colHideAlloc_PROPERTIES

      // END_USER_CODE-USER_colHideAlloc_PROPERTIES
    },
    grpbxMarket: {
      name: "grpbxMarket",
      type: "GroupBoxWidget",
      parent: "grpbxCntrctLimit",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxMarket_PROPERTIES

      // END_USER_CODE-USER_grpbxMarket_PROPERTIES
    },
    lstLockIn: {
      name: "lstLockIn",
      type: "ListBoxFieldWidget",
      parent: "grpbxCntrctLimit",
      RowSpanForTabPotrait: "4",
      RowSpanForLargeDesktop: "4",
      Label: "Lock in:",
      RowSpanForTabLandscape: "4",
      DataProviderForListBox: "inline",
      RowSpan: "4",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstLockIn_PROPERTIES

      // END_USER_CODE-USER_lstLockIn_PROPERTIES
    },
    txtAvailTons: {
      name: "txtAvailTons",
      type: "TextBoxWidget",
      parent: "grpbxActions",
      Label: "Available Tons:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtAvailTons_PROPERTIES

      // END_USER_CODE-USER_txtAvailTons_PROPERTIES
    },
    txtcolAllocTons: {
      name: "txtcolAllocTons",
      type: "TextBoxWidget",
      colName: "colAllocTons",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAllocTons_PROPERTIES

      // END_USER_CODE-USER_txtcolAllocTons_PROPERTIES
    },
    txtcolLockInDate1: {
      name: "txtcolLockInDate1",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate2: {
      name: "txtcolLockInDate2",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate3: {
      name: "txtcolLockInDate3",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate4: {
      name: "txtcolLockInDate4",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate5: {
      name: "txtcolLockInDate5",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate6: {
      name: "txtcolLockInDate6",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    
    txtcolLockInDate7: {
      name: "txtcolLockInDate7",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate8: {
      name: "txtcolLockInDate8",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate9: {
      name: "txtcolLockInDate9",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    txtcolLockInDate10: {
      name: "txtcolLockInDate10",
      type: "TextBoxWidget",
      colName: "colLockInDate1",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLockInDate1_PROPERTIES

      // END_USER_CODE-USER_txtcolLockInDate1_PROPERTIES
    },
    
    colLockInDate1: {
      name: "colLockInDate1",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },

    colLockInDate2: {
      name: "colLockInDate2",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate3: {
      name: "colLockInDate3",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate4: {
      name: "colLockInDate4",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate5: {
      name: "colLockInDate5",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date5",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate6: {
      name: "colLockInDate6",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date6",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate7: {
      name: "colLockInDate7",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date7",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate8: {
      name: "colLockInDate8",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date8",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate9: {
      name: "colLockInDate9",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date9",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },
    colLockInDate10: {
      name: "colLockInDate10",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Lock In Date10",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLockInDate1_PROPERTIES

      // END_USER_CODE-USER_colLockInDate1_PROPERTIES
    },

    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolContrctDryLand: {
      name: "txtcolContrctDryLand",
      type: "TextBoxWidget",
      colName: "colContrctDryLand",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolContrctDryLand_PROPERTIES

      // END_USER_CODE-USER_txtcolContrctDryLand_PROPERTIES
    },
    txtcolContrctedTons: {
      name: "txtcolContrctedTons",
      type: "TextBoxWidget",
      colName: "colContrctedTons",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolContrctedTons_PROPERTIES

      // END_USER_CODE-USER_txtcolContrctedTons_PROPERTIES
    },
    txtcolContrctIrrAcre: {
      name: "txtcolContrctIrrAcre",
      type: "TextBoxWidget",
      colName: "colContrctIrrAcre",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolContrctIrrAcre_PROPERTIES

      // END_USER_CODE-USER_txtcolContrctIrrAcre_PROPERTIES
    },
    txtcolDesc: {
      name: "txtcolDesc",
      type: "TextBoxWidget",
      colName: "colDesc",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolDesc_PROPERTIES

      // END_USER_CODE-USER_txtcolDesc_PROPERTIES
    },
    txtcolLoanRepay: {
      name: "txtcolLoanRepay",
      type: "TextBoxWidget",
      colName: "colLoanRepay",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolLoanRepay_PROPERTIES

      // END_USER_CODE-USER_txtcolLoanRepay_PROPERTIES
    },
    txtCollPt: {
      name: "txtCollPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Collection Point:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtCollPt_PROPERTIES

      // END_USER_CODE-USER_txtCollPt_PROPERTIES
    },
    txtcolMarketGain: {
      name: "txtcolMarketGain",
      type: "TextBoxWidget",
      colName: "colMarketGain",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolMarketGain_PROPERTIES

      // END_USER_CODE-USER_txtcolMarketGain_PROPERTIES
    },
    txtcolMarketWeeksPrior: {
      name: "txtcolMarketWeeksPrior",
      type: "TextBoxWidget",
      colName: "colMarketWeeksPrior",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolMarketWeeksPrior_PROPERTIES

      // END_USER_CODE-USER_txtcolMarketWeeksPrior_PROPERTIES
    },
    txtcolMrketCeil: {
      name: "txtcolMrketCeil",
      type: "TextBoxWidget",
      colName: "colMrketCeil",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolMrketCeil_PROPERTIES

      // END_USER_CODE-USER_txtcolMrketCeil_PROPERTIES
    },
    txtcolMrktFloor: {
      name: "txtcolMrktFloor",
      type: "TextBoxWidget",
      colName: "colMrktFloor",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolMrktFloor_PROPERTIES

      // END_USER_CODE-USER_txtcolMrktFloor_PROPERTIES
    },
    txtcolMultiPricings: {
      name: "txtcolMultiPricings",
      type: "TextBoxWidget",
      colName: "colMultiPricings",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolMultiPricings_PROPERTIES

      // END_USER_CODE-USER_txtcolMultiPricings_PROPERTIES
    },
    txtcolOleic: {
      name: "txtcolOleic",
      type: "TextBoxWidget",
      colName: "colOleic",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolOleic_PROPERTIES

      // END_USER_CODE-USER_txtcolOleic_PROPERTIES
    },
    txtcolOpenTons: {
      name: "txtcolOpenTons",
      type: "TextBoxWidget",
      colName: "colOpenTons",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolOpenTons_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenTons_PROPERTIES
    },
    txtcolOptPriceCap: {
      name: "txtcolOptPriceCap",
      type: "TextBoxWidget",
      colName: "colOptPriceCap",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolOptPriceCap_PROPERTIES

      // END_USER_CODE-USER_txtcolOptPriceCap_PROPERTIES
    },
    txtcolPayRebate: {
      name: "txtcolPayRebate",
      type: "TextBoxWidget",
      colName: "colPayRebate",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPayRebate_PROPERTIES

      // END_USER_CODE-USER_txtcolPayRebate_PROPERTIES
    },
    txtcolPnutTyp: {
      name: "txtcolPnutTyp",
      type: "TextBoxWidget",
      colName: "colPnutTyp",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPnutTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolPnutTyp_PROPERTIES
    },
    txtcolPriceMtchEnd: {
      name: "txtcolPriceMtchEnd",
      type: "TextBoxWidget",
      colName: "colPriceMtchEnd",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPriceMtchEnd_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceMtchEnd_PROPERTIES
    },
    txtcolPriceTon: {
      name: "txtcolPriceTon",
      type: "TextBoxWidget",
      colName: "colPriceTon",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPriceTon_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceTon_PROPERTIES
    },
    txtcolPricingTyp: {
      name: "txtcolPricingTyp",
      type: "TextBoxWidget",
      colName: "colPricingTyp",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPricingTyp_PROPERTIES

      // END_USER_CODE-USER_txtcolPricingTyp_PROPERTIES
    },
    txtcolPt: {
      name: "txtcolPt",
      type: "TextBoxWidget",
      colName: "colPt",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolPt_PROPERTIES

      // END_USER_CODE-USER_txtcolPt_PROPERTIES
    },
    txtcolRebateRate: {
      name: "txtcolRebateRate",
      type: "TextBoxWidget",
      colName: "colRebateRate",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolRebateRate_PROPERTIES

      // END_USER_CODE-USER_txtcolRebateRate_PROPERTIES
    },
    txtcolSeg: {
      name: "txtcolSeg",
      type: "TextBoxWidget",
      colName: "colSeg",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolSeg_PROPERTIES

      // END_USER_CODE-USER_txtcolSeg_PROPERTIES
    },
    txtcolVariety: {
      name: "txtcolVariety",
      type: "TextBoxWidget",
      colName: "colVariety",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolVariety_PROPERTIES
    },
    txtcolColor: {
      name: "txtcolColor",
      type: "TextBoxWidget",
      colName: "colColor",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      ReadOnly: true,
      // START_USER_CODE-USER_txtcolhideShow_PROPERTIES

      // END_USER_CODE-USER_txtcolhideShow_PROPERTIES
    },
    colColor: {
      name: "Color",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      Label: "Color",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    txtcolHideRecKey: {
      name: "txtcolHideRecKey",
      type: "TextBoxWidget",
      colName: "colHideRecKey",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHideRecKey_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolHideRecKey_PROPERTIES
    },
    txtcolHideAlloc: {
      name: "txtcolHideAlloc",
      type: "TextBoxWidget",
      colName: "colHideAlloc",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolHideAlloc_PROPERTIES
      ReadOnly: true
      // END_USER_CODE-USER_txtcolHideAlloc_PROPERTIES
    },
    txtDescBuyPt: {
      name: "txtDescBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Desc:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDescBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtDescBuyPt_PROPERTIES
    },
    txtLoanRepayBuyPt: {
      name: "txtLoanRepayBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Loan Repay Method:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayBuyPt_PROPERTIES
    },
    txtMrktCeilingBuyPt: {
      name: "txtMrktCeilingBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market Ceiling:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktCeilingBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtMrktCeilingBuyPt_PROPERTIES
    },
    txtMrktFloorBuyPt: {
      name: "txtMrktFloorBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market Floor:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktFloorBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtMrktFloorBuyPt_PROPERTIES
    },
    txtMrktGainBuyPt: {
      name: "txtMrktGainBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market Gain Share %:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktGainBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtMrktGainBuyPt_PROPERTIES
    },
    txtMrktWeeksPriorMatrtyBuyPt: {
      name: "txtMrktWeeksPriorMatrtyBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Market weeks prior to Maturity:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMrktWeeksPriorMatrtyBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtMrktWeeksPriorMatrtyBuyPt_PROPERTIES
    },
    txtMultiPricingsBuyPt: {
      name: "txtMultiPricingsBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxMarket",
      Label: "Multiple Pricings:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMultiPricingsBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtMultiPricingsBuyPt_PROPERTIES
    },
    txtOleicBuyPt: {
      name: "txtOleicBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Oleic:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOleicBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtOleicBuyPt_PROPERTIES
    },
    txtOptPrcCapBuyPt: {
      name: "txtOptPrcCapBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Option Price Cap:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptPrcCapBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtOptPrcCapBuyPt_PROPERTIES
    },
    txtPayRebtBuyPt: {
      name: "txtPayRebtBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Pay Rebate at:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayRebtBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtPayRebtBuyPt_PROPERTIES
    },
    txtPnutTypBuyPt: {
      name: "txtPnutTypBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Peanut Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPnutTypBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtPnutTypBuyPt_PROPERTIES
    },
    txtPrcngTypBuyPt: {
      name: "txtPrcngTypBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Pricing Type:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrcngTypBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtPrcngTypBuyPt_PROPERTIES
    },
    txtPriceMtchEndBuyPt: {
      name: "txtPriceMtchEndBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Price Match End Date:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPriceMtchEndBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtPriceMtchEndBuyPt_PROPERTIES
    },
    txtPriceTonBuyPt: {
      name: "txtPriceTonBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Price/Ton:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPriceTonBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtPriceTonBuyPt_PROPERTIES
    },
    txtRebtRateBuyPt: {
      name: "txtRebtRateBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Rebate Rate:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRebtRateBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtRebtRateBuyPt_PROPERTIES
    },
    txtSegBuyPt: {
      name: "txtSegBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Seg:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSegBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtSegBuyPt_PROPERTIES
    },
    txtVrtyBuyPt: {
      name: "txtVrtyBuyPt",
      type: "TextBoxWidget",
      parent: "grpbxCntrctLimit",
      Label: "Variety:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVrtyBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtVrtyBuyPt_PROPERTIES
    },
    grpbxCntrctLimit: {
      name: "grpbxCntrctLimit",
      type: "GroupBoxWidget",
      parent: "ContractLimitBuyPt",
      Height: "",
      Width: "",
      clonedExtId: "41573",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxCntrctLimit_PROPERTIES

      // END_USER_CODE-USER_grpbxCntrctLimit_PROPERTIES
    },
    colIsSts: {
      name: "colIsSts",
      type: "TextBoxWidget",
      colName: "colSts",
      parent: "gridContrctLimitBuyPt",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colIsSts_PROPERTIES

      // END_USER_CODE-USER_colIsSts_PROPERTIES
    },
    colSts: {
      name: "colSts",
      type: "GridColumnWidget",
      parent: "gridContrctLimitBuyPt",
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSts_PROPERTIES
      // END_USER_CODE-USER_colSts_PROPERTIES
    },
    gridContrctLimitBuyPt: {
      name: "gridContrctLimitBuyPt",
      type: "GridWidget",
      parent: "ContractLimitBuyPt",
      gridCellsOrder:
        "colIsSts,txtcolPt,txtcolBuyPt,txtcolAllocTons,txtcolColor,txtcolContrctedTons,txtcolOpenTons,txtcolContrctIrrAcre,txtcolContrctDryLand,txtcolPricingTyp,txtcolDesc,txtcolPnutTyp,txtcolVariety,txtcolSeg,txtcolOleic,txtcolPriceTon,txtcolMrktFloor,txtcolMrketCeil,txtcolRebateRate,txtcolMarketGain,txtcolLoanRepay,txtcolMultiPricings,txtcolMarketWeeksPrior,txtcolOptPriceCap,txtcolPayRebate,txtcolPriceMtchEnd,txtcolHideRecKey,txtcolHideAlloc,txtcolLockInDate1,txtcolLockInDate2,txtcolLockInDate3,txtcolLockInDate4,txtcolLockInDate5,txtcolLockInDate6,txtcolLockInDate7,txtcolLockInDate8,txtcolLockInDate9,txtcolLockInDate10",
      Pagination: false,
      HasLabel: false,
      toggleRender: true,
      Height: "",
      Width: "",
      clonedExtId: "41523",
      isEditable: true,
      isCobolTable: true,
      // START_USER_CODE-USER_gridContrctLimitBuyPt_PROPERTIES

      // END_USER_CODE-USER_gridContrctLimitBuyPt_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ContractLimitBuyPt",
      Height: "",
      Width: "",
      clonedExtId: "41680",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    disable(thisObj, 'btnSave')
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(()=>{
    // hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolhideShow')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPricingTyp')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolDesc')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolColor')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolHideRecKey')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolHideAlloc')
    
  },[thisObj.state.gridContrctLimitBuyPt.toggleRender])

  useEffect(() => {
    return () => {
      crossButton()
      setData(thisObj, "closingOfContractLimitBuyPt", true)
      goTo(thisObj,"SystemMaintenanceMasterManagement#ContractLimitSearch#DEFAULT#false#Click"); 
    }
  },[])

  useEffect(() => { //useeffect for custom cross button Fixed As Part of Prod Issue Fix INC5626542 
    let crossClick = commonContext.getStockTransfer_crossClick
    if (crossClick == true) {
      onbtnExitClick()
      commonContext.setStockTransfer_crossClick(false)
    }
  }, [commonContext.getStockTransfer_crossClick])

  let crossButton=async()=>{
    let mbError=getData(thisObj,'MbError')
    let mbDirty=getData(thisObj,'MbDirty')
    if (mbDirty == true || mbError == true) {
      let response = confirm('There are changes on the grid that have not yet been saved! Do you want save these changes?')
      if (response == true) {
        if (mbError == true) {
          showMessage(thisObj, 'Please correct data errors before Continuing!')
          return;
        }
        else {
          if (await bSaveForm() == false) return;
        }
      }
    }
}

  
  const [isEditable, setIsEditable] = useState(false);
  const [lblPriceType, setLblPriceType] = useState('');
  const [loading, setLoading] = useState(false);
  const [lblOrganic, setLblOrganic] = useState('')
  const [lblarea_id,setLblArea_id] = useState('');
  const [mbFormDirty, setMbFormDirty] = useState();
  const [mbFormError, setMbFormError] = useState();
  const [gridEditable, setGridEditable] = useState(false)
  // START_USER_CODE-USER_METHODS
  let FormLoad = async () => {
    setLoading(true)

    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolHideRecKey')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolHideAlloc')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolColor')

    let permission = await bfillAccess('PN0260', '003', 'U', null)
    if (permission == 'Y') {
      enable(thisObj, 'btnSave')
      setGridEditable(true);
      setIsEditable(true)
      setData(thisObj,"permissionToEditCol",true)
    }else{//As part of Prod Issue INC5626542 Added Flag to make column not editable as per condition
      setData(thisObj,"permissionToEditCol",false)
    }

    let data=getData(thisObj,'ContractLimitSearchDataForBuyingPoint')==null?getData(thisObj, 'ContractLimitSearchData'):getData(thisObj,'ContractLimitSearchDataForBuyingPoint')
    let lstrRecordKey = data.recordKey
    setData(thisObj,'lstrRecordKeyBP',lstrRecordKey)
    await bfillForm(lstrRecordKey);
    await disableTextboxes();
    setLoading(false)
  }

  let disableTextboxes=async()=>{
    disable(thisObj,'lstLockIn')
    disable(thisObj,'txtAvailTons')
    disable(thisObj,'txtDescBuyPt')
    disable(thisObj,'txtLoanRepayBuyPt')
    disable(thisObj,'txtMrktCeilingBuyPt')
    disable(thisObj,'txtMrktFloorBuyPt')
    disable(thisObj,'txtMrktGainBuyPt')
    disable(thisObj,'txtMrktWeeksPriorMatrtyBuyPt')
    disable(thisObj,'txtMultiPricingsBuyPt')
    disable(thisObj,'txtOleicBuyPt')
    disable(thisObj,'txtOptPrcCapBuyPt')
    disable(thisObj,'txtPayRebtBuyPt')
    disable(thisObj,'txtPnutTypBuyPt')
    disable(thisObj,'txtPrcngTypBuyPt')
    disable(thisObj,'txtPriceMtchEndBuyPt')
    disable(thisObj,'txtPriceTonBuyPt')
    disable(thisObj,'txtRebtRateBuyPt')
    disable(thisObj,'txtSegBuyPt')
    disable(thisObj,'txtVrtyBuyPt')
  }

  let bfillAccess = async (funcId, funcSubId, access, buyPtId) => {
    let permission = await ContractManagementService.RetrieveAccessPermissionDetails(funcId, funcSubId, access, buyPtId)
    return permission[0].permission
  }

  // gridEditable=false;
  let bfillForm = async (lstrRecordKey) => {
    try{
    let gridObj = {};
    let dataObj = {};

    let dblAvalableTons = 0;
    let TransformedRowsArray = [];
    dataObj.record_key = lstrRecordKey
    let response = await SystemMaintenanceMasterManagementService.RetrieveContractPriceLimitDetails(dataObj, 'EXPAND')

    let data = response
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let controlLevel = data[i].control_level;
        if (controlLevel == 'CP') {
          setLblPriceType(data[i].pricing_type)
          switch (data[i].pricing_type) {
            case 'C-BASIS':
              setValue(thisObj, 'txtPrcngTypBuyPt', 'Basis Contract')
              break;
            case 'C-FIRM':
              setValue(thisObj, 'txtPrcngTypBuyPt', 'Firm Contract')
              break;
            case 'OPTION':
              setValue(thisObj, 'txtPrcngTypBuyPt', 'Option Pricings')
              break;
            case 'FLEX MKT':
              setValue(thisObj, 'txtPrcngTypBuyPt', 'Flex Market Pricings')
              break;
            case 'SEED':
              setValue(thisObj, 'txtPrcngTypBuyPt', 'Seed Pricings')
              break;
            case 'FLOOR':
              setValue(thisObj, 'txtPrcngTypBuyPt', 'Floor Pricings')
              break;
          }
          setValue(thisObj,'txtCollPt',data[i].coll_pt_id)
          setValue(thisObj, 'txtDescBuyPt', data[i].pricing_type_desc)
          setLblArea_id(data[i].area_id)
          setValue(thisObj, 'txtPnutTypBuyPt', data[i].pnut_type_id)
          setLblOrganic(data[i].organic_ind)
          if (data[i].organic_ind == 'Y') {
            setValue(thisObj, 'txtPnutTypBuyPt', data[i].pnut_type_id + 'Organic')
          }
          setValue(thisObj, 'txtVrtyBuyPt', data[i].pnut_variety_id)

          switch (data[i].symbol_ind) {
            case "TRADEMARK":
              setValue(thisObj, 'txtVrtyBuyPt', data[i].pnut_variety_id + ' ᵀᴹ')
              break;
            case "REGISTERED TRADEMARK":
              setValue(thisObj, 'txtVrtyBuyPt', data[i].pnut_variety_id + ' ®')
              break;
            case "COPYRIGHT":
              setValue(thisObj, 'txtVrtyBuyPt', data[i].pnut_variety_id + ' ©')
              break;
          }
          setValue(thisObj, 'txtSegBuyPt', data[i].seg_type)
          setValue(thisObj, 'txtOleicBuyPt', data[i].oleic_ind)
          setValue(thisObj, 'txtPriceTonBuyPt', Number(data[i].price_per_ton).toFixed(2))

          //Hide and show of some textbox
          if (data[i].market_floor != '') {
            show(thisObj, 'txtMrktFloorBuyPt')
            setValue(thisObj, 'txtMrktFloorBuyPt', Number(data[i].market_floor).toFixed(5));
          }
          else {
            hide(thisObj, 'txtMrktFloorBuyPt',false)
            setValue(thisObj, 'txtMrktFloorBuyPt', '');
          }

          if (data[i].market_ceiling != '') {
            show(thisObj, 'txtMrktCeilingBuyPt')
            setValue(thisObj, 'txtMrktCeilingBuyPt', Number(data[i].market_ceiling).toFixed(5));
          }
          else {
            hide(thisObj, 'txtMrktCeilingBuyPt',false)
            setValue(thisObj, 'txtMrktCeilingBuyPt', '');
          }

          if (data[i].rebate_rate != '') {
            show(thisObj, 'txtRebtRateBuyPt')
            setValue(thisObj, 'txtRebtRateBuyPt', Number(data[i].rebate_rate).toFixed(2));
          }
          else {
            hide(thisObj, 'txtRebtRateBuyPt',false)
            setValue(thisObj, 'txtRebtRateBuyPt', '');
          }

          if (data[i].mkt_gain_share != '') {
            show(thisObj, 'txtMrktGainBuyPt')
            setValue(thisObj, 'txtMrktGainBuyPt', Number(data[i].mkt_gain_share).toFixed(2));
          }
          else {
            hide(thisObj, 'txtMrktGainBuyPt',false)
            setValue(thisObj, 'txtMrktGainBuyPt', '');
          }

          if (data[i].loan_repay_ind != '') {
            show(thisObj, 'txtLoanRepayBuyPt')
            if (data[i].loan_repay_ind == 'G') {
              setValue(thisObj, 'txtLoanRepayBuyPt', 'Golden')
            }
            else if (data[i].loan_repay_ind == 'U') {
              setValue(thisObj, 'txtLoanRepayBuyPt', 'USDA')
            }
            else {
              setValue(thisObj, 'txtLoanRepayBuyPt', '')
            }
          }
          else {
            hide(thisObj, 'txtLoanRepayBuyPt',false)
            setValue(thisObj, 'txtLoanRepayBuyPt', '')
          }

          if (data[i].multi_pricing_ind != '') {
            show(thisObj, 'txtMultiPricingsBuyPt')
            if (data[i].multi_pricing_ind == 'Y') {
              setValue(thisObj, 'txtMultiPricingsBuyPt', 'Yes')
            }
            else if (data[i].multi_pricing_ind == 'N') {
              setValue(thisObj, 'txtMultiPricingsBuyPt', 'No')
            }
            else setValue(thisObj, 'txtMultiPricingsBuyPt', '')
          }
          else {
            hide(thisObj, 'txtMultiPricingsBuyPt',false)
            setValue(thisObj, 'txtMultiPricingsBuyPt', '')
          }

          if (data[i].mkt_wk_prior_mat != '') {
            show(thisObj, 'txtMrktWeeksPriorMatrtyBuyPt')
            setValue(thisObj, 'txtMrktWeeksPriorMatrtyBuyPt', data[i].mkt_wk_prior_mat)
          }
          else {
            hide(thisObj, 'txtMrktWeeksPriorMatrtyBuyPt',false)
            setValue(thisObj, 'txtMrktWeeksPriorMatrtyBuyPt', '')
          }

          if (data[i].max_option_price != '') {
            show(thisObj, 'txtOptPrcCapBuyPt')
            setValue(thisObj, 'txtOptPrcCapBuyPt', data[i].max_option_price)
          }
          else {
            hide(thisObj, 'txtOptPrcCapBuyPt',false)
            setValue(thisObj, 'txtOptPrcCapBuyPt', '')
          }

          if (data[i].pay_rebate_at != '') {
            show(thisObj, 'txtPayRebtBuyPt')
            setValue(thisObj, 'txtPayRebtBuyPt', data[i].pay_rebate_at)
          }
          else {
            hide(thisObj, 'txtPayRebtBuyPt',false)
            setValue(thisObj, 'txtPayRebtBuyPt', '')
          }

          if (data[i].price_match_end_date != '') {
            show(thisObj, 'txtPriceMtchEndBuyPt')
            setValue(thisObj, 'txtPriceMtchEndBuyPt', data[i].price_match_end_date)
          }
          else {
            hide(thisObj, 'txtPriceMtchEndBuyPt',false)
            setValue(thisObj, 'txtPriceMtchEndBuyPt', '')
          }

          let strLockInData = data[i].lock_in_data
          if (strLockInData.length > 0) {
            let js=[];
            let obj={};
            show(thisObj, 'lstLockIn')

            for(let i=0;i<strLockInData.length;i++){
              obj = {
                key: strLockInData[i].lock_in_key,
                description: strLockInData[i].lock_in_pct+ '% by ' + strLockInData[i].lock_in_date.slice(0,-9)
              }
              js.push(obj)
              obj={};
            }

            thisObj.setState(current => {
              return {
                ...current,
                lstLockIn: {
                  ...state["lstLockIn"],
                  valueList: js,
                }
              }
            })
            setData(thisObj, 'listboxData', js)
          }
          else {
            hide(thisObj, 'lstLockIn',false)
            strLockInData = [];
          }
          gridObj.txtcolPt = data[i].coll_pt_id.slice(0, 3);
          gridObj.txtcolBuyPt = ""
          gridObj.txtcolHideRecKey = data[i].record_key
          gridObj.txtcolHideAlloc = data[i].tons_allocated
          gridObj.txtcolAllocTons = data[i].tons_allocated==""?'0.0000':data[i].tons_allocated
          gridObj.txtcolContrctedTons = data[i].tons_contracted==""?'0.0000':data[i].tons_contracted
          gridObj.txtcolOpenTons = data[i].tons_allocated - data[i].tons_contracted
          gridObj.txtcolContrctIrrAcre = data[i].irrigated_acre
          gridObj.txtcolContrctDryLand = data[i].dryland_acre
          dblAvalableTons = Number(dblAvalableTons) + Number(data[i].tons_allocated)

        }
        if (controlLevel == 'BP') {
          
          gridObj.txtcolPt =""
          gridObj.txtcolBuyPt = data[i].buy_pt_id.slice(0,3)
          gridObj.txtcolHideRecKey = data[i].record_key
          gridObj.txtcolHideAlloc = data[i].tons_allocated
          gridObj.txtcolAllocTons = data[i].tons_allocated==""?'0.0000':data[i].tons_allocated
          gridObj.txtcolContrctedTons = data[i].tons_contracted==""?'0.0000':data[i].tons_contracted
          gridObj.txtcolOpenTons = data[i].tons_allocated - data[i].tons_contracted
          gridObj.txtcolContrctIrrAcre = data[i].irrigated_acre
          gridObj.txtcolContrctDryLand = data[i].dryland_acre
          dblAvalableTons = Number(dblAvalableTons) - Number(data[i].tons_allocated)
        }

        if (i != 0) {
          gridObj.txtcolPricingTyp = txtPrcngTypBuyPt.value
          gridObj.txtcolDesc = txtDescBuyPt.value
          gridObj.txtcolPnutTyp = txtPnutTypBuyPt.value.slice(0,2)
          gridObj.txtcolVariety = txtVrtyBuyPt.value=='>>> Any Variety <<<'?txtVrtyBuyPt.value:txtVrtyBuyPt.value.slice(0,2)
          gridObj.txtcolSeg = txtSegBuyPt.value
          gridObj.txtcolOleic = txtOleicBuyPt.value
          gridObj.txtcolPriceTon = txtPriceTonBuyPt.value
          gridObj.txtcolMrktFloor = txtMrktFloorBuyPt.value
          gridObj.txtcolMrketCeil = txtMrktCeilingBuyPt.value
          gridObj.txtcolRebateRate = txtRebtRateBuyPt.value
          gridObj.txtcolMarketGain = txtMrktGainBuyPt.value
          gridObj.txtcolMarketWeeksPrior = txtMrktWeeksPriorMatrtyBuyPt.value
          gridObj.txtcolLoanRepay = txtLoanRepayBuyPt.value
          gridObj.txtcolMultiPricings = txtMultiPricingsBuyPt.value
          gridObj.txtcolOptPriceCap = txtOptPrcCapBuyPt.value
          gridObj.txtcolPayRebate = txtPayRebtBuyPt.value
          gridObj.txtcolPriceMtchEnd = txtPriceMtchEndBuyPt.value

          let lockinData = getData(thisObj, 'listboxData')
            if (lockinData != null && lockinData.length > 0) {
              for (let j = 0; j < lockinData.length; j++) {
                if (j == 0) {
                  gridObj.txtcolLockInDate1 = lockinData[j].description
                }
                if (j == 1) {
                  gridObj.txtcolLockInDate2 = lockinData[j].description
                }
                if (j == 2) {
                  gridObj.txtcolLockInDate3 = lockinData[j].description
                }
                if (j == 3) {
                  gridObj.txtcolLockInDate4 = lockinData[j].description
                }
                if (j == 4) {
                  gridObj.txtcolLockInDate5 = lockinData[j].description
                }
                if (j == 5) {
                  gridObj.txtcolLockInDate6 = lockinData[j].description
                }
                if (j == 6) {
                  gridObj.txtcolLockInDate7 = lockinData[j].description
                }
                if (j == 7) {
                  gridObj.txtcolLockInDate8 = lockinData[j].description
                }
                if (j == 8) {
                  gridObj.txtcolLockInDate9 = lockinData[j].description
                }
                if (j == 9) {
                  gridObj.txtcolLockInDate10 = lockinData[j].description
                }
              }
            }
        }
        TransformedRowsArray.push(gridObj);
        gridObj = {};
      }
      setValue(thisObj, 'gridContrctLimitBuyPt', TransformedRowsArray)
      setData(thisObj, 'gridDataBP', TransformedRowsArray)

      setValue(thisObj, 'txtAvailTons', dblAvalableTons.toString())
    }
    PopulateGrid(false)
    if (isEnabled(thisObj,'btnSave') == true) {
     await vsfResults_AfterEdit(true);//As Part of Prod Issues INC5626542 made Synchromous call.
    }
    let editablegridcol = getData(thisObj,"permissionToEditCol")
    if(editablegridcol !== true){//As part of Prod Issue Made the Grid column Disabled as per condition.
      disableGridColumn(thisObj,"gridContrctLimitBuyPt","txtcolAllocTons")
    }
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred during bfillForm Method"
      );
    }
    return false;
  }
}

  let vsfResults_AfterEdit = async (editable) => {
    try{
    let dblAvalableTons = 0;
    let gridData=getData(thisObj,'gridDataBP')

    setMbFormDirty(false)
    setData(thisObj,'mbDirty',false)
    setMbFormError(false)
    setData(thisObj,'mbError',false)
    let formError=false;
    if (editable == false) return;

    dblAvalableTons=Number(getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolAllocTons',0)).toFixed(4);

    for(let i=1;i<gridData.length;i++){

      let buyPtId=getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolPt',i)
      let C_ALLOCATED=Number(getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolAllocTons',i));
      let C_CONTRACTED=Number(getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolContrctedTons',i)).toFixed(4);
      let C_HID_ALLOCATED=Number(getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolHideAlloc',i)).toFixed(4);
      setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolOpenTons',i,(C_ALLOCATED-C_CONTRACTED).toFixed(4))
      dblAvalableTons=Number(dblAvalableTons-C_ALLOCATED).toFixed(4)

      let response=await bfillAccess('PN0260','003','U',buyPtId) // As part of Prod Issue INC5626542 SubId is not passed as per Old Application.
      if(response=='Y'){
        setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i,'AQUA')
      }
      let columnColor=getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i)
      if((columnColor=='AQUA') && C_ALLOCATED!=C_HID_ALLOCATED){
        setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i,'YELLOW')
        setMbFormDirty(true);
        setData(thisObj,'mbDirty',true)
      }
    }

    dblAvalableTons=Number(dblAvalableTons).toFixed(4);
    setValue(thisObj,'txtAvailTons',dblAvalableTons)

    for(let i=1;i<gridData.length;i++){
      let columnColor=getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i)
      let C_ALLOCATED=Number(getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolAllocTons',i)).toFixed(4);
      let C_CONTRACTED=Number(getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolContrctedTons',i)).toFixed(4);
      let C_HID_ASSIGNED=Number(getGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolHideAssign',i)).toFixed(4);

      if(columnColor=="AQUA" || columnColor=='YELLOW'){
        if(isNaN(C_ALLOCATED)==true){
          showMessage(thisObj,'Contract Assignment must be numeric!')
          setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i,'RED')
          setMbFormError(true);
          setData(thisObj,'mbError',true)
          formError=true
          return;
        }

        if(Number(C_ALLOCATED) < 0){
          showMessage(thisObj,'Contract Assignment cannot be negitive!')
          setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i,'RED')
          setMbFormError(true);
          setData(thisObj,'mbError',true)
          formError=true;
          return;
        }

        if(Number(C_ALLOCATED) >= 100000000){
          showMessage(thisObj,'Contract Assignment too large for database field. Contact support to increase the field size in the database!')
          setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i,'RED')
          setMbFormError(true);
          setData(thisObj,'mbError',true)
          formError=true;
          return
        }

        if(Number(C_ALLOCATED) < Number(C_CONTRACTED)){
          showMessage(thisObj,'Contract Assignment cannot be less than total tons contracted!')
          setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i,'RED')
          setMbFormError(true);
          setData(thisObj,'mbError',true)
          formError=true;
          return
        }

        if(Number(dblAvalableTons) < 0){
          if(formError==false){
            showMessage(thisObj,'Total Contract Assignment cannot exceed the avalable limit from the Collection Point!') //As Part of Prod Issue INC5626542 made the Correct erro message.
            setGridValue(_kaledo.thisObj,'gridContrctLimitBuyPt','txtcolColor',i,'RED')
            setMbFormError(true);
            setData(thisObj,'mbError',true)
            formError=true;
            return;
          }
        }
      }
    } //End of for loop

    } // End of TRY block

    catch(err){
      // showMessage(thisObj,`Error occurred in the grid AfterEdit function ${err}`)
    }
  }

  //Save button click event
  let onbtnSaveClick = async () => {
    try{
      setLoading(true)
    if (mbFormError) {
      showMessage(thisObj, "Please correct data errors before Continuing!")
      setLoading(false);//Fixed as part of collection buying point loader not stoping.
      return;
    }

    if(await bSaveForm()) {
      let lstrRecordKey=getData(thisObj,'lstrRecordKeyBP')
      await bfillForm(lstrRecordKey)
    }
    setLoading(false)
    return
  }
  catch(err){
    if (err instanceof EvalError) {
      showMessage(thisObj, err.message);
    } else {
      showMessage(
        thisObj,
        "Error occurred in Save button"
      );
    }
    return false;
  }
  }
  thisObj.onbtnSaveClick = onbtnSaveClick

  //Export to excel button
  let onbtnExportClick = () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridContrctLimitBuyPt")

      let gridLength = excelData.length;
      let fileName = "Contract Price limit coll Pt Details"
      let excelArray = [];
      

      for (let i = 0; i < gridLength; i++) {
        let obj = {};
        
        if (thisObj.state.gridContrctLimitBuyPt.columns[1].Visible) {
          obj['Coll Pt'] = excelData[i].txtcolPt
        }

        if (thisObj.state.gridContrctLimitBuyPt.columns[2].Visible) {
          obj['Buy Pt'] = excelData[i].txtcolBuyPt
        }

        if (thisObj.state.gridContrctLimitBuyPt.columns[3].Visible) {
          obj['Allocated Tons'] = excelData[i].txtcolAllocTons
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[5].Visible) {
          obj['Contracted Tons'] = excelData[i].txtcolContrctedTons
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[6].Visible) {
          obj['Open Tons'] = excelData[i].txtcolOpenTons
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[7].Visible) {
          obj['Contracted Irrigated Acres'] = excelData[i].txtcolContrctIrrAcre
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[8].Visible) {
          obj['Contracted Dry Land Acers'] = excelData[i].txtcolContrctDryLand
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[9].Visible) {
          obj['Pricing Type'] = excelData[i].txtcolPricingTyp
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[10].Visible) {
          obj['Desc'] = excelData[i].txtcolDesc
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[11].Visible) {
          obj['Peanut Type'] = excelData[i].txtcolPnutTyp
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[12].Visible) {
          obj['Variety'] = excelData[i].txtcolVariety
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[13].Visible) {
          obj['Seg'] = excelData[i].txtcolSeg
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[14].Visible) {
          obj['Oleic'] = excelData[i].txtcolOleic
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[15].Visible) {
          obj['Price/Ton'] = excelData[i].txtcolPriceTon
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[16].Visible) {
          obj['Market Floor'] = excelData[i].txtcolMrktFloor
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[17].Visible) {
          obj['Market Ceiling'] = excelData[i].txtcolMrketCeil
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[18].Visible) {
          obj['Rebate Rate'] = excelData[i].txtcolRebateRate
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[19].Visible) {
          obj['Market Share Gain%'] = excelData[i].txtcolMarketGain
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[20].Visible) {
          obj['Loan Repay Method'] = excelData[i].txtcolLoanRepay
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[21].Visible) {
          obj['Multiple Pricings'] = excelData[i].txtcolMultiPricings
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[22].Visible) {
          obj['Market Weeks Prior to Maturity'] = excelData[i].txtcolMarketWeeksPrior
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[23].Visible) {
          obj['Option Price Cap'] = excelData[i].txtcolOptPriceCap
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[24].Visible) {
          obj['Pay Rebate At'] = excelData[i].txtcolPayRebate
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[25].Visible) {
          obj['Price Match End Date'] = excelData[i].txtcolPriceMtchEnd
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[28].Visible) {
          obj['Lock In Date1'] = excelData[i].txtcolLockInDate1
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[29].Visible) {
          obj['Lock In Date2'] = excelData[i].txtcolLockInDate2
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[30].Visible) {
          obj['Lock In Date3'] = excelData[i].txtcolLockInDate3
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[31].Visible) {
          obj['Lock In Date4'] = excelData[i].txtcolLockInDate4
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[32].Visible) {
          obj['Lock In Date5'] = excelData[i].txtcolLockInDate5
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[33].Visible) {
          obj['Lock In Date6'] = excelData[i].txtcolLockInDate6
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[34].Visible) {
          obj['Lock In Date7'] = excelData[i].txtcolLockInDate7
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[35].Visible) {
          obj['Lock In Date8'] = excelData[i].txtcolLockInDate8
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[36].Visible) {
          obj['Lock In Date9'] = excelData[i].txtcolLockInDate9
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[37].Visible) {
          obj['Lock In Date10'] = excelData[i].txtcolLockInDate10
        }
        excelArray.push(obj);
      }
      const ws = XLSX.utils.json_to_sheet(excelArray)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "error on excel to export button")
      }
      return false;
    }
    return true;
  }
  thisObj.onbtnExportClick = onbtnExportClick

  //Print Button Click function
  let onbtnPrintGridClick = () => {
    try {
      var Griddata = getValue(thisObj, "gridContrctLimitBuyPt")
      var res = []
      var headerArray = []

      if (thisObj.state.gridContrctLimitBuyPt.columns[1].Visible) {
        headerArray.push('Coll Pt')
      }

      if (thisObj.state.gridContrctLimitBuyPt.columns[2].Visible) {
        headerArray.push('Buy Pt')
      }

      if (thisObj.state.gridContrctLimitBuyPt.columns[3].Visible) {
        headerArray.push('Allocated Tons')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[5].Visible) {
        headerArray.push('Contracted Tons')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[6].Visible) {
        headerArray.push('Open Tons')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[7].Visible) {
        headerArray.push('Contracted Irrigated Acres')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[8].Visible) {
        headerArray.push('Contracted Dry Land Acers')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[9].Visible) {
        headerArray.push('Pricing Type')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[10].Visible) {
        headerArray.push('Desc')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[11].Visible) {
        headerArray.push('Peanut Type')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[12].Visible) {
        headerArray.push('Variety')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[13].Visible) {
        headerArray.push('Seg')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[14].Visible) {
        headerArray.push('Oleic')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[15].Visible) {
        headerArray.push('Price/Ton')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[16].Visible) {
        headerArray.push('Market Floor')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[17].Visible) {
        headerArray.push('Market Ceiling')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[18].Visible) {
        headerArray.push('Rebate Rate')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[19].Visible) {
        headerArray.push('Market Share Gain%')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[20].Visible) {
        headerArray.push('Loan Repay Method')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[21].Visible) {
        headerArray.push('Multiple Pricings')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[22].Visible) {
        headerArray.push('Market Weeks Prior to Maturity')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[23].Visible) {
        headerArray.push('Option Price Cap')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[24].Visible) {
        headerArray.push('Pay Rebate At')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[25].Visible) {
        headerArray.push('Price Match End Date')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[28].Visible) {
        headerArray.push('Lock In Date1')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[29].Visible) {
        headerArray.push('Lock In Date2')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[30].Visible) {
        headerArray.push('Lock In Date3')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[31].Visible) {
        headerArray.push('Lock In Date4')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[32].Visible) {
        headerArray.push('Lock In Date5')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[33].Visible) {
        headerArray.push('Lock In Date6')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[34].Visible) {
        headerArray.push('Lock In Date7')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[35].Visible) {
        headerArray.push('Lock In Date8')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[36].Visible) {
        headerArray.push('Lock In Date9')
      }
      if (thisObj.state.gridContrctLimitBuyPt.columns[37].Visible) {
        headerArray.push('Lock In Date10')
      }
      for (let i = 0; i < Griddata.length; i++) {
        let finalPrintArray = [];

        if (thisObj.state.gridContrctLimitBuyPt.columns[1].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPt)
        }

        if (thisObj.state.gridContrctLimitBuyPt.columns[2].Visible) {
          finalPrintArray.push(Griddata[i].txtcolBuyPt)
        }

        if (thisObj.state.gridContrctLimitBuyPt.columns[3].Visible) {
          finalPrintArray.push(Griddata[i].txtcolAllocTons)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[5].Visible) {
          finalPrintArray.push(Griddata[i].txtcolContrctedTons)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[6].Visible) {
          finalPrintArray.push(Griddata[i].txtcolOpenTons)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[7].Visible) {
          finalPrintArray.push(Griddata[i].txtcolContrctIrrAcre)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[8].Visible) {
          finalPrintArray.push(Griddata[i].txtcolContrctDryLand)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[9].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPricingTyp)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[10].Visible) {
          finalPrintArray.push(Griddata[i].txtcolDesc)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[11].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPnutTyp)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[12].Visible) {
          finalPrintArray.push(Griddata[i].txtcolVariety)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[13].Visible) {
          finalPrintArray.push(Griddata[i].txtcolSeg)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[14].Visible) {
          finalPrintArray.push(Griddata[i].txtcolOleic)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[15].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPriceTon)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[16].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMrktFloor)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[17].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMrketCeil)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[18].Visible) {
          finalPrintArray.push(Griddata[i].txtcolRebateRate)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[19].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMarketGain)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[20].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLoanRepay)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[21].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMultiPricings)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[22].Visible) {
          finalPrintArray.push(Griddata[i].txtcolMarketWeeksPrior)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[23].Visible) {
          finalPrintArray.push(Griddata[i].txtcolOptPriceCap)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[24].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPayRebate)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[25].Visible) {
          finalPrintArray.push(Griddata[i].txtcolPriceMtchEnd)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[28].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate1)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[29].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate2)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[30].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate3)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[31].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate4)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[32].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate5)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[33].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate6)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[34].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate7)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[35].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate8)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[36].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate9)
        }
        if (thisObj.state.gridContrctLimitBuyPt.columns[37].Visible) {
          finalPrintArray.push(Griddata[i].txtcolLockInDate)
        }
        res.push(finalPrintArray)
      }

      var obj = new jsPDF();
      obj.autoTable({
        startY: 5,
        startX: 20,
        styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
        body: res,
        theme: 'plain',
        fontStyle: 'normal',
        head: [headerArray]
      });

      var pageCount = obj.internal.getNumberOfPages();
      for (let w = 0; w <= pageCount; w++) {
        obj.setPage(w);
        let pageCurrent = obj.internal.getCurrentPageInfo().pageNumber; //Current Page
        obj.setFontSize(8);
        obj.text("Golden Peanut Company, LLC - "+cropYear + ' - Contract Price Control', 2, obj.internal.pageSize.height - 2);
      }

      let fileName = prompt("Please enter the file name :");
      if (fileName != null && fileName != undefined) {
        if (fileName != '') {
          obj.save(fileName + '.pdf')
        }
        else {
          alert("Routine:FarmInquiry.cmdPrintSearch_Click")
        }
      }
      else {
        alert("Routine:FarmInquiry.cmdPrintSearch_Click")
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true
  }
  thisObj.onbtnPrintGridClick = onbtnPrintGridClick

  //Close button Click function
  let onbtnExitClick = async() => {
    try {
      if (mbFormDirty == true || mbFormError == true) {
        let response = confirm('There are changes on the grid that have not yet been saved! Do you want save these changes?')
        if (response == true) {
          if (mbFormError == true) {
            showMessage(thisObj, 'Please correct data errors before Continuing!')
            return;
          }
          else {
            if (await bSaveForm() == false) return;
          }
        }
      }
      setData(thisObj, "closingOfContractLimitBuyPt", true)
      let buyPtIdClose=getData(thisObj,'collectionPOintBTN')
      if(buyPtIdClose==true){
        goTo(thisObj, "SystemMaintenanceMasterManagement#ContractLimitCollPt#DEFAULT#true#Click");
      }
      goTo(thisObj,"SystemMaintenanceMasterManagement#ContractLimitSearch#DEFAULT#false#Click"); //Need to get the navigation from VEDA
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in Exit button"
        );
      }
      return false;
    }
  }
  thisObj.onbtnExitClick = onbtnExitClick

  //Grid Button Click function
  let onbtncol1Click = async(event) => {
    let rowSelected = event.target.parentElement.parentElement.parentElement
    let rowID = rowSelected.id.substring(4, 5);
    let valueGrid = getValue(thisObj, "gridContrctLimitBuyPt")[rowID]

    if (mbFormError || mbFormDirty) {
      let response = confirm("There are changes on the grid that have not yet been saved! Do you want save these changes?")
      if (response == true) {
        if (mbFormError == true) {
          showMessage(thisObj, "Please correct data errors before Continuing!")
          return;
        }
        else {
          if (await bSaveForm() == false) return;
        }
      }
    }

    let frmContractLimitBuyPt = {};
    frmContractLimitBuyPt.lblRecordKey = valueGrid.txtcolHideRecKey;
    setData(thisObj, 'frmContractLimitBuyPtData', frmContractLimitBuyPt)
    // goTo(thisObj,'') //Need navigation from VEDA
  }
  thisObj.onbtncol1Click = onbtncol1Click

  let ontxtcolAllocTonsBlur = async (event) => {
    let changesColDatas = getData(thisObj, "changedColData");// Fixed as Part of Prod Issue Loader Loading Unnecessarily without changing any value.
    if (changesColDatas === true) {
      setLoading(true)
      let nameEvent = event.target.name
      let arr = Number(nameEvent.split(".")[1])
      // let hideAllocatedData = getGridValue(_kaledo.thisObj, 'gridContrctLimitBuyPt', 'txtcolHideAlloc', arr)
      // setGridValue(_kaledo.thisObj, 'gridContrctLimitBuyPt', 'txtcolAllocTons', arr, hideAllocatedData)

      let newValue = getGridValue(_kaledo.thisObj, 'gridContrctLimitBuyPt', 'txtcolAllocTons', arr)
      newValue = Number(newValue).toFixed(4)
      setGridValue(_kaledo.thisObj, 'gridContrctLimitBuyPt', 'txtcolAllocTons', arr, newValue)

      _kaledo.thisObj.setState({
        ...thisObj.state,
        gridContrctLimitBuyPt: {
          ...state.gridContrctLimitBuyPt,
          toggleRender: !state.gridContrctLimitBuyPt.toggleRender
        }
      })
      PopulateGrid(false)
      await vsfResults_AfterEdit();
      setData(thisObj, "changedColData", false);
      setLoading(false)
    }
  }
  thisObj.ontxtcolAllocTonsBlur = ontxtcolAllocTonsBlur

  let ontxtcolAllocTonsChange = async () => {// Fixed as Part of Prod Issue Loader Loading Unnecessarily without changing any value.
    setData(thisObj, "changedColData", true);// Added this fLag when the Value changed in that column at that time only Loader will loades.
  }
  thisObj.ontxtcolAllocTonsChange = ontxtcolAllocTonsChange

  let bSaveForm = async () => {
    try {
      let bSaveForm = false
      let TransformedRowsArray = getData(thisObj, 'gridDataBP')
      let ArrayLength = TransformedRowsArray.length
      let lstrRecordKey=getData(thisObj,'lstrRecordKeyBP')
      let coll_pt_id = getValue(thisObj, 'txtCollPt')?.split('-')[0]
      for (let i = 1; i < ArrayLength; i++) {
        let C_ALLOCATED=getGridValue(thisObj,'gridContrctLimitBuyPt','txtcolAllocTons',i)
        let C_HID_ALLOCATED=getGridValue(thisObj,'gridContrctLimitBuyPt','txtcolHideAlloc',i)
        let C_HID_REC_KEY=getGridValue(thisObj,'gridContrctLimitBuyPt','txtcolHideRecKey',i)
        //let coll_pt_id=getGridValue(thisObj,'gridContrctLimitBuyPt','txtcolPt',i-1)
        let buy_pt_id=getGridValue(thisObj,'gridContrctLimitBuyPt','txtcolBuyPt',i)
        if (Number(C_ALLOCATED) != Number(C_HID_ALLOCATED)) {
          let modify_tag;
          if(C_HID_REC_KEY == '') {
            modify_tag = 'ADD'
          }
          else {
            modify_tag = 'UPDATE'
          }
          let record_key = C_HID_REC_KEY;
          let parent_key = lstrRecordKey
          let area_id = lblarea_id.slice(0,2);
          let coll_pt = coll_pt_id
          let buyPtId=buy_pt_id
          let pricing_type = getValue(thisObj, 'txtPrcngTypBuyPt')

          switch (pricing_type) {
            case 'Basis Contract':
              pricing_type='C-BASIS'
              break;
            case 'Firm Contract':
              pricing_type= 'C-FIRM'
              break;
            case 'Option Pricings':
              pricing_type= 'OPTION'
              break;
            case 'Flex Market Pricings':
              pricing_type= 'FLEX MKT'
              break;
            case 'Seed Pricings':
              pricing_type= 'SEED'
              break;
            case 'Floor Pricings':
              pricing_type= 'FLOOR'
              break;
          }

          let seg_type = getValue(thisObj, 'txtSegBuyPt')
          let organic_ind = lblOrganic
          let pnut_type_id = getValue(thisObj, 'txtPnutTypBuyPt').slice(0,2)
          let pnut_variety_id = "";
          if (getValue(thisObj, 'txtVrtyBuyPt') != ">>> Any Variety <<<") {
            pnut_variety_id = getValue(thisObj, 'txtVrtyBuyPt').slice(0, 2)
          }
          let oleic_ind = "";
          if (getValue(thisObj, 'txtOleicBuyPt') != '>>Any<<') {
            oleic_ind = getValue(thisObj, 'txtOleicBuyPt')
          }
          let price_per_ton = getValue(thisObj, 'txtPriceTonBuyPt')
          let market_floor = getValue(thisObj, 'txtMrktFloorBuyPt')
          let market_ceiling = getValue(thisObj, 'txtMrktCeilingBuyPt')
          let rebate_rate = getValue(thisObj, 'txtRebtRateBuyPt')
          let mkt_gain_share = getValue(thisObj, 'txtMrktGainBuyPt')
          let pricing_type_desc = getValue(thisObj, 'txtDescBuyPt')
          let loan_repay_ind = getValue(thisObj, 'txtLoanRepayBuyPt')
          let mkt_wk_prior_mat = getValue(thisObj, 'txtMrktWeeksPriorMatrtyBuyPt')
          let multi_pricing_ind = getValue(thisObj, 'txtMultiPricingsBuyPt')
          let max_option_price = getValue(thisObj, 'txtOptPrcCapBuyPt')
          let pay_rebate_at = getValue(thisObj, 'txtPayRebtBuyPt')
          let price_match_end_date = getValue(thisObj, 'txtPriceMtchEndBuyPt')
          let tons_allocated = C_ALLOCATED;
          let dataObj = {
            "parent_key": Number(parent_key),
            "control_level": 'BP',
            "area_id": area_id,
            'coll_pt_id': coll_pt,
            "buy_pt_id": buyPtId,
            "vendor_num": "",
            "contract_num": 0,
            "pricing_type": pricing_type,
            "seg_type": seg_type,
            "organic_ind": organic_ind,
            "pnut_type_id": pnut_type_id,
            "pnut_variety_id": pnut_variety_id,
            "oleic_ind": oleic_ind,
            "price_per_ton": (price_per_ton),
            "market_floor": (market_floor),
            "market_ceiling": (market_ceiling),
            "rebate_rate": (rebate_rate),
            "mkt_gain_share": (mkt_gain_share),
            "pricing_type_desc": pricing_type_desc,
            "loan_repay_ind": loan_repay_ind,
            "multi_pricing_ind": multi_pricing_ind,
            "mkt_wk_prior_mat": (mkt_wk_prior_mat),
            "pay_rebate_at": (pay_rebate_at),
            "tons_allocated": (tons_allocated),
            "price_match_end_date": price_match_end_date,
            "max_option_price": max_option_price,
            "user_id": useridFromLS(),
            "lock_in_cnt": 0,
            "lock_in_data": [
              {
                "lock_in_key": 0,
                "lock_in_date": "",
                "lock_in_pct": 0
              }
            ]  
          }
          let response = await SystemMaintenanceMasterManagementService.UpdateContractPriceLimit(modify_tag, record_key, dataObj)
          if (response != undefined && response != '' && response != null) {
            if (response.result != '') {
              setGridValue(_kaledo.thisObj, 'gridContrctLimitBuyPt', 'txtcolHideRecKey', i, response.result)
              setGridValue(_kaledo.thisObj, 'gridContrctLimitBuyPt', 'txtcolHideAlloc', i, C_ALLOCATED)
            }
            else {
              setMbFormError(true);
            }
          }
          else {
            setMbFormError(true)
          }
        }
      }
      if (mbFormError) {
        showMessage(thisObj, "Errors occurred during update!")
        bSaveForm = false
      }
      else {
        showMessage(thisObj, "Contract Assignment Limits have been updated.",true)
        bSaveForm = true
      }
      return bSaveForm;
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred in Save Function"
        );
      }
      return false;
    }
  }


  let PopulateGrid = (hideShow) => {

    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPricingTyp')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolDesc')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPnutTyp')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolVariety')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolSeg')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolOleic')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPriceTon')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMrktFloor')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMrketCeil')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolRebateRate')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMarketGain')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolLoanRepay')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMultiPricings')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMarketWeeksPrior')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolOptPriceCap')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPayRebate')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPriceMtchEnd')
    hideColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolLockInDate1')

    for(let i=1;i<=10;i++){
      hideColumn(thisObj, 'gridContrctLimitBuyPt', `txtcolLockInDate${i}`)
    }

    if (hideShow) {
      let PriceType = getValue(thisObj, 'txtcolPricingTyp')
      if (PriceType != "") {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPricingTyp')
      }
      let description = getValue(thisObj, 'txtcolDesc')
      if (description != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolDesc')
      }
      let PeanutType = getValue(thisObj, 'txtcolPnutTyp')
      if (PeanutType != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPnutTyp')
      }

      let Variety = getValue(thisObj, 'txtcolVariety')
      if (Variety != '>>> Any Variety <<<') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolVariety')
      }

      let seg = getValue(thisObj, 'txtcolSeg')
      if (seg != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolSeg')
      }

      let Oleic = getValue(thisObj, 'txtcolOleic')
      if (Oleic != '>>Any<<') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolOleic')
      }

      let PriceTon = getValue(thisObj, 'txtcolPriceTon')
      if (PriceTon != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPriceTon')
      }

      let txtMrkt = getValue(thisObj, 'txtcolMrktFloor')
      if (txtMrkt != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMrktFloor')
      }

      let MrktCeilling = getValue(thisObj, 'txtcolMrketCeil')
      if (MrktCeilling != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMrketCeil')
      }

      let RebtRate = getValue(thisObj, 'txtcolRebateRate')
      if (RebtRate != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolRebateRate')
      }

      let MrktGain = getValue(thisObj, 'txtcolMarketGain')
      if (MrktGain != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMarketGain')
      }

      let LoanRepan = getValue(thisObj, 'txtcolLoanRepay')
      if (LoanRepan != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolLoanRepay')
      }

      let MultiPricing = getValue(thisObj, 'txtcolMultiPricings')
      if (MultiPricing != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMultiPricings')
      }

      // let MrktWeeks = getValue(thisObj, 'txtcolMarketWeeksPrioruic25')
      // if (MrktWeeks != '') {
      //   showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolMarketWeeksPrioruic25')
      // }

      let OptPrc = getValue(thisObj, 'txtcolOptPriceCap')
      if (OptPrc != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolOptPriceCap')
      }

      let PayRebt = getValue(thisObj, 'txtcolPayRebate')
      if (PayRebt != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPayRebate')
      }

      let PriceMtch = getValue(thisObj, 'txtcolPriceMtchEnd')
      if (PriceMtch != '') {
        showColumn(thisObj, 'gridContrctLimitBuyPt', 'txtcolPriceMtchEnd')
      }
    }
  }

  // START_USER_CODE-USER_METHODS

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractLimitBuyPt*/}

              {/* END_USER_CODE-USER_BEFORE_ContractLimitBuyPt*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCntrctLimit*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCntrctLimit*/}

              <GroupBoxWidget conf={state.grpbxCntrctLimit} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtPrcngTypBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtPrcngTypBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPrcngTypBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPrcngTypBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtPrcngTypBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtDescBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtDescBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtDescBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtDescBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtDescBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtCollPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtCollPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCollPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCollPt*/}

                {/* END_USER_CODE-USER_AFTER_txtCollPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtPnutTypBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtPnutTypBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPnutTypBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPnutTypBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtPnutTypBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtVrtyBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtVrtyBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVrtyBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVrtyBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtVrtyBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtSegBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtSegBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSegBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSegBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtSegBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtOleicBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtOleicBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOleicBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOleicBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtOleicBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtPriceTonBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtPriceTonBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPriceTonBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPriceTonBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtPriceTonBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtPayRebtBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayRebtBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayRebtBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayRebtBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtPayRebtBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtOptPrcCapBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtOptPrcCapBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtOptPrcCapBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtOptPrcCapBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtOptPrcCapBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtPriceMtchEndBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtPriceMtchEndBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPriceMtchEndBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPriceMtchEndBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtPriceMtchEndBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_txtRebtRateBuyPt*/}

                {/* END_USER_CODE-USER_BEFORE_txtRebtRateBuyPt*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtRebtRateBuyPt}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtRebtRateBuyPt*/}

                {/* END_USER_CODE-USER_AFTER_txtRebtRateBuyPt*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxMarket*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxMarket*/}

                <GroupBoxWidget conf={state.grpbxMarket} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtMrktFloorBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktFloorBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktFloorBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktFloorBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktFloorBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMrktCeilingBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktCeilingBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktCeilingBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktCeilingBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktCeilingBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMrktGainBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktGainBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktGainBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktGainBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktGainBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMultiPricingsBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMultiPricingsBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMultiPricingsBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMultiPricingsBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtMultiPricingsBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLoanRepayBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLoanRepayBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLoanRepayBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLoanRepayBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtLoanRepayBuyPt*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMrktWeeksPriorMatrtyBuyPt*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMrktWeeksPriorMatrtyBuyPt*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMrktWeeksPriorMatrtyBuyPt}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMrktWeeksPriorMatrtyBuyPt*/}

                  {/* END_USER_CODE-USER_AFTER_txtMrktWeeksPriorMatrtyBuyPt*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxMarket*/}

                {/* END_USER_CODE-USER_AFTER_grpbxMarket*/}
                {/* START_USER_CODE-USER_BEFORE_lstLockIn*/}

                {/* END_USER_CODE-USER_BEFORE_lstLockIn*/}

                <ListboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.lstLockIn}
                  screenConf={state}
                ></ListboxWidget>
                {/* START_USER_CODE-USER_AFTER_lstLockIn*/}

                {/* END_USER_CODE-USER_AFTER_lstLockIn*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCntrctLimit*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCntrctLimit*/}
              {/* START_USER_CODE-USER_BEFORE_gridContrctLimitBuyPt*/}

              {/* END_USER_CODE-USER_BEFORE_gridContrctLimitBuyPt*/}

              <GridWidget
                conf={state.gridContrctLimitBuyPt}
                screenConf={state}
                linkClick={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                errors={errors}
                touched={touched}
                rows={values.gridContrctLimitBuyPt}
                onEvent={event =>
                  invokeEventHandler(event, handleChange, handleBlur, thisObj)
                }
                refObject={thisObj}
                key={state.gridContrctLimitBuyPt.toggleRender}
              ></GridWidget>
              {/* START_USER_CODE-USER_AFTER_gridContrctLimitBuyPt*/}

              {/* END_USER_CODE-USER_AFTER_gridContrctLimitBuyPt*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_txtAvailTons*/}

                {/* END_USER_CODE-USER_BEFORE_txtAvailTons*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtAvailTons}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtAvailTons*/}

                {/* END_USER_CODE-USER_AFTER_txtAvailTons*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintGrid*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintGrid*/}

                <ButtonWidget
                  conf={state.btnPrintGrid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintGrid*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintGrid*/}
                {/* START_USER_CODE-USER_BEFORE_btnExport*/}

                {/* END_USER_CODE-USER_BEFORE_btnExport*/}

                <ButtonWidget
                  conf={state.btnExport}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExport*/}

                {/* END_USER_CODE-USER_AFTER_btnExport*/}
                {/* START_USER_CODE-USER_BEFORE_btnSave*/}

                {/* END_USER_CODE-USER_BEFORE_btnSave*/}

                <ButtonWidget
                  conf={state.btnSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSave*/}

                {/* END_USER_CODE-USER_AFTER_btnSave*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ContractLimitBuyPt*/}

              {/* END_USER_CODE-USER_AFTER_ContractLimitBuyPt*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(SystemMaintenanceMasterManagement_ContractLimitBuyPt);
