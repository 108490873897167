/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  LabelWidget,
  TextAreaWidget,
  disable,
  enable,
  setData,
  setValue,
  getData,
  hide,
  goTo
} from "../../shared/WindowImports";

import "./ContractScan.scss";

import ContractManagement_header from "../../Header/Header";
import ModalPopUp from "react-bootstrap/Modal";
import CommonContext from "../../Store/CommonContext";
import { useDropzone } from "react-dropzone";
import { ContractManagementService } from "../Service/ContractManagementService";
import { inputPropsType } from "react-bootstrap-typeahead/lib/propTypes";
import StripPic from "../../../../assets/img/PinStrip.png";
function ContractManagement_ContractScan(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const commonContext = useContext(CommonContext);
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));

  const selectionMode = "Selection"
  const scanningMode = "Scanning"
  const verifyMode = "Verifying"
  
  const [isShow, invokeModal] = React.useState(false);
  const [files, setFiles] = useState([]);
  
  let cropYear = cropYearFromLS();
  let comp_id  = compIdFromLS();

  let buy_pt_id = ""  
  let cont_num  = "" 
  let func_id = ""  
  let mbMode = selectionMode;
  let timeout = null;
  let dialogOpen;

  let states = {
    Label: "SaveAndScanContract",
    windowName: "ContractScan",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "ContractManagement.ContractScan",
    
    headerData: {
      scrName: "Scan and Save Contract",
      scrCode: "PN1010F"
    },
    
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "",
      HasLabel:false,
      CharWidth: "6",
      ofTypeDomain: "d_boolean",
      hasIcon : true,
      iconName : "fa-alarm-clock"
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "2",
      CharWidth: "6",
      ofTypeDomain: "d_boolean",
    },
    btnAct3: {
      name: "btnAct3",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "3",
      CharWidth: "6",
      ofTypeDomain: "d_boolean",
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Cancel",
      CharWidth: "15",
      Enabled: false,
      ofTypeDomain: "d_boolean",
    },
    btnCancleScan: {
      name: "btnCancleScan",
      type: "ButtonWidget",
      parent: "grpbxScanningOptions",
      Label: "Cancel Scan",
      CharWidth: "24",
      ofTypeDomain: "d_boolean",
    },
    btnCncl: {
      name: "btnCncl",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "Cancel",
      CharWidth: "15",
      ofTypeDomain: "d_boolean",
    },
    btnSaveFrmExFile: {
      name: "btnSaveFrmExFile",
      type: "ButtonWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Save From Existing File",
      CharWidth: "47",
      ofTypeDomain: "d_boolean",
    },
    btnScanCntrct: {
      name: "btnScanCntrct",
      type: "ButtonWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Scan Contract",
      CharWidth: "28",
      Disabled: true,
      ofTypeDomain: "d_boolean",
    },
    btnScanDocFeeder: {
      name: "btnScanDocFeeder",
      type: "ButtonWidget",
      parent: "grpbxScanningOptions",
      Label: "Scan Using Doc. Feeder",
      CharWidth: "45",
      ofTypeDomain: "d_boolean",
    },
    btnScanFlatbed: {
      name: "btnScanFlatbed",
      type: "ButtonWidget",
      parent: "grpbxScanningOptions",
      Label: "Scan Using Flatbed",
      CharWidth: "37",
      ofTypeDomain: "d_boolean",
    },
    btnSignCntrctWOScng: {
      name: "btnSignCntrctWOScng",
      type: "ButtonWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Sign Contract Without Scanning",
      CharWidth: "59",
      ofTypeDomain: "d_boolean",
    },
    btnVerSave: {
      name: "btnVerSave",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "Verify And Save",
      CharWidth: "32",
      Enabled: false,
      ofTypeDomain: "d_boolean",
    },
    lblSavingCntrctOpt: {
      name: "lblSavingCntrctOpt",
      type: "LabelWidget",
      parent: "grpbxSavingCntrctOpt",
      Label: "Saving Contract Options",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    lblScanningOptions: {
      name: "lblScanningOptions",
      type: "LabelWidget",
      parent: "grpbxScanningOptions",
      Label: "Scanning Options",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtarScanningStatus: {
      name: "txtarScanningStatus",
      type: "TextAreaWidget",
      parent: "grpbxScanningOptions",
      Label: "Scanning Status",
      ColSpan: "3",
      RowSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    txtarVerResult: {
      name: "txtarVerResult",
      type: "TextAreaWidget",
      parent: "grpbxVerResultActs",
      Label: "Verification Result",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
    },
    grpbxSavingCntrctOpt: {
      name: "grpbxSavingCntrctOpt",
      type: "GroupBoxWidget",
      parent: "ContractScan",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
    },
    grpbxScanningOptions: {
      name: "grpbxScanningOptions",
      type: "GroupBoxWidget",
      parent: "ContractScan",
      Height: "",
      Width: "",
      HasLabel: false,
    },
    grpbxVerResultActs: {
      name: "grpbxVerResultActs",
      type: "GroupBoxWidget",
      parent: "ContractScan",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
    },
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "1",
      CharWidth: "6",
      ofTypeDomain: "d_boolean",
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "2",
      CharWidth: "6",
      ofTypeDomain: "d_boolean",
    },
    btnVerSave: {
      name: "btnVerSave",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "Verify And Save",
      Enabled: false,
      CharWidth: "32",
      ofTypeDomain: "d_boolean",
    },    
    btnCncl: {
      name: "btnCncl",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "Cancel",
      CharWidth: "15",
      //Enabled: false,
      ofTypeDomain: "d_boolean",
    },
    btn3: {
      name: "btn3",
      type: "ButtonWidget",
      parent: "grpbxVerResultActs",
      Label: "3",
      CharWidth: "6",
      ofTypeDomain: "d_boolean",
    },  
    _dataCopy: {
      FORWARD: {},
      REVERSE: {},
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    func_id = state.headerData.scrCode
    if(commonContext.getContractProfile_ContractNumber != undefined){
      cont_num = commonContext.getContractProfile_ContractNumber
    }
    
    if(commonContext.getContractProfile_BuyingPointId != undefined){
      buy_pt_id = commonContext.getContractProfile_BuyingPointId
    }
    
    EnableDisableControls()
    disable(thisObj, "txtCntrctPrcNum")
    hide(thisObj, "grpbxScanningOptions")
    disable(thisObj, "txtarVerResult")    
  }, [thisObj.state.dropzoneFlag]);

  
  function UploadScannedContract(fileName){
    try{
      if(fileName == ''){
        showMessage(thisObj, "FileName is empty")
        return false;
      } else if(fileName.split('.')[1] !== 'pdf'){
        showMessage(thisObj, "File is not pdf format")
        return false;
      }
      setValue(thisObj, "txtarVerResult", fileName + " loaded. Select Verify and Save to verify. Cancel to exit scanning.")
      invokeModal(false)
      return true; 
    }
    catch(error){
      setValue(thisObj, "txtarVerResult", "Please try to upload the file again.")
      invokeModal(false)
      return false;
    }
  }

  async function FTPContract(file){   
    var date = new Date();
    var contractNumber  = commonContext.getContractProfile_ContractNumber
    var cont = ''
    if(contractNumber.length <= 6){
      for(var i=0; i<(6-contractNumber.length); i++){
        cont+= '0'
      }
    }
    contractNumber = cont + contractNumber;
    const numArr = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]
    var actionType = commonContext.getContractProfile_ButtonEventName == 'Sign' ? 'S' : 'R'
    var fileNameToProcess = "CUF" + actionType + comp_id.slice(0,2) + cropYear + contractNumber + 
                            commonContext.getContractProfile_BuyingPointId +
                            date.getFullYear() + numArr[date.getMonth() + 1] + numArr[date.getDate()] + numArr[date.getHours()] + 
                            numArr[date.getMinutes()] + numArr[date.getSeconds()] + ".pdf"
    
    var environmentType = 'PPSTest'
    if(process.env.REACT_APP_ENVIR == 'PROD'){
      environmentType = 'PPSProd'
    }
    
    const formData = new FormData();
    formData.append('FormFile', file[0]);           
    formData.append('filename', fileNameToProcess)  
    formData.append('environment', environmentType)
    formData.append('folderType', 'contract')
    
    let response = await ContractManagementService.UploadFtpFile(formData)
    if (response != undefined && response != null) {
      if (response.message == 'File transfer successfully') {
        setValue(thisObj, "txtarVerResult", "File " + file[0].name + " successfully transferred as " + fileNameToProcess)
        enable(thisObj, "btnSaveFrmExFile")
        disable(thisObj, "btnVerSave")
        disable(thisObj, "btnCancel")
        enable(thisObj, "btnCncl")
        let scanObj = {
          method : commonContext.getContractProfile_ButtonEventName == 'Sign' ? 'Sign' : 'Rescan',
          uploadstatus : '',
          filname : fileNameToProcess
        }
        setData(thisObj, 'ContractScan', scanObj)

        alert("File " + file[0].name + " successfully transferred as " + fileNameToProcess)
        goTo(thisObj, 'ContractManagement#ContractProfile#DEFAULT#false#Click')
      }       
      else if (response.message != 'File transfer successfully') {
        setValue(thisObj, "txtarVerResult", "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later")
        showMessage(thisObj, "Problem with saving (FTP) the Contract Pricing. You can retry or upload the file later")
        disable(thisObj, "btnVerSave")
        enable(thisObj, "btnSaveFrmExFile")
        enable(thisObj, "onbtnCnclClick")
        enable(thisObj, "btnCncl")
      }
    }
    else {
      setValue(thisObj, "txtarVerResult", "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
      showMessage(thisObj, "The PDF file is of a lower version and not supported. To re-create the file in higher version, Open the File > Go to File > Print > Select Printer Microsoft Print to PDF > Save this version somewhere. Upload the new saved contract. Please contact your system administrator if the problem persist.")
      disable(thisObj, "btnVerSave")
      enable(thisObj, "btnSaveFrmExFile")
      enable(thisObj, "onbtnCnclClick")
      enable(thisObj, "btnCncl")
    }
    enable(thisObj, "btnCncl")
    setValue(thisObj, "txtarVerResult", response.message)
    // showMessage(thisObj, response.message)
  }

  function VerifyContract(file){
    try{
      FTPContract(file)
    } 
    catch(err) {
      enable(thisObj, "btnCncl")
      showMessage(thisObj, err.message)
      return false;
    }
  }

  function EnableDisableControls(){
    if(mbMode == selectionMode){
      disable(thisObj, "btnCancel")
      disable(thisObj, "btnVerSave")
      disable(thisObj, "txtCntrctPrcNum")
      setValue(thisObj, "txtarScanningStatus", "")
      setValue(thisObj, "txtarVerResult", "")
    }
    else if(mbMode == scanningMode){
      disable(thisObj, "btnSaveFrmExFile")
      disable(thisObj, "btnCancel")
      setValue(thisObj, "txtarScanningStatus", "Load Contract Pricing and select scanning option. Cancel to exit scanning.")      
      setValue(thisObj, "txtarVerResult", "")
    }
    else if(mbMode == verifyMode){
      disable(thisObj, "btnSaveFrmExFile")
      disable(thisObj, "btnCancel")
      disable(thisObj, "btnScanDocFeeder")
      setValue(thisObj, "txtarScanningStatus", "")      
      setValue(thisObj, "txtarVerResult", "Review scanned Contract Pricing. Click verify and Save to save Contract Pricing.  Click Cancel to return to Saving Options")
    }
    else{
      showMessage(thisObj, "Invalid selection. ")
    }
  }

  function checkFiles() {
    dialogOpen = false;
  }
  
  const { getRootProps, getInputProps } = useDropzone({
    accept : ".pdf",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
      // if(acceptedFiles != null && acceptedFiles != undefined){
      //   dialogOpen = true;
      //   if(dialogOpen){
      //     clearTimeout(timeout);
      //     timeout = setTimeout(checkFiles, 100)
      //   }
      // }            
      if (acceptedFiles != undefined && acceptedFiles != null) {
        if (acceptedFiles[0].name.split('.')[1] != 'pdf') {
          invokeModal(false);
          disable(thisObj, "btnVerSave")
          enable(thisObj, "btnSaveFrmExFile")
          disable(thisObj, "btnCancel")
          setValue(thisObj, "txtarVerResult", "Please select only pdf files.")
          return;
        }
        invokeModal(false);
      }
      state.currentFile = acceptedFiles
      let promptresponse = window.confirm("Do you want to Preview Document before Uploading");
      if(promptresponse==true){
      window.open(acceptedFiles[0].preview)
      }
      setData(thisObj, "currentFile", acceptedFiles)
      setValue(thisObj, "currentFile", acceptedFiles)
      var res = UploadScannedContract(acceptedFiles[0].name)
      if (res) {
        disable(thisObj, "btnSaveFrmExFile")
        enable(thisObj, "btnVerSave")
        enable(thisObj, "btnCancel")
      }
      else {
        invokeModal(false);
        enable(thisObj, "btnSaveFrmExFile")
        disable(thisObj, "btnVerSave")
        disable(thisObj, "btnCancel")
      }
    }
  })

  const onbtnCancelClick = () => {
    try {
      disable(thisObj, "btnVerSave")
      enable(thisObj, "btnSaveFrmExFile")
      disable(thisObj, "btnCancel")
      setValue(thisObj, "txtarVerResult", "")
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Exception in PreSubmit Event code for widget:onbtnCancelClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnSaveFrmExFileClick = () => {
    try {
      invokeModal(true)
      thisObj.setState((current)=>{
        return{
          ...current,
          dropzoneFlag: !thisObj.state.dropzoneFlag
        }
      })

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Exception in PreSubmit Event code for widget:btnScanCntrct event:Click");
      }
      return false;
    }
  };
  thisObj.onbtnSaveFrmExFileClick = onbtnSaveFrmExFileClick;

  const onbtnCnclClick = () => {
    try {
      document.getElementsByClassName("close")[0].click();
    } 
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Exception in PreSubmit Event code for widget:onbtnCnclClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCnclClick = onbtnCnclClick;

  const onbtnVerSaveClick = () => {
    try {
      disable(thisObj, "btnCncl")
      VerifyContract(getData(thisObj, "currentFile"))  
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,"Exception in PreSubmit Event code for widget:onVerSaveClick event:Click");
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnVerSaveClick = onbtnVerSaveClick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            
            <ContractManagement_header headerData={state.headerData} />            
            <h1 className="pageHeader">{state.Label}</h1>            
            <Form noValidate className="row">
            
              <GroupBoxWidget
                conf={state.grpbxSavingCntrctOpt}
                screenConf={state}
              >
                <LabelWidget
                  values={values}
                  conf={state.lblSavingCntrctOpt}
                  screenConf={state}
                ></LabelWidget>                
                <ButtonWidget
                  conf={state.btnSaveFrmExFile}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>                
                <ModalPopUp id="blur"
                    show={isShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    keyboard = "false"
                    style={{ width: "50%", height : "100%", left : "26%" }} 
                    autoFocus
                    centered   
                >                  
                  <ModalPopUp.Body>
                    <div style= {{ alignContent : "centered", textAlign : "centered", height : "100%" }}>
                      <div className="dropArea" {...getRootProps()} style={{ border:"1px solid #ccc", textAlign: "centered", height: "100%",backgroundColor:"white" }}>
                      {/* {...getRootProps()} */}
                        <label for="dropFile" style={{ textAlign: "center", width : "100%" , height : "100%" }} class="btn">
                          <strong> Drag 'n' drop some file here, or click to select file. </strong>
                        </label>
                        {/* <input {...getInputProps()} accept=".pdf" id="dropFile" type="file" style={{ width: "50%", height : "100%" , visibility: "hidden" }}/> */}
                      </div>
                    </div>                    
                  </ModalPopUp.Body>
                </ModalPopUp>
                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>          
              </GroupBoxWidget>
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxVerResultActs}
                screenConf={state}
              >                
                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarVerResult}
                  screenConf={state}
                ></TextAreaWidget>
                <ButtonWidget
                  conf={state.btnVerSave}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnCncl}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              </GroupBoxWidget>

            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(ContractManagement_ContractScan);