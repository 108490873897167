/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setData,
  getData,
  setValue,
  hide,
  show,
  readonlyGridColumn,
  getValue,
  hideColumn,
  enable,
  disable,
  goTo
} from "../../shared/WindowImports";

import "./ContractApplications2009.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { SettlementService } from "../Service/SettlementService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import moment from "moment";//For date time
import { isEnabled, isVisible, showColumn } from "../../shared/ParentWindow";
import CommonContext from "../../Store/CommonContext";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_ContractApplications2009(props) {
  const usecommonContext = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [cellId, setCellId] = useState('');
  const [cellValue, setCellValue] = useState('');
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ContractApplications2009",
    windowName: "ContractApplications2009",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ContractApplications2009",
    // START_USER_CODE-USER_ContractApplications2009_PROPERTIES
    headerData: {
      scrName: "Contract Applications",
      scrCode: "PN1050B",
    },
    filterColumnsbCMAValue:"",
    filterColumnsPSValue: "",
    // END_USER_CODE-USER_ContractApplications2009_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridViewContractApplication",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    btncol2: {
      name: "btncol2",
      type: "ButtonWidget",
      colName: "col2",
      parent: "gridSeedGroverApp",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol2_PROPERTIES

      // END_USER_CODE-USER_btncol2_PROPERTIES
    },
    btnEditApp: {
      name: "btnEditApp",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Edit Applications",
      CharWidth: "35",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEditApp_PROPERTIES

      // END_USER_CODE-USER_btnEditApp_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnPurchase: {
      name: "btnPurchase",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Purchase",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPurchase_PROPERTIES

      // END_USER_CODE-USER_btnPurchase_PROPERTIES
    },
    btnUndoEdit: {
      name: "btnUndoEdit",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Undo Edit",
      CharWidth: "21",
      Visible: true,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUndoEdit_PROPERTIES

      // END_USER_CODE-USER_btnUndoEdit_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },
    colAppliedSG: {
      name: "colAppliedSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedSG_PROPERTIES

      // END_USER_CODE-USER_colAppliedSG_PROPERTIES
    },
    colApplied: {
      name: "colApplied",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplied_PROPERTIES

      // END_USER_CODE-USER_colApplied_PROPERTIES
    },
    colApplLbs: {
      name: "colApplLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "appl_lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colApplLbs_PROPERTIES

      // END_USER_CODE-USER_colApplLbs_PROPERTIES
    },
    colContApplLbs: {
      name: "colContApplLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "cont-appl_lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContApplLbs_PROPERTIES

      // END_USER_CODE-USER_colContApplLbs_PROPERTIES
    },
    colContApplTentLbs: {
      name: "colContApplTentLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "cont-appl-tent_lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContApplTentLbs_PROPERTIES

      // END_USER_CODE-USER_colContApplTentLbs_PROPERTIES
    },
    colContDateSG: {
      name: "colContDateSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Cont. Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContDateSG_PROPERTIES

      // END_USER_CODE-USER_colContDateSG_PROPERTIES
    },
    colContDate: {
      name: "colContDate",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Contract Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContDate_PROPERTIES

      // END_USER_CODE-USER_colContDate_PROPERTIES
    },
    colContGroup: {
      name: "colContGroup",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Grouping",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContGroup_PROPERTIES

      // END_USER_CODE-USER_colContGroup_PROPERTIES
    },
    colContract: {
      name: "colContract",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContract_PROPERTIES

      // END_USER_CODE-USER_colContract_PROPERTIES
    },
    colContractCA: {
      name: "colContractCA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractCA_PROPERTIES

      // END_USER_CODE-USER_colContractCA_PROPERTIES
    },
    colContractsPoundsSG: {
      name: "colContractsPoundsSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractsPoundsSG_PROPERTIES

      // END_USER_CODE-USER_colContractsPoundsSG_PROPERTIES
    },
    colContractsPounds: {
      name: "colContractsPounds",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractsPounds_PROPERTIES

      // END_USER_CODE-USER_colContractsPounds_PROPERTIES
    },
    colContType: {
      name: "colContType",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Cont Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContType_PROPERTIES

      // END_USER_CODE-USER_colContType_PROPERTIES
    },
    colDeliveryEndDate: {
      name: "colDeliveryEndDate",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Delivery End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeliveryEndDate_PROPERTIES

      // END_USER_CODE-USER_colDeliveryEndDate_PROPERTIES
    },
    colDeliveryStartDate: {
      name: "colDeliveryStartDate",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Delivery Start Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDeliveryStartDate_PROPERTIES

      // END_USER_CODE-USER_colDeliveryStartDate_PROPERTIES
    },
    colFOrB: {
      name: "colFOrB",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "F-B",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFOrB_PROPERTIES

      // END_USER_CODE-USER_colFOrB_PROPERTIES
    },
    colGrpLbs: {
      name: "colGrpLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpLbs_PROPERTIES

      // END_USER_CODE-USER_colGrpLbs_PROPERTIES
    },
    colGrpShareLbs: {
      name: "colGrpShareLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Grp Share Lbs.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpShareLbs_PROPERTIES

      // END_USER_CODE-USER_colGrpShareLbs_PROPERTIES
    },
    colGrpSharePct: {
      name: "colGrpSharePct",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Grp Share%",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrpSharePct_PROPERTIES

      // END_USER_CODE-USER_colGrpSharePct_PROPERTIES
    },
    colMeetGenProduced: {
      name: "colMeetGenProduced",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Meet Gen Produced",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMeetGenProduced_PROPERTIES

      // END_USER_CODE-USER_colMeetGenProduced_PROPERTIES
    },
    colMeetGenProducedCA: {
      name: "colMeetGenProducedCA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Meet Gen Produced",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colMeetGenProducedCA_PROPERTIES

      // END_USER_CODE-USER_colMeetGenProducedCA_PROPERTIES
    },
    colOpenBalance: {
      name: "colOpenBalance",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalance_PROPERTIES

      // END_USER_CODE-USER_colOpenBalance_PROPERTIES
    },
    colOpenBalanceSG: {
      name: "colOpenBalanceSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenBalanceSG_PROPERTIES

      // END_USER_CODE-USER_colOpenBalanceSG_PROPERTIES
    },
    colTentOpenBalance: {
      name: "colTentOpenBalance",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Open Balance",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentOpenBalance_PROPERTIES

      // END_USER_CODE-USER_colTentOpenBalance_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colPurPoundsToApplySG: {
      name: "colPurPoundsToApplySG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Pounds to Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurPoundsToApplySG_PROPERTIES

      // END_USER_CODE-USER_colPurPoundsToApplySG_PROPERTIES
    },
    colPoundsToApply: {
      name: "colPoundsToApply",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Pounds to Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPoundsToApply_PROPERTIES

      // END_USER_CODE-USER_colPoundsToApply_PROPERTIES
    },
    colPriceSG: {
      name: "colPriceSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceSG_PROPERTIES

      // END_USER_CODE-USER_colPriceSG_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPurchLbs: {
      name: "colPurchLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurchLbs_PROPERTIES
      // END_USER_CODE-USER_colPurchLbs_PROPERTIES
    },
    colPurchOrder: {
      name: "colPurchOrder",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Order",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurchOrder_PROPERTIES
      // END_USER_CODE-USER_colPurchOrder_PROPERTIES
    },
    colPurLbsToApply: {
      name: "colPurLbsToApply",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "pur lbs to apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurLbsToApply_PROPERTIES

      // END_USER_CODE-USER_colPurLbsToApply_PROPERTIES
    },
    colPoundsToApplySG: {
      name: "colPoundsToApplySG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPoundsToApplySG_PROPERTIES

      // END_USER_CODE-USER_colPoundsToApplySG_PROPERTIES
    },
    colPurSto1: {
      name: "colPurSto1",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Pur/Sto",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurSto1_PROPERTIES

      // END_USER_CODE-USER_colPurSto1_PROPERTIES
    },
    colPurSto1CA: {
      name: "colPurSto1CA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Pur/Sto",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurSto1CA_PROPERTIES

      // END_USER_CODE-USER_colPurSto1CA_PROPERTIES
    },
    colPurSto2: {
      name: "colPurSto2",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Pue/Sto",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurSto2_PROPERTIES

      // END_USER_CODE-USER_colPurSto2_PROPERTIES
    },
    colPurSto2CA: {
      name: "colPurSto2CA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Pur/Sto",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPurSto2CA_PROPERTIES

      // END_USER_CODE-USER_colPurSto2CA_PROPERTIES
    },
    colSeedCont: {
      name: "colSeedCont",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Seed Cont.",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedCont_PROPERTIES

      // END_USER_CODE-USER_colSeedCont_PROPERTIES
    },
    colSeedLeft: {
      name: "colSeedLeft",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Seed Left",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedLeft_PROPERTIES

      // END_USER_CODE-USER_colSeedLeft_PROPERTIES
    },
    colSeed: {
      name: "colSeed",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed_PROPERTIES

      // END_USER_CODE-USER_colSeed_PROPERTIES
    },
    colSeedVarietyTBD: {
      name: "colSeedVarietyTBD",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Sed Variety TBD",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedVarietyTBD_PROPERTIES

      // END_USER_CODE-USER_colSeedVarietyTBD_PROPERTIES
    },
    colSeedVarietyTBDCA: {
      name: "colSeedVarietyTBDCA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Seed Variety TBD",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedVarietyTBDCA_PROPERTIES

      // END_USER_CODE-USER_colSeedVarietyTBDCA_PROPERTIES
    },
    colStoLbsToApply: {
      name: "colStoLbsToApply",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "sto lbs to apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStoLbsToApply_PROPERTIES

      // END_USER_CODE-USER_colStoLbsToApply_PROPERTIES
    },
    colStoPoundsToApply: {
      name: "colStoPoundsToApply",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStoPoundsToApply_PROPERTIES

      // END_USER_CODE-USER_colStoPoundsToApply_PROPERTIES
    },
    colStoreLbs: {
      name: "colStoreLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStoreLbs_PROPERTIES
      // END_USER_CODE-USER_colStoreLbs_PROPERTIES
    },
    colStoreOrder: {
      name: "colStoreOrder",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Order",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStoreOrder_PROPERTIES
      // END_USER_CODE-USER_colStoreOrder_PROPERTIES
    },
    colTentativeApplied: {
      name: "colTentativeApplied",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Tentative Applied",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentativeApplied_PROPERTIES

      // END_USER_CODE-USER_colTentativeApplied_PROPERTIES
    },
    colTentLbs: {
      name: "colTentLbs",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "tent_lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentLbs_PROPERTIES

      // END_USER_CODE-USER_colTentLbs_PROPERTIES
    },
    colTotalApplicaion: {
      name: "colTotalApplicaion",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Total Application",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTotalApplicaion_PROPERTIES

      // END_USER_CODE-USER_colTotalApplicaion_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colVendorCA: {
      name: "colVendorCA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorCA_PROPERTIES

      // END_USER_CODE-USER_colVendorCA_PROPERTIES
    },
    colVendorContNumsSG: {
      name: "colVendorContNumsSG",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Vendor - Cont. Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorContNumsSG_PROPERTIES

      // END_USER_CODE-USER_colVendorContNumsSG_PROPERTIES
    },
    colVendorContNum: {
      name: "colVendorContNum",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Vendor - Contract Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorContNum_PROPERTIES

      // END_USER_CODE-USER_colVendorContNum_PROPERTIES
    },
    colVendorLbs1: {
      name: "colVendorLbs1",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Vendor Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorLbs1_PROPERTIES

      // END_USER_CODE-USER_colVendorLbs1_PROPERTIES
    },
    colVendorLbs1CA: {
      name: "colVendorLbs1CA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Vendor Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorLbs1CA_PROPERTIES

      // END_USER_CODE-USER_colVendorLbs1CA_PROPERTIES
    },
    colVendorLbs2: {
      name: "colVendorLbs2",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Vendor Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorLbs2_PROPERTIES

      // END_USER_CODE-USER_colVendorLbs2_PROPERTIES
    },
    colVendorLbs2CA: {
      name: "colVendorLbs2CA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Vendor Lbs",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorLbs2CA_PROPERTIES

      // END_USER_CODE-USER_colVendorLbs2CA_PROPERTIES
    },
    colVendorRcpt: {
      name: "colVendorRcpt",
      type: "GridColumnWidget",
      parent: "gridSeedGroverApp",
      Label: "Vendor Rcpt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorRcpt_PROPERTIES

      // END_USER_CODE-USER_colVendorRcpt_PROPERTIES
    },
    colVendorRcptCA: {
      name: "colVendorRcptCA",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "Vendor Rcpt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorRcptCA_PROPERTIES

      // END_USER_CODE-USER_colVendorRcptCA_PROPERTIES
    }, txtColIsStatus: {
      name: "txtColIsStatus",
      type: "GridColumnWidget",
      parent: "grpbxSeedGroverApp",
      Label: "",
      HasLabel: false,
      isStatusColumn: true,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridcolumnwidget7_PROPERTIES

      // END_USER_CODE-USER_gridcolumnwidget7_PROPERTIES
    },
    txtIStatus: {
      name: "txtIStatus",
      type: "TextBoxWidget",
      colName: "txtColIsStatus",
      parent: "grpbxSeedGroverApp",
      Label: "",
      HasLabel: false,
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget8_PROPERTIES

      // END_USER_CODE-USER_textboxwidget8_PROPERTIES
    },

    gridSeedGroverApp: {
      name: "gridSeedGroverApp",
      type: "GridWidget",
      parent: "grpbxSeedGroverApp",
      gridCellsOrder:
        "btncol2,txtcolVendorContNumsSG,txtcolContDateSG,txtcolPurPoundsToApplySG,txtcolStoPoundsToApplySG,txtcolContractsPoundsSG,txtcolAppliedSG,txtcolOpenBalanceSG,txtcolPriceSG,txtcolVendor,txtcolContract,txtcolPurSto1,txtcolVendorLbs1,txtcolPurSto2,txtcolVendorLbs2,txtcolMeetGenProduced,txtcolSeedVarietyTBD,txtcolVendorRcpt,txtIStatus",
      ColSpan: "1",
      Pagination: false,
      HasLabel: false,
      Cols: "1",
      Height: "",
      Width: "",
      isCobolTable: true,
      isEditable: true,
      // START_USER_CODE-USER_gridSeedGroverApp_PROPERTIES
      conditionalRowStyles: [
        {
          when: (row) => { return checkForgridSeedGroverAppRow(row) },
          style: {
            display: "none",
          }
        }
      ],
      // END_USER_CODE-USER_gridSeedGroverApp_PROPERTIES
    }, txtColIsStatus: {
      name: "txtColIsStatus",
      type: "GridColumnWidget",
      parent: "gridViewContractApplication",
      Label: "",
      HasLabel: false,
      isStatusColumn: true,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridcolumnwidget7_PROPERTIES

      // END_USER_CODE-USER_gridcolumnwidget7_PROPERTIES
    },
    txtIStatus: {
      name: "txtIStatus",
      type: "TextBoxWidget",
      colName: "txtColIsStatus",
      parent: "gridViewContractApplication",
      Label: "",
      HasLabel: false,
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget8_PROPERTIES

      // END_USER_CODE-USER_textboxwidget8_PROPERTIES
    },
    gridViewContractApplication: {
      name: "gridViewContractApplication",
      type: "GridWidget",
      parent: "grpbxContractApplication2009",
      gridCellsOrder:
      "btncol1,txtcolContGroup,txtcolVendorContNum,txtcolContDate,txtcolPeanutVariety,txtcolSeed,txtcolFOrB,txtcolPurchOrder,txtcolPurchLbs,txtcolStoreOrder,txtcolStoreLbs,txtcolGrpSharePct,txtcolGrpShareLbs,txtcolContType,txtcolDeliveryStartDate,txtcolDeliveryEndDate,txtcolContractsPounds,txtcolApplied,txtcolOpenBalance,txtcolTentativeApplied,txtcolTotalApplicaion,txtcolTentOpenBalance,txtcolPrice,txtcolApplLbs,txtcolTentLbs,txtcolContApplLbs,txtcolContApplTentLbs,txtcolGrpLbs,txtcolPurLbsToApply,txtcolStoLbsToApply,txtcolSeedCont,txtcolSeedLeft,txtcolVendorCA,txtcolContractCA,txtcolPurSto1CA,txtcolVendorLbs1CA,txtcolPurSto2CA,txtcolVendorLbs2CA,txtcolMeetGenProducedCA,txtcolSeedVarietyTBDCA,txtcolVendorRcptCA,txtIStatus",
      ColSpan: "8",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isCobolTable: true,
      isEditable: true,
      // START_USER_CODE-USER_gridViewContractApplication_PROPERTIES
      conditionalRowStyles: [
        {
          when: (row) => { return checkForgridViewContractApplicationRow(row) },
          style: {
            display: "none",
          }
        }
      ],
      // END_USER_CODE-USER_gridViewContractApplication_PROPERTIES
    },
    grpbxSeedGroverApp: {
      name: "grpbxSeedGroverApp",
      type: "GroupBoxWidget",
      parent: "grpbxContractApplication2009",
      ColSpan: "5",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxSeedGroverApp_PROPERTIES

      // END_USER_CODE-USER_grpbxSeedGroverApp_PROPERTIES
    },
    grpbxSpotApplication: {
      name: "grpbxSpotApplication",
      type: "GroupBoxWidget",
      parent: "grpbxContractApplication2009",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSpotApplication_PROPERTIES

      // END_USER_CODE-USER_grpbxSpotApplication_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES
      Visible:false
      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES
   
      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES
      Visible:false
      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES
      
      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblContractApplications: {
      name: "lblContractApplications",
      type: "LabelWidget",
      parent: "grpbxContractApplication2009",
      Label: "Contract Applications",
      ColSpan: "8",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContractApplications_PROPERTIES

      // END_USER_CODE-USER_lblContractApplications_PROPERTIES
    },
    lblSeedGroverApp: {
      name: "lblSeedGroverApp",
      type: "LabelWidget",
      parent: "grpbxSeedGroverApp",
      Label: "Seed Grover Application",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeedGroverApp_PROPERTIES

      // END_USER_CODE-USER_lblSeedGroverApp_PROPERTIES
    },
    lblSpotApplications: {
      name: "lblSpotApplications",
      type: "LabelWidget",
      parent: "grpbxSpotApplication",
      Label: "Spot Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpotApplications_PROPERTIES

      // END_USER_CODE-USER_lblSpotApplications_PROPERTIES
    },
    lblUnappliedLbs: {
      name: "lblUnappliedLbs",
      type: "LabelWidget",
      parent: "grpbxSpotApplication",
      Label: "Unapplied Lbs",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblUnappliedLbs_PROPERTIES

      // END_USER_CODE-USER_lblUnappliedLbs_PROPERTIES
    },
    txt1007Num: {
      name: "txt1007Num",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "1007 #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007Num_PROPERTIES

      // END_USER_CODE-USER_txt1007Num_PROPERTIES
    },
    txtApplyPounds: {
      name: "txtApplyPounds",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtApplyPounds_PROPERTIES

      // END_USER_CODE-USER_txtApplyPounds_PROPERTIES
    },
    txtcolAppliedSG: {
      name: "txtcolAppliedSG",
      type: "TextBoxWidget",
      colName: "colAppliedSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolAppliedSG_PROPERTIES

      // END_USER_CODE-USER_txtcolAppliedSG_PROPERTIES
    },
    txtcolApplied: {
      name: "txtcolApplied",
      type: "TextBoxWidget",
      colName: "colApplied",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolApplied_PROPERTIES
      // END_USER_CODE-USER_txtcolApplied_PROPERTIES
    },
    txtcolApplLbs: {
      name: "txtcolApplLbs",
      type: "TextBoxWidget",
      colName: "colApplLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolApplLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolApplLbs_PROPERTIES
    },
    txtcolContApplLbs: {
      name: "txtcolContApplLbs",
      type: "TextBoxWidget",
      colName: "colContApplLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContApplLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolContApplLbs_PROPERTIES
    },
    txtcolContApplTentLbs: {
      name: "txtcolContApplTentLbs",
      type: "TextBoxWidget",
      colName: "colContApplTentLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContApplTentLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolContApplTentLbs_PROPERTIES
    },
    txtcolContDateSG: {
      name: "txtcolContDateSG",
      type: "TextBoxWidget",
      colName: "colContDateSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContDateSG_PROPERTIES

      // END_USER_CODE-USER_txtcolContDateSG_PROPERTIES
    },
    txtcolContDate: {
      name: "txtcolContDate",
      type: "TextBoxWidget",
      colName: "colContDate",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContDate_PROPERTIES

      // END_USER_CODE-USER_txtcolContDate_PROPERTIES
    },
    txtcolContGroup: {
      name: "txtcolContGroup",
      type: "TextBoxWidget",
      colName: "colContGroup",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContGroup_PROPERTIES

      // END_USER_CODE-USER_txtcolContGroup_PROPERTIES
    },
    txtcolContract: {
      name: "txtcolContract",
      type: "TextBoxWidget",
      colName: "colContract",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContract_PROPERTIES

      // END_USER_CODE-USER_txtcolContract_PROPERTIES
    },
    txtcolContractCA: {
      name: "txtcolContractCA",
      type: "TextBoxWidget",
      colName: "colContractCA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContractCA_PROPERTIES

      // END_USER_CODE-USER_txtcolContractCA_PROPERTIES
    },
    txtcolContractsPoundsSG: {
      name: "txtcolContractsPoundsSG",
      type: "TextBoxWidget",
      colName: "colContractsPoundsSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContractsPoundsSG_PROPERTIES

      // END_USER_CODE-USER_txtcolContractsPoundsSG_PROPERTIES
    },
    txtcolContractsPounds: {
      name: "txtcolContractsPounds",
      type: "TextBoxWidget",
      colName: "colContractsPounds",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContractsPounds_PROPERTIES

      // END_USER_CODE-USER_txtcolContractsPounds_PROPERTIES
    },
    txtcolContType: {
      name: "txtcolContType",
      type: "TextBoxWidget",
      colName: "colContType",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolContType_PROPERTIES

      // END_USER_CODE-USER_txtcolContType_PROPERTIES
    },
    txtcolDeliveryEndDate: {
      name: "txtcolDeliveryEndDate",
      type: "TextBoxWidget",
      colName: "colDeliveryEndDate",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolDeliveryEndDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDeliveryEndDate_PROPERTIES
    },
    txtcolDeliveryStartDate: {
      name: "txtcolDeliveryStartDate",
      type: "TextBoxWidget",
      colName: "colDeliveryStartDate",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolDeliveryStartDate_PROPERTIES

      // END_USER_CODE-USER_txtcolDeliveryStartDate_PROPERTIES
    },
    txtcolFOrB: {
      name: "txtcolFOrB",
      type: "TextBoxWidget",
      colName: "colFOrB",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolFOrB_PROPERTIES

      // END_USER_CODE-USER_txtcolFOrB_PROPERTIES
    },
    txtcolGrpLbs: {
      name: "txtcolGrpLbs",
      type: "TextBoxWidget",
      colName: "colGrpLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolGrpLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpLbs_PROPERTIES
    },
    txtcolGrpShareLbs: {
      name: "txtcolGrpShareLbs",
      type: "TextBoxWidget",
      colName: "colGrpShareLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolGrpShareLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpShareLbs_PROPERTIES
    },
    txtcolGrpSharePct: {
      name: "txtcolGrpSharePct",
      type: "TextBoxWidget",
      colName: "colGrpSharePct",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolGrpSharePct_PROPERTIES

      // END_USER_CODE-USER_txtcolGrpSharePct_PROPERTIES
    },
    txtcolMeetGenProduced: {
      name: "txtcolMeetGenProduced",
      type: "TextBoxWidget",
      colName: "colMeetGenProduced",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolMeetGenProduced_PROPERTIES

      // END_USER_CODE-USER_txtcolMeetGenProduced_PROPERTIES
    },
    txtcolOpenBalance: {
      name: "txtcolOpenBalance",
      type: "TextBoxWidget",
      colName: "colOpenBalance",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolOpenBalance_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalance_PROPERTIES
    },
    txtcolMeetGenProducedCA: {
      name: "txtcolMeetGenProducedCA",
      type: "TextBoxWidget",
      colName: "colMeetGenProducedCA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolMeetGenProducedCA_PROPERTIES

      // END_USER_CODE-USER_txtcolMeetGenProducedCA_PROPERTIES
    },
    txtcolOpenBalanceSG: {
      name: "txtcolOpenBalanceSG",
      type: "TextBoxWidget",
      colName: "colOpenBalanceSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolOpenBalanceSG_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenBalanceSG_PROPERTIES
    },
    txtcolTentOpenBalance: {
      name: "txtcolTentOpenBalance",
      type: "TextBoxWidget",
      colName: "colTentOpenBalance",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolTentOpenBalance_PROPERTIES

      // END_USER_CODE-USER_txtcolTentOpenBalance_PROPERTIES
    },
    txtcolPeanutVariety: {
      name: "txtcolPeanutVariety",
      type: "TextBoxWidget",
      colName: "colPeanutVariety",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
    },
    txtcolPurPoundsToApplySG: {
      name: "txtcolPurPoundsToApplySG",
      type: "TextBoxWidget",
      colName: "colPurPoundsToApplySG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurPoundsToApplySG_PROPERTIES

      // END_USER_CODE-USER_txtcolPurPoundsToApplySG_PROPERTIES
    },
    txtcolPoundsToApply: {
      name: "txtcolPoundsToApply",
      type: "TextBoxWidget",
      colName: "colPoundsToApply",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPoundsToApply_PROPERTIES

      // END_USER_CODE-USER_txtcolPoundsToApply_PROPERTIES
    },
    txtcolPriceSG: {
      name: "txtcolPriceSG",
      type: "TextBoxWidget",
      colName: "colPriceSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPriceSG_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceSG_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPurchLbs: {
      name: "txtcolPurchLbs",
      type: "TextBoxWidget",
      colName: "colPurchLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurchLbs_PROPERTIES
      // END_USER_CODE-USER_txtcolPurchLbs_PROPERTIES
    },
    txtcolPurchOrder: {
      name: "txtcolPurchOrder",
      type: "TextBoxWidget",
      colName: "colPurchOrder",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurchOrder_PROPERTIES
      // END_USER_CODE-USER_txtcolPurchOrder_PROPERTIES
    },

    txtcolPurLbsToApply: {
      name: "txtcolPurLbsToApply",
      type: "TextBoxWidget",
      colName: "colPurLbsToApply",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtcolPurLbsToApply_PROPERTIES
    },
    txtcolPurSto1: {
      name: "txtcolPurSto1",
      type: "TextBoxWidget",
      colName: "colPurSto1",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurSto1_PROPERTIES

      // END_USER_CODE-USER_txtcolPurSto1_PROPERTIES
    },
    txtcolPurSto1CA: {
      name: "txtcolPurSto1CA",
      type: "TextBoxWidget",
      colName: "colPurSto1CA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurSto1CA_PROPERTIES

      // END_USER_CODE-USER_txtcolPurSto1CA_PROPERTIES
    },
    txtcolPurSto2: {
      name: "txtcolPurSto2",
      type: "TextBoxWidget",
      colName: "colPurSto2",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurSto2_PROPERTIES

      // END_USER_CODE-USER_txtcolPurSto2_PROPERTIES
    },
    txtcolPurSto2CA: {
      name: "txtcolPurSto2CA",
      type: "TextBoxWidget",
      colName: "colPurSto2CA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPurSto2CA_PROPERTIES

      // END_USER_CODE-USER_txtcolPurSto2CA_PROPERTIES
    },
    txtcolSeedCont: {
      name: "txtcolSeedCont",
      type: "TextBoxWidget",
      colName: "colSeedCont",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolSeedCont_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedCont_PROPERTIES
    },
    txtcolSeedLeft: {
      name: "txtcolSeedLeft",
      type: "TextBoxWidget",
      colName: "colSeedLeft",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolSeedLeft_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedLeft_PROPERTIES
    },
    txtcolSeed: {
      name: "txtcolSeed",
      type: "TextBoxWidget",
      colName: "colSeed",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolSeed_PROPERTIES
    },
    txtcolSeedVarietyTBD: {
      name: "txtcolSeedVarietyTBD",
      type: "TextBoxWidget",
      colName: "colSeedVarietyTBD",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolSeedVarietyTBD_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedVarietyTBD_PROPERTIES
    },
    txtcolSeedVarietyTBDCA: {
      name: "txtcolSeedVarietyTBDCA",
      type: "TextBoxWidget",
      colName: "colSeedVarietyTBDCA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolSeedVarietyTBDCA_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedVarietyTBDCA_PROPERTIES
    },
    txtcolStoLbsToApply: {
      name: "txtcolStoLbsToApply",
      type: "TextBoxWidget",
      colName: "colStoLbsToApply",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolStoLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtcolStoLbsToApply_PROPERTIES
    },
    txtcolStoPoundsToApplySG: {
      name: "txtcolStoPoundsToApplySG",
      type: "TextBoxWidget",
      colName: "colStoPoundsToApply",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolStoPoundsToApplySG_PROPERTIES

      // END_USER_CODE-USER_txtcolStoPoundsToApplySG_PROPERTIES
    },
    txtcolStoreLbs: {
      name: "txtcolStoreLbs",
      type: "TextBoxWidget",
      colName: "colStoreLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolStoreLbs_PROPERTIES
      // END_USER_CODE-USER_txtcolStoreLbs_PROPERTIES
    },
    txtcolStoreOrder: {
      name: "txtcolStoreOrder",
      type: "TextBoxWidget",
      colName: "colStoreOrder",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolStoreOrder_PROPERTIES
      // END_USER_CODE-USER_txtcolStoreOrder_PROPERTIES
    },
    txtcolTentativeApplied: {
      name: "txtcolTentativeApplied",
      type: "TextBoxWidget",
      colName: "colTentativeApplied",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolTentativeApplied_PROPERTIES

      // END_USER_CODE-USER_txtcolTentativeApplied_PROPERTIES
    },
    txtcolTentLbs: {
      name: "txtcolTentLbs",
      type: "TextBoxWidget",
      colName: "colTentLbs",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolTentLbs_PROPERTIES

      // END_USER_CODE-USER_txtcolTentLbs_PROPERTIES
    },
    txtcolTotalApplicaion: {
      name: "txtcolTotalApplicaion",
      type: "TextBoxWidget",
      colName: "colTotalApplicaion",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolTotalApplicaion_PROPERTIES
      // END_USER_CODE-USER_txtcolTotalApplicaion_PROPERTIES
    },

    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolVendorCA: {
      name: "txtcolVendorCA",
      type: "TextBoxWidget",
      colName: "colVendorCA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorCA_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorCA_PROPERTIES
    },
    txtcolVendorContNumsSG: {
      name: "txtcolVendorContNumsSG",
      type: "TextBoxWidget",
      colName: "colVendorContNumsSG",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorContNumsSG_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorContNumsSG_PROPERTIES
    },
    txtcolVendorContNum: {
      name: "txtcolVendorContNum",
      type: "TextBoxWidget",
      colName: "colVendorContNum",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorContNum_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorContNum_PROPERTIES
    },
    txtcolVendorLbs1: {
      name: "txtcolVendorLbs1",
      type: "TextBoxWidget",
      colName: "colVendorLbs1",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorLbs1_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorLbs1_PROPERTIES
    },
    txtcolVendorLbs1CA: {
      name: "txtcolVendorLbs1CA",
      type: "TextBoxWidget",
      colName: "colVendorLbs1CA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorLbs1CA_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorLbs1CA_PROPERTIES
    },
    txtcolVendorLbs2: {
      name: "txtcolVendorLbs2",
      type: "TextBoxWidget",
      colName: "colVendorLbs2",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorLbs2_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorLbs2_PROPERTIES
    },
    txtcolVendorLbs2CA: {
      name: "txtcolVendorLbs2CA",
      type: "TextBoxWidget",
      colName: "colVendorLbs2CA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorLbs2CA_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorLbs2CA_PROPERTIES
    },
    txtcolVendorRcpt: {
      name: "txtcolVendorRcpt",
      type: "TextBoxWidget",
      colName: "colVendorRcpt",
      parent: "gridSeedGroverApp",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorRcpt_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorRcpt_PROPERTIES
    },
    txtcolVendorRcptCA: {
      name: "txtcolVendorRcptCA",
      type: "TextBoxWidget",
      colName: "colVendorRcptCA",
      parent: "gridViewContractApplication",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendorRcptCA_PROPERTIES

      // END_USER_CODE-USER_txtcolVendorRcptCA_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "Farm",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtLbsToApply: {
      name: "txtLbsToApply",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Lbs to Apply:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtLbsToApply_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "Peanut Type",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtTentPounds: {
      name: "txtTentPounds",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "Pounds",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTentPounds_PROPERTIES

      // END_USER_CODE-USER_txtTentPounds_PROPERTIES
    },
    txtUnappliedLbs: {
      name: "txtUnappliedLbs",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Lbs to Apply:",
      Enabled: false,
      // HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUnappliedLbs_PROPERTIES

      // END_USER_CODE-USER_txtUnappliedLbs_PROPERTIES
    },
    txtPrchsStrg: {
      name: "txtPrchsStrg",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "Purchase / Storage",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrchsStrg_PROPERTIES

      // END_USER_CODE-USER_txtPrchsStrg_PROPERTIES
    },
    txtPrice: {
      name: "txtPrice",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPrice_PROPERTIES

      // END_USER_CODE-USER_txtPrice_PROPERTIES
    },
    txtSC95Num: {
      name: "txtSC95Num",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "SC95 #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95Num_PROPERTIES

      // END_USER_CODE-USER_txtSC95Num_PROPERTIES
    },
    txtSeedSpec: {
      name: "txtSeedSpec",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "Seed Spec.",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeedSpec_PROPERTIES

      // END_USER_CODE-USER_txtSeedSpec_PROPERTIES
    },
    txtSeed: {
      name: "txtSeed",
      type: "TextBoxWidget",
      parent: "grpbxContractApplication2009",
      Label: "Seed",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeed_PROPERTIES

      // END_USER_CODE-USER_txtSeed_PROPERTIES
    },
    grpbxContractApplication2009: {
      name: "grpbxContractApplication2009",
      type: "GroupBoxWidget",
      parent: "ContractApplications2009",
      Height: "",
      Width: "",
      ColsForMobile: "8",
      ColsForTabLandscape: "8",
      HasLabel: false,
      Cols: "8",
      ColsForTabPotrait: "8",
      ColsForLargeDesktop: "8",
      // START_USER_CODE-USER_grpbxContractApplication2009_PROPERTIES

      // END_USER_CODE-USER_grpbxContractApplication2009_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ContractApplications2009",
      Height: "",
      Width: "",
      ColsForMobile: "6",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const [msInspDate, setmsInspDate] = useState("")
  const [lblCMAVendor, setlblCMAVendor] = useState("")
  const [mbUnappliedSeedContAvail, setmbUnappliedSeedContAvail] = useState(false)

  // START_USER_CODE-USER_METHODS
  let msInspStatus;
  let lblEditMode = "FALSE"
  let mbAuthContApplEditAllow = false
  let lblVendorNumber
  let cboPS
  let lblPounds
  let lblCMAParticipant
  let CboWRNum
  let cmdPurchaseStorage = false
  let C_VEND_LBS_1
  let C_VEND_RCPT
  let C_P_S_IND_1
  let msBuyingPointID
  let lblApplyPounds = 0
  let lblTentPounds = 0

  async function FormLoad() {
    lblEditMode = "FALSE"
    mbAuthContApplEditAllow = false
    //setDataForContractApplications2009();
    await bFillContractApplications();
    await InitContractApplicationsGrid();
    await InitSeedGrowerApplicationsGrid();
    await GetEditPermission();
    hide(thisObj, "btnUndoEdit")
    setLoading(false);
  }

  async function InitContractApplicationsGrid() {
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolPurchOrder') //C_PURCH_ORDER
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolStoreOrder') //C_STORE_ORDER
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolGrpShareLbs') //C_SHARE_LBS
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolOpenBalance') //C_OPEN_LBS 
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolApplLbs')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolTentLbs')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolContApplLbs')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolContApplTentLbs')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolGrpLbs')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolPurLbsToApply')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolStoLbsToApply')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolSeedCont')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolSeedLeft')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolVendorCA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolContractCA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolPurSto1CA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolVendorLbs1CA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolPurSto2CA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolVendorLbs2CA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolMeetGenProducedCA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolSeedVarietyTBDCA')
    hideColumn(thisObj, 'gridViewContractApplication', 'txtcolVendorRcptCA')
  }

  async function InitSeedGrowerApplicationsGrid() {
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolVendor')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolContract')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolPurSto1')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolVendorLbs1')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolPurSto2')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolVendorLbs2')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolMeetGenProduced')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolSeedVarietyTBD')
    hideColumn(thisObj, 'gridSeedGroverApp', 'txtcolVendorRcpt')
  }

  async function bFillContractApplications() {
    let response
    let lstrxml
    let LstrFarmList
    let LstrSTATE_ABBR
    let LstrCOUNTY_ID
    let sRemarks
    let sScaleTickets
    let mlLastRevNum
    let sEdible
    let dtInspDate
    let msSeedMeetingSpecInd
    let sGroupID
    let nControlIndex = 0
    let sFree1007
    let bFreed1007 = false
    let sInspNum
    let varietyidTag
    let lblSeedGenProduced
    let sContractNum
    let sSplitVendor
    let bFarmAllowed = false
    let contract_pricing
    let vsfDataSGA
    let sSeg
    let lblFarmNumber
    let mdblPurchPct = 0
    let mdblStorePct = 0
    let bFillContractApplications = false
    let lblPurchaseStorageTag = ""

    let frmContractApplications2009LoadDataObj = getData(thisObj, 'frmContractApplications2009LoadData')

    lblPurchaseStorageTag = frmContractApplications2009LoadDataObj.PurchaseStorageTag
    let SC95_num = frmContractApplications2009LoadDataObj.FV95Number
    let Insp1007_num = frmContractApplications2009LoadDataObj.I1007Number
    setlblCMAVendor(frmContractApplications2009LoadDataObj.lblCMAVendor)
    setValue(thisObj, "txtSC95Num", SC95_num)
    setValue(thisObj, "txt1007Num", Insp1007_num)
    if (lblPurchaseStorageTag === "P") {
      setValue(thisObj, "txtPrchsStrg", "Storage")
      cmdPurchaseStorage = true
    }
    else {
      setValue(thisObj, "txtPrchsStrg", "Purchase")
      cmdPurchaseStorage = true
    }

    if (SC95_num != null && SC95_num != undefined) {
      response = await SettlementService.RetrieveInspectionHeaderList(SC95_num, null, null, null)
      if (response.length != 0) {
        sRemarks = response[0].inspect_remarks
        sScaleTickets = response[0].inspect_scale_tickets
        LstrSTATE_ABBR = response[0].state_abbr
        LstrCOUNTY_ID = response[0].county_id
        msInspStatus = response[0].insp_status
        setData(thisObj, "msInspStatus", response[0].insp_status)
        setmsInspDate(response[0].insp_date_time)
        let farmname = response[0].farm_id + " - " + response[0].farm_suffix + " - " + response[0].farm_name
        setValue(thisObj, 'txtFarm', farmname)
        setValue(thisObj, 'txtPeanutType', response[0].pnut_type_name)
        varietyidTag = response[0].pnut_variety_id
        msBuyingPointID = response[0].buy_pt_id
        mlLastRevNum = response[0].revision_num
        sEdible = response[0].edible_oil_ind
        dtInspDate = ((response[0].insp_date_time))//.split(' ')[0]);//Commented as part of UAT Bug_546666 need to pass both Date and Time to get spot Price.
        lblSeedGenProduced = response[0].seed_gen
        sSeg = response[0].seg_type
        lblFarmNumber = response[0].farm_id

        if ((response[0].seg_type) === "1" && (response[0].gen_produced_name) != "" && (response[0].gen_produced_name) != null) {
          setValue(thisObj, "txtSeed", "Yes")
        }
        else {
          setValue(thisObj, "txtSeed", "No")
        }
        msSeedMeetingSpecInd = (response[0].seed_meeting_spec_ind).toUpperCase()
        if (msSeedMeetingSpecInd == "Y") {
          setValue(thisObj, "txtSeedSpec", "Yes")
        }
        else if (msSeedMeetingSpecInd == "N") {
          setValue(thisObj, "txtSeedSpec", "No")
        }
        else {
          setValue(thisObj, "txtSeedSpec", "")
        }
        setData(thisObj, "lblSeedPremExpected", msSeedMeetingSpecInd)//Data Passing To 1007Settlement
        sGroupID = response[0].buy_pt_group_id

        lblApplyPounds = 0
        lblTentPounds = 0
        nControlIndex = 0
        let inspectVendorSplitMainArray = []
        let inspectVendorSplitSubArray = []
        let inspectVendorSplitObj
        let respone_inspect_vendor_splits = response[0].inspect_vendor_splits

        for (var i = 0; i < respone_inspect_vendor_splits.length; i++) {
          inspectVendorSplitObj = {}

          inspectVendorSplitObj.split_vendor = respone_inspect_vendor_splits[i].split_vendor
          inspectVendorSplitObj.split_share_pct = respone_inspect_vendor_splits[i].split_share_pct
          inspectVendorSplitObj.purh_stor_ind = respone_inspect_vendor_splits[i].purh_stor_ind
          inspectVendorSplitObj.net_wt = respone_inspect_vendor_splits[i].net_wt
          inspectVendorSplitSubArray.push(inspectVendorSplitObj)
          inspectVendorSplitMainArray.push(inspectVendorSplitSubArray)

          if (msInspStatus != "I" && msInspStatus != "P") {
            if (inspectVendorSplitObj.purh_stor_ind === "P") {
              lblApplyPounds = Number(lblApplyPounds) + Number(inspectVendorSplitObj.net_wt)
              mdblPurchPct = Number(mdblPurchPct) + Number(inspectVendorSplitObj.split_share_pct)
            }
            if (inspectVendorSplitObj.purh_stor_ind === "S") {
              lblTentPounds = Number(lblTentPounds) + Number(inspectVendorSplitObj.net_wt)
              mdblStorePct = Number(mdblStorePct) + Number(inspectVendorSplitObj.split_share_pct)
            }
          }
          else {
            if (msInspStatus === "I") {
              if (await GetVendorPurStoInd(inspectVendorSplitObj.split_vendor) === "P") {
                lblApplyPounds = Number(lblApplyPounds) + Number(inspectVendorSplitObj.net_wt)
                mdblPurchPct = Number(mdblPurchPct) + Number(inspectVendorSplitObj.split_share_pct)
              }
              else {
                lblTentPounds = Number(lblTentPounds) + Number(inspectVendorSplitObj.net_wt)
                mdblStorePct = Number(mdblStorePct) + Number(inspectVendorSplitObj.split_share_pct)
              }
            }
          }
          setData(thisObj, "lblApplyPounds", lblApplyPounds)
          setData(thisObj, "lblTentPounds", lblTentPounds)
        }
        if (lblApplyPounds > 0) {
          setValue(thisObj, "txtApplyPounds", lblApplyPounds)
        }
        else{
          setValue(thisObj, "txtApplyPounds", "0")
        }

        if (lblTentPounds > 0) {
          setValue(thisObj, "txtTentPounds", lblTentPounds)
        }
        else{
          setValue(thisObj, "txtTentPounds", "0")
        }
      }
      else {
        return;
      }

      let navaillbs
      let ntentavaillbs

      if (Number(txtTentPounds.value) === 0) {
        navaillbs = (txtApplyPounds.value)
      }
      else {
        ntentavaillbs = (txtTentPounds.value)
      }
      if (((txtTentPounds.value) != "0") && ((txtApplyPounds.value) != "0")) {
        navaillbs = (txtApplyPounds.value)
        ntentavaillbs = (txtTentPounds.value)
      }
      if (lblEditMode != "TRUE" && (response[0].freE1007) === "Y") {
        sFree1007 = "Y"
        bFreed1007 = true
        sInspNum = SC95_num
      }
      else {
        sFree1007 = "N"
        bFreed1007 = false
        sInspNum = ""
      }
      lstrxml = await SettlementService.RetrieveContractDetails(null, varietyidTag, null, null, null, null, null, null, msBuyingPointID, null, "PN1050", null, userid, SC95_num, sFree1007, Insp1007_num, lblSeedGenProduced, null)
      let js = []
      let jsSG = []
      if (lstrxml.length > 0) {
        for (var i = 0; i < lstrxml.length; i++) {
          bFarmAllowed = true
          if (lstrxml[i].farm_spec_ind === "Y") {
            LstrFarmList = await ContractManagementService.RetrieveFarmPickDetails(msBuyingPointID, sSplitVendor, sContractNum, lblFarmNumber)
            if (LstrFarmList.length != 0) {
              if (LstrFarmList[0].in_contract_farm_list === "Y") {
                bFarmAllowed = true
              }
              else {
                bFarmAllowed = false
              }
            }
            else {
              bFarmAllowed = false
            }
          }

          if (bFarmAllowed === true) {
            if ((lstrxml[i].cont_type) === "S") {
              //======================================gridSeedGroverApp==========================================================
              let SD_VEND_RCPT
              for (var j = 0; j < lstrxml[i].addendum_seed_growers.length; j++) {
                var sGAObj = {}
                vsfDataSGA = lstrxml[i].addendum_seed_growers[j]
                if (vsfDataSGA.length != 0 || lstrxml[i].seed_tbd === "Y") {
                  if ((Number(vsfDataSGA.addendum_seed_cont_lbs)) > (Number(vsfDataSGA.addendum_seed_appl_lbs))) {
                    setmbUnappliedSeedContAvail(true)
                    setData(thisObj,"mbUnappliedSeedContAvail",true)
                  }
                  if (!isNaN(navaillbs)) {
                    navaillbs = navaillbs
                  }
                  else {
                    navaillbs = 0
                  }
                  sGAObj.txtcolVendorContNumsSG = lstrxml[i].split_vendor + " - " + lstrxml[i].contract_num
                  sGAObj.txtcolContDateSG = (lstrxml[i].cont_date).split(' ')[0];
                  sGAObj.txtcolContractsPoundsSG = Number(vsfDataSGA.addendum_seed_cont_lbs);
                  sGAObj.txtcolAppliedSG = Number(vsfDataSGA.addendum_seed_appl_lbs);
                  sGAObj.txtcolOpenBalanceSG = (Number(vsfDataSGA.addendum_seed_cont_lbs) - Number(vsfDataSGA.addendum_seed_appl_lbs));
                  sGAObj.txtcolPriceSG = Number((vsfDataSGA.addendum_seed_prem_per_ton));
                  sGAObj.txtcolVendor = lstrxml[i].split_vendor;
                  sGAObj.txtcolContract = lstrxml[i].contract_num;
                  if (lblCMAVendor === "") {
                    sGAObj.txtcolPurSto1 = await GetVendorPurStoInd(lstrxml[i].split_vendor);
                    C_P_S_IND_1 = await GetVendorPurStoInd(lstrxml[i].split_vendor);
                    sGAObj.txtcolVendorLbs1 = await GetVendorPounds(lstrxml[i].split_vendor, false, C_P_S_IND_1);
                    C_VEND_LBS_1 = await GetVendorPounds(lstrxml[i].split_vendor, false, C_P_S_IND_1);
                  }
                  else {
                    sGAObj.txtcolPurSto1 = "S";
                    sGAObj.txtcolVendorLbs1 = await GetVendorPounds(lstrxml[i].split_vendor, true, "S");
                    sGAObj.txtcolPurSto2 = "P";
                    sGAObj.txtcolVendorLbs2 = await GetVendorPounds(lstrxml[i].split_vendor, true, "P");
                    C_VEND_LBS_1 = await GetVendorPounds(lstrxml[i].split_vendor, true, "P");
                    sGAObj.txtcolVendorRcpt = await GetVendorRcpt(lstrxml[i].split_vendor);
                    SD_VEND_RCPT = await GetVendorRcpt(lstrxml[i].split_vendor);
                  }
                  if ((bFreed1007 === true) && (SD_VEND_RCPT != "") && (vsfDataSGA.seed_contract_free1007).toUpperCase() === "Y") {//722   vsfDataSGA.TextMatrix(vsfDataSGA.Row, SD_VEND_RCPT)
                    sGAObj.txtcolStoPoundsToApplySG = vsfDataSGA[i].seed_contract_insp_lbs;
                  }
                  sGAObj.txtcolMeetGenProduced = lstrxml[i].meet_seed_gen_prod;
                  sGAObj.txtcolSeedVarietyTBD = lstrxml[i].seed_tbd
                }
                jsSG.push(sGAObj);
              }
              setValue(thisObj, "gridSeedGroverApp", jsSG)
            } else {
              //======================================gridViewContractApplication=================================
              for (var j = 0; j < lstrxml[i].contract_pricing.length; j++) {
                let cAObj = {}
                contract_pricing = lstrxml[i].contract_pricing[j]
                let addendum_seed_growers = lstrxml[i].addendum_seed_growers;
                cAObj.txtcolContGroup = lstrxml[i].grouping_num;
                cAObj.txtcolVendorContNum = lstrxml[i].split_vendor + "-" + lstrxml[i].contract_num;
                cAObj.txtcolContDate = (lstrxml[i].cont_date).split(' ')[0];
                var pnutVarietyName = lstrxml[i].pnut_variety_name;
                cAObj.txtcolPeanutVariety = pnutVarietyName
                if ((contract_pricing.symbol_ind) != undefined) {
                  switch ((contract_pricing.symbol_ind).toUpperCase()) {
                    case "TRADEMARK":
                      cAObj.txtcolPeanutVariety = pnutVarietyName + ' ᵀᴹ';
                      break;
                    case "REGISTERED TRADEMARK":
                      cAObj.txtcolPeanutVariety = pnutVarietyName + ' ®';
                      break;
                    case "COPYRIGHT":
                      cAObj.txtcolPeanutVariety = pnutVarietyName + ' ©';
                      break;
                    default:
                      cAObj.txtcolPeanutVariety = pnutVarietyName
                  }
                }
                cAObj.txtcolSeed = lstrxml[i].seed_grower;
                if (contract_pricing.firm_basis === "F") {
                  cAObj.txtcolFOrB = "Firm";
                }
                else {
                  cAObj.txtcolFOrB = "Basis";
                }

                cAObj.txtcolPurchOrder = "";
                cAObj.txtcolContType = lstrxml[i].cont_type;
                cAObj.txtcolDeliveryStartDate = (contract_pricing.delv_beg_date).split(' ')[0];;
                cAObj.txtcolDeliveryEndDate = (contract_pricing.delv_end_date).split(' ')[0];;
                cAObj.txtcolContractsPounds = contract_pricing.cont_lbs;
                cAObj.txtcolApplied = contract_pricing.appl_lbs;

                cAObj.txtcolOpenBalance = Number(contract_pricing.cont_lbs) - Number(contract_pricing.appl_lbs);
                cAObj.txtcolTentativeApplied = contract_pricing.tentative_lbs;
                cAObj.txtcolTotalApplicaion = Number(contract_pricing.appl_lbs) + Number(contract_pricing.tentative_lbs);
                cAObj.txtcolTentOpenBalance = Number(contract_pricing.cont_lbs) - Number(Number(contract_pricing.appl_lbs) + Number(contract_pricing.tentative_lbs));
                cAObj.txtcolPrice = (contract_pricing.price_per_ton)
                cAObj.txtcolApplLbs = contract_pricing.appl_lbs;
                cAObj.txtcolTentLbs = contract_pricing.tentative_lbs;

                cAObj.txtcolContApplLbs = Number(contract_pricing.cont_lbs) - Number(contract_pricing.appl_lbs);
                cAObj.txtcolContApplTentLbs = Number(contract_pricing.cont_lbs) - Number(contract_pricing.appl_lbs) - Number(contract_pricing.tentative_lbs);
                let C_HID_TENT_2 = Number(contract_pricing.cont_lbs) - Number(contract_pricing.appl_lbs) - Number(contract_pricing.tentative_lbs);
                if (C_HID_TENT_2 < 0) {
                  cAObj.txtcolContApplTentLbs = 0
                }
                cAObj.txtcolSeedCont = lstrxml[i].seed_adden_cont_num;
                cAObj.txtcolSeedLeft = addendum_seed_growers.length == 0 ? '' : addendum_seed_growers.addendum_seed_cont_lbs;
                cAObj.txtcolVendorCA = lstrxml[i].split_vendor
                cAObj.txtcolContractCA = lstrxml[i].contract_num

                if (lblCMAVendor === "") {
                  cAObj.txtcolPurSto1CA = await GetVendorPurStoInd(lstrxml[i].split_vendor)
                  C_P_S_IND_1 = await GetVendorPurStoInd(lstrxml[i].split_vendor)
                  cAObj.txtcolVendorLbs1CA = await GetVendorPounds(lstrxml[i].split_vendor, false, C_P_S_IND_1)
                  cAObj.txtcolVendorRcptCA = await GetVendorRcpt(lstrxml[i].split_vendor)
                }
                else {
                  cAObj.txtcolPurSto1CA = "S"
                  cAObj.txtcolVendorLbs1CA = await GetVendorPounds(lstrxml[i].split_vendor, true, "S")
                  cAObj.txtcolPurSto2CA = "P"
                  cAObj.txtcolVendorLbs2CA = await GetVendorPounds(lstrxml[i].split_vendor, true, "P")
                  cAObj.txtcolVendorRcptCA = await GetVendorRcpt(lstrxml[i].split_vendor)
                  C_VEND_RCPT = await GetVendorRcpt(lstrxml[i].split_vendor)
                }

                cAObj.txtcolMeetGenProducedCA = lstrxml[i].meet_seed_gen_prod;
                cAObj.txtcolSeedVarietyTBDCA = lstrxml[i].seed_tbd;

                if (bFreed1007 === true && (C_VEND_RCPT != "") && ((contract_pricing.free1007).toUpperCase() === "Y")) {
                  cAObj.txtcolPurchLbs = contract_pricing.cont_appl_lbs;
                  cAObj.txtcolStoreLbs = contract_pricing.cont_tent_lbs;

                  await AdjustPounds((C_P_S_IND_1).toUpperCase(), gridViewContractApplicationData) //pending
                }
                else {
                  cAObj.txtcolStoreLbs = ''
                  cAObj.txtcolPurchLbs = ''
                }

                if (!isNaN(lstrxml[i].grouping_share)) {
                  cAObj.txtcolGrpSharePct = lstrxml[i].grouping_share
                }
                cAObj.txtcolStoLbsToApply = ''
                cAObj.txtcolPurLbsToApply = ''
                js.push(cAObj);
              }
              setValue(thisObj, "gridViewContractApplication", js)
            }
          }
        }
      }
      if( response[0].spot_pounds !=undefined && response[0].spot_pounds!= null){
        setValue(thisObj, "txtLbsToApply", response[0].spot_pounds)
      }
      switch ((response[0].insp_status)) {
        case "I" || "P":
          let Lstrxml = await SettlementService.RetrieveSpotPriceControlList(compId, cropYear, msBuyingPointID, response[0].pnut_type_id, dtInspDate)//dtInspDate
          if (Lstrxml.length > 0) {
            switch ((sSeg)) {
              case "1":
                setValue(thisObj, "txtPrice", Number(Lstrxml[0].seg_1_spot))// As part of UAT Bug_546666 formated the spot Price value.
                break
              case "2":
                switch ((sEdible).toUpperCase()) {
                  case "":
                    setValue(thisObj, "txtPrice", Number(Lstrxml[0].seg_2_spot))
                    break             //Added Break statement As part of UAT Bug_546666 
                  case "E":
                    setValue(thisObj, "txtPrice", Number(Lstrxml[0].seg_2_edible_spot))
                    break             //Added Break statement As part of UAT Bug_546666
                  case "O":
                    setValue(thisObj, "txtPrice", Number(Lstrxml[0].seg_2_oil_spot))
                    break             //Added Break statement As part of UAT Bug_546666
                }
                break
              case "3":
                switch ((sEdible).toUpperCase()) {
                  case "":
                    setValue(thisObj, "txtPrice", Number(Lstrxml[0].seg_2_spot))
                    break             //Added Break statement As part of UAT Bug_546666
                  case "E":
                    setValue(thisObj, "txtPrice", Number(Lstrxml[0].seg_2_edible_spot))
                    break              //Added Break statement As part of UAT Bug_546666
                  case "O":
                    setValue(thisObj, "txtPrice",Number(Lstrxml[0].seg_2_oil_spot))
                    break              //Added Break statement As part of UAT Bug_546666
                }
                break
            }
          }
          break;
        default:
          setValue(thisObj, "txtPrice", response[0].spot_price)
      }

      if (lblEditMode != "TRUE") {

        let gridViewContractApplicationData = getValue(thisObj, "gridViewContractApplication")
        let gridSeedGroverAppData = getValue(thisObj, "gridSeedGroverApp")

        if (bFreed1007 === true) {
          for (var j = gridViewContractApplicationData.length - 1; j >= 0; j--) {
            if (Number(gridViewContractApplicationData[j].txtcolPurchLbs) === 0 && Number(gridViewContractApplicationData[j].txtcolStoreLbs) === 0) {
              if (gridViewContractApplicationData[j].txtcolVendorRcptCA === "") {
                if (lblCMAVendor === "") {
                  await EnterPounds(false, "C_P_S_IND_1", "C_VEND_LBS_1", gridViewContractApplicationData, gridSeedGroverAppData)
                }
                else {
                  await EnterPounds(true, "C_P_S_IND_1", "C_VEND_LBS_1", gridViewContractApplicationData, gridSeedGroverAppData)
                  await EnterPounds(true, "C_P_S_IND_1", "C_VEND_LBS_1", gridViewContractApplicationData, gridSeedGroverAppData)
                }
              }
            }
          }
          for (var k = gridViewContractApplicationData.length - 1; k >= 0; k--) {
            if (Number(gridViewContractApplicationData[k].txtcolPurchLbs) === 0 && Number(gridViewContractApplicationData[k].txtcolStoreLbs) === 0) {
              gridViewContractApplicationData.splice(k, 1) //Removes item from Array
            }
          }
          for (var l = gridSeedGroverAppData.length - 1; l >= 0; l--) {
            if (Number(gridSeedGroverAppData[l].txtcolStoPoundsToApplySG) === 0) {
              gridSeedGroverAppData.splice(l, 1) //Removes item from Array
            }
          }
        }
        else {
          if ((lblCMAVendor) === "") {
            await EnterPounds(false, "C_P_S_IND_1", "C_VEND_LBS_1", gridViewContractApplicationData, gridSeedGroverAppData)
          }
          else {
            await EnterPounds(true, "C_P_S_IND_1", "C_VEND_LBS_1", gridViewContractApplicationData, gridSeedGroverAppData)
            await EnterPounds(true, "C_P_S_IND_1", "C_VEND_LBS_1", gridViewContractApplicationData, gridSeedGroverAppData)
          }
        }
        setValue(thisObj, "gridViewContractApplication", gridViewContractApplicationData)
        setValue(thisObj, "gridSeedGroverApp", gridSeedGroverAppData)  
      }
      bFillContractApplications = true

      await EnableDisableControls()

      if (lblPurchaseStorageTag === "") {
        //     If vsfData.Rows > 1 Then
        //     vsfData.Row = 1
        //  End If
      }
      else {
        if (lblPurchaseStorageTag === "P") {
          document.getElementById("btnPurchase").innerText = "Storage";
          cmdPurchaseStorage = true
          await onbtnPurchaseClick()
          // if(vsfData.Rows > 1){
          //   vsfData.Row = 1
          // }
        }
        else {
          document.getElementById("btnPurchase").innerText = "Purchase";
          cmdPurchaseStorage = true
          await onbtnPurchaseClick()
          // if(vsfData.Rows > 1){
          //   vsfData.Row = 1
          // }
        }
      }
      bFillContractApplications = true
    }
  }

  // ==========================================bformValid functionality===============================
  const bFormValid = async () => {
    let bFormValid = false;
    let bAllPoundsUsed = false;
    
    if (getValue(thisObj, 'txt1007Num') == 0 || getValue(thisObj, 'txt1007Num') == "" || getValue(thisObj, 'txt1007Num') == undefined) {
      alert("The 1007s cannnot be created until a number range has been entered for this buying point location");
      return;
    }
    let gridData = getValue(thisObj, 'gridViewContractApplication');
    for (let i = 0; i < gridData.length; i++) {
      if (gridData[i].txtcolPurchLbs.toString().replace(/\s/g, '')  != "") {
        if (isNumeric(gridData[i].txtcolPurchLbs.toString().replace(/\s/g, '') ) == true) {
          if (parseFloat(gridData[i].txtcolPurchLbs.toString().replace(/\s/g, '')) < 0 || parseFloat(gridData[i].txtcolPurchLbs.toString().replace(/\s/g, '') ) > 999999999) {
            alert("Pounds to Apply must be of format #########.");
            //focus
            if (cellValue < 0) {
              document.getElementById(cellId).style.backgroundColor = "#ccccff"
            } else {
              document.getElementById(cellId).style.backgroundColor = "transparent"
            }
            return;
          }
          if (gridData[i].txtcolSeedVarietyTBDCA.trim() == "Y") {
            alert("Seed varieties have not been defined.  Contract number:" + gridData[i].txtcolContractCA);
            //focus
            if (cellValue < 0) {
              document.getElementById(cellId).style.backgroundColor = "#ccccff"
            } else {
              document.getElementById(cellId).style.backgroundColor = "transparent"
            }
            return;
          }
        }
        else {
          alert("Pounds to Apply must be of format #########.");
          //focus
          if (cellValue < 0) {
            document.getElementById(cellId).style.backgroundColor = "#ccccff"
          } else {
            document.getElementById(cellId).style.backgroundColor = "transparent"
          }
          return;
        }
        //PRB0053423-fix
        if (gridData[i].txtcolVendorContNum != "" && Number(gridData[i].txtcolPurchLbs.toString().replace(/\s/g, '')) > 0) {
          let inspDt = moment(msInspDate,"MM/DD/YYYY HH:mm:ss A")
          let delstrtDt = moment(gridData[i].txtcolDeliveryStartDate.trim() + " 12:00:00 AM","MM/DD/YYYY hh:mm:ss A")
          let delendDt = moment(gridData[i].txtcolDeliveryEndDate.trim() + " 11:59:59 PM","MM/DD/YYYY hh:mm:ss A")
      
          if (!inspDt.isValid() || !delstrtDt.isValid() || !delendDt.isValid()) {
              alert("Invalid date format. Please enter valid dates.");
              return;
          }
      
          if (inspDt.isBefore(delstrtDt) || inspDt.isAfter(delendDt)) {
              alert("Contract " + gridData[i].txtcolContractCA + " delivery date does not match inspection date.")
              return;
          }
      }   
      }
      if (gridData[i].txtcolStoreLbs.toString().replace(/\s/g, '') != "") {
        if (isNumeric(gridData[i].txtcolStoreLbs.toString().replace(/\s/g, '')) == true) {
          if (parseFloat(gridData[i].txtcolStoreLbs.toString().replace(/\s/g, '')) < 0 || parseFloat(gridData[i].txtcolStoreLbs.toString().replace(/\s/g, '')) > 999999999) {
            alert("Pounds to Apply must be of format #########.");
            //focus
            if (cellValue < 0) {
              document.getElementById(cellId).style.backgroundColor = "#ccccff"
            } else {
              document.getElementById(cellId).style.backgroundColor = "transparent"
            }
            return;
          }
          if (gridData[i].txtcolSeedVarietyTBDCA.trim() == "Y") {
            alert("Seed varieties have not been defined.  Contract number: " + gridData[i].txtcolContractCA);
            //focus
            if (cellValue < 0) {
              document.getElementById(cellId).style.backgroundColor = "#ccccff"
            } else {
              document.getElementById(cellId).style.backgroundColor = "transparent"
            }
            return;
          }
        }
        else {
          alert("Pounds to Apply must be of format #########.");
          //focus
          if (cellValue < 0) {
            document.getElementById(cellId).style.backgroundColor = "#ccccff"
          } else {
            document.getElementById(cellId).style.backgroundColor = "transparent"
          }
          return;
        }
        if (gridData[i].txtcolVendorContNum != "" && Number(gridData[i].txtcolStoreLbs.toString().replace(/\s/g, '')) > 0) {
          let inspDt = moment(msInspDate).format('MM/DD/YYYY HH:mm:ss A')
          let delstrtDt = moment(gridData[i].txtcolDeliveryStartDate.trim() + " 12:00:00 AM").format('MM/DD/YYYY HH:mm:ss A') 
          let delendDt = moment(gridData[i].txtcolDeliveryEndDate.trim() + " 11:59:59 PM").format('MM/DD/YYYY HH:mm:ss A')  
          if ((inspDt < delstrtDt) || (inspDt > delendDt)) {
            alert("Contract " + gridData[i].txtcolContractCA + " delivery date does not match inspection date.")
            return;
          }
        }
      }
    }

    let LbsToApply = getValue(thisObj, 'txtLbsToApply')//.trim();
    if (isEnabled(thisObj, "txtLbsToApply") == true) {
      if (LbsToApply != "") {
        if (isNumeric(LbsToApply) == true) {
          if (parseFloat(LbsToApply) < 0 || parseFloat(LbsToApply) > 999999999) {
            alert("Pounds to Apply must be of format #########.")
            document.getElementById("txtLbsToApply").focus();
            // if (cellValue < 0) {
            //   document.getElementById(cellId).style.backgroundColor = "#ccccff"
            // } else {
            //   document.getElementById(cellId).style.backgroundColor = "transparent"
            // }
            return;
          }
        }
        else {
          alert("Pounds to Apply must be of format #########.")
          document.getElementById("txtLbsToApply").focus();
          // if (cellValue < 0) {
          //   document.getElementById(cellId).style.backgroundColor = "#ccccff"
          // } else {
          //   document.getElementById(cellId).style.backgroundColor = "transparent"
          // }
          return;
        }
      }
    }
    let nStoreSeedContApplLbs
    let nPurchSeedContApplLbs
    let nApplPoundsToApply = 0;
    let nTentPoundsToApply = 0;
    let nAddenSeedLbsPriceLineApplied = 0;
    let nAddenSeedLbsApplied = 0;
    let nAddenSeedLbs = 0;
    let nAddenSeedLbsNonPriceLineApplied = 0;
    let mlAddenSeedLbsLeft = getValue(thisObj, 'txtTentPounds') !== undefined && getValue(thisObj, 'txtTentPounds') !== "" ? Number(getValue(thisObj, 'txtTentPounds')) : 0 + getValue(thisObj, 'txtTentPounds') !== undefined && getValue(thisObj, 'txtTentPounds') !== "" ? Number(getValue(thisObj, 'txtApplyPounds')) : 0;

    for (let j = 0; j < gridData.length; j++) {
      if (gridData[j].txtcolVendorContNum != "") {
        if (parseFloat(gridData[j].txtcolOpenBalance) < 0) {
          alert("Negative Open Balance Exists. Contract - " + gridData[j].txtcolContractCA)
          return;
        }
      }
      if (gridData[j].txtcolVendorContNum != "") {
        if (parseFloat(gridData[j].txtcolTentOpenBalance) < 0) {
          alert("Negative Tentative Open Balance Exists. Contract - " + gridData[j].txtcolContractCA)
          return;
        }
      }
      if (gridData[j].txtcolPurchLbs.toString().replace(/\s/g, '') != "") {
        if (parseFloat(gridData[j].txtcolPurchLbs.toString().replace(/\s/g, '')) > parseFloat(gridData[j].txtcolContApplTentLbs)) {
          alert("Pounds To Apply (" + gridData[j].txtcolPurchLbs + ") cannot exceed Tentative Open Pounds (" + gridData[j].txtcolContApplTentLbs + "). Contract - " + gridData[j].txtcolContractCA);
          return;
        }
      }
      if (gridData[j].txtcolStoreLbs != "") {
        if (parseFloat(gridData[j].txtcolStoreLbs.toString().replace(/\s/g, '')) > parseFloat(gridData[j].txtcolContApplTentLbs)) {
          alert("Pounds To Apply (" + gridData[j].txtcolStoreLbs + ") cannot exceed Tentative Open Pounds (" + gridData[j].txtcolContApplTentLbs + "). Contract - " + gridData[j].txtcolContractCA);
          return;
        }
      }
      if (gridData[j].txtcolVendorContNum != "") {
        nApplPoundsToApply += Number(gridData[j].txtcolPurchLbs.toString().replace(/\s/g, ''));
        nTentPoundsToApply += Number(gridData[j].txtcolStoreLbs.toString().replace(/\s/g, ''));
        if (Number(gridData[j].txtcolStoreLbs.toString().replace(/\s/g, '')) > 0) {
          if (gridData[j].txtcolSeed.trim().toUpperCase() == "Y" && gridData[j].txtcolMeetGenProducedCA.trim().toUpperCase() == "Y") {
            nAddenSeedLbsApplied = gridData[j].txtcolStoreLbs;
            nAddenSeedLbs = gridData[j].txtcolSeedLeft;
            if (nAddenSeedLbsApplied <= nAddenSeedLbs) {
              nAddenSeedLbsPriceLineApplied += nAddenSeedLbsApplied;
            }
            else {
              nAddenSeedLbsPriceLineApplied += nAddenSeedLbs;
            }
          }
        }
      }
    }

    if (nApplPoundsToApply > getValue(thisObj, 'txtApplyPounds')) {
      alert("Too many purchase pounds applied.");
      return;
    }
    if (nTentPoundsToApply > getValue(thisObj, 'txtTentPounds')) {
      alert("Too many tentative pounds applied.");
      return;
    }
    mlAddenSeedLbsLeft -= nAddenSeedLbsPriceLineApplied;
    nAddenSeedLbsNonPriceLineApplied = 0;


    let gridDataSGA = getValue(thisObj, 'gridSeedGroverApp');
    for (let k = 0; k < gridDataSGA.length && (isVisible(thisObj, 'grpbxSeedGroverApp')==true); k++) {
      if (gridDataSGA[k].txtcolContract != "") {
        if(gridDataSGA[k].txtcolPurPoundsToApplySG === undefined){
          gridDataSGA[k].txtcolPurPoundsToApplySG = ""
        }
        if(gridDataSGA[k].txtcolStoPoundsToApplySG === undefined){
          gridDataSGA[k].txtcolStoPoundsToApplySG  = ""
        }
        if ((Number(gridDataSGA[k].txtcolPurPoundsToApplySG.toString().replace(/\s/g, '')) + Number(gridDataSGA[k].txtcolStoPoundsToApplySG.toString().replace(/\s/g, ''))) > 0) {
          if (gridDataSGA[k].txtcolSeedVarietyTBD.trim() == "Y") {
            alert("Seed varieties have not been defined.  Contract number: " + gridDataSGA[k].txtcolContract);
            return;
          }
          if (Number(gridDataSGA[k].txtcolOpenBalanceSG) < 0) {
            alert("Too many seed pounds applied to seed grower addendum " + gridDataSGA[k].txtcolContract);
            return;
          }
          nAddenSeedLbsNonPriceLineApplied += Number(gridDataSGA[k].txtcolPurPoundsToApplySG.toString().replace(/\s/g, '')) + Number(gridDataSGA[k].txtcolStoPoundsToApplySG.toString().replace(/\s/g, ''));
        }
      }
    }
    for (let l = 0; l < gridDataSGA.length && (isVisible(thisObj, 'grpbxSeedGroverApp')==true); l++) {
      nStoreSeedContApplLbs = 0
      nPurchSeedContApplLbs = 0
      let n = 1;
      while (n <= gridData.length - 1) {
        if (gridDataSGA[l].txtcolContract.trim() == gridData[n].txtcolSeedCont.trim()) {
          if (isNumeric(gridData[n].txtcolPurchLbs.toString().replace(/\s/g, ''))) {
            if (gridData[n].txtcolSeedLeft < gridData[n].txtcolPurchLbs.toString().replace(/\s/g, '')) {
              nPurchSeedContApplLbs += gridData[n].txtcolSeedLeft;
            }
            else {
              nPurchSeedContApplLbs += Number(gridData[n].txtcolPurchLbs.toString().replace(/\s/g, ''));
            }
          }
          if (isNumeric(gridData[n].txtcolStoreLbs.toString().replace(/\s/g, ''))) {
            if (gridData[n].txtcolSeedLeft < gridData[n].txtcolStoreLbs.toString().replace(/\s/g, '')) {
              nStoreSeedContApplLbs += gridData[n].txtcolSeedLeft;
            }
            else {
              nStoreSeedContApplLbs += Number(gridData[n].txtcolStoreLbs.toString().replace(/\s/g, ''));
            }
          }
        }
        n += 1;
      }
    }

    for (let m = 0; m < gridDataSGA.length && (isVisible(thisObj, 'grpbxSeedGroverApp')==true); m++) {
      nStoreSeedContApplLbs = 0
      nPurchSeedContApplLbs = 0
      if (gridDataSGA[m].txtcolContract != "") {
        if ((Number(gridDataSGA[m].txtcolPurPoundsToApplySG.toString().replace(/\s/g, '')) + Number(gridDataSGA[m].txtcolStoPoundsToApplySG.toString().replace(/\s/g, ''))) > 0) {
          let n = 1;
          while (n <= gridData.length - 1) {
            if (gridDataSGA[m].txtcolContract.trim() == gridData[n].txtcolSeedCont.trim()) {
              if (isNumeric(gridData[n].txtcolPurchLbs.toString().replace(/\s/g, ''))) {
                if (gridData[n].txtcolSeedLeft.trim() < gridData[n].txtcolPurchLbs.toString().replace(/\s/g, '')) {
                  nPurchSeedContApplLbs += gridData[n].txtcolSeedLeft.trim();
                }
                else {
                  nPurchSeedContApplLbs += gridData[n].txtcolPurchLbs.toString().replace(/\s/g, '');
                }
              }
              if (isNumeric(gridData[n].txtcolStoreLbs.toString().replace(/\s/g, ''))) {
                if (gridData[n].txtcolSeedLeft.trim() < gridData[n].txtcolStoreLbs.toString().replace(/\s/g, '')) {
                  nStoreSeedContApplLbs += gridData[n].txtcolSeedLeft.trim();
                }
                else {
                  nStoreSeedContApplLbs += gridData[n].txtcolStoreLbs.toString().replace(/\s/g, '');
                }
              }
            }
            n += 1;
          }
          if (Number(gridDataSGA[m].txtcolOpenBalanceSG) < 0) {
            alert("Too many seed pounds applied to seed grower addendum" + gridDataSGA[m].txtcolContract);
            return;
          }
        }
      }
    }
    mlAddenSeedLbsLeft -= nAddenSeedLbsNonPriceLineApplied;

    let PrchsStrg = getValue(thisObj, 'txtPrchsStrg');
    let frmContractApplications2009LoadDataObj = getData(thisObj, 'frmContractApplications2009LoadData')
    let lbsapplyTotalValue = 0
    let totalunapplied = 0

    lblTentPounds = getData(thisObj, "lblTentPounds")
    lblApplyPounds = getData(thisObj, "lblApplyPounds")

    let lblCMAAllowUnapplyLbs = frmContractApplications2009LoadDataObj.lblCMAAllowUnapplyLbs
    if (PrchsStrg.trim().toUpperCase() != "") {
      if (nApplPoundsToApply != (Number(txtApplyPounds.value))) {//getValue(thisObj, "txtApplyPounds")
        if (lblCMAVendor != "" && lblCMAAllowUnapplyLbs != "Y") {
          alert("Not all purchase pounds distributed. All pounds must be distributed to the CMA contract");
        }
        else {
          const confirmBox = window.confirm(
            "Not all purchase pounds distributed. Would you like to distribute the remaining " + (Number(lblApplyPounds) - Number(nApplPoundsToApply)) + " pounds as spot?"
          )
          if (confirmBox == true) {
            if (getValue(thisObj, 'txtPrice') > 0) {
              lbsapplyTotalValue = (Number(lblApplyPounds) - Number(nApplPoundsToApply))
              setValue(thisObj, 'txtLbsToApply', lbsapplyTotalValue);// getValue(thisObj, 'txtApplyPounds')
            }
            else {
              alert("Zero Spot Price. Unable to apply pounds");
              return;
            }
          }
          else {
            setValue(thisObj, 'txtLbsToApply', "0");
            return;
          }
        }
      }
      if (nTentPoundsToApply != Number(txtTentPounds.value)) {
        let confirmBox;
        if (bAllPoundsUsed == false) {
          confirmBox = window.confirm(
            "Not all storage pounds distributed. Would you like to distribute the remaining " + (Number(lblTentPounds) - Number(nTentPoundsToApply)) + " pounds as unapplied?"
          )
        }
        else {
          confirmBox = true;
        }

        if (confirmBox == true) {
          totalunapplied = (Number(lblTentPounds) - Number(nTentPoundsToApply))
          setValue(thisObj, 'txtUnappliedLbs', totalunapplied);// getValue(thisObj, 'txtTentPounds')
        }
        else {
          setValue(thisObj, 'txtUnappliedLbs', "0");
          return;
        }
      }
      if (mlAddenSeedLbsLeft > 0 && getValue(thisObj, 'txtSeedSpec').toUpperCase() == "YES" && mbUnappliedSeedContAvail) {
        const confirmBox = window.confirm(
          "There are " + mlAddenSeedLbsLeft + " seed pounds not applied to a seed grower addendum. Would you like to apply these pounds to a seed grower addendum?"
        )
        if (confirmBox == true) {
          let lblPurchaseStorageTag = ""
          let frmContractApplications2009LoadDataObj = getData(thisObj, "frmContractApplications2009LoadData")
          if (frmContractApplications2009LoadDataObj != null && frmContractApplications2009LoadDataObj != undefined) {
            lblPurchaseStorageTag = frmContractApplications2009LoadDataObj.PurchaseStorageTag
          }
          if (lblPurchaseStorageTag === "P") {
            cmdPurchaseStorage = true
          }
          else {
            await EnableDisableControls()
          }
          return;
        }
      }
    }
    nApplPoundsToApply = Number(nApplPoundsToApply) + Number(lbsapplyTotalValue)//getValue(thisObj, 'txtLbsToApply'));
    if (Number(nApplPoundsToApply) > Number(getValue(thisObj, 'txtApplyPounds'))) {
      alert("Too many purchase pounds applied.");
      return;
    }
    nTentPoundsToApply = Number(nTentPoundsToApply) + Number(totalunapplied);
    if (Number(nTentPoundsToApply) > Number(getValue(thisObj, 'txtTentPounds'))) {
      alert("Too many tentative pounds applied.");
      return;
    }
    if ((Number(nAddenSeedLbsPriceLineApplied) + Number(nAddenSeedLbsNonPriceLineApplied)) > (Number(getValue(thisObj, 'txtTentPounds')) + Number(getValue(thisObj, 'txtApplyPounds')))) {
      alert("Too many seed pounds applied.");
      return;
    }

    let resp = await SettlementService.RetrieveCheckWareHouseStatusDetails(txtSC95Num.value)
    if (resp.length > 0) {
      if (resp[0].tent_cont_appl_ind === "Y" && nTentPoundsToApply === 0) {
        alert("Must Apply to Contract to proceed.")
        bFormValid = false
        return;
      }
    }
    bFormValid = true;
    return bFormValid;
  }


  const ontxtcolPurchLbsChange = (event) => {
    setCellId(event.target.id) 
    setCellValue(event.target.value)
  };
  thisObj.ontxtcolPurchLbsChange = ontxtcolPurchLbsChange;

  const ontxtcolStoreLbsChange = (event) => {
    setCellId(event.target.id)
    setCellValue(event.target.value)
  };
  thisObj.ontxtcolStoreLbsChange = ontxtcolStoreLbsChange;

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  async function EnterPounds(bCMA, iColPS, iColPounds, gridViewContractApplicationData, gridSeedGroverAppData) {
    let sVendor
    let sPurStoInd
    let sGroupNum
    let lVendorLbs
    let lGroupLbs
    let lContractLbs
    let bVendorLbsLeft = false
    let bp = false
    let bS = false
    let sSeedInd
    let bVendContInGrid = false
    sVendor = "FIRST"
    sGroupNum = ""
    bp = false
    bS = false
    if ((txtSeedSpec.value).toUpperCase() === "YES") {
      sSeedInd = "Y"
    } else {
      sSeedInd = "N"
    }
    for (var i = 0; i < gridViewContractApplicationData.length; i++) {
      if (sVendor != gridViewContractApplicationData[i].txtcolVendorCA) {
        if (sGroupNum != "") {
          await GroupingAdjustment(sGroupNum, lGroupLbs, sPurStoInd, lVendorLbs, gridViewContractApplicationData)
        }
        sVendor = (gridViewContractApplicationData[i].txtcolVendorCA).toUpperCase()
        if (bCMA === false) {
          sPurStoInd = gridViewContractApplicationData[i].txtcolPurSto1CA
          if (gridViewContractApplicationData[i].txtcolVendorLbs1CA === "") {
            lVendorLbs = lVendorLbs
          }
          else {
            lVendorLbs = gridViewContractApplicationData[i].txtcolVendorLbs1CA
          }
        }
        else {

          if (iColPS === "C_P_S_IND_1") {
            sPurStoInd = (gridViewContractApplicationData[i].txtcolPurSto1CA)//iColPS
            if (gridViewContractApplicationData[i].txtcolVendorLbs1CA === "") {//iColPounds
              lVendorLbs = lVendorLbs
            }
            else {
              lVendorLbs = gridViewContractApplicationData[i].txtcolVendorLbs1CA ////iColPounds
            }
          }
          else if (iColPS === "C_P_S_IND_2") {
            sPurStoInd = (gridViewContractApplicationData[i].txtcolPurSto2CA)//iColPS
            if (gridViewContractApplicationData[i].txtcolVendorLbs2CA === "") {//iColPounds
              lVendorLbs = lVendorLbs
            }
            else {
              lVendorLbs = gridViewContractApplicationData[i].txtcolVendorLbs2CA ////iColPounds
            }
          }
        }
        sGroupNum = gridViewContractApplicationData[i].txtcolContGroup
        lGroupLbs = lVendorLbs
      }

      if (sGroupNum != gridViewContractApplicationData[i].txtcolContGroup) {
        if (sGroupNum != "") {
          await GroupingAdjustment(sGroupNum, lGroupLbs, sPurStoInd, lVendorLbs, gridViewContractApplicationData)
        }
        sGroupNum = gridViewContractApplicationData[i].txtcolContGroup
        lGroupLbs = lVendorLbs
      }

      if (Number(lVendorLbs) > 0) {
        if (gridViewContractApplicationData[i].txtcolGrpSharePct != "") {
          lContractLbs = (Number(lGroupLbs) * Number(gridViewContractApplicationData[i].txtcolGrpSharePct)) / 100
        }
        else {
          lContractLbs = lVendorLbs
        }

        if (sPurStoInd === "P" && ((gridViewContractApplicationData[i].txtcolContType).toUpperCase() != "M")) {
          bp = true
          if (Number(gridViewContractApplicationData[i].txtcolTentOpenBalance) > 0) {
            if (Number(lContractLbs) < Number(gridViewContractApplicationData[i].txtcolTentOpenBalance)) {
              gridViewContractApplicationData[i].txtcolPurchLbs = lContractLbs
              gridViewContractApplicationData[i].txtcolStoreLbs = ""
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
            else {
              gridViewContractApplicationData[i].txtcolPurchLbs = gridViewContractApplicationData[i].txtcolTentOpenBalance
              gridViewContractApplicationData[i].txtcolStoreLbs = ""
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
            lVendorLbs = lVendorLbs - (gridViewContractApplicationData[i].txtcolPurchLbs)
          }
        }

        if (sPurStoInd === "S") {
          bS = true
          if (Number(gridViewContractApplicationData[i].txtcolTentOpenBalance) > 0) {
            if (Number(lContractLbs) < Number(gridViewContractApplicationData[i].txtcolTentOpenBalance)) {
              gridViewContractApplicationData[i].txtcolStoreLbs = lContractLbs
              gridViewContractApplicationData[i].txtcolPurchLbs = ""
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
            else {
              gridViewContractApplicationData[i].txtcolStoreLbs = gridViewContractApplicationData[i].txtcolTentOpenBalance
              gridViewContractApplicationData[i].txtcolPurchLbs = ""
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
            lVendorLbs = Number(lVendorLbs) - Number(gridViewContractApplicationData[i].txtcolStoreLbs)
          }
        }
      }
    }

    if (sGroupNum != "") {
      await GroupingAdjustment(sGroupNum, lGroupLbs, sPurStoInd, lVendorLbs, gridViewContractApplicationData)
    }

    //Tushar 02 May 2023 : Discussed with Koteswar. Below code is not required as variable bVendContInGrid is not used in current EnterPounds or anywhere in this page

    // For v = 0 To UBound(msVendor, 1)
    //     If msVendor(v, 0) <> "" Then
    //         bVendContInGrid = False
    //         For n = 1 To vsfData.Rows - 1
    //             If UCase(msVendor(v, 0)) = UCase(vsfData.TextMatrix(n, C_VENDOR)) Then
    //                 bVendContInGrid = True
    //                 Exit For
    //             End If
    //         Next n
    //     End If
    // Next v

    if (lblCMAVendor === "") {
      await EnterSeedPounds(false, "SD_P_S_IND_1", "SD_VEND_LBS_1", gridSeedGroverAppData, gridViewContractApplicationData)
    }
    else {
      if (iColPS === "C_P_S_IND_1") {
        await EnterSeedPounds(true, "SD_P_S_IND_1", "SD_VEND_LBS_1", gridSeedGroverAppData, gridViewContractApplicationData)
      }
      if (iColPS === "C_P_S_IND_2") {
        await EnterSeedPounds(true, "SD_P_S_IND_2", "SD_VEND_LBS_2", gridSeedGroverAppData, gridViewContractApplicationData)
      }
    }    
  }

  async function EnterSeedPounds(bCMA, iColPS, iColPounds, gridSeedGroverAppData, gridViewContractApplicationData) {
    let sVendor
    let sPurStoInd
    let lVendorLbs
    let bVendorLbsLeft

    sVendor = "FIRST"

    for (var i = 0; i < gridSeedGroverAppData.length; i++) {

      if (bCMA === false) {
        if (sVendor != (gridSeedGroverAppData[i].txtcolVendor).toUpperCase()) {
          sVendor = (gridSeedGroverAppData[i].txtcolVendor).toUpperCase()
          sPurStoInd = (gridSeedGroverAppData[i].txtcolPurSto1).toUpperCase()
          lVendorLbs = await CalcSeedLbsUnapplied(gridSeedGroverAppData[i].txtcolVendorLbs1, sVendor, gridViewContractApplicationData)
        }
      }
      else {
        if (sVendor != (gridSeedGroverAppData[i].txtcolVendor).toUpperCase()) {
          sVendor = (gridSeedGroverAppData[i].txtcolVendor).toUpperCase()
          if (iColPS === "SD_P_S_IND_1") {
            sPurStoInd = (gridSeedGroverAppData[i].txtcolPurSto1).toUpperCase() // Need to replace column txtcolPurSto1 with iColPS
            lVendorLbs = await CalcSeedLbsUnapplied(gridSeedGroverAppData[i].txtcolVendorLbs1, sVendor, gridViewContractApplicationData) //Need to replace column txtcolVendorLbs1 with iColPounds
          }
          else if (iColPS === "SD_P_S_IND_2") {
            sPurStoInd = (gridSeedGroverAppData[i].txtcolPurSto2).toUpperCase() // Need to replace column txtcolPurSto1 with iColPS
            lVendorLbs = await CalcSeedLbsUnapplied(gridSeedGroverAppData[i].txtcolVendorLbs2, sVendor, gridViewContractApplicationData) //Need to replace column txtcolVendorLbs1 with iColPounds
          }
        }
      }

      if (Number(lVendorLbs) > 0) {
        for (var j = 0; j < gridViewContractApplicationData.length; j++) {
          if ((gridViewContractApplicationData[j].txtcolSeedCont === gridSeedGroverAppData[i].txtcolContract) && (gridViewContractApplicationData[j].txtcolVendorCA === gridSeedGroverAppData[i].txtcolVendor) && (gridViewContractApplicationData[j].txtcolSeed === "Y") && (gridViewContractApplicationData[j].txtcolMeetGenProducedCA === "Y")) {
            gridSeedGroverAppData[i].txtcolAppliedSG = Number(gridSeedGroverAppData[i].txtcolAppliedSG) + Number(gridViewContractApplicationData[j].txtcolStoreLbs)
            gridSeedGroverAppData[i].txtcolOpenBalanceSG = Number(gridSeedGroverAppData[i].txtcolOpenBalanceSG) - Number(gridViewContractApplicationData[j].txtcolStoreLbs)
          }
        }

        if (Number(gridSeedGroverAppData[i].txtcolOpenBalanceSG) > 0) {
          if (lVendorLbs < Number(gridSeedGroverAppData[i].txtcolOpenBalanceSG)) {
            if (sPurStoInd === "P") {
              gridSeedGroverAppData[i].txtcolPurPoundsToApplySG = lVendorLbs
            }
            if (sPurStoInd === "S") {
              gridSeedGroverAppData[i].txtcolStoPoundsToApplySG = lVendorLbs
            }
            gridSeedGroverAppData[i].txtcolAppliedSG = Number(gridSeedGroverAppData[i].txtcolAppliedSG) + lVendorLbs
            gridSeedGroverAppData[i].txtcolOpenBalanceSG = Number(gridSeedGroverAppData[i].txtcolOpenBalanceSG) - lVendorLbs
          }
          else {
            if (sPurStoInd === "P") {
              gridSeedGroverAppData[i].txtcolPurPoundsToApplySG = Number(gridSeedGroverAppData[i].txtcolOpenBalanceSG)
            }
            if (sPurStoInd === "S") {
              gridSeedGroverAppData[i].txtcolStoPoundsToApplySG = Number(gridSeedGroverAppData[i].txtcolOpenBalanceSG)
            }
            gridSeedGroverAppData[i].txtcolAppliedSG = Number(gridSeedGroverAppData[i].txtcolContractsPoundsSG)
            gridSeedGroverAppData[i].txtcolOpenBalanceSG = 0
          }
          if (sPurStoInd === "P") {
            lVendorLbs = lVendorLbs - Number(gridSeedGroverAppData[i].txtcolPurPoundsToApplySG)
          }
          if (sPurStoInd === "S") {
            lVendorLbs = lVendorLbs - Number(gridSeedGroverAppData[i].txtcolStoPoundsToApplySG)
          }
        }

      }
    }
  }

  async function CalcSeedLbsUnapplied(lPounds, sVendor, gridViewContractApplicationData) {
    let nSeedLbsApplied
    let CalcSeedLbsUnapplied
    nSeedLbsApplied = 0
    CalcSeedLbsUnapplied = Number(lPounds)

    if (sVendor != "") {
      for (var i = 0; i < gridViewContractApplicationData.length; i++) {
        if ((gridViewContractApplicationData[i].txtcolVendorCA).toUpperCase() === (sVendor).toUpperCase()) {
          if (Number(gridViewContractApplicationData[i].txtcolStoreLbs) > 0) {
            if (((gridViewContractApplicationData[i].txtcolSeed).toUpperCase() === "Y") && ((gridViewContractApplicationData[i].txtcolSeed).toUpperCase() === "Y")) {
              if (Number(gridViewContractApplicationData[i].txtcolStoreLbs) <= Number(gridViewContractApplicationData[i].txtcolSeedLeft)) {
                nSeedLbsApplied = nSeedLbsApplied + Number(gridViewContractApplicationData[i].txtcolStoreLbs)
              }
              else {
                nSeedLbsApplied = nSeedLbsApplied + Number(gridViewContractApplicationData[i].txtcolSeedLeft)
              }
            }
          }
        }
      }
    }
    else {
      for (var i = 0; i < gridViewContractApplicationData.length; i++) {
        if (Number(gridViewContractApplicationData[i].txtcolStoreLbs) > 0) {
          if (((gridViewContractApplicationData[i].txtcolSeed).toUpperCase() === "Y") && ((gridViewContractApplicationData[i].txtcolMeetGenProducedCA).toUpperCase() === "Y")) {
            if (Number(gridViewContractApplicationData[i].txtcolStoreLbs) <= Number(gridViewContractApplicationData[i].txtcolSeedLeft)) {
              nSeedLbsApplied = nSeedLbsApplied + Number(gridViewContractApplicationData[i].txtcolStoreLbs)
            }
            else {
              nSeedLbsApplied = nSeedLbsApplied + Number(gridViewContractApplicationData[i].txtcolSeedLeft)
            }
          }
        }
      }
    }
    CalcSeedLbsUnapplied = CalcSeedLbsUnapplied - nSeedLbsApplied
    return CalcSeedLbsUnapplied;
  }

  async function AdjustPounds(n, sPurStoInd, gridViewContractApplicationData) {
    if (sPurStoInd === "P") {
      gridViewContractApplicationData[n].txtcolApplied = Number(gridViewContractApplicationData[n].txtcolApplLbs) + Number(gridViewContractApplicationData[n].txtcolPurchLbs)
      gridViewContractApplicationData[n].txtcolTotalApplicaion = Number(gridViewContractApplicationData[n].txtcolApplied) + Number(gridViewContractApplicationData[n].txtcolTentativeApplied)
    }
    gridViewContractApplicationData[n].txtcolOpenBalance = Number(gridViewContractApplicationData[n].txtcolContractsPounds) - Number(gridViewContractApplicationData[n].txtcolApplied)

    if (sPurStoInd === "S") {
      gridViewContractApplicationData[n].txtcolTentativeApplied = Number(gridViewContractApplicationData[n].txtcolTentLbs) + Number(gridViewContractApplicationData[n].txtcolStoreLbs)
      gridViewContractApplicationData[n].txtcolTotalApplicaion = Number(gridViewContractApplicationData[n].txtcolApplied) + Number(gridViewContractApplicationData[n].txtcolTentativeApplied)
    }
    gridViewContractApplicationData[n].txtcolTentOpenBalance = Number(gridViewContractApplicationData[n].txtcolOpenBalance) - Number(gridViewContractApplicationData[n].txtcolTentativeApplied)
  }

  async function GroupingAdjustment(sGroupNum, lGroupLbs, sPurStoInd, lVendorLbs, gridViewContractApplicationData) {
    let lApplLbs
    let lAdjustLbs
    let bAdjustment

    lApplLbs = 0

    for (var i = 0; i < gridViewContractApplicationData.length; i++) {
      if (gridViewContractApplicationData[i].txtcolContGroup === sGroupNum) {

        if (sPurStoInd === "P") {
          lApplLbs = lApplLbs + Number(gridViewContractApplicationData[i].txtcolPurchLbs)
        }
        if (sPurStoInd === "S") {
          lApplLbs = lApplLbs + Number(gridViewContractApplicationData[i].txtcolStoreLbs)
        }
      }
    }

    if (lApplLbs === 0) {
      return
    }
    lAdjustLbs = lGroupLbs - lApplLbs

    if (lAdjustLbs === 0) {
      return
    }

    if (lAdjustLbs < 0) {
      do {
        bAdjustment = false
        for (var i = 0; i < gridViewContractApplicationData.length; i++) {
          if (gridViewContractApplicationData[i].txtcolContGroup === sGroupNum) {

            if ((sPurStoInd === "P") && (lAdjustLbs != 0) && (Number(gridViewContractApplicationData[i].txtcolPurchLbs) > 0)) {
              bAdjustment = true
              gridViewContractApplicationData[i].txtcolPurchLbs = Number(gridViewContractApplicationData[i].txtcolPurchLbs) - 1
              lVendorLbs = lVendorLbs + 1
              lAdjustLbs = lAdjustLbs + 1
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
            if ((sPurStoInd === "S") && (lAdjustLbs != 0) && (Number(gridViewContractApplicationData[i].txtcolStoreLbs) > 0)) {
              bAdjustment = true
              gridViewContractApplicationData[i].txtcolStoreLbs = Number(gridViewContractApplicationData[i].txtcolStoreLbs) - 1
              lVendorLbs = lVendorLbs + 1
              lAdjustLbs = lAdjustLbs + 1
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
          }
        }
        if (!bAdjustment) {
          lAdjustLbs = 0
        }
      }
      while (lAdjustLbs != 0);
    }

    if (lAdjustLbs > 0) {
      do {
        bAdjustment = false
        for (var i = 0; i < gridViewContractApplicationData.length; i++) {
          if (gridViewContractApplicationData[i].txtcolContGroup === sGroupNum) {

            if ((sPurStoInd === "P") && (lAdjustLbs != 0) && (Number(gridViewContractApplicationData[i].txtcolTentOpenBalance) > 0)) {
              bAdjustment = true
              gridViewContractApplicationData[i].txtcolPurchLbs = Number(gridViewContractApplicationData[i].txtcolPurchLbs) + 1
              lVendorLbs = lVendorLbs - 1
              lAdjustLbs = lAdjustLbs - 1
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
            if ((sPurStoInd === "S") && (lAdjustLbs != 0) && (Number(gridViewContractApplicationData[i].txtcolTentOpenBalance) > 0)) {
              bAdjustment = true
              gridViewContractApplicationData[i].txtcolStoreLbs = Number(gridViewContractApplicationData[i].txtcolStoreLbs) + 1
              lVendorLbs = lVendorLbs - 1
              lAdjustLbs = lAdjustLbs - 1
              await AdjustPounds(i, sPurStoInd, gridViewContractApplicationData)
            }
          }
        }
        if (!bAdjustment) {
          lAdjustLbs = 0
        }
      }
      while (lAdjustLbs != 0);
    }
  }

  async function GetVendorPurStoInd(sVendor) {
    let getVendorPurStoInd = ""
    if (sVendor === "")
      return;
    let frmContractApplications2009LoadDataObj = getData(thisObj, "frmContractApplications2009LoadData")
    if (frmContractApplications2009LoadDataObj != null & frmContractApplications2009LoadDataObj.vendorSplitData != null && frmContractApplications2009LoadDataObj.vendorSplitData.length > 0) {
      let vendorSplitDataObj = frmContractApplications2009LoadDataObj.vendorSplitData
      for (var i = 0; i < vendorSplitDataObj.length; i++) {
        if ((vendorSplitDataObj[i].lblVendorNumber).toUpperCase() === (sVendor).toUpperCase()) {
          getVendorPurStoInd = vendorSplitDataObj[i].cboPS
          break;
        }
      }
    }
    return getVendorPurStoInd;
  }

  async function GetVendorPounds(sVendor, bCMA, sIndicator) {
    let getVendorPounds = 0
    if (sVendor === "") {
      return;
    }
    let frmContractApplications2009LoadDataObj = getData(thisObj, "frmContractApplications2009LoadData")
    if (frmContractApplications2009LoadDataObj != null & frmContractApplications2009LoadDataObj.vendorSplitData != null && frmContractApplications2009LoadDataObj.vendorSplitData.length > 0) {
      let vendorSplitDataObj = frmContractApplications2009LoadDataObj.vendorSplitData
      for (var i = 0; i < vendorSplitDataObj.length; i++) {
        if (bCMA === true) {
          if ((vendorSplitDataObj[i].lblCMAParticipant === "N") && ((vendorSplitDataObj[i].lblVendorNumber).toUpperCase() === (sVendor).toUpperCase()) && (vendorSplitDataObj[i].cboPS === sIndicator)) {
            if (!isNaN(vendorSplitDataObj[i].lblPounds)) {
              getVendorPounds = vendorSplitDataObj[i].lblPounds
              break
            }
            if ((vendorSplitDataObj[i].lblCMAParticipant === "Y") && ((lblCMAVendor).toUpperCase() === (sVendor).toUpperCase()) && (vendorSplitDataObj[i].cboPS === sIndicator)) {
              if (!isNaN(vendorSplitDataObj[i].lblPounds)) {
                getVendorPounds += vendorSplitDataObj[i].lblPounds
              }
            }
          }
        }
        else {
          if ((vendorSplitDataObj[i].lblVendorNumber).toUpperCase() === (sVendor).toUpperCase()) {
            if (!isNaN(vendorSplitDataObj[i].lblPounds)) {
              getVendorPounds = vendorSplitDataObj[i].lblPounds
              break
            }
          }
        }
      }
    }
    return getVendorPounds;
  }

  async function GetVendorRcpt(sVendor) {
    let getVendorRcpt = ""
    if (sVendor === "") {
      return;
    }
    let frmContractApplications2009LoadDataObj = getData(thisObj, "frmContractApplications2009LoadData")
    if (frmContractApplications2009LoadDataObj != null & frmContractApplications2009LoadDataObj.vendorSplitData != null && frmContractApplications2009LoadDataObj.vendorSplitData.length > 0) {
      let vendorSplitDataObj = frmContractApplications2009LoadDataObj.vendorSplitData
      for (var i = 0; i < vendorSplitDataObj.length; i++) {
        if ((vendorSplitDataObj[i].lblVendorNumber).toUpperCase() === (sVendor).toUpperCase()) {
          if ((vendorSplitDataObj[i].cboPS === "S") && (vendorSplitDataObj[i].CboWRNum != "")) {
            getVendorRcpt = vendorSplitDataObj[i].CboWRNum
            break;
          }
        }
      }
    }
    return getVendorRcpt;
  }


  async function EnableDisableControls() {

    let lblPurchaseStorageTag = ""
    let frmContractApplications2009LoadDataObj = getData(thisObj, "frmContractApplications2009LoadData")
    if (frmContractApplications2009LoadDataObj != null && frmContractApplications2009LoadDataObj != undefined) {
      lblPurchaseStorageTag = frmContractApplications2009LoadDataObj.PurchaseStorageTag
    }

    if (lblPurchaseStorageTag === "P") {
      // document.getElementsByClassName("txtLbsToApply")[0].style.visibility = "visible"; //show(thisObj, "txtLbsToApply")
      show(thisObj, "txtLbsToApply")
      // document.getElementsByClassName("txtUnappliedLbs")[0].style.visibility = "hidden"; //hide(thisObj, "txtUnappliedLbs")
      hide(thisObj, "txtUnappliedLbs",false)
      // document.getElementsByClassName("txtPrice")[0].style.visibility = "visible"; //show(thisObj, "txtPrice")
      show(thisObj, "txtPrice")
      show(thisObj, "txtApplyPounds")
      hide(thisObj, "txtTentPounds")
      showColumn(thisObj, "gridViewContractApplication", "txtcolPurchLbs")
      hideColumn(thisObj, "gridViewContractApplication", "txtcolStoreLbs")
      showColumn(thisObj, "gridSeedGroverApp", "txtcolPurPoundsToApplySG")
      hideColumn(thisObj, "gridSeedGroverApp", "txtcolStoPoundsToApplySG")
      // document.getElementsByClassName("lblSpotApplications")[0].style.visibility = "visible"; //show(thisObj,"lblSpotApplications")
      show(thisObj,"lblSpotApplications")
      // document.getElementsByClassName("lblUnappliedLbs")[0].style.visibility = "hidden"; //hide(thisObj,"lblUnappliedLbs")
      hide(thisObj,"lblUnappliedLbs",false)
    }
    else {
      // document.getElementsByClassName("txtLbsToApply")[0].style.visibility = "hidden"; //hide(thisObj, "txtLbsToApply")
      hide(thisObj, "txtLbsToApply",false)
      // document.getElementsByClassName("txtUnappliedLbs")[0].style.visibility = "visible";  //show(thisObj, "txtUnappliedLbs")
      show(thisObj, "txtUnappliedLbs")
      // document.getElementsByClassName("txtPrice")[0].style.visibility = "hidden"; //hide(thisObj, "txtPrice")
      hide(thisObj, "txtPrice",false)
      hide(thisObj, "txtApplyPounds")
      show(thisObj, "txtTentPounds")
      hideColumn(thisObj, "gridViewContractApplication", "txtcolPurchLbs")
      showColumn(thisObj, "gridViewContractApplication", "txtcolStoreLbs")
      hideColumn(thisObj, "gridSeedGroverApp", "txtcolPurPoundsToApplySG")
      showColumn(thisObj, "gridSeedGroverApp", "txtcolStoPoundsToApplySG")
      // show(thisObj, "txtUnappliedLbs")// only one textbox from our end
      // document.getElementsByClassName("lblSpotApplications")[0].style.visibility = "hidden"; // hide(thisObj,"lblSpotApplications")
      hide(thisObj, "lblSpotApplications",false)
      // document.getElementsByClassName("lblUnappliedLbs")[0].style.visibility = "visible"; //show(thisObj,"lblUnappliedLbs")
      show(thisObj,"lblUnappliedLbs")
    }
    let usemsInspStatus = getData(thisObj,"msInspStatus")
    if(usemsInspStatus!=undefined && usemsInspStatus != null){
    switch (usemsInspStatus) {
      case "P":
        enable(thisObj, "btnOk")
        disable(thisObj, "btnPurchase")
        hide(thisObj, "btnPurchase",false)
        break;
      case "I":
        enable(thisObj, "btnOk")
        enable(thisObj, "btnPurchase")
        show(thisObj, "btnPurchase")
        break;
      default:
        disable(thisObj, "btnOk")
        enable(thisObj, "btnPurchase")
        show(thisObj, "btnPurchase")
        break;
    }
  }
    if (txtApplyPounds.value === '0' || txtApplyPounds.value === "") {
      hide(thisObj, "btnPurchase",false)
      if (lblPurchaseStorageTag === "P") {
        cmdPurchaseStorage = true
        await onbtnPurchaseClick()
      }
    }
    else {
      if (txtTentPounds.value === "0" || txtTentPounds.value === "") {
        hide(thisObj, "btnPurchase",false)
        if (lblPurchaseStorageTag === "S") {
          cmdPurchaseStorage = true
          await onbtnPurchaseClick()
        }
      }
    }

    let mbUnappliedSeedContAvaile = getData(thisObj,"mbUnappliedSeedContAvail")

    if ((txtSeedSpec.value).toUpperCase() === "YES" && (mbUnappliedSeedContAvaile === true)) {
      show(thisObj, "lblSeedGroverApp")
      show(thisObj, "gridSeedGroverApp")
    }
    else {
      hide(thisObj, "lblSeedGroverApp")
      hide(thisObj, "grpbxSeedGroverApp",false)//document.getElementsByClassName("gridSeedGroverApp")[0].style.visibility = "hidden";//hide(thisObj, "gridSeedGroverApp")
    }
  }

  async function GetEditPermission() {
    mbAuthContApplEditAllow = false
    let response = await SettlementService.RetrieveContractGrantList(msBuyingPointID, txtSC95Num.value)
    if (response.length != 0) {
      if (response[0].permission === "Y") {
        mbAuthContApplEditAllow = true
        show(thisObj, "btnEditApp")
      }
    }
  }

  async function bFillContractList() {
    let bFillContractListFlag = false;
    try {
      let lstNewContractLine = []; //     lstNewContractLine.Clear
      let vsfData = getValue(thisObj, "gridViewContractApplication")
      let labelSeedPremApplyLBS = (getData(thisObj, 'frmContractApplications2009LoadData').lblSeedPremApplyLBS);
      for (var i = 0; i < vsfData.length; i++) {
        if (vsfData[i].txtcolVendorContNum !== '') {
          if (!isNaN(vsfData[i].txtcolStoreLbs) && Number(vsfData[i].txtcolStoreLbs) > 0) {
            let str = vsfData[i].txtcolContractCA + '|'
            str += vsfData[i].txtcolFOrB + '|'
            str += vsfData[i].txtcolStoreLbs + '|T|'
            str += vsfData[i].txtcolPrice + '|'
            str += vsfData[i].txtcolContType + '|'
            //str += vsfData[i].txtcolContType + '|'
            str += vsfData[i].txtcolStoLbsToApply + '|'
            str += Number(vsfData[i].txtcolSeedCont) + '|'
            str += vsfData[i].txtcolVendorCA
            lstNewContractLine.push(str)

            if (vsfData[i].txtcolSeed == 'Y' && vsfData[i].txtcolMeetGenProducedCA == 'Y') {              
              labelSeedPremApplyLBS += (Number(vsfData[i].txtcolSeedLeft) <= Number(vsfData[i].txtcolStoreLbs) ? vsfData[i].txtcolSeedLeft : vsfData[i].txtcolStoreLbs)
              setData(thisObj, 'lblSeedPremApplyLBS', labelSeedPremApplyLBS)
            }
          }

          if (!isNaN(vsfData[i].txtcolPurchLbs) && Number(vsfData[i].txtcolPurchLbs) > 0) {
            let str = vsfData[i].txtcolContractCA + '|'
            str += vsfData[i].txtcolFOrB + '|'
            str += vsfData[i].txtcolPurchLbs + '|A|'
            str += vsfData[i].txtcolPrice + '|'
            str += vsfData[i].txtcolContType + '|'
            str += vsfData[i].txtcolPurLbsToApply + '|'
            str += Number(vsfData[i].txtcolSeedCont) + '|'
            str += vsfData[i].txtcolVendorCA
            lstNewContractLine.push(str)

            if (vsfData[i].txtcolSeed == 'Y' && vsfData[i].txtcolMeetGenProducedCA == 'Y') {
              labelSeedPremApplyLBS += (Number(vsfData[i].txtcolSeedLeft) <= Number(vsfData[i].txtcolPurchLbs) ? vsfData[i].txtcolSeedLeft : vsfData[i].txtcolPurchLbs)
              setData(thisObj, 'lblSeedPremApplyLBS', labelSeedPremApplyLBS)
            }
          }
        }
      }

      if (!isNaN(Number(thisObj.values.txtPrice))) {
        if (!isNaN(Number(thisObj.values.txtLbsToApply))) {
          if (Number(thisObj.values.txtLbsToApply) > 0) {
            lstNewContractLine.push('0|SPOT|' + thisObj.values.txtLbsToApply + '|A|' + (Number(thisObj.values.txtPrice)) + '|S')
          }
        }
      }

      if (!isNaN(thisObj.values.txtUnappliedLbs)) {
        if (Number(thisObj.values.txtUnappliedLbs) > 0) {
          lstNewContractLine.push('0|SPOT|' + thisObj.values.txtUnappliedLbs + '|T|0|S')
        }
      }

      let vsfDataSGA = getValue(thisObj, 'gridSeedGroverApp')
      for (var i = 0; i < vsfDataSGA.length; i++) {
        if (vsfDataSGA[i].txtcolVendorContNumsSG !== '') {
          if (vsfDataSGA[i].txtcolStoPoundsToApplySG === null || vsfDataSGA[i].txtcolStoPoundsToApplySG === undefined) {
            vsfDataSGA[i].txtcolStoPoundsToApplySG = 0
          }
          if ((Number(vsfDataSGA[i].txtcolPurPoundsToApplySG) + Number(vsfDataSGA[i].txtcolStoPoundsToApplySG)) > 0) {
            let str = vsfDataSGA[i].txtcolContract + '|SEED|'
            str += (Number(vsfDataSGA[i].txtcolPurPoundsToApplySG) + Number(vsfDataSGA[i].txtcolStoPoundsToApplySG))
            str += '|S|' + vsfDataSGA[i].txtcolPriceSG + '|A|'
            str += (Number(vsfDataSGA[i].txtcolPurPoundsToApplySG) + Number(vsfDataSGA[i].txtcolStoPoundsToApplySG))
            lstNewContractLine.push(str)
            if(vsfDataSGA[i].txtcolMeetGenProduced === "Y"){
              labelSeedPremApplyLBS = Number(labelSeedPremApplyLBS) + Number(vsfDataSGA[i].txtcolPurPoundsToApplySG) + Number(vsfDataSGA[i].txtcolStoPoundsToApplySG)
              setData(thisObj, 'lblSeedPremApplyLBS', labelSeedPremApplyLBS) 
            }
          }
        }
      }
      setData(thisObj, "lstNewContractLine", lstNewContractLine)
      bFillContractListFlag = true;
    }
    catch (err) {
      console.log(err)
      showMessage(thisObj, err instanceof EvalError ? err.message : 'Something went wrong. Please try again')
      bFillContractListFlag = false;
    }
    finally {
      return bFillContractListFlag;
    }

  }

  const onbtnOkClick = async () => {
    try {
      if (!await bFormValid()) {
        return;
      }
      if (!await bFillContractList()) {
        return;
      }
      let lstNewContractLine = getData(thisObj, "lstNewContractLine")
      // for(var i = 0 ; i < lstNewContractLine.length ; i++){
      setData(thisObj, "lstNewContractLineFromContractApplication2009", lstNewContractLine)
      // }
      setData(thisObj, "lblApplicationsCanceled", false)
      setData(thisObj, "closingOfContractApplication2009",true)
      if (document.getElementById("Settlements_ContractApplications2009PopUp") != null) {
        document.getElementById("Settlements_ContractApplications2009PopUp").childNodes[0].click();
      }
    }
    catch (err) {
      console.log(err)
      showMessage(thisObj, err instanceof EvalError ? err.message : 'Something went wrong. Please try again')
    }
  }
  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnEditAppClick = async () => {
    try {
      setLoading(true)
      lblEditMode = "TRUE"
      await InitContractApplicationsGrid();
      await InitSeedGrowerApplicationsGrid();
      await bFillContractApplications();
      await GetEditPermission();

      if (mbAuthContApplEditAllow === true) {
        readonlyGridColumn(thisObj, "gridViewContractApplication", "txtcolPurchLbs", false)
        readonlyGridColumn(thisObj, "gridViewContractApplication", "txtcolStoreLbs", false)
        let gridSeedGroverAppData = getValue(thisObj, "gridSeedGroverApp")
        if (gridSeedGroverAppData != null && gridSeedGroverAppData != undefined && gridSeedGroverAppData.length > 0) {
          readonlyGridColumn(thisObj, "gridSeedGroverApp", "txtcolPurPoundsToApplySG", false)
          readonlyGridColumn(thisObj, "gridSeedGroverApp", "txtcolStoPoundsToApplySG", false)
        }
        enable(thisObj, "txtUnappliedLbs")
        enable(thisObj, "txtLbsToApply")
      }
      hide(thisObj, "btnEditApp")
      show(thisObj, "btnUndoEdit")
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Edit Application button click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnEditAppClick = onbtnEditAppClick;

  const onbtnUndoEditClick = async () => {
    try {
      setLoading(true)
      lblEditMode = "FALSE"
      setValue(thisObj, "txtUnappliedLbs", "")
      setValue(thisObj, "txtLbsToApply", "")

      await InitContractApplicationsGrid();
      await InitSeedGrowerApplicationsGrid();
      await bFillContractApplications();

      disable(thisObj, "txtUnappliedLbs")
      disable(thisObj, "txtLbsToApply")

      readonlyGridColumn(thisObj, "gridViewContractApplication", "txtcolPurchLbs", true)
      readonlyGridColumn(thisObj, "gridViewContractApplication", "txtcolStoreLbs", true)

      readonlyGridColumn(thisObj, "gridSeedGroverApp", "txtcolPurPoundsToApplySG", true)
      readonlyGridColumn(thisObj, "gridSeedGroverApp", "txtcolStoPoundsToApplySG", true)

      hide(thisObj, "btnUndoEdit")
      show(thisObj, "btnEditApp")
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Undo Edit button click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnUndoEditClick = onbtnUndoEditClick;

  const onbtnPurchaseClick = async () => {
    try {
      let lblPurchaseStorageTag = ""
      let frmContractApplications2009LoadDataObj = getData(thisObj, "frmContractApplications2009LoadData")
      if (frmContractApplications2009LoadDataObj != null && frmContractApplications2009LoadDataObj != undefined) {
        lblPurchaseStorageTag = frmContractApplications2009LoadDataObj.PurchaseStorageTag
      }

      switch (lblPurchaseStorageTag) {
        case "P":
          setValue(thisObj, "txtPrchsStrg", "Storage")
          document.getElementById("btnPurchase").innerText = "Purchase";
          frmContractApplications2009LoadDataObj.PurchaseStorageTag = "S"
          if (lblCMAVendor === "") {
            await ShowPurchaseStorageVendors("S", false)
          } else {
            await ShowPurchaseStorageVendors("S", true)
          }
          break;
        case "S":
          setValue(thisObj, "txtPrchsStrg", "Purchase")
          document.getElementById("btnPurchase").innerText = "Storage";
          frmContractApplications2009LoadDataObj.PurchaseStorageTag = "P"
          if (lblCMAVendor === "") {
             await ShowPurchaseStorageVendors("P", false)
          } else {
             await ShowPurchaseStorageVendors("P", true)
          }
          break;
      }
      setData(thisObj, "frmContractApplications2009LoadData", frmContractApplications2009LoadDataObj)
     await EnableDisableControls()
    } catch (err) {
      setLoading(false);
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during Purchase Storage button click event"
        );
      }
    }
    return true
  }
  thisObj.onbtnPurchaseClick = onbtnPurchaseClick;


  const checkForgridSeedGroverAppRow = (row) => {
    if (_kaledo.thisObj.state.filterColumnsbCMAValue == false) {
      if (_kaledo.thisObj.state.filterColumnsPSValue == "P") {
        if (row.txtcolPurSto1 != "P") {
          return true
        } else {
          return false
        }
      } else if (_kaledo.thisObj.state.filterColumnsPSValue == "S") {
        if (row.txtcolPurSto1 != "S") {
          return true
        } else {
          return false
        }
      }
    } else if (_kaledo.thisObj.state.filterColumnsbCMAValue == true) {
      if (_kaledo.thisObj.state.filterColumnsPSValue == "S") {
        if (Number(row.txtcolVendorLbs1) == 0) {
          return true
        }
        else {
          return false
        }
      } else {
        if (Number(row.txtcolVendorLbs2) == 0) {
          return truev
        }
        else {
          return false
        }
      }
    }
  }


  const checkForgridViewContractApplicationRow = (row) => {
    if (_kaledo.thisObj.state.filterColumnsbCMAValue == false) {
      if (_kaledo.thisObj.state.filterColumnsPSValue == "P") {
        if (row.txtcolPurSto1CA != "P" || (row.txtcolPurSto1CA == "P" && row.txtcolContType == "M")) {
          return true
        } else {
          return false
        }
      } else if (_kaledo.thisObj.state.filterColumnsPSValue == "S") {
        if (row.txtcolPurSto1CA != "S") {
          return true
        } else {
          return false
        }
      }
    } else if (_kaledo.thisObj.state.filterColumnsbCMAValue == true) {
      if (_kaledo.thisObj.state.filterColumnsPSValue == "S") {
        if (Number(row.txtcolVendorLbs1CA) == 0) {
          return true
        } else {
          return false
        }
      } else {
        if (Number(row.txtcolVendorLbs2CA) == 0 || row.txtcolContType === "M") {
          return true
        } else {
          return false
        }
      }
    }
  }

  function ShowPurchaseStorageVendors(sPS, bCMA) {
    if (sPS != "P" && sPS != "S") {
      return;
    }
    thisObj.setState({
      ...thisObj.state,
      filterColumnsPSValue: sPS,
      filterColumnsbCMAValue: bCMA
    })
  }

  const onbtncol1Click = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let gridViewContractApplicationData = getValue(thisObj, 'gridViewContractApplication')

      if (gridViewContractApplicationData[rowID].txtcolVendorContNum != null && gridViewContractApplicationData[rowID].txtcolVendorContNum != undefined && gridViewContractApplicationData[rowID].txtcolVendorContNum != "") {
        let vendorContractNumber = gridViewContractApplicationData[rowID].txtcolVendorContNum;
        if (vendorContractNumber != null && vendorContractNumber != "") {
          let vendorContractNumberArray = vendorContractNumber.split('-')
          if (vendorContractNumberArray != null & vendorContractNumberArray.length > 0 && vendorContractNumberArray[1] != null && vendorContractNumberArray != "") {
            usecommonContext.setContractProfile_ContractNumber(vendorContractNumberArray[1]);
            usecommonContext.setContractProfile_FormState("edit");
            goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
          }
        } else {
          showMessage(thisObj, "Contract does not exist")
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!")
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while opening contract profile page");
      }
    }
  };
  thisObj.onbtncol1Click = onbtncol1Click;

  const onbtncol2Click = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let gridSeedGroverAppData = getValue(thisObj, 'gridSeedGroverApp')

      if (gridSeedGroverAppData[rowID].txtcolVendorContNumsSG != null && gridSeedGroverAppData[rowID].txtcolVendorContNumsSG != undefined && gridSeedGroverAppData[rowID].txtcolVendorContNumsSG != "") {
        let vendorContractNumber = gridSeedGroverAppData[rowID].txtcolVendorContNumsSG;
        if (vendorContractNumber != null && vendorContractNumber != "") {
          let vendorContractNumberArray = vendorContractNumber.split('-')
          if (vendorContractNumberArray != null & vendorContractNumberArray.length > 0 && vendorContractNumberArray[1] != null && vendorContractNumberArray != "") {
            usecommonContext.setContractProfile_ContractNumber(vendorContractNumberArray[1]);
            usecommonContext.setContractProfile_FormState("edit");
            goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
          }
        } else {
          showMessage(thisObj, "Contract does not exist")
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!")
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while opening contract profile page");
      }
    }
  };
  thisObj.onbtncol2Click = onbtncol2Click;

  function setDataForContractApplications2009() {
    let frmContractApplications2009LoadDataObj = {}
    frmContractApplications2009LoadDataObj.FV95Number = "3632630" 
    frmContractApplications2009LoadDataObj.I1007Number = "3632630"
    frmContractApplications2009LoadDataObj.ApplicationsCanceled = "TRUE"
    frmContractApplications2009LoadDataObj.PurchaseStorageCaption = "Purchase"
    frmContractApplications2009LoadDataObj.PurchaseStorageTag = "P"

    frmContractApplications2009LoadDataObj.lblCMAVendor = ""
    frmContractApplications2009LoadDataObj.lblSeedPremApplyLBS = ""
    frmContractApplications2009LoadDataObj.lblCMAAllowUnapplyLbs = "P"

    let vendorSplitDataFrom1007SettlementScreen = []

    let vendorObj = {}
    vendorObj.CboWRNum = ""
    vendorObj.cboPS = "P"
    vendorObj.lblVendorNumber = "357006"
    vendorObj.lblPounds = "24696"
    vendorObj.lblCMAParticipant = "Y"
    vendorSplitDataFrom1007SettlementScreen.push(vendorObj)

    vendorObj = {}
    vendorObj.CboWRNum = ""
    vendorObj.cboPS = "S"
    vendorObj.lblVendorNumber = "850560"
    vendorObj.lblPounds = "16464"
    vendorObj.lblCMAParticipant = "Y"
    vendorSplitDataFrom1007SettlementScreen.push(vendorObj)

    vendorObj = {}
    vendorObj.CboWRNum = ""
    vendorObj.cboPS = "S"
    vendorObj.lblVendorNumber = "M24463"
    vendorObj.lblPounds = "24696"
    vendorObj.lblCMAParticipant = "Y"
    vendorSplitDataFrom1007SettlementScreen.push(vendorObj)

    frmContractApplications2009LoadDataObj.vendorSplitData = vendorSplitDataFrom1007SettlementScreen

    setData(thisObj, "frmContractApplications2009LoadData", frmContractApplications2009LoadDataObj)
  }

  const onbtnCancelClick = () => {
    try {
      //START_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
      document.getElementById("Settlements_ContractApplications2009PopUp").childNodes[0].click();
      //END_USER_CODE-e76b31ec624e9e5b-926e09d64aace5d6/ContractManagement_1a4bcb940c92e3ef_30a70b089f2b5dd2_PreSubmit_EventCode.js
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnClose event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ContractApplications2009*/}

              {/* END_USER_CODE-USER_BEFORE_ContractApplications2009*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxContractApplication2009*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxContractApplication2009*/}

              <GroupBoxWidget
                conf={state.grpbxContractApplication2009}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtSC95Num*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95Num*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95Num}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95Num*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95Num*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007Num*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007Num*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007Num}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007Num*/}

                {/* END_USER_CODE-USER_AFTER_txt1007Num*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPeanutType}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeed*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeed*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeed}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeed*/}

                {/* END_USER_CODE-USER_AFTER_txtSeed*/}
                {/* START_USER_CODE-USER_BEFORE_txtSeedSpec*/}

                {/* END_USER_CODE-USER_BEFORE_txtSeedSpec*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSeedSpec}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSeedSpec*/}

                {/* END_USER_CODE-USER_AFTER_txtSeedSpec*/}
                {/* START_USER_CODE-USER_BEFORE_txtTentPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtTentPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtTentPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtTentPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtTentPounds*/}
                {/* START_USER_CODE-USER_BEFORE_txtApplyPounds*/}

                {/* END_USER_CODE-USER_BEFORE_txtApplyPounds*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtApplyPounds}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtApplyPounds*/}

                {/* END_USER_CODE-USER_AFTER_txtApplyPounds*/}
                {/* START_USER_CODE-USER_BEFORE_txtPrchsStrg*/}

                {/* END_USER_CODE-USER_BEFORE_txtPrchsStrg*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPrchsStrg}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPrchsStrg*/}

                {/* END_USER_CODE-USER_AFTER_txtPrchsStrg*/}
                {/* START_USER_CODE-USER_BEFORE_lblContractApplications*/}

                {/* END_USER_CODE-USER_BEFORE_lblContractApplications*/}

                <LabelWidget
                  values={values}
                  conf={state.lblContractApplications}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblContractApplications*/}

                {/* END_USER_CODE-USER_AFTER_lblContractApplications*/}
                {/* START_USER_CODE-USER_BEFORE_gridViewContractApplication*/}

                {/* END_USER_CODE-USER_BEFORE_gridViewContractApplication*/}

                <GridWidget
                  conf={state.gridViewContractApplication}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridViewContractApplication}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridViewContractApplication*/}

                {/* END_USER_CODE-USER_AFTER_gridViewContractApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                <GroupBoxWidget
                  conf={state.grpbxSpotApplication}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSpotApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSpotApplications*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSpotApplications}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSpotApplications*/}

                  {/* END_USER_CODE-USER_AFTER_lblSpotApplications*/}
                  {/* START_USER_CODE-USER_BEFORE_lblUnappliedLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_lblUnappliedLbs*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblUnappliedLbs}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblUnappliedLbs*/}

                  {/* END_USER_CODE-USER_AFTER_lblUnappliedLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLbsToApply}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_AFTER_txtLbsToApply*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUnappliedLbs*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUnappliedLbs*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUnappliedLbs}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUnappliedLbs*/}

                  {/* END_USER_CODE-USER_AFTER_txtUnappliedLbs*/}
                  {/* START_USER_CODE-USER_BEFORE_txtPrice*/}

                  {/* END_USER_CODE-USER_BEFORE_txtPrice*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtPrice}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtPrice*/}

                  {/* END_USER_CODE-USER_AFTER_txtPrice*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSpotApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSeedGroverApp*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSeedGroverApp*/}

                <GroupBoxWidget
                  conf={state.grpbxSeedGroverApp}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSeedGroverApp*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSeedGroverApp*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSeedGroverApp}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSeedGroverApp*/}

                  {/* END_USER_CODE-USER_AFTER_lblSeedGroverApp*/}
                  {/* START_USER_CODE-USER_BEFORE_gridSeedGroverApp*/}

                  {/* END_USER_CODE-USER_BEFORE_gridSeedGroverApp*/}

                  <GridWidget
                    conf={state.gridSeedGroverApp}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridSeedGroverApp}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    refObject={thisObj}
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridSeedGroverApp*/}

                  {/* END_USER_CODE-USER_AFTER_gridSeedGroverApp*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSeedGroverApp*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSeedGroverApp*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxContractApplication2009*/}

              {/* END_USER_CODE-USER_AFTER_grpbxContractApplication2009*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnEditApp*/}

                {/* END_USER_CODE-USER_BEFORE_btnEditApp*/}

                <ButtonWidget
                  conf={state.btnEditApp}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEditApp*/}

                {/* END_USER_CODE-USER_AFTER_btnEditApp*/}
                {/* START_USER_CODE-USER_BEFORE_btnUndoEdit*/}
                {/* END_USER_CODE-USER_BEFORE_btnUndoEdit*/}
                <ButtonWidget
                  conf={state.btnUndoEdit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUndoEdit*/}
                {/* END_USER_CODE-USER_AFTER_btnUndoEdit*/}
                {/* START_USER_CODE-USER_BEFORE_btnPurchase*/}

                {/* END_USER_CODE-USER_BEFORE_btnPurchase*/}

                <ButtonWidget
                  conf={state.btnPurchase}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPurchase*/}

                {/* END_USER_CODE-USER_AFTER_btnPurchase*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ContractApplications2009*/}

              {/* END_USER_CODE-USER_AFTER_ContractApplications2009*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ContractApplications2009);
